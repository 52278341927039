import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { colors, TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const ConsolidateTable = (props) => {
  const [t, i18n] = useTranslation("common");
  let e = []


  const HorizontalRow = ({ data, isTotal }) => (
    <TableRow>
      <VBTableCellBody sx={{color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 500 : 400}}> {data.particular}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right' }}>{data.subTotal}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right', borderRight: "1px solid #e6e6e6",color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 500 : 400 }}>{data.total}</VBTableCellBody>
      <VBTableCellBody sx={{color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 500 : 400}}> {data.secondParticular}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right' }}>{data.secondSubTotal}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right',color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 500 : 400 }}> {data.secondTotal} </VBTableCellBody>
    </TableRow>
  );

  const VeriticalRow = ({ data, isTotal }) => (
    <TableRow>
      <VBTableCellBody sx={{color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 600 : 400}}> {data.particular}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right' }}>{data.subTotal}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right',color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 500 : 400 }}>{data.total}</VBTableCellBody>
      <VBTableCellBody > </VBTableCellBody>
      <VBTableCellBody> </VBTableCellBody>
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
    </TableRow>
  );
  
  const tading_account = [
    ...(props.filterOptions.includeCostOfGoodsSold ? [
      {
        particular: 'Cost Of Goods Sold',
        subTotal: '',
        total: props.data?.CostOfGoodsSold,
        secondParticular: 'Sales Account',
        secondSubTotal: '',
        secondTotal: props.data?.Sales,
        is_heading:true,
      },
      {
        particular: 'Opening Inventory',
        subTotal: props?.data?.TotalOpeningStockValue,
        total: '',
        secondParticular: 'Direct Income',
        secondSubTotal: '',
        secondTotal: props.data?.DirectIncome,
      },
      {
        particular: 'Purchase Account',
        subTotal: props.data?.Purchase,
        total: '',
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: '',
      },
      {
        particular: 'Direct Expense',
        subTotal: props.data?.DirectExpence,
        total: '',
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: '',
      },
      {
        particular: 'Less Closing Inventory',
        subTotal: `(${(props.data?.TotalClosingStockValue)})`,
        total: '',
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: '',
      },
  ] : [
    {
      particular: 'Opening Stock',
      subTotal: '',
      total: props?.data?.TotalOpeningStockValue,
      secondParticular: 'Sales Account',
      secondSubTotal: '',
      secondTotal: props.data?.Sales,
      is_heading:true,
    },
    {
      particular: 'Purchase Account',
      subTotal: '',
      total: props.data?.Purchase,
      secondParticular: 'Closing Stock',
      secondSubTotal: '',
      secondTotal: props.data?.TotalClosingStockValue,
      is_heading:true,
    },
    {
      particular: 'Direct Expense',
      subTotal: '',
      total: props.data?.DirectExpence,
      secondParticular: 'Direct Income',
      secondSubTotal: '',
      secondTotal: props.data?.DirectIncome,
      is_heading:true,
    },
    {
      particular: ' ',
      subTotal: '',
      total: ' ',
      secondParticular: '',
      secondSubTotal: '',
      secondTotal: ' ',
    },]),

    ...(props.data?.GrossProfit > 0 ? [
      {
        particular: 'Gross Profit c/o',
        subTotal: '',
        total: props.data?.GrossProfit,
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: ' ',
        color : 'green',
        is_heading:true,
      },
    ] : [
      {
        particular: '',
        subTotal: '',
        total: '',
        secondParticular: 'Gross Loss c/o',
        secondSubTotal: '',
        secondTotal: props.data?.GrossLoss,
        color : 'red',
        is_heading:true,
      },
    ]),
    ...(props.filterOptions.includeCostOfGoodsSold ? [
      {
        particular: '',
        subTotal: '',
        total: props.data?.GrossTotalInCostOfGoodsSold,
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: props.data?.GrossTotalInCostOfGoodsSold,
        is_heading:true,

      },
    ] : [
      {
        particular: '',
        subTotal: '',
        total: props.data?.GrossTotal,
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: props.data?.GrossTotal,
        is_heading:true,

      },
    ])
  ];
  const profit_and_loss = [
    ...(props.data?.GrossProfit > 0 ? [
      {
        particular: '',
        subTotal: '',
        total: '',
        secondParticular: 'Gross Profit b/f',
        secondSubTotal: '',
        secondTotal: props.data?.GrossProfit,
        is_heading:true,

      },
    ] : [
      {
        particular: 'Gross Loss b/f',
        subTotal: '',
        total: props.data?.GrossLoss,
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: ' ',
        is_heading:true,

      },
    ]),
    {
      particular: 'Indirect Expense',
      subTotal: '',
      total: props.data?.IndirectExpence,
      secondParticular: 'Indirect Income',
      secondSubTotal: '',
      secondTotal: props.data?.IndirectIncome,
      is_heading:true,
    },


    ...(props.data?.NetProfit > 0 ? [
      {
        particular: 'Net Profit',
        subTotal: '',
        total: props.data?.NetProfit,
        secondParticular: '',
        secondSubTotal: '',
        secondTotal: '',
        color : 'green',
        is_heading:true,
      },
    ] : [
      {
        particular: '',
        subTotal: '',
        total: '',
        secondParticular: 'Net Loss',
        secondSubTotal: '',
        secondTotal: props.data?.NetLoss,
        color : 'red',
        is_heading:true,
      },
    ]),
    {
      particular: 'Total',
      subTotal: '',
      total: props.data?.NetTotal,
      secondParticular: 'Total',
      secondSubTotal: '',
      secondTotal: props.data?.NetTotal,
      is_heading:true,
    },
  ];


  const veritical_trading_account= [
    {
      particular: 'Sales',
      subTotal: '',
      total: props.data?.Sales,
    },
    {
      particular: 'Carriage outward (Sales)',
      subTotal: '',
      total: props.data?.DirectIncome,
    },
    {
      particular: '',
      subTotal: '',
      total: '',
    },
    {
      particular: 'Less COST',
      subTotal: '',
      total: '',
      is_heading : true,
    },
    {
      particular: 'Opening Inventory',
      subTotal: props?.data?.TotalOpeningStockValue,
      total: '',
    },
    {
      particular: 'Add Purchase',
      subTotal: props.data?.Purchase,
      total: '',
    },
    {
      particular: 'Add Carriage inward (Purchase)',
      subTotal: props.data?.DirectExpence,
      total: '',
    },
    {
      particular: 'Less Closing Inventory',
      subTotal: `(${(props.data?.TotalClosingStockValue)})`,
      total: '',
    },
    {
      particular: '',
      subTotal: '',
      total: '',
    },
    {
      particular: 'Cost of goods sold',
      subTotal: '',
      total: props.data?.CostOfGoodsSold,
      is_heading : true,
    },
    {
      particular: '',
      subTotal: '',
      total: '',
    },
    ...(props.data?.GrossProfit > 0 ? [
    {
      particular: 'Gross Profit',
      subTotal: '',
      total: props.data?.GrossProfit,
      is_heading : true,
      color : 'green',
    },
    ] : [
      {
        particular: 'Gross Loss',
        subTotal: '',
        total: props.data?.GrossLoss,
        is_heading : true,
        color : 'red',
      },
    ])
  ]
  const veritical_profit_and_Loss = [
    ...(props?.filterOptions?.filterValue === 'ProfitAndLossAccount' ? [
      ...(props.data?.GrossProfit > 0 ? [
        {
          particular: 'Gross Profit',
          subTotal: '',
          total: props.data?.GrossProfit,
          is_heading : true,
          color : 'green',
        },
        ] : [
          {
            particular: 'Gross Loss',
            subTotal: '',
            total: props.data?.GrossLoss,
            is_heading : true,
            color : 'red',
          },
        ])
  
  ] : []),
    {
      particular: '',
      subTotal: '',
      total: '',
    },
    {
      particular: 'Add Other Incomes',
      subTotal: '',
      total: props.data?.IndirectIncome,
      is_heading : true,
    },
    {
      particular: 'Less Operating Expenses',
      subTotal: '',
      is_heading : true,
      total: props.data?.IndirectExpence,
    },
    {
      particular: '',
      subTotal: '',
      total: '',
    },
    ...(props.data?.NetProfit > 0 ? [
      {
        particular: 'Net Profit',
        subTotal: '',
        total: props.data?.NetProfit,
        is_heading : true,
        color : 'green',
  
      },
    ] : [
      {
        particular: 'Net Loss',
        subTotal: '',
        total: props.data?.NetLoss,
        is_heading : true,
        color : 'red',
  
      },
    ])
  ]
  const horizontal_data = props?.filterOptions?.filterValue === 'All' ? [...tading_account, ...profit_and_loss] : props?.filterOptions?.filterValue === 'TradingAccount' ? tading_account : profit_and_loss ;
  const veritical_data = props?.filterOptions?.filterValue === 'All' ? [...veritical_trading_account, ...veritical_profit_and_Loss] : props?.filterOptions?.filterValue === 'TradingAccount' ? veritical_trading_account : veritical_profit_and_Loss ;
  return (
    <VBTableContainer>

    {props.isLoading ? <VBSkeletonLoader /> 
    : props?.filterOptions?.filterType === 'horizontal' ? 
    < >
      <TableRow>
        <VBTableCellHeader sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> TOTAL</VBTableCellHeader>

        <VBTableCellHeader sx={{ fontWeight: "bold"}}>PARTICULAR</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right"}}>SUB TOTAL</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right" }}> TOTAL</VBTableCellHeader>
      </TableRow>

      <TableBody>
        {horizontal_data?.map((row, index) => (
          <HorizontalRow
            key={index}
            data={row}
            isTotal={row.particular === 'Total' || row.secondParticular === 'Total'}
          />
        ))}
        <TableRow></TableRow>
      </TableBody>
    </>
    : 
    <TableBody>
      <TableRow>
        <VBTableCellHeader sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right"}}> TOTAL</VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>               
        <VBTableCellHeader > </VBTableCellHeader>               
      </TableRow>

      {veritical_data?.map((row,index) => (
        <VeriticalRow
          key={index}
          data={row}
          isTotal={row.particular === 'Total' || row.secondParticular === 'Total'}
        />
      ))}

      <TableRow></TableRow>
  </TableBody>
  }
  </VBTableContainer>
  )
}

export default ConsolidateTable