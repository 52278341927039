import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";
import { store } from '../../app/store';




const getNewPriceList = async function (body, dispatch) {
    try {
      const { data } = await axios.post("api/v11/priceLists/get-pricelist-list/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on getNewProductList API", error);
      dispatch(
        openSnackbar({
          open: true,
          message: error.response?.data?.message || "Error Occurred In API",
          severity: "error",
        })
      );
      return error
    }
  };
  
  
  const getSinglePriceList = async function (body, dispatch) {
    try {
      const { data } = await axios.post("api/v11/priceLists/single-priceList/", {
        ...body,
      });
      return data;
    } catch (error) {
      console.log("error on getNewProductList API", error);
      dispatch(
        openSnackbar({
          open: true,
          message: error.response?.data?.message || "Error Occurred In API",
          severity: "error",
        })
      );
      return error
    }
  };
  
  
  
export {
  getNewPriceList,
  getSinglePriceList,
}