import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { Lite, Essential, Standard } from "../../Edition/edition";
import {  useSelector } from "react-redux";
import { VIKNBOOKS_FRONT_URL } from "../../generalVeriable";

const ReportsList = () => {
  // const dispatch = useDispatch()
  const {
    EnableProductBatchWise,
    EnableFaeraSettings,
    GST,
    VAT,
    EnableVbassist,
    EnableEinvoiceKSA
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { Edition, BranchID, BusinessTypeName } = useSelector((state) => state.companyDetails );
  const { acts, userPermission } = useSelector((state) => state.rollPermessions)

  const trialBalanceView = userPermission["Trial Balance"].view_permission
  const profitAndLossView = userPermission["Profit And Loss Account"].view_permission;
  const balanceSheetView = userPermission["Balance Sheet"].view_permission;
  const outStandingReportView = userPermission["Outstanding Report"].view_permission;
  const dailyReportView = userPermission["Daily Report"].view_permission;
  const cashBookView = userPermission["Cash Book"].view_permission;
  const bankBookView = userPermission["Bank Book"].view_permission;
  const receiptReportView = userPermission["Receipt Report"].view_permission;
  const paymentReportView = userPermission["Payment Report"].view_permission;
  const ledgerReportView = userPermission["Ledger Report"].view_permission;
  const billWiseReportView = userPermission["Bill Wise Profit Report"].view_permission;
  const faeraReportView = userPermission["Faera Report"].view_permission;
  const salesReportView = userPermission["Sales Report"].view_permission;
  const salesSummaryReportView = userPermission["Sales Summary Report"].view_permission;
  const salesRegisterReportView = userPermission["Sales Register Report"].view_permission;
  const salesReturnRegisterView = userPermission["Sales Return Register Report"].view_permission;
  const salesIntegratedReportView = userPermission["Sales Integrated Report"].view_permission;
  const purchaseIntegratedReportView = userPermission["Purchase Integrated Report"].view_permission;
  const purchaseReportView = userPermission["Purchase Report"].view_permission;
  const purchaseOrderReportView = userPermission["Purchase Order Report"].view_permission;
  const purchaseRegisterReportView = userPermission["Purchase Register Report"].view_permission;
  const purchaseReturnRegisterView = userPermission["Purchase Return Register Report"].view_permission;
  const supplierVsProductView = userPermission["Supplier Vs Product Report"].view_permission;
  const stockReportView = userPermission["Stock Report"].view_permission;
  const stockValueReportView = userPermission["Stock Value Report"].view_permission;
  const stockLedgerReportView = userPermission["Stock Ledger Report"].view_permission;
  const productReportView = userPermission["Product Report"].view_permission;
  const productAnalysisReportView = userPermission["Product Analysis Report"].view_permission;
  const batchWiseReportView = userPermission["Batch Wise Report"].view_permission;
  const batchReportView = userPermission["Batch Report"].view_permission;
  const excessStockReportView = userPermission["Excess Stock Report"].view_permission;
  const shortageStockView = userPermission["Shortage Stock Report"].view_permission;
  const openingStockView = userPermission["Opening Stock Report"].view_permission;
  const taxSummaryView = userPermission["Tax Summary"].view_permission;
  const vatReturnView = userPermission["VAT Return"].view_permission;
  const vatReportView = userPermission["VAT Return"].view_permission;
  const salesVATReportView = userPermission["Sales VAT Report"].view_permission;
  const purchaseVATReportView = userPermission["Purchase VAT Report"].view_permission;
  const GSTR1ReportView = userPermission["GSTR1 Report"].view_permission;
  const salesGSTReportView = userPermission["Sales GST Report"].view_permission;
  const purchaseGSTReportView = userPermission["Purchase GST Report"].view_permission;
  const expenseSummaryView = userPermission["Expense Summary"].view_permission;
  const quickReportView = userPermission["Quick Report"].view_permission;
  const debtorAgeingReportView = userPermission["Debtor Ageing Report"].view_permission;
  const creditorAgeingReportView = userPermission["Creditor Ageing Report"].view_permission;
  const salesOrderReportView = userPermission["Sales Order Report"].view_permission;
  const productSummaryReportView = userPermission["Product Summary Report"].view_permission;
  const stockTransferRegisterReportView = userPermission["Stock Transfer Register"].view_permission;
  // const BranchTransferReportView = userPermission["Branch Transfer Report"].view_permission;



  // const dailyReportView =
  //   user_role_settings.find((item) => item.name === "Daily Report")
  //     ?.view_permission ?? true;
  // const cashBookView =
  //   user_role_settings.find((item) => item.name === "Cash Book")
  //     ?.view_permission ?? true;
  // const bankBookView =
  //   user_role_settings.find((item) => item.name === "Bank Book")
  //     ?.view_permission ?? true;
  // const receiptReportView =
  //   user_role_settings.find((item) => item.name === "Receipt Report")
  //     ?.view_permission ?? true;
  // const paymentReportView =
  //   user_role_settings.find((item) => item.name === "Payment Report")
  //     ?.view_permission ?? true;
  // const ledgerReportView =
  //   user_role_settings.find((item) => item.name === "Ledger Report")
  //     ?.view_permission ?? true;
  // const billWiseReportView =
  //   user_role_settings.find((item) => item.name === "Bill Wise Profit Report")
  //     ?.view_permission ?? true;
  // const faeraReportView =
  //   user_role_settings.find((item) => item.name === "Faera Report")
  //     ?.view_permission ?? true;
  // const salesReportView =
  //   user_role_settings.find((item) => item.name === "Sales Report")
  //     ?.view_permission ?? true;
  // const salesSummaryReportView =
  //   user_role_settings.find((item) => item.name === "Sales Summary Report")
  //     ?.view_permission ?? true;
  // const salesRegisterReportView =
  //   user_role_settings.find((item) => item.name === "Sales Register Report")
  //     ?.view_permission ?? true;
  // const salesReturnRegisterView =
  //   user_role_settings.find(
  //     (item) => item.name === "Sales Return Register Report"
  //   )?.view_permission ?? true;
  // const salesIntegratedReportView =
  //   user_role_settings.find((item) => item.name === "Sales Integrated Report")
  //     ?.view_permission ?? true;
  // const purchaseIntegratedReportView =
  //   user_role_settings.find(
  //     (item) => item.name === "Purchase Integrated Report"
  //   )?.view_permission ?? true;
  // const purchaseReportView =
  //   user_role_settings.find((item) => item.name === "Purchase Report")
  //     ?.view_permission ?? true;
  // const purchaseOrderReportView =
  //   user_role_settings.find((item) => item.name === "Purchase Order Report")
  //     ?.view_permission ?? true;
  // const purchaseRegisterReportView =
  //   user_role_settings.find((item) => item.name === "Purchase Register Report")
  //     ?.view_permission ?? true;
  // const purchaseReturnRegisterView =
  //   user_role_settings.find(
  //     (item) => item.name === "Purchase Return Register Report"
  //   )?.view_permission ?? true;
  // const supplierVsProductView =
  //   user_role_settings.find(
  //     (item) => item.name === "Supplier Vs Product Report"
  //   )?.view_permission ?? true;
  // const stockReportView =
  //   user_role_settings.find((item) => item.name === "Stock Report")
  //     ?.view_permission ?? true;
  // const stockValueReportView =
  //   user_role_settings.find((item) => item.name === "Stock Value Report")
  //     ?.view_permission ?? true;
  // const stockLedgerReportView =
  //   user_role_settings.find((item) => item.name === "Stock Ledger Report")
  //     ?.view_permission ?? true;
  // const productReportView =
  //   user_role_settings.find((item) => item.name === "Product Report")
  //     ?.view_permission ?? true;
  // const productAnalysisReportView =
  //   user_role_settings.find((item) => item.name === "Product Analysis Report")
  //     ?.view_permission ?? true;
  // const batchWiseReportView =
  //   user_role_settings.find((item) => item.name === "Batch Wise Report")
  //     ?.view_permission ?? true;
  // const batchReportView =
  //   user_role_settings.find((item) => item.name === "Batch Report")
  //     ?.view_permission ?? true;
  // const excessStockReportView =
  //   user_role_settings.find((item) => item.name === "Excess Stock Report")
  //     ?.view_permission ?? true;
  // const shortageStockView =
  //   user_role_settings.find((item) => item.name === "Shortage Stock Report")
  //     ?.view_permission ?? true;
  // const openingStockView =
  //   user_role_settings.find((item) => item.name === "Opening Stock Report")
  //     ?.view_permission ?? true;
  // const taxSummaryView =
  //   user_role_settings.find((item) => item.name === "Tax Summary")
  //     ?.view_permission ?? true;
  // const vatReturnView =
  //   user_role_settings.find((item) => item.name === "VAT Return")
  //     ?.view_permission ?? true;
  // const vatReportView =
  //   user_role_settings.find((item) => item.name === "VAT Return")
  //     ?.view_permission ?? true;
  // const salesVATReportView =
  //   user_role_settings.find((item) => item.name === "Sales VAT Report")
  //     ?.view_permission ?? true;
  // const purchaseVATReportView =
  //   user_role_settings.find((item) => item.name === "Purchase VAT Report")
  //     ?.view_permission ?? true;
  // const GSTR1ReportView =
  //   user_role_settings.find((item) => item.name === "GSTR1 Report")
  //     ?.view_permission ?? true;
  // const salesGSTReportView =
  //   user_role_settings.find((item) => item.name === "Sales GST Report")
  //     ?.view_permission ?? true;
  // const purchaseGSTReportView =
  //   user_role_settings.find((item) => item.name === "Purchase GST Report")
  //     ?.view_permission ?? true;
  // const expenseSummaryView =
  //   user_role_settings.find((item) => item.name === "Expense Summary")
  //     ?.view_permission ?? true;
  // const quickReportView =
  //   user_role_settings.find((item) => item.name === "Quick Report")
  //     ?.view_permission ?? true;
  // const debtorAgeingReportView =
  //   user_role_settings.find((item) => item.name === "Debtor Ageing Report")
  //     ?.view_permission ?? true;
  // const creditorAgeingReportView =
  //   user_role_settings.find((item) => item.name === "Creditor Ageing Report")
  //     ?.view_permission ?? true;
  // const salesOrderReportView =
  //   user_role_settings.find((item) => item.name === "Sales Order Report")
  //     ?.view_permission ?? true;
  // const productSummaryReportView =
  //   user_role_settings.find((item) => item.name === "Product Summary Report")
  //     ?.view_permission ?? true;
  // const stockTransferRegisterReportView =
  //   user_role_settings.find((item) => item.name === "Stock Transfer Register")
  //     ?.view_permission ?? true;
  // const BranchTransferReportView = 
  // user_role_settings.find((item) => item.name === "Branch Transfer Report")
  // ?.view_permission ?? true;


  const activateFunction = function (name) {
    let is_Active = true;
    if (Edition === "Lite" && (Lite[name] === false || Lite[name] === true)) {
      is_Active = Lite[name];
    } else if (
      Edition === "Essential" &&
      (Essential[name] === false || Essential[name] === true)
    ) {
      // else if ((Essential[name]===false || Essential[name]===true)){
      is_Active = Essential[name];
    } else if (
      Edition === "Standard" &&
      (Standard[name] === false || Standard[name] === true)
    ) {
      // }else if(Edition==="Standard"&& (Standard[name]===false || Standard[name]===true)){
      is_Active = Standard[name];
    }
    // if () {

    // }
    return is_Active;

    // if (name === "branch transfer report" && name === "batch report") {
    //   return false;
    // }
    // return true;
  };

  let row1 = {
    column1: [
      {
        name: "trial balance",
        link: "trial-balance",
        is_Active: activateFunction("TrialBalance") && trialBalanceView,
      },
      {
        name: "balance sheet",
        link: "balance-sheet",
        is_Active: activateFunction("BalanceSheet") && balanceSheetView,
      },
      {
        name: "profit and loss account",
        link: "profit-and-loss-account",
        is_Active:
          activateFunction("TradingProfitAndLossAccount") && profitAndLossView,
      },
      {
        name: "outstanding report",
        link: "outstanding-report",
        is_Active:
          activateFunction("OutstandingReport") && outStandingReportView,
      },
      {
        name: "expense summary",
        link: "expense-summary",
        is_Active: activateFunction("ExpenseSummary") && BranchID === 1,
      },
    ],
    column2: [
      {
        name: "stock report",
        link: "stock-report",
        is_Active: activateFunction("StockReport") && stockReportView,
      },
      {
        name: "stock ledger report",
        link: "stock-ledger-report",
        is_Active:
          activateFunction("StockLedgerReport") && stockLedgerReportView,
      },
      {
        name: "stock value report",
        link: "stock-value-report",
        is_Active: activateFunction("StockValueReport") && stockValueReportView,
      },
      {
        name: "product summary report",
        link: "product-summary",
        is_Active:
          activateFunction("ProductSummary") && productSummaryReportView,
      },
      {
        name: "Product Serialisation Report",
        link: "product-serialisation-report",
        is_Active: true, // activateFunction("Product Serialisation Report"),
      },
      {
        name: "Product Warehouse Report",
        link: "product-warehouse-report",
        is_Active: true, // activateFunction("Product Serialisation Report"),
      },

      {
        name: "opening stock report",
        link: "opening-stock-report",
        is_Active: activateFunction("OpeningStockReport") && openingStockView,
      },
      {
        name: "batch report",
        link: "batch-report",
        is_Active: activateFunction("batch report") && batchReportView && EnableProductBatchWise,
      },
      {
        name: "stock transfer register report",
        link: "stock-transfer-register-report",
        is_Active:
          activateFunction("StockTransferRegister") &&
          stockTransferRegisterReportView,
      },
      {
        name: "branch transfer report",
        link: "branch-transfer-report",
        is_Active: activateFunction("branch transfer report"),
      },
    ],
  };

  let row2 = {
    column1: [
      {
        name: "Day Book",
        link: "day-report",
        is_Active: activateFunction("DayReport"),
      },
      {
        name: "cash book",
        link: "cashbook",
        is_Active: activateFunction("CashBook") && cashBookView,
      },
      {
        name: "bank book",
        link: "bankbooks",
        is_Active: activateFunction("BankBook") && bankBookView,
      },
      {
        name: "daily report",
        is_Active: activateFunction("DailyReport") && dailyReportView,
        link: "daily-report",
      },
      {
        name: "receipt report",
        is_Active: activateFunction("ReceiptReport") && receiptReportView,
        link: "receipt-report",
      },
      {
        name: "payment report",
        is_Active: activateFunction("PaymentReport") && paymentReportView,
        link: "payment-report",
      },
      {
        name: "expense report",
        is_Active: activateFunction("ExpenseReport"),
        link: "expense-report",
      },
      {
        name: "ledger report",
        is_Active: activateFunction("LedgerReport") && ledgerReportView,
        link: "ledger-report",
      },
      {
        name: "chart of accounts",
        is_Active:true,
        link: "chart-of-account-report",
      },
      {
        name: "bill wise profit report",
        is_Active: activateFunction("BillwiseProfit") && billWiseReportView,
        link: "billwise-profit-report",
      },
      // {
      //   name: "bank reconciliation report",
      //   is_Active: activateFunction("BankReconciliationReport"),
      //   link: "bank-reconciliation-report",
      // },
      {
        name: "bill wise report",
        is_Active: activateFunction("BillwiseProfit") && billWiseReportView,
        link: "billwise-report",
      },
      {
        name: "faera report",
        is_Active:
          EnableFaeraSettings === true && faeraReportView ? true : false,
        link: "faera-report",
      },
      {
        name: "quick report",
        is_Active: activateFunction("QuickReport") && quickReportView,
        link: "quick-report",
      },
      {
        name: "Debtor Ageing report",
        is_Active:
          activateFunction("DebtorAgeingreport") && debtorAgeingReportView,
        link: "debtor-ageing-report",
      },
      {
        name: "Creditor Ageing report",
        is_Active:
          activateFunction("CreditorAgeingreport") && creditorAgeingReportView,
        link: "creditor-ageing-report",
      },
      {
        name: "Debit Note Report",
        is_Active: activateFunction("DebitNote"),
        link: "debit-note-report",
      },
      {
        name: "Credit Note Report",
        is_Active: activateFunction("CreditNote"),
        link: "credit-note-report",
      },
      {
        name: "VB Assist report",
        is_Active: EnableVbassist && activateFunction(),
        link: "vb-assist-report",
      },
    ],
    column2: [
      {
        name: "tax summary",
        is_Active: activateFunction("TaxSummary") && taxSummaryView,
        link: "tax-summery",
      },
      {
        name: VAT ? "VAT Return" : "TAX Return",
        is_Active: VAT ? activateFunction("VATReturn") && vatReturnView : false,
        link: VAT ? "vat-return" : "tax-return",
      },
      {
        name: "vat report",
        is_Active: VAT ? activateFunction("VATReport") && vatReportView : false,
        link: "vat-report",
      },
      {
        name: "sales gst report",
        is_Active: GST && GSTR1ReportView ? activateFunction("GSTR1") : false,
        link: "sales-gst-report",
      },
      {
        name: "purchase gst report",
        is_Active: GST ? activateFunction("GSTR1") : false,
        link: "gst-purchase-report",
      },
      {
        name: "Zatca E-Invoice Report",
        is_Active:EnableEinvoiceKSA ? activateFunction("GSTR1") : false,
        link: "list-e-invoice",
      },
    ],
  };

  let row3 = {
    column1: [
      {
        name: "sales report",
        is_Active: activateFunction("SalesReport") && salesReportView,
        link: "sales-report",
      },
      {
        name: "sales order report",
        is_Active: activateFunction("SalesOrderReport") && salesOrderReportView,
        link: "sales-order-report",
      },
      {
        name: "sales summary report",
        is_Active: activateFunction("SalesSummary") && salesSummaryReportView,
        link: "sales-summery-report",
      },
      {
        name: "sales register report",
        is_Active:
          activateFunction("SalesRegisterReport") && salesRegisterReportView,
        link: "sales-register-report",
      },
      {
        name: "sales return register report",
        is_Active:
          activateFunction("SalesRegisterReport") && salesReturnRegisterView,
        link: "sales-return-register-report",
      },
      {
        name: "sales integrated report",
        is_Active:
          activateFunction("SalesIntegratedReport") &&
          salesIntegratedReportView,
        link: "sales-integrated-report",
      },
    ],
  };

  let row4 = {
    column1: [
      {
        name: "purchase report",
        is_Active: activateFunction("PurchaseReport") && purchaseReportView,
        link: "purchase-report",
      },
      {
        name: "purchase order report",
        is_Active:
          activateFunction("PurchaseOrderReport") && purchaseOrderReportView,
        link: "purchase-order-report",
      },
      {
        name: "supplier vs product report",
        is_Active:
          activateFunction("SupplierVsProductReport") && supplierVsProductView,
        link: "supplier-vs-product-report",
      },
      {
        name: "purchase register report",
        is_Active:
          activateFunction("PurchaseRegisterReport") &&
          purchaseRegisterReportView,
        link: "purchase-register-report",
      },
      {
        name: "purchase return register report",
        is_Active:
          activateFunction("PurchaseRegisterReport") &&
          purchaseReturnRegisterView,
        link: "purchase-return-register-report",
      },
      {
        name: "purchase integrated report",
        is_Active:
          activateFunction("PurchaseIntegratedReport") &&
          purchaseIntegratedReportView,
        link: "purchase-integrated-report",
      },
    ],
  };

  const [t, i18n] = useTranslation("common");

  function capitalizeWord(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => setLoading(false), 1000);

  //   return () => clearTimeout(timer); // Cleanup function
  // }, []);

  // console.log(row1.column1.length);
  // console.log(row1.column1);
  return (
    <Box sx={{ padding: "5px", height: "100%" }}>
      <Box sx={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Typography
            sx={{
              color: "#001746",
              fontWeight: "bold",
              fontSize: "16px",
              mb: 1,
            }}
          >
            {t("Reports")}
          </Typography>
          {/* <Tooltip title="You can only access the old style for a limited time.">
            <Button
              variant="outlined"
              size="small"
              style={{ opacity: "0.1" }}
              onClick={() => {
                window.location.href =
                  VIKNBOOKS_FRONT_URL + "dashboard/reports";
              }}
            >
              Old Style
            </Button>
          </Tooltip> */}
        </div>

        {/* <Zoom in={!loading}> */}
        {!acts.isLoading && <Paper sx={{ width: "100%", height: "96%", p: 2, overflowY: "scroll" }}>
          <Grid
            container
            sx={{ height: "100%" }}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
                {row1.column1.some(obj => obj.is_Active === true)  ?
                <Grid item xs={12}>
                  <Paper sx={{p: 2,}}>
                    <ReportTitle>{t("Financial Reports")}</ReportTitle>

                    {row1.column1
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>{capitalizeWord(t(item.name))}</ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>:null}

                <Grid item xs={12}>
                  <Paper sx={{ p: 2, }}>
                    <ReportTitle>
                      {t("Inventory")}
                    </ReportTitle>

                    {row1.column2
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>
                            {capitalizeWord(t(item.name))}
                          </ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, }}>
                    <ReportTitle>
                      {t("Accounts")}
                    </ReportTitle>

                    {row2.column1
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>
                            {capitalizeWord(t(item.name))}
                          </ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, }}>
                    <ReportTitle
                    >
                      {t("Sales Reports")}
                    </ReportTitle>

                    {row3.column1
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>
                            {capitalizeWord(t(item.name))}
                          </ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>

                {(GST || VAT) && <Grid item xs={12}>
                  <Paper sx={{ p: 2, }}>
                    <ReportTitle
                    >
                      {t("Tax Reports")}
                    </ReportTitle>

                    {row2.column2
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>
                            {capitalizeWord(t(item.name))}
                          </ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>}
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, }}>
                    <ReportTitle>
                      {t("Purchase")}
                    </ReportTitle>

                    {row4.column1
                      .filter((item) => item.is_Active)
                      .map((item, i) => (
                        <ReportXontainer
                          key={i}
                          to={item.link}
                          component={Link}
                        >
                          <ReportName>
                            {capitalizeWord(t(item.name))}
                          </ReportName>
                          <ArrowForwardIosIcon
                            sx={{ fontSize: "17px" }}
                            color="primary"
                          />
                        </ReportXontainer>
                      ))}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>}
        {/* </Zoom> */}

        {acts.isLoading && <Paper sx={{ width: "100%", height: "96%", p: 2, overflowY: "scroll" }}>
          <Grid
            container
            sx={{ height: "100%" }}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
              <Paper sx={{ p: 2, width: "100% ", m: 2}}>
                  <Skeleton variant="rounded" width={180} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 2 }} />
                  {Array.from({ length: 9 }, (_, index) => (
                    <Skeleton variant="rounded" width={"100%"} height={30} sx={{backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 1 }} key={index} />
                  ))}
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
              <Paper sx={{ p: 2, width: "100% ", m: 2 }}>
                  <Skeleton variant="rounded" width={180} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 2 }} />
                  {Array.from({ length: 14 }, (_, index) => (
                    <Skeleton variant="rounded" width={"100%"} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 1 }} key={index} />
                  ))}
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
                <Paper sx={{ p: 2, width: "100%", m: 2 }}>
                  <Skeleton variant="rounded" width={180} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 2 }} />
                  {Array.from({ length: 7 }, (_, index) => (
                    <Skeleton variant="rounded" width={"100%"} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 1 }} key={index} />
                  ))}
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 2 }}>
              <Paper sx={{ p: 2, width: "100%", m: 2 }}>
                  <Skeleton variant="rounded" width={180} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 2 }} />
                  {Array.from({ length: 9 }, (_, index) => (
                    <Skeleton variant="rounded" width={"100%"} height={30} sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: 1 }} key={index} />
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Paper>}
        {/* <Grid
          container
          sx={{ height: "100%" }}
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }}>
              <Grid item xs={12}>
                <CardContainer height={"425px"}>
                  <ContainerHeading>{t("Financial")}</ContainerHeading>
                  {row1.column1
                    .filter((item) => item.is_Active)
                    .map((item, i) => (
                      <ReportCard
                        key={i}
                        to={item.link}
                        component={Link}
                        position={(i + 1) * 50}
                      >
                        <CardHeading component="p" language={i18n.language}>
                          {t(item.name)}
                        </CardHeading>
                      </ReportCard>
                    ))}
                </CardContainer>
              </Grid>

              <Grid item xs={12}>
                <CardContainer height={"635px"}>
                  <ContainerHeading>{t("Inventory")}</ContainerHeading>
                  {row1.column2
                    .filter((item) => item.is_Active === true)
                    .map((item, i) => (
                      <ReportCard
                        key={i}
                        to={item.link}
                        component={Link}
                        position={(i + 1) * 50}>
                        <CardHeading component="p" language={i18n.language}>
                          {t(item.name)}
                        </CardHeading>
                      </ReportCard>
                    ))}
                </CardContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }} sx={{ height: "100%" }}>
              <Grid item xs={12}>
                <CardContainer height={"925px"}>
                  <ContainerHeading>{t("Accounts")}</ContainerHeading>
                  {row2.column1
                    .filter((item) => item.is_Active)
                    .map((item, i) => (
                      <ReportCard key={i}
                        to={item.link}
                        component={Link} position={(i + 1) * 50}>
                        <CardHeading component="p" language={i18n.language}>
                          {t(item.name)}
                        </CardHeading>
                      </ReportCard>
                    ))}
                </CardContainer>
              </Grid>

              <Grid item xs={12}>
                <CardContainer height={"422px"}>
                  <ContainerHeading>{t("Taxes")}</ContainerHeading>
                  {row2.column2
                    .filter((item) => item.is_Active)
                    .map((item, i) => (
                      <ReportCard key={i}
                        to={item.link}
                        component={Link} position={(i + 1) * 50}>
                        <CardHeading component="p" language={i18n.language}>
                          {t(item.name)}
                        </CardHeading>
                      </ReportCard>
                    ))}
                </CardContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
            <CardContainer height={"476px"}>
              <ContainerHeading>{t("Sales")}</ContainerHeading>
              {row3.column1
                .filter((item) => item.is_Active)
                .map((item, i) => (
                  <ReportCard key={i}
                    to={item.link}
                    component={Link} position={(i + 1) * 50}>
                    <CardHeading component="p" language={i18n.language}>
                      {t(item.name)}
                    </CardHeading>
                  </ReportCard>
                ))}
            </CardContainer>
          </Grid>

          <Grid item xs={1} sm={2} md={3} sx={{ flexGrow: 1 }}>
            <CardContainer height={"476px"}>
              <ContainerHeading>{t("Purchase")}</ContainerHeading>
              {row4.column1
                .filter((item) => item.is_Active)
                .map((item, i) => (
                  <ReportCard key={i}
                    to={item.link}
                    component={Link} position={(i + 1) * 50}>
                    <CardHeading component="p" language={i18n.language}>
                      {t(item.name)}
                    </CardHeading>
                  </ReportCard>
                ))}
            </CardContainer>
          </Grid>
          
        </Grid> */}
      </Box>
    </Box>
  );
};

export default ReportsList;

const ReportXontainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "20px",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingRight: "12px",
  paddingLeft: "8px",
  textDecoration: "none",
  ":hover": {
    backgroundColor: theme.palette.mode === "light" ? "#E9F6FF" : "#203040",
  },
}));

const ReportTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "#333333" : "white",
  fontWeight: "bold",
  fontSize: "20px",
  marginBottom: "8px",
  // textTransform:"uppercase"
}));

const ReportName = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.mode === "light",
  black: "white",
  fontWeight: "500",
  letterSpacing: "2px",
  textDecoration: "none",
  // textTransform:"uppercase"
}));

const ContainerHeading = styled(Typography)(({ language }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: "10px",
  letterSpacing: "2px",
  width: "100%",
  textTransform: "capitalize",
  textAlign: language === "ar" ? "right" : "initial",
}));

const CardContainer = styled(Box)(({ height }) => ({
  position: "relative",
  background:
    "linear-gradient(292deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.025647759103641476) 100%)",
  height: height,
  borderRadius: "5px",
  padding: "10px",
  boxShadow: "inset 0px 0px 8px -5px rgb(0 0 0 / 75%)",
  display: "flex",
  justifyContent: "center",
  marginBottom: "5%",
}));

const ReportCard = styled(Box)(({ position, theme }) => ({
  position: "absolute",
  color: "inherit",
  textDecoration: "none",
  top: `${position}px`,
  display: "inline-block",
  width: "90%",
  height: "150px",
  padding: "10px",
  borderRadius: "5px",
  boxShadow:
    theme.palette.mode === "light"
      ? "1px -2px 7px -5px rgb(0 0 0 / 75%)"
      : "1px -2px 7px -5px rgb(255 255 255 / 30%)",
  transition: "all ease 0.2s",
  background:
    theme.palette.mode === "light"
      ? "linear-gradient(108deg, rgba(255, 255, 255, 1) 0, rgb(231 231 231) 100%)"
      : "linear-gradient(108deg, rgba(25, 25, 25, 1) 0%, rgb(50, 50, 50) 100%)",
  "&:hover": {
    transform: "rotate(6deg)",
    transition: "all ease 0.2s",
    top: `${position - 5}px`,
    opacity: 1,
    "&::after": {
      width: "100%",
      backgroundColor:
        theme.palette.mode === "light"
          ? "rgba(255, 255, 255, 0)"
          : "rgba(0, 0, 0, 0)",
      transition: "all 0.4s ease-in-out 0s",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "0px",
    height: "100%",
    backgroundColor:
      theme.palette.mode === "light" ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
    transition: "none 0s ease 0s",
  },
}));

// const ReportCard = styled(Box)(({ position, theme }) => ({
//   position: "absolute",
//   top: `${position}px`,
//   display: "inline-block",
//   width: "90%",
//   height: "150px",
//   padding: "10px",
//   borderRadius: "5px",
//   boxShadow: theme.palette.mode === "light"
//   ? "1px -2px 7px -5px rgb(0 0 0 / 75%)" : "1px -2px 7px -5px rgb(0 0 0 / 90%)",
//   transition: "all ease 0.2s",
//   background:
//     theme.palette.mode === "light"
//     ? "linear-gradient(108deg, rgba(255, 255, 255, 1) 0, rgb(231 231 231) 100%)"
//     : "linear-gradient(108deg, rgba(25, 25, 25, 1) 0%, rgb(50, 50, 50) 100%)",
//   "&:hover": {
//     // color: "#000",
//     transform: "rotate(6deg)",
//     transition: "all ease 0.2s",
//     top: `${position - 5}px`,
//     opacity: 1,
//     "&::after": {
//       width: "100%",
//       backgroundColor: "rgba(255, 255, 255, 0)",
//       transition: "all 0.4s ease-in-out 0s",
//     },
//   },
//   "&::after": {
//     content: '""',
//     position: "absolute",
//     top: "0px",
//     left: "0px",
//     width: "0px",
//     height: "100%",
//     backgroundColor: "rgb(255, 255, 255)",
//     transition: "none 0s ease 0s",
//   },
// }));

const CardHeading = styled(Box)(({ language }) => ({
  margin: "0",
  fontSize: "14px",
  fontWeight: "600",
  letterSpacing: "2px",
  textTransform: "uppercase",
  lineHeight: "20px",
  textAlign: language === "ar" ? "right" : "initial",
}));
