import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../generalFunction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_stock_management,
  list_stock_management,
} from "../../Api/CommonApi/InvoiceApis/StockManagementApi";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CreateNewButton,
  DeleteButton,
} from "../../Components/Utils/StyledButtons";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import { delete_sales_api } from "../../Api/Sales/SalesApis";
import { MenuOptions } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import {
  cancelSalesOrder,
  delete_sales_order_api,
} from "../../Api/Sales/SalesOrder";
import { VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
import { list_sales_order_api } from "../../Api/Sales/SalesOrder";
import SearchTextField from "../../Components/Utils/SearchTextField";
import SideMenuBottom from "../../Components/Utils/BottomNavigationMenu";
import FilterBtn from "../../Components/Utils/FilterBtn";
import ViknDrawer from "../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../Components/Utils/ViknSearchAutoComplete";
import { CallUsers } from "../../Api/Reports/CommonReportApi";
import ViknCheckboxGroup from "../../Components/Utils/ViknCheckboxGroup";

const SalesOrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    search: '',
    selectedItems: [],
    isSelectedAll: false,
    cancelID :null,
  });
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Order"
  );
  let sales_delete = sales_permission[0].delete_permission;
  let sales_edit = sales_permission[0].edit_permission;
  let sales_view = sales_permission[0].view_permission;
  let sales_save = sales_permission[0].save_permission;

  const OptionList = [
    { name: "Edit", permission: sales_edit },
    { name: "Cancel"},
    { name: "Delete", permission: sales_delete },
    { name: "Convert To Sales"},
  ];

  const OptionOnClick = (e, name, uniq_id, is_billwised,is_expense,IsPurchaseExpense,orderNo) => {
    
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit") {
        if (permission) {
          navigate("/create-sales-order", {
            state: { is_edit: true, uniq_id: uniq_id },
          });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for edit",
              severity: "warning",
            })
          );
        }
      } else if (name === "Delete") {
        if (permission) {
          setState({ ...state, selectedItems: [uniq_id] });
          setConfirmBox(true);
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      } else if (name === "Cancel"){
        setState({...state,cancelID:uniq_id})
        setConfirmBoxCancel(true);
        
      } else if (name === "Convert To Sales"){
        navigate("/create-sales", {
          state:{isOrderNo: true, orderNo: orderNo}
        })
      }
    }
  };
  const [searchText, setSearchText] = useState("");

  const SingleView = (id) => {
    if (sales_view) {
      const url = `${VIKNBOOKS_FRONT_URL}dashboard/sales-order-preview?id=${id}&unq_id=${id}`;
      const newTab = window.open(url, "_blank");
    }
  };

  const [IsConfirmBox, setConfirmBox] = useState(false);
  const [IsConfirmBoxCancel, setConfirmBoxCancel] = useState(false)

  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("common");

  const [openFilter, setOpenFilter] = useState(false);
  const closeFilter = () => setOpenFilter(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  
  const [filterOptions, setFilterOptions] = useState({
    userList: [],
    userObj: { customer_name: "" },

    invoiceType: {
      invoice: { isActive: false, name: "Invoice" },
      cancelled: { isActive: false, name: "Cancelled" },
      pending: { isActive: true, name: "Pending" },
    },
  })

  const searchReport = function () {
    fetchData();
  };

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      invoiceType: {
        invoice: { isActive: false, name: "Invoice" },
        cancelled: { isActive: false, name: "Cancelled" },
        pending: { isActive: true, name: "Pending" },
      },
      userObj: { customer_name: "" },
    })
  }

  const handleAutoComplete = function (e, value, name) {
    if (value) {
        setFilterOptions({ ...filterOptions, [name]: value });
    }
  };

  const filterApis = async function () {
    setIsLoadingUsers(true);
    const userResponse = await CallUsers({
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    let userList = [];
    if (userResponse?.StatusCode === 6000) {
      userList = userResponse.data;
    }
    setFilterOptions({
      ...filterOptions,
      userList,
    });
    setIsLoadingUsers(false);
  }


  const handleReportType = function (key) {
    switch (key) {
      case "Invoice":
        setFilterOptions({
          ...filterOptions,
          invoiceType: {
            ...filterOptions.invoiceType,
            invoice: {
              ...filterOptions.invoiceType.invoice,
              isActive: !filterOptions.invoiceType.invoice.isActive,
            },
          },
        });
        break;
      case "Cancelled":
        setFilterOptions({
          ...filterOptions,
          invoiceType: {
            ...filterOptions.invoiceType,
            cancelled: {
              ...filterOptions.invoiceType.cancelled,
              isActive: !filterOptions.invoiceType.cancelled.isActive,
            },
          },
        });
        break;
      case "Pending":
        setFilterOptions({
          ...filterOptions,
          invoiceType: {
            ...filterOptions.invoiceType,
            pending: {
              ...filterOptions.invoiceType.pending,
              isActive: !filterOptions.invoiceType.pending.isActive,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "/list-sales-order",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];

  let filterType = [];
  if (filterOptions.invoiceType.invoice.isActive) {
    filterType.push("I");
  }
  if (filterOptions.invoiceType.cancelled.isActive) {
    filterType.push("C");
  }
  if (filterOptions.invoiceType.pending.isActive) {
    filterType.push("N");
  }

  
  const fetchData = async () => {
    setLoading(true)
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      search: searchText,
      page: page_no,
      NoOfItems: noOfItems,
      UserID: filterOptions.userObj?.UserID,
      FilterType:String(filterType),
    };
    let responseData = await list_sales_order_api({ ...payload });
    console.log(responseData, "responseData==========================");
    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    } else if (responseData.StatusCode === 6001) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
      dispatch(
        openSnackbar({
          open: true,
          message:
            responseData?.message ??
            "Something went wrong!",
          severity: "warning",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message:
            responseData?.message + " : " + responseData?.error ??
            "Something went wrong!",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      IdList: state.selectedItems,
    };

    let responseData = await delete_sales_order_api({ ...payload });
    console.log(responseData, "responseeeeeeeeeDataaaaaaaaaa");
    if (responseData.StatusCode === 6000) {
      fetchData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const cancelOrder = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID:BranchID,
      CreatedUserID :user_id,
      uniq_id : state.cancelID,
    }

    let responseData = await cancelSalesOrder({...payload})
    if (responseData.StatusCode === 6000) {
      fetchData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  }

  const confirmDelete = () => {
    let selectedItems = state.selectedItems;
    let DataLists = state.dataList
    let invoiced = [];
    let Canceled = [];

    if (selectedItems.length > 0) {
      selectedItems.forEach(selectedItem => {
        const findItem = DataLists.find(item => item.id === selectedItem)
        if (findItem && findItem.IsInvoiced === "C") {
          Canceled.push(findItem.VoucherNo)
        } else if (findItem && findItem.IsInvoiced === "I") {
          invoiced.push(findItem.VoucherNo)
        }
      })
    }
    if (invoiced.length > 0){
      dispatch(
        openSnackbar({
          open: true,
          message: `Invoiced Voucher Nos ${invoiced.join(', ')} Cannot Be Deleted`,
          severity: "warning",
        })
      );
    } else if (Canceled.length > 0) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Canceled Voucher Nos ${Canceled.join(', ')} Cannot Be Deleted`,
          severity: "warning",
        })
      );
    } else {
      setConfirmBox(true);
    }
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList?.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  useEffect(() => {
    fetchData();
    filterApis();
  }, [searchText, page_no]);
  
  return (
    <>
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
          backgroundColor: 'white',
          position: "relative",
          zIndex:5
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Sales Order List")}
          </Typography>
            <SearchTextField
              value={searchText}
              escClearState={setSearchText}
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
                page_no !== 1 && setPage_no(1)
                }}
            />
          <FilterBtn onClick={() => setOpenFilter(true)} />
        </FlexBox>

        <FlexBox className="right">
          <DeleteButton onClick={confirmDelete} t={t} />

          <CreateNewButton
            onClick={() => {
              if (sales_save) {
                navigate("/create-sales-order");
              } else {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "You don't have permission for create",
                    severity: "warning",
                  })
                );
              }
            }}
            t={t}
          />

          <Pagenation
            totalItem={state.data_count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
        </FlexBox>
      </Box>

      {/* ==============TABLE=============== */}

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("Created User")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Gross Amount")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Total Tax")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Grand Total")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "center" }}>{t("Is Invoiced")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "center" }}>{t("Notes")}</VBTableCellHeader>
          {/* <VBTableCellHeader>{t("Status")} </VBTableCellHeader> */}
          <VBTableCellHeader sx={{ textAlign: "right", color: "white" }}>
            .
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          <TableBody>
            {state.dataList?.map((data, i) => (
              <TableRow
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              //   onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody
                  sx={{
                    // cursor: "pointer",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={
                      state.selectedItems.includes(data.id) ? true : false
                    } // The checked prop determines whether this checkbox is checked.
                    onChange={() => {
                      selectItems("not", data.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ textAlign: "left", cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {(page_no - 1) * appSetting.itemPerPage + i + 1}
                </VBTableCellBody>
                <VBTableCellBody
                  className="truncated-text "
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {data.created_user}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {data.VoucherNo}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {data.Date}
                </VBTableCellBody>
                <VBTableCellBody
                  className="truncated-text "
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {data.LedgerName}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {formatNumber(data.TotalGrossAmt)}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {formatNumber(data.TotalTax)}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {formatNumber(data.GrandTotal)}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{
                    // cursor: "pointer",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    checked={
                      data.IsInvoiced == "I" ? true : false
                    } // The checked prop determines whether this checkbox is checked.
                    // onChange={() => {
                    //   selectItems("not", data.id);
                    // }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody
                  className="truncated-text "
                  sx={{ cursor: "pointer", textAlign : "center" }}
                  onClick={() => {
                    SingleView(data.id);
                  }}
                >
                  {data.Notes ? data.Notes : '-'}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "right" }}>
                  {data.IsInvoiced === "C" ?
                  <Typography sx={{color:'red', fontSize:'11px',fontWeight:'500'}}>Canceled</Typography>
                  :data.IsInvoiced === "I" ?
                  <Typography sx={{color:'green', fontSize:'11px',fontWeight:'500'}}>Invoiced</Typography>
                  :
                  <MenuOptions
                  OptionList={OptionList}
                  OptionOnClick={OptionOnClick}
                  uniq_id={data.id}
                  orderNo={data.VoucherNo}
                  disable={data.IsNewSalesOrder === false}
                />
                  }
                </VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull
              length={
                state.data_count ? state.data_count : state.dataList?.length
              }
            />
          </TableBody>
        )}
      </VBTableContainer>
      <ConfirmBox
        heading={"Confirm to delete?"}
        message={"Once you delete, you will not get it back!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={deleteData}
      />

      <ConfirmBox
        heading={"Confirm to Cancel?"}
        message={"Once Canceled, you can See Order in the List,it won't be Removed!"}
        open={IsConfirmBoxCancel}
        setOpen={setConfirmBoxCancel}
        confirmFunction={cancelOrder}
      />
    </Paper>
    <SideMenuBottom data={MenuBottomData} />
    <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no={setPage_no}
        handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("User")}
                filterName={t("User")}
                loading={isLoadingUsers}
                options={filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || ""}
                name="userObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, userObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "userObj", -1)}
                value={filterOptions.userObj}
              />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Invoiced"),
                    checked: filterOptions.invoiceType?.invoice.isActive,
                    onChange: () => handleReportType("Invoice"),
                  },
                  {
                    label: t("Cancelled"),
                    checked: filterOptions.invoiceType.cancelled.isActive,
                    onChange: () => handleReportType("Cancelled"),
                  },
                  {
                    label: t("Pending"),
                    checked: filterOptions.invoiceType.pending.isActive,
                    onChange: () => handleReportType("Pending"),
                  },
                ]}
              />
            </ListItem>

          </List>
        }
    />
    </>

  );
};

export default SalesOrderList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "50%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
