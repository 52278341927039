// function roundOffNumber(value, decimalPlaces) {

//     const roundedValue = Math.round(value,2);
    
//     return roundedValue
//   }
function roundOffNumber(value, roundOffFigure) {
  // Extract integer and decimal parts
  let intPart = Math.floor(value);       // Integer part
  let decPart = value % 1;               // Decimal part

  // Calculate the rounding threshold
  let round = roundOffFigure > 0 ? roundOffFigure / 10 : 0;

  // Round up or down based on the threshold
  if (round > 0) {
    if (decPart <= round) {
      return intPart; // Round down
    } else {
      return intPart + 1; // Round up
    }
  }

  // If no rounding is required, return the original value
  return value;
}
  
  export function SalesInvoiceCalc(dataState, state, DataList,is_manual_roundoff,customRound,Country,RoundOffSales=2) {
    let TotalTaxableAmount = 0;
    let TotalGrossAmt = 0;
    let TotalDiscount = 0;
    let TotalTax = 0;
    let NetTotal = 0;
    let AdditionalCost = 0;
    let GrandTotal = 0;
    let RoundOff = customRound(state.RoundOff) || 0 ;
    let CashReceived = 0;
    let CashAmount = 0;
    let BankAmount = 0;
    let BillDiscPercent = 0;
    let BillDiscAmt = customRound(state.BillDiscAmt) || 0;
    let VATAmount = 0;
    let SGSTAmount = 0;
    let CGSTAmount = 0;
    let IGSTAmount = 0;
    let Balance = 0;
    let OldLedgerBalance = 0;
    let ShippingCharge = customRound(state.ShippingCharge) || 0;
    let shipping_tax_amount = customRound(state.shipping_tax_amount) || 0;
    let TaxTaxableAmount = 0;
    let NonTaxTaxableAmount = 0;
    
    let DetailsAmountTotal = 0;
    console.log(DataList,"Datttttaaaaaaaaaaaaaaaaaa:::::::::::::::<<<<<<<<<<<<<<<<<<<");
    let DataListLength = DataList.filter((i) => i.Product != null)?.length ?? 0;
    let TotalQty = DataListLength;
  
    // let BillDiscAmt_Split = 0;
    // if (BillDiscAmt && TotalQty) {
    //   BillDiscAmt_Split = BillDiscAmt / TotalQty;
    // }
    let TaxForDiscountObject = null
    let BillDisc_taxAmount = 0
    let TotalGrossAmount_for_billdisc = DataList.reduce((acc,curr) => acc + Number(curr.GrossAmount),0)
    TotalGrossAmount_for_billdisc = customRound(TotalGrossAmount_for_billdisc) || 0
    
    
    //Looping dataList
    for (let index = 0; index < DataList.length; index++) {
      const item = DataList[index];
  
      let GrossAmount = customRound(item.GrossAmount) || 0;
      let DiscountAmount = customRound(item.DiscountAmount) || 0;
      let TaxAmount = customRound(item.TaxAmount) || 0;
      let Qty = Number(item.Qty) || 0;
      let Tax = item.Tax
      let SalesTax = customRound(Tax?.SalesTax ?? 0);
      let Amount = customRound(item.Amount);
  
      TotalGrossAmt += GrossAmount || 0;
      TotalDiscount += DiscountAmount || 0;
      TotalTaxableAmount += GrossAmount - DiscountAmount;
  
      if (TaxAmount > 0) {
        TaxTaxableAmount += GrossAmount - DiscountAmount;
      } else {
        NonTaxTaxableAmount += GrossAmount - DiscountAmount;
      }
  
      //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
      // TotalTax += (GrossAmount - (DiscountAmount + BillDiscAmt_Split)) * SalesTax / 100;
      TotalTax +=  TaxAmount
  
      DetailsAmountTotal += Amount;
    }
    console.log('Working outside the country side !!',Country,"94e4ce66-26cc-4851-af1e-ecc068e80224");
      
    if (Country === "94e4ce66-26cc-4851-af1e-ecc068e80224"){
      console.log('Working inside the country side !!');
      
      TaxForDiscountObject = DataList.find((i)=> i.Tax?.SalesTax > 0)?.Tax || DataList.find((i)=> i.Tax?.SalesTax < 1?.Tax)
      let SalesTaxForDiscount = TaxForDiscountObject ?Number(TaxForDiscountObject.SalesTax):0 || 0
      BillDisc_taxAmount = customRound(BillDiscAmt*SalesTaxForDiscount/100)
      TotalTax = TotalTax-BillDisc_taxAmount
      if (customRound(TaxTaxableAmount) >= BillDiscAmt){
        TaxTaxableAmount -= BillDiscAmt
      }else{
        NonTaxTaxableAmount -= BillDiscAmt
      }
    }else{
      GrandTotal -= BillDiscAmt
    }
    //after
    TotalDiscount += BillDiscAmt;
  
    GrandTotal += customRound(TaxTaxableAmount + NonTaxTaxableAmount);
    GrandTotal += customRound(TotalTax);
    NetTotal += customRound(DetailsAmountTotal );
    // GrandTotal += customRound(ShippingCharge + shipping_tax_amount);
    // GrandTotal -= BillDiscAmt;
  

    console.log(RoundOff,GrandTotal,'RoundOff>>???');
    console.log(TaxTaxableAmount,"<--------TaxTaxableAmount\n",NonTaxTaxableAmount,"<--------NonTaxTaxableAmount\n",TotalTax,"<--------TotalTax\n",'RoundOff>>???');
    if (is_manual_roundoff === false){
      let roundedValue= roundOffNumber(GrandTotal, RoundOffSales);
      console.log(roundedValue,"------------------>>>> Rounded value");
      RoundOff =customRound(roundedValue - GrandTotal)
      GrandTotal = roundedValue
    }
    else{
      console.log("---Working1")
      GrandTotal += RoundOff
    }
    console.log(RoundOff,'RoundOff>>???');
  
    // Update the state
    state.TotalQty = TotalQty;
    state.TotalGrossAmt = TotalGrossAmt;
    state.TotalDiscount = TotalDiscount;
    state.TotalTax = customRound(TotalTax);
    state.NetTotal = NetTotal;
    state.TotalTaxableAmount = TotalTaxableAmount;
    state.TaxTaxableAmount = TaxTaxableAmount;
    state.NonTaxTaxableAmount = NonTaxTaxableAmount;
    state.RoundOff =customRound(RoundOff)
    state.GrandTotal = GrandTotal;
    state.BillDiscTaxAmt = BillDisc_taxAmount;
    state.BillDiscTaxPerc = TaxForDiscountObject?.SalesTax;
    state.BillDiscTaxID = TaxForDiscountObject?.TaxID;

  
    return state;
  }
  
  // Line Calculation
  export function SalesLineCalc(dataState, state, lineItem,customRound) {
    let Qty = Number(lineItem.Qty) || 0
    let Rate = Number(lineItem.Rate) || 0
    // let Rate = customRound(lineItem.Rate) || 0
    let DiscountAmount = (lineItem.DiscountAmount) || 0
    let Tax = lineItem.Tax 
    let TaxID = lineItem.TaxID
    let SalesTax = customRound(Tax?.SalesTax??0)

    let UnitTaxAmount = Number((Rate/100) * SalesTax)
    // let UnitTaxAmount = customRound((Rate/100) * SalesTax)
    console.log(Rate,DiscountAmount,"Rate-------------------");
    console.log(UnitTaxAmount,"UnitTaxAmount-------------------");
    
    let InclusivePrice =( Rate + UnitTaxAmount )
    let GrossAmount = customRound(Rate * Qty)
    // let GrossAmount = customRound(Rate * Qty)

    let TaxableAmount = Number((Rate * Qty) - DiscountAmount)
    console.log(TaxableAmount,"TaxableAmount RoundOf");
    // let TaxAmount = customRound((Rate * Qty) - DiscountAmount)
    // let TaxableAmount = customRound(GrossAmount - DiscountAmount)
    // let TaxAmount = customRound(GrossAmount - DiscountAmount)
    let TaxAmount = customRound((((Rate * Qty) - DiscountAmount)/100) * SalesTax)
    console.log(TaxAmount,"TaxAmount RoundOf");
    let Amount = ((Rate * Qty) - DiscountAmount) + TaxAmount
    // let Amount = (GrossAmount - DiscountAmount) + TaxAmount
    Amount = customRound(Amount)
    

    //update item
    lineItem.Qty = Qty
    lineItem.Rate = Number(lineItem.Rate) || 0
    lineItem.DiscountAmount = DiscountAmount
    
    lineItem.TaxableAmount = TaxableAmount
    lineItem.TaxAmount = TaxAmount
    lineItem.InclusivePrice = InclusivePrice
    lineItem.GrossAmount = GrossAmount
    lineItem.Amount = Amount

  
    return lineItem;
  }
  
  
  // validation 
  export const PurchaseReturnValidation = (dataState,state,DataList,GeneralSettingsData) => {
    let field = null;
    let message = null;
    let error = null;
    let indexList = [];
  
    let Data = DataList
    let length = Data.length;
  
    let is_non_taxable_amount = false
    let is_taxable_amount = false
    
    if (GeneralSettingsData.EnableEinvoiceKSA && state.Treatment?.value === "7") {
      error = true;
      message = "Import cannot be saved in e-invoice";
    }
    if (GeneralSettingsData.EnableEinvoiceKSA && !state.RefferenceBillNo) {
      error = true;
      message = "Refference Bill No must be saved in e-invoice";
    }
    if (GeneralSettingsData.EnableEinvoiceKSA && !state.Notes) {
      error = true;
      message = "Notes must be saved in e-invoice";
    }


    if (Data.length === 0) {
      error = true;
      message = "At least need one valid row";
    }
    else if (Data.length === 1 && !Data[0].Product) {
      message = "At least need one valid row"
    }
  
    //looping the dataList and checking all mandatory values are included
    Data.map((obj,i) => {
      if (!obj.Product) {
        if (
          i + 1 === length &&
          !obj.ProductCode &&
          !obj.BarCode &&
          !obj.Product &&
          // !obj.Description &&
          !obj.Unit &&
          // !obj.UnitList &&
          !obj.Stock &&
          !obj.Qty &&
          obj.Qty <= 0 &&
          // !obj.FQty &&
          !obj.Rate &&
          // !obj.AvgCost &&
          !obj.Tax &&
          !obj.InclusivePrice &&
          !obj.GrossAmount &&
          // !obj.DiscPerc &&
          !obj.DiscountAmount &&
          !obj.TaxAmount &&
          !obj.Amount &&
          !obj.MRP 
          // !obj.PurchasePrice &&
          // !obj.BatchCode &&
          // !obj.MinimumSalesPrice &&
          // !obj.AverageCost 
        ) {
          console.log("IGNORE THE PART");
        }  else {
          indexList.push(i);
        }
      } else if (!obj.Product) {
        indexList.push(i);
      } else if (!obj.Unit) {
        indexList.push(i);
      } else if (!obj.Qty && Number(obj.Qty) <= 0) {
        indexList.push(i);
      } else if (!obj.Rate && Number(obj.Rate)<= 0 ) {
        indexList.push(i);
      }
      else if (!obj.Tax) {
        indexList.push(i);
      }
      else if (!obj.InclusivePrice) {
        indexList.push(i);
      }
      else if (!obj.GrossAmount) {
        indexList.push(i);
      }
      // else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
      //   indexList.push(i);
      // }
      else if (!obj.Amount) {
        indexList.push(i);
      }
  
      if (obj.Product){
        //checking is there any non tax amount and tax amount
        if (obj.Tax.TaxName === "None"){
          is_non_taxable_amount = true
        }
        else{
          is_taxable_amount = true
        }
        }
    });
  
    let newIndexList = indexList.map(i => i + 1);
    
    if (indexList.length > 0) {
      message = "Data missing in Lines " + newIndexList;
      error = true;
    }

  
      //checking values of state
      if (!state.AccountLedger && !error) {
        error = true;
        message = "Provide valid AccountLedger";
        } 
        else if (!state.Treatment && !error) {
            error = true;
            message = "Provide valid Treatment";
        }
        else if (!state.PlaceOfSupply && !error) {
            error = true;
            message = "Provide valid PlaceOfSupply";
        }
        else if (!state.Date && !error) {
            error = true;
            message = "Provide valid Date";
        }
        else if (!state.TotalGrossAmt && !error) {
            error = true;
            message = "Provide valid TotalGrossAmt";
        }
        // else if (!state.TotalTax && !error && is_taxable_amount) {
        //     error = true;
        //     message = "Provide valid TotalTax";
        // }
  
        else if (!state.NetTotal && !error) {
            error = true;
            message = "Provide valid NetTotal";
        }
  
        else if (!state.TotalTaxableAmount && !error) {
            error = true;
            message = "Provide valid TotalTaxableAmount";
        }
        // else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
        //     error = true;
        //     message = "Provide valid TaxTaxableAmount";
        // }
        else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
            error = true;
            message = "Provide valid NonTaxTaxableAmount";
        }
  
        else if (!state.GrandTotal && !error) {
            error = true;
            message = "Provide valid GrandTotal";
        }
        else if (state.BillDiscAmt > state.TotalTaxableAmount && GeneralSettingsData.EnableEinvoiceKSA ){
          error = true;
         message = "Bill Discount Can't be Grater than Taxable Amount "
       }

  
      console.log(state,";ppppppppppppppppppppppppppppperrrororo");
      console.log(GeneralSettingsData.EnableEinvoiceKSA,";ppppppppppppppppppppppppppppperrrororo");
      return {error, message, field, indexList};
  }