import { useSelector } from "react-redux";

function roundOffNumber(value, decimalPlaces) {

  const roundedValue = Math.round(value,2);
  
  return roundedValue
}

export function SalesInvoiceCalc(dataState, state, DataList,is_manual_roundoff,customRound,GST,CompanyState,RoundOffSales,Country) {
  let TotalTaxableAmount = 0;
  let TotalGrossAmt = 0;
  let TotalDiscount = 0;
  let TotalTax = 0;
  let NetTotal = 0;
  let AdditionalCost = 0;
  let GrandTotal = 0;
  let RoundOff = customRound(state.RoundOff) || 0 ;
  let CashReceived = 0;
  let CashAmount = 0;
  let BankAmount = 0;
  let BillDiscPercent = customRound(state.BillDiscPercent) || 0;;
  let BillDiscAmt = customRound(state.BillDiscAmt) || 0;
  let VATAmount = 0;
  let SGSTAmount = 0;
  let CGSTAmount = 0;
  let IGSTAmount = 0;
  let Balance = 0;
  let OldLedgerBalance = 0;
  let ShippingCharge = customRound(state.ShippingCharge) || 0;
  let shipping_tax_amount = customRound(state.shipping_tax_amount) || 0;
  let TaxTaxableAmount = 0;
  let NonTaxTaxableAmount = 0;

  let DetailsAmountTotal = 0;
  console.log(DataList,"Datttttaaaaaaaaaaaaaaaaaa:::::::::::::::<<<<<<<<<<<<<<<<<<<");
  let DataListLength = DataList?.filter((i) => i?.Product != null)?.length ?? 0;
  let TotalQty = DataListLength;

  // let BillDiscAmt_Split = 0;
  // if (BillDiscAmt && TotalQty) {
  //   BillDiscAmt_Split = BillDiscAmt / TotalQty;
  // }

  // let BillDisc_taxAmount = customRound(BillDiscAmt*15/100)
  
  //Looping dataList
  for (let index = 0; index < DataList.length; index++) {
    const item = DataList[index];

    let GrossAmount = customRound(item.GrossAmount)|| 0;
    let DiscountAmount = customRound(item.DiscountAmount) || 0;
    let TaxAmount = customRound(item.TaxAmount )|| 0;
    let Qty = Number(item.Qty) || 0;
    let Tax = item.Tax
    let SalesTax = customRound(Tax?.SalesTax ?? 0);
    let Amount = 0 ;
    if (!isNaN(customRound(item.Amount))) {
      Amount = customRound(item.Amount);
    }

    TotalGrossAmt += GrossAmount || 0;
    TotalDiscount += DiscountAmount || 0;
    TotalTaxableAmount += GrossAmount - DiscountAmount;

    if (TaxAmount > 0) {
      TaxTaxableAmount += GrossAmount - DiscountAmount;
    } else {
      NonTaxTaxableAmount += GrossAmount - DiscountAmount;
    }

    //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
    TotalTax += TaxAmount//(GrossAmount - (DiscountAmount + BillDiscAmt_Split)) * SalesTax / 100;

    DetailsAmountTotal += Amount;

    if(GST){
      if (state.PlaceOfSupply?.id === CompanyState){
        SGSTAmount += customRound(item.SGSTAmount)
        CGSTAmount += customRound(item.CGSTAmount)
      } else {
        IGSTAmount += customRound(item.IGSTAmount) 
      }
    }
  }

  let TaxForDiscountObject = null
  let BillDisc_taxAmount = 0
  if (Country === "94e4ce66-26cc-4851-af1e-ecc068e80224"){
    TaxForDiscountObject = DataList.find((i)=> i.Tax?.SalesTax > 0)?.Tax || DataList.find((i)=> i.Tax?.SalesTax < 1?.Tax)
    let SalesTaxForDiscount = TaxForDiscountObject ?Number(TaxForDiscountObject.SalesTax):0 || 0
    console.log(TaxForDiscountObject,"----TaxForDiscountObject");
    console.log(SalesTaxForDiscount,"----fFFf");
    
    BillDisc_taxAmount = customRound(BillDiscAmt*SalesTaxForDiscount/100)
    TotalTax = TotalTax-BillDisc_taxAmount
    if (customRound(TaxTaxableAmount) >= BillDiscAmt){
      TaxTaxableAmount -= BillDiscAmt
    }else{
      NonTaxTaxableAmount -= BillDiscAmt
    }
  }else{
    GrandTotal -= BillDiscAmt
  }
  
  
  // // TotalTax = TotalTax-BillDisc_taxAmount
  // BillDiscAmt = (BillDiscPercent / 100) * TotalGrossAmt
  // state.BillDiscAmt = BillDiscAmt

  // let BillDisc_taxAmount = customRound(BillDiscAmt*15/100)
  // if (TotalTax > 0) {
  //   TotalTax = TotalTax-BillDisc_taxAmount
  // }


  //after
  TotalDiscount += BillDiscAmt;

  GrandTotal += customRound(TaxTaxableAmount + NonTaxTaxableAmount);
  GrandTotal += customRound(TotalTax);
  NetTotal += customRound(DetailsAmountTotal + ShippingCharge);
  GrandTotal += customRound(ShippingCharge + shipping_tax_amount);
  // GrandTotal -= BillDiscAmt;

  if (is_manual_roundoff === false){
    let round_off_value = RoundOffSales
    let rounding = (round_off_value)/10
    let roundedValue= roundOffNumber(GrandTotal, 2);
    let GrandTotal_Dec = Number(GrandTotal % 1);
    let GrandTotal_INT = Math.floor(GrandTotal);
    
    if (GrandTotal_Dec <= rounding){
      RoundOff = GrandTotal_Dec * -1
      GrandTotal =  GrandTotal_INT
    }else {
      RoundOff = 1 - GrandTotal_Dec
      GrandTotal =  GrandTotal_INT + 1
    }
  }
  else{
    GrandTotal += RoundOff
  }


  // Update the state
  state.TotalQty = TotalQty;
  state.TotalGrossAmt = customRound(TotalGrossAmt);
  state.TotalDiscount = customRound(TotalDiscount);
  state.TotalTax = customRound(TotalTax);
  state.NetTotal = customRound(NetTotal);
  state.TotalTaxableAmount = customRound(TotalTaxableAmount);
  state.TaxTaxableAmount = customRound(TaxTaxableAmount);
  state.NonTaxTaxableAmount = customRound(NonTaxTaxableAmount);
  state.RoundOff = customRound(RoundOff)
  state.GrandTotal = customRound(GrandTotal);
  
  state.TotalCGST = customRound(CGSTAmount);
  state.TotalSGST = customRound(SGSTAmount);
  state.TotalIGST = customRound(IGSTAmount);

  state.BillDiscTaxAmt = BillDisc_taxAmount;
  state.BillDiscTaxPerc = TaxForDiscountObject?.SalesTax;
  state.BillDiscTaxID = TaxForDiscountObject?.TaxID;

  return state;
}

// Line Calculation
export function SalesLineCalc(dataState, state, lineItem,customRound,GST,CompanyState) {
  let Qty = Number(lineItem.Qty) || 0;
  let Rate = Number(lineItem.Rate) || 0;
  let DiscountAmount = customRound(lineItem.DiscountAmount) || 0;
  let DiscountPerc = customRound(lineItem.DiscountPerc) || 0;
  let Tax = lineItem.Tax;
  let TaxID = lineItem.TaxID;
  let SalesTax = customRound(Tax?.SalesTax??0);

  let UnitTaxAmount = Number((Rate / 100) * SalesTax);
  let InclusivePrice = customRound(Rate + UnitTaxAmount);
  let GrossAmount = customRound(Rate * Qty);
  DiscountAmount = customRound((DiscountPerc / 100) * GrossAmount);

  let TaxableAmount = Number((Rate * Qty) - DiscountAmount)
  // let TaxAmount = customRound(GrossAmount - DiscountAmount);
  // TaxAmount = customRound((TaxAmount / 100) * SalesTax);
  let TaxAmount = customRound((((Rate * Qty) - DiscountAmount)/100) * SalesTax);
  let Amount =customRound(((Rate * Qty) - DiscountAmount) + TaxAmount)
  // let Amount = customRound((GrossAmount - DiscountAmount) + TaxAmount);
  console.log(Rate,"Rate-------------------",Amount,'TaxAmount',TaxAmount);
  console.log(UnitTaxAmount,"UnitTaxAmount-------------------",InclusivePrice);
  let GstHalfAmount = customRound(TaxAmount / 2)
  let Gst_perc = (SalesTax / 2)

  lineItem.Qty = Qty
  lineItem.Rate = Number(lineItem.Rate) || 0
  lineItem.DiscountAmount = DiscountAmount
  //update item
  lineItem.TaxableAmount = TaxableAmount;
  lineItem.TaxAmount = TaxAmount;
  lineItem.InclusivePrice = InclusivePrice;
  lineItem.GrossAmount = GrossAmount;
  lineItem.Amount = Amount;

    // if (lineItem.TaxAmount > 0) {
  //   TaxableAmount = customRound(GrossAmount - DiscountAmount);
  // } else {
  //   TaxableAmount = 0
  // }
  if (GST){
    console.log(state.PlaceOfSupply?.id,'koOOOOOOOOOOOOOOOOOOOOOOFFF',CompanyState);

    if (state?.Treatment?.value === "4" || state?.Treatment?.value === "5" || state?.Treatment?.value === "6"){
      lineItem.IGSTAmount = 0
      lineItem.IGSTPerc = 0
      lineItem.CGSTAmount = 0
      lineItem.SGSTAmount = 0
      lineItem.CGSTPerc = 0
      lineItem.SGSTPerc = 0
      lineItem.TaxAmount = 0
      lineItem.Amount = customRound(GrossAmount - DiscountAmount);
    }
    else if(state.PlaceOfSupply?.id === CompanyState){
      console.log(lineItem,'koOOOOOOOOOOOOO1111111111111OOOOOOOOOFFF');
      
      lineItem.CGSTAmount = GstHalfAmount
      lineItem.SGSTAmount = GstHalfAmount
      lineItem.CGSTPerc = Gst_perc
      lineItem.SGSTPerc = Gst_perc
      lineItem.IGSTAmount = 0
      lineItem.IGSTPerc = 0
    } else {
      console.log(lineItem,'koOOOOOOOOOO2222222222OOOOOOOOOOOOFFF');
      lineItem.IGSTAmount = TaxAmount
      lineItem.IGSTPerc = SalesTax
      lineItem.CGSTAmount = 0
      lineItem.SGSTAmount = 0
      lineItem.CGSTPerc = 0
      lineItem.SGSTPerc = 0
    }

  }

  return lineItem;
}


// validation 
export const SalesReturnValidation = (dataState,state,DataList,GeneralSettingsData) => {

  console.log(("SalesReturnValidationHELLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL"));
  let field = null;
  let message = null;
  let error = null;
  let indexList = [];

  let Data = DataList
  let length = Data.length;

  let is_non_taxable_amount = false
  let is_taxable_amount = false

  if (Data.length === 0) {
    error = true;
    message = "At least need one valid row";
  }
  else if (Data.length === 1 && !Data[0].Product) {
    message = "At least need one valid row"
  }

  //looping the dataList and checking all mandatory values are included
  Data.map((obj,i) => {
    if (!obj.Product) {
      if (
        i + 1 === length &&
        !obj.ProductCode &&
        !obj.BarCode &&
        !obj.Product &&
        // !obj.Description &&
        !obj.Unit &&
        // !obj.UnitList &&
        !obj.Stock &&
        !obj.Qty &&
        obj.Qty <= 0 &&
        // !obj.FQty &&
        !obj.Rate &&
        // !obj.AvgCost &&
        !obj.Tax &&
        !obj.InclusivePrice &&
        !obj.GrossAmount &&
        // !obj.DiscPerc &&
        !obj.DiscountAmount &&
        !obj.TaxAmount &&
        !obj.Amount &&
        !obj.MRP 
        // !obj.PurchasePrice &&
        // !obj.BatchCode &&
        // !obj.MinimumSalesPrice &&
        // !obj.AverageCost 
      ) {
        console.log("IGNORE THE PART");
      }  else {
        indexList.push(i);
      }
    } else if (!obj.Product) {
      indexList.push(i);
    } else if (!obj.Unit) {
      indexList.push(i);
    } else if (!obj.Qty && Number(obj.Qty) <= 0) {
      indexList.push(i);
    } else if (!obj.Rate && Number(obj.Rate)<= 0 ) {
      indexList.push(i);
    }
    else if (!obj.Tax) {
      indexList.push(i);
    }
    else if (!obj.InclusivePrice) {
      indexList.push(i);
    }
    else if (!obj.GrossAmount) {
      indexList.push(i);
    }
    // else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
    //   indexList.push(i);
    // }
    else if (!obj.Amount) {
      indexList.push(i);
    }
    else if ((Number(obj.DiscountPerc || 0) > GeneralSettingsData.sales_discount_limit) && GeneralSettingsData.sales_discount_perm){
      error = true;
      indexList.push(i);
      // message = `Your Bill discount is more than your limit, your limit is ${Number(GeneralSettingsData.sales_discount_limit).toFixed(1)}%`;
    }
    else if (GeneralSettingsData.EnableProductBatchWise === true && !obj.BatchCode){
      error = true;
      indexList.push(i);
    }



    if (obj.Product){
      //checking is there any non tax amount and tax amount
      if (obj.Tax.TaxName === "None"){
        is_non_taxable_amount = true
      }
      else{
        is_taxable_amount = true
      }
      }
  });

  let newIndexList = indexList.map(i => i + 1) 
  
  console.log(indexList,'lllLLLLLLLLLLPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP');
  if (indexList.length > 0) {
    message = "Data missing Lines" + newIndexList;
    error = true;
  }

    //checking values of state
    if (!state.AccountLedger && !error) {
      error = true;
      message = "Provide valid AccountLedger";
      } else if (!state.CashAccount && !error) {
      error = true;
      message = "Provide valid CashAccount";
      } else if (!state.BankAccount && !error) {
      error = true;
      message = "Provide valid BankAccount";
      } else if (!state.Employee && GeneralSettingsData.EnableSalesManInSales && !error) {
      error = true;
      message = "Provide valid Employee";
      }
      else if (!state.Warehouse && !error) {
          error = true;
          message = "Provide valid Warehouse";
      }
      else if (!state.Treatment && !error) {
          error = true;
          message = "Provide valid Treatment";
      }
      else if (!state.PlaceOfSupply && !error) {
          error = true;
          message = "Provide valid PlaceOfSupply";
      }
      else if (!state.Date && !error) {
          error = true;
          message = "Provide valid Date";
      }
      else if (!state.TotalGrossAmt && !error) {
          error = true;
          message = "Provide valid TotalGrossAmt";
      }
      // else if (!state.TotalTax && !error && is_taxable_amount) {
      //     error = true;
      //     message = "Provide valid TotalTax";
      // }

      else if (!state.NetTotal && !error) {
          error = true;
          message = "Provide valid NetTotal";
      }

      else if (!state.TotalTaxableAmount && !error) {
          error = true;
          message = "Provide valid TotalTaxableAmount";
      }
      // else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
      //     error = true;
      //     message = "Provide valid TaxTaxableAmount";
      // }
      else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
          error = true;
          message = "Provide valid NonTaxTaxableAmount";
      }

      else if (!state.GrandTotal && !error) {
          error = true;
          message = "Provide valid GrandTotal";
      }
      else if (!state.RefferenceBillNo && !error && GeneralSettingsData.EnableEinvoiceKSA) {
        error = true;
        message = "Provide valid RefferenceBillNo";
    }
    else if (!state.Notes && !error && GeneralSettingsData.EnableEinvoiceKSA) {
      error = true;
      message = "Provide Notes";
  }
//   else if (state.BillDiscAmt > state.TotalTaxableAmount && GeneralSettingsData.EnableEinvoiceKSA ){
//     error = true;
//    message = "Bill Discount Can't be Grater than Taxable Amount "
//  }

    console.log(error,";ppppppppppppppppppppppppppppperrrororo");
    return {error, message, field, indexList};
}