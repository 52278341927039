import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,CircularProgress
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";

import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { CallStockValueReport, CallStockValueReportTotal } from "../../../../Api/Reports/InventoryApi";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { formatNumber } from "../../../../generalFunction";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import {
  CallBrands,
  CallProductCategories,
  CallProductGroups,
  CallProductSearch,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
import { useSelector } from "react-redux";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
// import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { debounce1, goToInvoice } from "../../../../Function/comonFunction";
import { BASE_V11_URL } from "../../../../generalVeriable";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";
import { useTranslation } from "react-i18next";
// import VBInputField from "@/Utils/VBInputField";

// import VBInputField from "@vb_ui/Utils/VBInputField";

const StockValueReport = () => {
  let Export_view = UserRolePermission("Stock Value Report", "print_permission");
  const appSettings = useSelector((state) => state.appSettingsSclice);
  const [progress, setProgress] = useState(0);
  const [t, i18n] = useTranslation("common");
  const [clearFilter, setClearFilter] = useState(false)
  const [page_no, setPage_no] = useState(1);
  const [reportData, setReportData] = useState({
    data: [],
    count: 0,
    // TotalData: {
    //   TotalStockInBaseUnit: 0,
    //   TotalCost: 0,
    //   TotalCostEXP: 0,
    //   TotalTotalEXP: 0,
    //   // TotalCost: 0,
    //   TotalGrandTotal: 0,
    // },
  });
  const [totalDataLoader,setTotalDataLoader] = useState(false)
  const [TotalData,setTotalData] = useState({
    TotalStockInBaseUnit:0,
    TotalCost:0,
    TotalUnitEXP:0,
    TotalCostEXP:0,
    TotalTotalEXP:0,
    TotalTotalCost:0,
    TotalGrandTotal:0,
  })
  const [filterData, setfilterData] = useState({
    productList: [],
    productCategoryList: [],
    productGrouplist: [],
    brandList: [],
    warehouseList: [],
  });

  const [filterOptions, setFilterOptions] = useState({
    isProduct: true,
    isProductCategory: false,
    isProductGroup: false,
    isBrand: false,
    date: moment(),
    product: null,
    productCategory: null,
    productGroup: null,
    brand: null,
    warehouse: null,
    showActiveProductsOnly:true,
  });

  const [search, setSearch] = useState({ product: "" });

  const [isLoading, setIsLoading] = useState({
    isLoadingProduct: false,
    report: false,
  });

  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function() {
    setFilterOptions({
      isProduct: true,
      isProductCategory: false,
      isProductGroup: false,
      isBrand: false,
      date: moment(),
      product: null,
      productCategory: null,
      productGroup: null,
      brand: null,
      warehouse: null,
    })
    page_no === 1 && fetchReport(false)
    setPage_no(1)
    setClearFilter(!clearFilter)
  }

  const radiosValue = [
    t("Product"),
    t("Product Category"),
    t("Product Group"),
    t("Brand"),
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () =>
        setFilterOptions({
          ...filterOptions,
          isProduct: false,
          isProductCategory: false,
          isProductGroup: false,
          isBrand: false,
          product: null,
          productCategory: null,
          productGroup: null,
          brand: null,
          [`is${option.replace(" ", "")}`]: true,
        }),
      label: option,
    };
  });
  const searchReport = function () {
    fetchReport(false);
    setPage_no(1)
    // filterApi()
  };

  // API Fetch data
  const fetchReport = async function (is_pageChange) {
    setIsLoading({ ...isLoading, report: true });
    console.log(filterOptions.productGroup);
    let filterData = {
      ProductID: 0,
      CategoryID: 0,
      GroupID: 0,
      BrandID: 0,
      WareHouseID: 0,
    };
    if (filterOptions.product) filterData.ProductID = filterOptions.product.ProductID;
    if (filterOptions.brand) filterData.BrandID = filterOptions.brand.BrandID;
    if (filterOptions.productGroup) filterData.GroupID = filterOptions.productGroup.ProductGroupID;
    if (filterOptions.productCategory) filterData.CategoryID = filterOptions.productCategory.ProductCategoryID;
    if (filterOptions.warehouse) filterData.WareHouseID = filterOptions.warehouse.WarehouseID;

    const data = await CallStockValueReport({
      ...filterData,
      ToDate: filterOptions.date.format("YYYY-MM-DD"),
      page_no: page_no,
      items_per_page: appSettings.itemPerPage,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      ShowActiveProductOnly: filterOptions.showActiveProductsOnly,
    });
    // console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData({
        ...reportData,
        data: data.data,
        count: data.Count,
        // TotalData: {
        //   TotalStockInBaseUnit: data.TotalData.TotalStockInBaseUnit,
        //   TotalCost: data.TotalData.TotalCost,
        //   TotalCostEXP: data.TotalData.TotalCostEXP,
        //   TotalTotalEXP: data.TotalData.TotalTotalEXP,
        //   TotalTotalCost: data.TotalData.TotalTotalCost,
        //   TotalGrandTotal: data.TotalData.TotalGrandTotal,
        // },
      });
    }
    setIsLoading({ ...isLoading, report: false });
    if(!is_pageChange){
    getTotal()
    }
  };
  const getTotal = async () => {
    setTotalData({...TotalData,ErrorMessage:null})
    setTotalDataLoader(true)
    let filterData = {
      ProductID: 0,
      CategoryID: 0,
      GroupID: 0,
      BrandID: 0,
      WareHouseID: 0,
    };
    console.log("filterOptions.warehouse",filterOptions.warehouse);
    if (filterOptions.product) filterData.ProductID = filterOptions.product.ProductID;
    if (filterOptions.brand) filterData.BrandID = filterOptions.brand.BrandID;
    if (filterOptions.productGroup) filterData.GroupID = filterOptions.productGroup.ProductGroupID;
    if (filterOptions.productCategory) filterData.CategoryID = filterOptions.productCategory.ProductCategoryID;
    if (filterOptions.warehouse) filterData.WareHouseID = filterOptions.warehouse.WarehouseID;
    const data = await CallStockValueReportTotal({
      ...filterData,
      ToDate: filterOptions.date.format("YYYY-MM-DD"),
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
   

    const newTaskId = data.task_id;
    // setTaskId(newTaskId);

    // Start monitoring task status
    checkTaskStatus(newTaskId);
};
const checkTaskStatus = async (taskId) => {
  const response = await fetch(`${BASE_V11_URL}reports/check-task-status/` + taskId + '/');
  const data = await response.json();


  if (data.status == "completed") {
      let resultData = data.result 
      setTotalData(resultData)
      setTotalDataLoader(false)
      
  }
  else if (data.status == "pending"){
    setTimeout(() => checkTaskStatus(taskId), 3000); // Poll every 1 second
  }
  
  else {
     console.log("ERROR!!!");
     setTotalData({...TotalData,ErrorMessage:"Failed to load data !!"})
    //  setTotalDataLoader(false)
  }
};
  

  const searchProduct = async function () {
    setIsLoading({ ...isLoading, isLoadingProduct: true });
    const product = await CallProductSearch({
      product_name: search.product,
      length: search.product.length,
      type: "report",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      showActiveProductsOnly:filterOptions.showActiveProductsOnly,
    });

    if (product.StatusCode === 6000) {
      setfilterData(prev => ({
        ...prev,
        productList: product.data
      }))
    }

    setIsLoading({ ...isLoading, isLoadingProduct: false });
  };

  const filterApi = async function () {
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all:true
    }
    try {
      const apiNames = [
        "warehouseList",
        "brandList",
        "productGrouplist",
        "productCategoryList",
      ];
      const responses = await Promise.all([
        CallWarehouses(payload),
        CallBrands(payload),
        CallProductGroups(payload),
        CallProductCategories(payload),
      ]);
      
      let resp = {
        warehouseList: [],
        brandList: [],
        productGrouplist: [],
        productCategoryList: [],
      }
      responses.forEach((response, index) => {
        console.log(response,"responseresponseresponse----");
        if (response.StatusCode === 6000) {
          // console.log(response.data, index);
          resp[apiNames[index]] = response.data
        } else {
          console.log(
            `API ${apiNames[index]} returned StatusCode ${response.StatusCode}`
          );
        }
      });
      
      setfilterData((prevState) => ({
        ...prevState,
        warehouseList:resp.warehouseList,
        brandList: resp.brandList,
        productGrouplist: resp.productGrouplist,
        productCategoryList: resp.productCategoryList,
      }));
      // getTotal()
    } catch (error) {
      console.error("An error occurred while making the API calls", error);
    }
  };

  const debouncedSetFilterOptions = useMemo(
    () => debounce1((newValue) => {
      setFilterOptions((prev) => ({ ...prev, productCategory: newValue }));
    }, 300),
    []
  );

  useEffect(() => {
    filterApi();
  }, []);

  // useEffect(() => {
  //   fetchReport(true);
  // }, [page_no]);

  useEffect(() => {
    if(search.product !==""){
      searchProduct()
    }
  }, [search.product])
  
  useEffect(() => {
    fetchReport()
    // searchProduct()
  },[filterOptions.showActiveProductsOnly,page_no])
console.log(filterOptions.productGroup,"filterOptions.productGroup");
console.log(filterData.productGrouplist,"filterData.productGrouplist");

  return (
    <>
    {progress > 0 ?
      // <LoaderLite message={"Generating Report... " + progress + "%"} progress={progress} fullscreen={true} />
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Stock Value Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // width: "26%",
            }}
          >
            {Export_view ? 
          <ReportDownload
            download_type={"PDF"}
            invoice_type={"stock_value_report"}
            FromDate={filterOptions.date.format("YYYY-MM-DD")}
            ToDate={filterOptions.date.format("YYYY-MM-DD")}
            ProductID={filterOptions.product ? filterOptions.product.ProductID : 0}
            WarehouseID={filterOptions.warehouse ? filterOptions.warehouse.WarehouseID : 0}
            BrandID={filterOptions.brand ? filterOptions.brand.BrandID : 0}
            CategoryID={filterOptions.productCategory ? filterOptions.productCategory.ProductCategoryID : 0}
            GroupID={filterOptions.productGroup ? filterOptions.productGroup.ProductGroupID : 0}
            is_celery={true}
            setProgress={setProgress}
            ActiveProductOnly={filterOptions.showActiveProductsOnly}
          />
          : null}
          {/* <ReportDownload
            download_type={"PDF"}
            invoice_type={"stock_value_report"}
            FromDate={filterOptions.date.format("YYYY-MM-DD")}
            ToDate={moment().format("YYYY-MM-DD")}
            ProductID={0}
            WarehouseID={0}
            BrandID={0}
            CategoryID={0}
            GroupID={0}
            is_celery={true}
            setProgress={setProgress}
          /> */}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData.count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
            name={t("Date")}
            value={filterOptions.date.format("DD/MM/YYYY")}
          />
          {!filterOptions.showActiveProductsOnly &&
            <FilterLable
              name={t("Inactive Product")}
              value={filterOptions.showActiveProductsOnly? "false" : "true"}
              handleDelete={() =>{
                setFilterOptions({
                  ...filterOptions,
                  showActiveProductsOnly: true,
                })
              }
              }
            />
          }

          {filterOptions.isProduct && filterOptions.product && (
            <FilterLable
              name={t("Product")}
              value={filterOptions.product.ProductName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, product: null }))
              }
            />
          )}
          {filterOptions.isProductCategory && filterOptions.productCategory && (
            <FilterLable
              name={t("Category")}
              value={filterOptions.productCategory.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, productCategory: null }))
              }
            />
          )}
          {filterOptions.isProductGroup && filterOptions.productGroup && (
            <FilterLable
              name={t("Product Group")}
              value={filterOptions.productGroup.GroupName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, productGroup: null }))
              }
            />
          )}
          {filterOptions.isBrand && filterOptions.brand && (
            <FilterLable
              name={t("Brand")}
              value={filterOptions.brand.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, brand: null }))
              }
            />
          )}

          {filterOptions.warehouse && (
            <FilterLable
              name={t("Warehouse")}
              value={filterOptions.warehouse.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, warehouse: null }))
              }
            />
          )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product Code")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Product")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Purchase Price")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Sales Price")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Stock In Base Unit")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Stock")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Cost")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Unit EXP")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Cost + EXP")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Total EXP")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Total Cost")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Grand Total")} </VBTableCellHeader>
          </VBTableHeader>
          {isLoading.report ? <VBSkeletonLoader /> : <TableBody>
            {reportData.data.map((data, i) => (
              <TableRow                 
                sx={{ cursor: data.id === "nan" || "" ? "auto" : "pointer" }} 
                onClick={() => data.id === "nan" || "" ? null : goToInvoice(data.id, "Create Product",)}
              >
                <VBTableCellBody>
                  {(page_no - 1) * appSettings.itemPerPage + i + 1}
                </VBTableCellBody>
                <VBTableCellBody>{data.ProductCode}</VBTableCellBody>
                <VBTableCellBody>{data.ProductName}</VBTableCellBody>
                <VBTableCellBody>
                  {formatNumber(data.PurchasePrice)}
                </VBTableCellBody>
                <VBTableCellBody>
                  {formatNumber(data.SalesPrice)}
                </VBTableCellBody>
                <VBTableCellBody>{data.StockInBaseUnit} {data.StockInBaseUnit !== 0 && data.BaseUnitName} </VBTableCellBody>
                <VBTableCellBody>{data.Stock} {data.Stock !== 0 && data.UnitName}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.Cost)}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.UnitEXP)}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.CostEXP)}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.TotalEXP)}</VBTableCellBody>
                <VBTableCellBody>
                  {formatNumber(data.TotalCost)}
                </VBTableCellBody>
                <VBTableCellBody>
                  {formatNumber(data.GrandTotal)}
                </VBTableCellBody>
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>}
          {/* {reportData.TotalData && ( */}
          <VBTableFooter>
            <TableRow>
 
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalStockInBaseUnit)}
              </VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalCost)}
              </VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalCostEXP)}
              </VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalTotalEXP)}
              </VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalTotalCost)}
              </VBTableCellFooter>
              <VBTableCellFooter loader={totalDataLoader|isLoading.report}>
                {formatNumber(TotalData.TotalGrandTotal)}
              </VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
          {/* )} */}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.date}
                fromOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, date: value })
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup radios={radiosValue} />
            </ListItem>
            
            {filterOptions.isProduct && (
            <ListItem>
               <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Inactive Products"),
                    checked: filterOptions.showActiveProductsOnly
                      ? false
                      : true,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        showActiveProductsOnly: !prev.showActiveProductsOnly,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem>)
            }
            <ListItem>
              {/* Product Search AutoComplete */}
              {filterOptions.isProduct && (
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={"Search for Product"}
                  value={filterOptions.product}
                  loading={isLoading.isLoadingProduct}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                      (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                      <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                    </Box>
                  )}
                  onChange={(e, newValue) =>

                    setFilterOptions((prev) => ({ ...prev, product: newValue }))
                  }
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, product: value });
                    }
                  }}
                  options={filterData.productList}
                  getOptionLabel={(option) => option.ProductName}
                />
              )}
              {filterOptions.isProductCategory && (
                <ViknAutoComplete
                  placeholder={"Category"}
                  value={filterOptions.productCategory}
                  onChange={(e, newValue) =>
                    debouncedSetFilterOptions(newValue)
                  }
                  options={filterData.productCategoryList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                />
              )}
              {filterOptions.isProductGroup && (
                <ViknAutoComplete
                  placeholder={"Group"}
                  value={filterOptions.productGroup}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      productGroup: newValue,
                    }))
                  }
                  options={filterData.productGrouplist}
                  getOptionLabel={(option) => option.GroupName}
                  // isOptionEqualToValue={(option, value) => option?.GroupName === value?.GroupName}
                />
              )}
              {filterOptions.isBrand && (
                <ViknAutoComplete
                  placeholder={"Brand"}
                  value={filterOptions.brand}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, brand: newValue }))
                  }
                  options={filterData.brandList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                />
              )}
            </ListItem>
            <ListItem>
              <ViknAutoComplete
                placeholder={"Warehouse"}
                label={t("Warehouse")}
                value={filterOptions.warehouse}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, warehouse: newValue }))
                }
                options={filterData.warehouseList}
                getOptionLabel={(option) => option?.name}
                // isOptionEqualToValue={(option, value) => option?.name === value?.name}
              />
            </ListItem>

            {/* <Divider /> */}
            {/* <ListItem>
              <VBInputField />
            </ListItem> */}
          </List>
        }
      />
    </>
  );
};

export default StockValueReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "26%",
}));
