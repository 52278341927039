import React, { useEffect, useState } from "react";
import VBRouter from "./Router/Router";
import Header from "./Components/Header/Header";
import { Alert, Box, CssBaseline, Snackbar, styled, ThemeProvider } from "@mui/material";
import SideNavigation from "./Components/SideNavigation/SideNavigation";
import { useDispatch, useSelector } from "react-redux";
import { callSettingsTunk } from "./features/generalSettingsSclice";
import { useNetwork } from "@mantine/hooks";
import { closeSnackbar } from "./features/SnackBarslice";
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import LoaderLite from "./Components/Utils/LoaderLite";
// import { callUserRollTunk } from "./features/userRoleSclice";
import { callUserTableTunk } from "./features/userTableSlice";
import { callUserRollTunk } from "./features/rollPremessionSclice";
import { getCookie } from "./generalFunction";
import AlertWindow1 from "./Components/Utils/AlertWindow1";
import AppSettings from "./Components/Modals/AppSettings";
import { lightTheme } from "./theme";

const MainPage = () => {
  const snackData = useSelector((state) => state.SnackBarslice.snackBarRedux);
  const alertData = useSelector((state) => state.SnackBarslice.alertPopUp);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_type } = useSelector((state) => state.userTable);
  const theme = useSelector((state) => state.themeSclice);
  
  
  const dispatch = useDispatch();
  const networkStatus = useNetwork();
  const [loading, setLoading] = useState(false); // header loader temp turn off naszi
  const [openModal, setopenModal] = useState(false);

  const handleCloseModa = function () {
    setopenModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar({}));
  };

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === "0") {
      event.preventDefault();
      setopenModal(!openModal);
    }
  };

  useEffect(
    () => {
      if (getCookie("is_change") !== "true") {
        dispatch(callSettingsTunk());
        dispatch(callUserTableTunk());
      }
    },
    [CompanyID, BranchID]
    // []
  );

  useEffect(() => {
    if (getCookie("is_change") !== "true") {
      dispatch(callUserRollTunk());
    }
  }, [user_type]);

  // ShortCut Area
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={lightTheme(theme)}>
        <CssBaseline />

        <div className="">
          <Header setLoading={setLoading} />
        </div>
        {loading ? (
          <LoaderLite fullscreen={true} ContainerWidth={"100%"} />
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "65px" }}>
              <SideNavigation />
            </Box>
            <ComponentContainer>
              <VBRouter />
            </ComponentContainer>
            <Snackbar open={!networkStatus.online} autoHideDuration={6000}>
              <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                Connection Losed.....
              </Alert>
            </Snackbar>
          </div>
        )}
        {/* <VBRouter /> */}
        {snackData && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackData.open}
            autoHideDuration={2500}
            onClose={handleClose}
          >
            <Alert
              elevation={4}
              onClose={handleClose}
              severity={snackData.severity || "info"}
              variant="filled"
              iconMapping={{
                error: <ErrorOutlineOutlined fontSize="inherit" />,
                warning: <WarningOutlined fontSize="inherit" />,
                success: <CheckCircleOutlineOutlined fontSize="inherit" />,
              }}
              sx={{
                width: "100%",
                // color: snackData.severity === 'warning' ? 'white' : snackData.severity === 'error' ? 'white' : 'white', // Adjusted color based on severity
                // backgroundColor:  snackData.severity === 'warning' ? 'black' : snackData.severity === 'error' ? '#cc3300' : '#339900'
              }}
            >
              {snackData.message}
            </Alert>
          </Snackbar>
        )}

        {/* For Safty purpes */}
        {alertData.open && <AlertWindow1/>}
        <AppSettings open={openModal} handleClose={handleCloseModa} />
      </ThemeProvider>
    </>
  );
};

export default MainPage;

const ComponentContainer = styled(Box)(() => ({
  width: "100%",
  height: "calc(100vh - 62px)",
  padding: "10px",
  paddingTop: "0px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar-track": {
    display: "none",
  },
}));
