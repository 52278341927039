import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("companyUrl")
  ? JSON.parse(localStorage.getItem("companyUrl"))
  : {
      companyUrl: "",
    };
const companyUrlSlice = createSlice({
  name: "companyUrl",
  initialState,
  reducers: {
    companyUrlSuccess: (state, action) => {
      state.companyUrl = action.payload.companyUrl;
      localStorage.setItem("companyUrl", JSON.stringify(action.payload));
    },
  },
});

export const { companyUrlSuccess } = companyUrlSlice.actions;

export const selectcompanyUrl = (state) => state.companyUrl;

export default companyUrlSlice.reducer;
