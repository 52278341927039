import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import FilterLable from "../../../../Components/Utils/FilterLable";
import SearchReport from "../../../../Components/Utils/SearchReport";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import PrintBtn from "../../../../Components/Utils/PrintBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { CallExpenseSummaryReport } from "../../../../Api/Reports/FinanceApi";
import moment from "moment";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { CallBranchsList, CallLedgerListByGroupsV11, CallUsers, CallWarehouses } from "../../../../Api/Reports/CommonReportApi";
import {
  callSalesReport,
  callSalesSummary,
} from "../../../../Api/Reports/SalesApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { callSearchAccountLedgers } from "../../../../Api/Reports/PurchaseApi";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { formatNumber } from "../../../../generalFunction";
import { VIKNBOOKS_FRONT_URL } from "../../../../generalVeriable";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { UserRolePermission } from "../../../../Function/Editions";

const SalesSummaryReport = () => {

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePenIconClose = () => {
    setAnchorEl(null)
  };

  let Export_view = UserRolePermission("Sales Summary Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({
    count: 0,
    data: [],
  });
  const [page_no, setPage_no] = useState(1);
  const [progress, setProgress] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingLedgers, setisLoadingLedgers] = useState(false);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");
  const [t, i18n] = useTranslation("common");
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableWarehouse} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const Is_Warehouse = EnableWarehouse
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    userList : [],
    UserObj : {customer_name : "" },

    LedgerList : [],
    LedgerObj : {LedgerName :''},

    WarehouseList : [],
    WarehouseObj : {WarehouseName :''},

    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },
    branch: {
      name: "Branch",
      isActive: false,
      value: "",
    },
    filterList : [
      {name: 'cashsales', label: t("Cash Sales"), isActive:false },
      {name: 'banksales', label: t('Bank Sales'),isActive:false},
      {name: 'creditsales', label: t('Credit Sales'),isActive:false},
    ],
    pdfFilterData:[
      {name: 'cashsales', label: t("Cash Sales"), isActive:false },
      {name: 'banksales', label: t('Bank Sales'),isActive:false},
    ]
  });

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const searchReport = function () {
    fetchReport();
  };

  // const handleAutoComplete = function (e, value) {
  //   if (value) {
  //     let date = date_range(value.name);
  //     setFilterOptions({
  //       date: {
  //         isActive: true,
  //         value: value,
  //         ToDate: date.todate,
  //         FromDate: date.fromdate,
  //         ...filterOptions.date,
  //       },
  //       ...filterOptions,
  //     });
  //   } else {
  //     let date = date_range();
  //     setFilterOptions({
  //       date: {
  //         isActive: false,
  //         value: { name: "Today" },
  //         ToDate: date.todate,
  //         FromDate: date.fromdate,
  //         ...filterOptions.date,
  //       },
  //       ...filterOptions,
  //     });
  //   }
  // };

  const handleDeletefilter = (name, index) => {
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
    }
  };

  const handleChange = (index) => {
    const UpdatefilterList = filterOptions.filterList.map((item,i) => {
      if (i === index){
        return {
          ...item,
          isActive : !item.isActive
        }
      }
      return item 
    });
    setFilterOptions({
      ...filterOptions,
      filterList : UpdatefilterList,
    })
  }

  const handlePdfFilterChange = (index) => {
    const UpdatefilterList = filterOptions.pdfFilterData.map((item,i) => {
      if (i === index){
        return {
          ...item,
          isActive : !item.isActive
        }
      }
      return item 
    });
    setFilterOptions({
      ...filterOptions,
      pdfFilterData : UpdatefilterList,
    })
  }  
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "date") {
        if (value.name === "Custom") {
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: true,
              value: value,
            },
          });
        } else {
          let date = date_range(value.name);
          setFilterOptions({
            ...filterOptions,
            date: {
              ...filterOptions.date,
              isActive: false,
              value: value,
              ToDate: date.todate,
              FromDate: date.fromdate,
            },
          });
        }
      } else {
        setFilterOptions({ ...filterOptions, [name]: value });
      }
    }
  };

  const handleFilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.FromDate &&filterdata.date.ToDate ) {
      let date = String(filterOptions.date.FromDate.format("DD-MM-YYYY") + " to " + filterOptions.date.ToDate.format("DD-MM-YYYY"))
      list.push({ name: t("Date"), value: date });
    }
    if (filterdata && filterdata.UserObj && filterdata.UserObj.customer_name !== undefined && filterdata.UserObj.customer_name !== ''){
      list.push({
        name: t('User'),
        value: filterdata.UserObj.customer_name,
        handleDelete: () => handleDeletefilter('UserObj')
      })
    }
    if (filterdata && filterdata?.LedgerObj?.LedgerName !== undefined && filterdata?.LedgerObj?.LedgerName !== '') {
      list.push({
        name : t('Ledger'),
        value : filterdata?.LedgerObj?.LedgerName,
        handleDelete : () => handleDeletefilter('LedgerObj')
      })
    }
    if (filterdata && filterdata.WarehouseObj?.WarehouseName !== undefined && filterdata.WarehouseObj.WarehouseName !== '') {
      list.push({
        name : t('Warehouse'),
        value : filterdata.WarehouseObj.WarehouseName,
        handleDelete: () => handleDeletefilter('WarehouseObj')
      })
    }
    if (filterdata.filtertype !== undefined && filterdata.filtertype !== '') {
      list.push({
        name : t('Filter Type'),
        value : filterdata.filtertype,
      })
    }
    filterdata.filterList?.forEach((item,index)=> {
      if (item.isActive === true) {
        list.push({
          name : t('Filter Type'),
          value : item.label,
          handleDelete: () => handleChange(index)

        })
      }
    })
    return list;
  } 

  const handleSearchLedgers = async (e,value) => {
    if (value) {
      setisLoadingLedgers(true);
      let length = value.length;
      let LedgersList = await CallLedgerListByGroupsV11({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        name : value,
        length : length,
        GroupUnder : [8, 9, 10, 29],
      });
      let LedgerList = [];
      if (LedgersList.data){
        LedgerList = LedgersList.data
      }
      setFilterOptions({...filterOptions,LedgerList : LedgersList.data})
      setisLoadingLedgers(false)
    } else {
      setFilterOptions({...filterOptions,LedgerObj : {LedgerName : ''}});
    }
  };

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      UserObj: { customer_name: "" },
      WarehouseObj : {WarehouseName: ''},
      Barcode : '',
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      LedgerObj : {LedgerName :''},
      filterList : [
        {name: 'cashsales', label: 'Cash Sales', isActive:false },
        {name: 'banksales', label: 'Bank Sales',isActive:false},
        {name: 'creditsales', label: 'Credit Sales',isActive:false},
      ],
    })
  }
  
  const handlePenIconClick = (event) => {
    setAnchorEl(event.currentTarget)
  };

  // API Fetch data
  const fetchReport = async function () {
    setLoading(true);
    const data = await callSalesSummary({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.date.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.date.ToDate.format("YYYY-MM-DD"),
      UserID: filterOptions?.UserObj?.id ? filterOptions.UserObj.id : 0,
      LedgerID: filterOptions.LedgerObj?.LedgerID ? filterOptions.LedgerObj.LedgerID : 0,
      WarehouseID: filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0 ,
      offset: (page_no -1) * appSetting.itemPerPage,
      page_no: page_no,
      items_per_page: appSetting.itemPerPage,
      count : reportData?.count ? reportData.count : 0,
    },dispatch);
    if (data?.StatusCode === 6000) {
      setReportData(data);
    } else if (data?.StatusCode === 6001){
      setReportData();
      dispatch(
        openSnackbar({
          open : true,
          message : data?.message,
          severity : 'warning',
        })
      )
    }
    setLoading(false);
  };

  const filterApis = async function () {
    setIsLoadingUser(true);
    const userResponse = await CallUsers({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
    })
    let userList = [];
    if (userResponse.StatusCode === 6000) {
      userList = userResponse.data;
    }
    let WarehouseList = [];
    const WarehouseLists = await CallWarehouses ({
      CompanyID : CompanyID,
      BranchID : BranchID,
      CreatedUserID: user_id,
      show_all:true
    })
    if (WarehouseLists.StatusCode === 6000){
      WarehouseList = WarehouseLists.data;
    }
    setFilterOptions({
      ...filterOptions,
      userList,
      WarehouseList,
    })
    setIsLoadingUser(false);

  };

  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.data?.slice(0,reportData?.data?.length -1)
    return alldata.slice(startIndex, endIndex) || [];
  }

  useEffect(() => {
    filterApis()
  }, []);
  useEffect(() => {
    fetchReport()
  },[page_no])
  const handleTableRowClick = (data) => {
    const destinationURL = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single?id=${data.id}`;
    window.open(destinationURL, '_blank');
  };
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className='left'>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Sales Summary Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className='right'>
            {Export_view ? 
            <ReportDownload
              invoice_type={"sales_summary"}
              download_type={"PDF"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              UserID={filterOptions?.UserObj?.id ? filterOptions.UserObj.id : 0}
              WarehouseID={filterOptions.WarehouseObj?.WarehouseID?filterOptions.WarehouseObj.WarehouseID : 0 }
              LedgerID={filterOptions.LedgerObj?.LedgerID ? filterOptions.LedgerObj.LedgerID : 0}
              is_celery={true}
              setProgress={setProgress}
              setProgressMessage={setProgressMessage}
              showBankLedgerDetailsInPDF={filterOptions.pdfFilterData.find(i=>i.name==='banksales').isActive}
              showCashLedgerDetailsInPDF={filterOptions.pdfFilterData.find(i=>i.name==='cashsales').isActive}
            />
            : null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.count}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handleFilterList(filterOptions).map((val, i) => (
          <FilterLable
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>
        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('Serial No')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Date')}</VBTableCellHeader>
            <VBTableCellHeader>{t('Invoice No')} </VBTableCellHeader>
            <VBTableCellHeader>{t('Ledger Name')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Gross Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Tax Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Net Amount')} </VBTableCellHeader>
            {filterOptions.filterList?.some(item => item.label === 'Cash Sales' && item.isActive)?<VBTableCellHeader sx={{textAlign:"right"}}>{t('Cash Sale')}</VBTableCellHeader> : null}
            {filterOptions.filterList?.some(item => item.label === 'Bank Sales' && item.isActive)?<VBTableCellHeader sx={{textAlign:"right"}}>{t('Bank Sale')}</VBTableCellHeader> : null}
            {filterOptions.filterList?.some(item => item.label === 'Credit Sales' && item.isActive)?<VBTableCellHeader sx={{textAlign:"right"}}>{t('Credit Sale')}</VBTableCellHeader> : null}
            {/* <VBTableCellHeader>Grand Total </VBTableCellHeader> */}
            <VBTableCellHeader
              sx={{ textAlign: "right", width: "20px", padding: "3px" }}
            >
            <BorderColorIcon
              sx={{
                color: "#0A9EF3",
                cursor: "pointer",
                width: "20px",
                textAlign: "center",
              }}
              onClick={handlePenIconClick}
            />
            </VBTableCellHeader>
          </VBTableHeader>
          {Loading ?
          <VBSkeletonLoader /> :
          <TableBody>
            {reportData?.data?.slice(0,-1).map((data,i) => (
              <TableRow
              onClick={() =>
                handleTableRowClick(data, data.VoucherType, data.VoucherNo)
              }
              >
                <VBTableCellBody sx={{cursor:"pointer"}}>{(page_no - 1) * appSetting.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherDate}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer"}}>{data.LedgerName}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrossAmount)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.TotalTax)}</VBTableCellBody>
                <VBTableCellBody sx={{cursor:"pointer",textAlign:"right"}}>{formatNumber(data.GrandTotal)}</VBTableCellBody>

                {filterOptions.filterList?.some(item => item.label === 'Cash Sales' && item.isActive)?<VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CashSales)}</VBTableCellBody> : null}
                {filterOptions.filterList?.some(item => item.label === 'Bank Sales' && item.isActive)?<VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.BankSales)}</VBTableCellBody> : null}
                {filterOptions.filterList?.some(item => item.label === 'Credit Sales' && item.isActive)?<VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CreditSales)}</VBTableCellBody> : null}
              </TableRow>
            ))}
          <VBTableRowNull length = {reportData?.data?.length }/>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.GrossAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.TotalTax ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data.length -1]?.GrandTotal ?? 0)}</VBTableCellFooter>

              {filterOptions.filterList?.some(item => item.label === 'Cash Sales' && item.isActive)?<VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.CashSales ?? 0)}</VBTableCellFooter> : null}
              {filterOptions.filterList?.some(item => item.label === 'Bank Sales' && item.isActive)?<VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.BankSales ?? 0)}</VBTableCellFooter> : null}
              {filterOptions.filterList?.some(item => item.label === 'Credit Sales' && item.isActive)?<VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.data?.[reportData.data?.length -1]?.CreditSales ?? 0)}</VBTableCellFooter> : null}
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        setPage_no = {setPage_no}
        onFilter={() => fetchReport()}
        handleDefaultFilter={() => handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <ListItem>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Filter Name
                </InputLabel>
                <VBInputField />
              </FormControl>
            </ListItem> */}
            {/* <ListItem>
              <ViknAutoComplete
                value={filterOptions.date.value}
                disableClearable
                options={[
                  { name: t("Custom") },
                  { name: t("Today") },
                  { name: t("This Week") },
                  { name: t("This Month") },
                  { name: t("Last 90 Days") },
                  { name: t("This Year")},
                  { name: t("Last Year") },
                ]}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) =>
                  handleAutoComplete(e, newValue, "date")
                }
                // onChange={(e, newValue) => handleAutoComplete(e, newValue)}
              />
            </ListItem> */}
            <Divider />
            <ListItem>
              <VBDatePiker
                  handleAutoComplete={handleAutoComplete}
                  dateValue={filterOptions.date.value}
                  isDateRange={true}
                  fromDate={filterOptions.date.FromDate}
                  toDate={filterOptions.date.ToDate}
                  // fromReadOnly={filterOptions.date.value.name !== "Custom"}
                  // toReadOnly={filterOptions.date.value.name !== "Custom"}
                  fromOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        FromDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
                  toOnChange={(value) =>
                    setFilterOptions({
                      ...filterOptions,
                      date: {
                        ...filterOptions.date,
                        ToDate: value,
                        value:{ name: "Custom" },
                      },
                    })
                  }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("User")}
                filterName = {t("User")}
                loading = {isLoadingUser}
                options = {filterOptions.userList ? filterOptions.userList : []}
                getOptionLabel={(option) => option.customer_name || "" }
                name = "UserObj"
                onInputChange={(event, value, reason) => { 
                  // if (reason === "input") {
                  //   handleSearchUser(event,value);
                  // }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, UserObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "UserObj")}
                value={filterOptions.UserObj}
                
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Ledgers")}
                filterName = {t("Ledgers")}
                loading = {isLoadingLedgers}
                options = {filterOptions.LedgerList ? filterOptions.LedgerList : []}
                getOptionLabel={(option) => option.LedgerName || "" }
                name = "LedgerObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.LedgerName && option.LedgerName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.LedgerCode && option.LedgerCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.LedgerCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.LedgerName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => { 
                  if (reason === "input") {
                    handleSearchLedgers(event,value);
                  }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, LedgerObj: {} });
                  }
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "LedgerObj")}
                value={filterOptions.LedgerObj}
                
              />
            </ListItem>
            <Divider />
            {Is_Warehouse ? 
            <ListItem>
              <ViknAutoComplete 
                placeholder = {t('Warehouse')}
                label = {t("Warehouse")}
                options = {filterOptions.WarehouseList ? filterOptions.WarehouseList : []}
                getOptionLabel={(option) => option.WarehouseName || "" } 
                value = {filterOptions.WarehouseObj}  
                onChange={(e, v) => handleAutoComplete(e, v, "WarehouseObj")}
              />
            </ListItem>
            :null}
            <Divider />
            <ListItem>
            <ViknCheckboxGroup 
              labelName={t('Show Full Ledger Details on PDF')}
              radios={filterOptions.pdfFilterData && Array.isArray(filterOptions.pdfFilterData) ? 
                filterOptions.pdfFilterData.map((item, index) => ({
                    label: item.label,
                    checked: item.isActive,
                    onChange: () => handlePdfFilterChange(index),
              })) : []
            }/>
            </ListItem>
          </List>
        }
      />
      <Popover
        id={Boolean(anchorEl) ? "simple-popover" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePenIconClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ padding: "10px" }}
      >
        <ViknCheckboxGroup
          radios={filterOptions.filterList && Array.isArray(filterOptions.filterList) ? 
            filterOptions.filterList.map((item, index) => ({
                label: item.label,
                checked: item.isActive,
                onChange: () => handleChange(index),
          })) : []
        }
        />
      </Popover>
    </>
  );
};

export default SalesSummaryReport;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));
