import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatNumber } from "../../../../../generalFunction";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Collapse icon from Material-UI

const ExpandTable = function (props) {
  // const [expanded, setExpanded] = useState("panel" + props.index +1)
  const openReportLink = function(
    id, 
    ledgerName
  ) {

    let fromDate = props.date.fromDate.format("YYYY-MM-DD")
    let toDate = props.date.toDate.format("YYYY-MM-DD")
    const url = `https://viknbooks.com/dashboard/ledger-report?LedgerID=${id}&ToDate=${toDate}&fromDate=${fromDate}&LedgerName=${ledgerName}`
    window.open(url, '_blank');
  }

  return (
    <VBTableCellBody colspan="4" sx={{ padding: 0, paddingBottom: "10px" }}>
      <Accordion
        // expanded={props.expanded === `panel${props.index+1}`}
        expanded={props.expanded.includes(`panel${props.index + 1}`)}
        onChange={props.handleChange(`panel${props.index + 1}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            pl: 0,
            minHeight: "auto",
            backgroundColor: "#e4ebf3",
            "&.MuiAccordionSummary-root": {
              minHeight: "10px", // Adjust the minHeight as needed
            },
            ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
              my: "0px",
            },
            ".MuiAccordionSummary-content": {
              my: 0,
            },
            // '.Mui-expanded .MuiAccordionSummary-content': {
            //   // Customize the margin only when expanded
            //   my: 0,
            // },
          }}
        >
          {/* {props.name} */}
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <VBTableCellBody sx={{ width: "25%" }}>
                  {" "}
                  {props.index}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "34%" }}>
                  {props.name}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "19%", textAlign: "right" }}>
                  {formatNumber(props.TotalDebit)}
                </VBTableCellBody>
                <VBTableCellBody sx={{ width: "18%", textAlign: "right"  }}>
                  {formatNumber(props.TotalCredit)}
                </VBTableCellBody>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <VBTableContainer sx={{ width: "100%" }}>
            <TableBody>
              {props.data.map((e, i) => (
                <TableRow 
                  sx={{ cursor: e.AccountGroupName === "Opening Stock"? "" :"pointer"}}
                  onClick={() => openReportLink(e.LedgerID, e.LedgerName)}
                >
                  <VBTableCellBody sx={{ width: "25%" }}>
                    <span> {i + 1} </span>
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "34%" }}>
                    {e.LedgerName}{" "}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "19%", textAlign: "right"  }}>
                    {formatNumber(e.TotalDebit)}
                  </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "21%", textAlign: "right"  }}>
                    {formatNumber(e.TotalCredit)}
                  </VBTableCellBody>
                </TableRow>
              ))}
            </TableBody>
          </VBTableContainer>
        </AccordionDetails>
      </Accordion>
    </VBTableCellBody>
  );
};
const TrialBalanceTable = ({ filterData, handleChangeExpand, isLoadiing, date }) => {
  const [expanded, setExpanded] = useState(null);  // To manage open/close state

  const [grandTotal, setGrandTotal] = useState({
    totalCredit: "",
    totalDebit: "",
  });
  const [t, i18n] = useTranslation("common");
  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle open/close
  };
  const calculateTotals = () => {
    const { totalDebit, totalCredit } = filterData.trileBalanceList.reduce(
      (accumulator, currentItem) => {
        return {
          totalDebit: accumulator.totalDebit + currentItem.TotalDebit,
          totalCredit: accumulator.totalCredit + currentItem.TotalCredit,
        };
      },
      { totalDebit: 0, totalCredit: 0 }
    );
    setGrandTotal({ totalDebit, totalCredit });
  };

  useEffect(() => {
    calculateTotals();
  }, [filterData.trileBalanceList]);

  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",width:"92px"}}>{t("Serial No")} </VBTableCellHeader>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",}}>{t("Ledger Name")} </VBTableCellHeader>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",textAlign: "center" }}>{t("Debit")} </VBTableCellHeader>
        <VBTableCellHeader sx={{textAlign: "center" }}>{t("Credit")} </VBTableCellHeader>
        <VBTableCellHeader sx={{textAlign: "" }}>{" "} </VBTableCellHeader>
      </VBTableHeader>
      {isLoadiing ? <VBSkeletonLoader /> : <TableBody>
        {filterData.trileBalanceList.map((e, i) => (
          // <TableRow>
          //   <ExpandTable
          //     expanded={expanded}
          //     handleChange={handleChangeExpand}
          //     index={i + 1}
          //     name={e.AccountGroupName}
          //     data={e.data}
          //     TotalCredit={e.TotalCredit}
          //     TotalDebit={e.TotalDebit}
          //     date={date}
          //   />
          // </TableRow>
          <React.Fragment key={i}>
          {/* Main Account Row */}
          <TableRow onClick={() => handleToggle(i)} >
            <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#dff0ff":"",fontSize: "15px",paddingLeft: "20px", cursor: 'pointer' }}>{i + 1}</VBTableCellBody>
            <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#dff0ff":"",fontSize: "15px" }}>{e.AccountGroupName}</VBTableCellBody> {/* Reduced font size */}
            <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#dff0ff":"", textAlign: "right"}}>{formatNumber(e.TotalDebit)}</VBTableCellBody>
            <VBTableCellBody sx={{ backgroundColor:expanded === i?"#dff0ff":"", textAlign: "right"}}>{formatNumber(e.TotalCredit)}</VBTableCellBody>
            <VBTableCellBody sx={{ backgroundColor:expanded === i?"#dff0ff":"", transition: 'transform 0.3s ease', width:"15px"}}>
            {/* transition: 'transform 0.3s ease', 
            transform: expanded === i ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate icon */}
              {/* {expanded === i ?  */}
                <ExpandLessIcon sx={{ color: '#0a9ef3' ,marginLeft: 'auto', display: 'inline-block',transition: 'transform 0.3s ease', transform:expanded === i ? 'rotate(0deg)' : 'rotate(180deg)', }} /> 
              {/* // :  */}
              {/* //   <ExpandMoreIcon sx={{ marginLeft: 'auto', display: 'inline-block',transition: 'transform 0.3s ease', }} /> */}
              {/* // } */}
            </VBTableCellBody>
          </TableRow>

          {/* Data Rows under this account with toggle (open/close) */}
          {expanded === i && e.data.map((item, idx) => (
            <TableRow key={`data-${idx}`}>
              <VBTableCellBody sx={{borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#f0f8ff":"",fontSize: "13px", paddingRight: "40px" ,textAlign: "right",}}>{idx + 1}</VBTableCellBody> {/* Indentation */}
              <VBTableCellBody sx={{borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#f0f8ff":"",fontSize: "13px",  }}>{formatNumber(item.LedgerName)}</VBTableCellBody>
              <VBTableCellBody sx={{borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#f0f8ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.TotalDebit)}</VBTableCellBody>
              <VBTableCellBody sx={{backgroundColor:expanded === i?"#f0f8ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.TotalCredit)}</VBTableCellBody>
              <VBTableCellBody sx={{backgroundColor:expanded === i?"#f0f8ff":"",fontSize: "13px",  }}>{" "}</VBTableCellBody>
            </TableRow>
          ))}
        </React.Fragment>
          
        ))}
        <TableRow></TableRow>
      </TableBody>}
      <VBTableFooter>
        <TableRow>
          <VBTableCellFooter sx={{borderRight:"1px solid #cac6c6",}}>{t("Total")}</VBTableCellFooter>
          <VBTableCellFooter sx={{borderRight:"1px solid #cac6c6",}}></VBTableCellFooter>
          <VBTableCellFooter sx={{ borderRight:"1px solid #cac6c6",textAlign: "right" }} >{formatNumber(grandTotal.totalDebit)} </VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }} >{formatNumber(grandTotal.totalCredit)} </VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }} > </VBTableCellFooter>
        </TableRow>
      </VBTableFooter>
    </VBTableContainer>
  );
};

export default TrialBalanceTable;
