import axios from "axios";
import { BASE_URL } from "../../generalVeriable";
import { store } from "../../app/store";
import { get_user_role_parents } from "../../Components/Utils/UserRoleJsons";

const callUserRoll = async function () {
  try {
    const state = store.getState();
    const { CompanyID, BranchID } = state.companyDetails;
    const user_type = state.userTable;
    const { data } = await axios.post("api/v10/settings/user-role/settings/list-api/", {
      BranchID: BranchID,
      CompanyID: CompanyID,
      UserType: user_type.user_type,
      // CreatedUserID: user_id,
      // action_type: "List",
      // PreDate: 0,
      // PostDate: 0,
      // permission_datas: get_user_role_parents,
    });
    return data;
  } catch (error) {
    console.log("error in callUserRoll ");
  }
};

const callUserTable = async function () {
  try {
    const state = store.getState();
    const { CompanyID, BranchID } = state.companyDetails;
    const { user_id } = state.user;
    const { data } = await axios.post("api/v10/users/get-user-table/", {
      CompanyID: CompanyID,
      BranchID: BranchID,
      userId: user_id,
    });
    return data;
  } catch (error) {
    console.log("error in callUser Table ");
  }
};

const callGeneralSettings = async function (body) {
  try {
    const state = store.getState();
    const { CompanyID, BranchID } = state.companyDetails;
    const { user_id } = state.user;
    const { data } = await axios.post("api/v10/users/general-settings-list/", {
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in callGeneralSettings", error);
  }
};
const callGeneralSettingsList = async function (body) {
  try {
    const state = store.getState();
    const { CompanyID, BranchID } = state.companyDetails;
    const { data } = await axios.post("api/v10/users/general-settings-list/", {
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: state.user.user_id,
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in callGeneralSettings", error);
  }
};

const callGeneralSettingsSingle  = async function (body) {
  try {
    const { data } = await axios.post("api/v11/users/general-settings/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in callGeneralSettings", error);
  }
};

// This will not important for the first stage of VB only for testing purpos
const callCompanies = function (body) {
  const state = store.getState();
  const { user_id } = state.user;
  const { data } = axios.post("api/v11/users/companies/", {
    userId: user_id,
  });
  return data;
};

const callBranches = function (body) {
  const { data } = axios.post("");
};

const get_UserRoleSettings = async (
  CompanyID,
  BranchID,
  UserType,
  access,
  CreatedUserID,
  permission_datas,
  action_type,
  BASE_URL1,
  PreDate = 0,
  PostDate = 0
) => {
  try {
    let data_response = await fetch(`${BASE_URL1}/api/v10/settings/user-role/settings/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        CompanyID: CompanyID,
        UserType: UserType,
        CreatedUserID: CreatedUserID,
        BranchID: BranchID,
        permission_datas: permission_datas,
        action_type,
        PreDate,
        PostDate,
      }),
    }).then((response) => response.json());
    let datas = [];
    if (data_response.StatusCode === 6000) {
      datas = data_response.data;
    }
    return datas;
  } catch (error) {
    console.log("error in callGeneralSettings", error);
  }
};

export {
  callUserRoll,
  callGeneralSettings,
  callCompanies,
  get_UserRoleSettings,
  callGeneralSettingsList,
  callGeneralSettingsSingle,
  callUserTable,
};
