import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
  Popover,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../generalFunction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_stock_management,
  list_stock_management,
} from "../../Api/CommonApi/InvoiceApis/StockManagementApi";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CreateNewButton,
  DeleteButton,
} from "../../Components/Utils/StyledButtons";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import { list_sales_api, delete_sales_api } from "../../Api/Sales/SalesApis";
import { MenuOptions } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import { domain, Report_URL, VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
import ComingSoon from "./ComingSoon";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";
import SideMenuBottom from "../../Components/Utils/BottomNavigationMenu";
import SearchTextField from "../../Components/Utils/SearchTextField";
import ViknCheckboxGroup from "../../Components/Utils/ViknCheckboxGroup";

const SalesInvoiceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableBillwise,
    EnableWarehouse,
    EnableSalesManInSales,
    PriceCategory,
    EnableDeliveryDateInSales,
    EnableShippingCharge,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Invoice"
  );
  let sales_delete = sales_permission[0].delete_permission;
  let sales_edit = sales_permission[0].edit_permission;
  let sales_view = sales_permission[0].view_permission;
  let sales_save = sales_permission[0].save_permission;

  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain: "books",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];

  const { EnableEinvoiceKSA } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const { user_id } = useSelector((state) => state.user);
  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    selectedItems: [],
    isSelectedAll: false,
  });


  const [filterOptions, setFilterOptions] = useState({
    CustomTable: {
      User :{ isActive : false,},
      Salesman: {isActive: false,},
    },
  })

  const [searchText, setSearchText] = useState("");

  const OptionList = [
    { name: "Edit", permission: sales_edit },
    { name: "Delete", permission: sales_delete },
    { name: "Clone" },
  ];
  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit") {
        if (permission) {
          navigate("/create-sales", {
            state: { is_edit: true, uniq_id: uniq_id },
          });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for edit",
              severity: "warning",
            })
          );
        }
      } else if (name === "Delete") {
        if (permission) {
          setState({ ...state, selectedItems: [uniq_id] });
          setConfirmBox(true);
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      }if (name === "Clone") {
        navigate("/create-sales", {
          state: { is_clone: true, uniq_id: uniq_id },
        });
      }
    }
  };

  const handleChangeCheckbox = (option) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      CustomTable: {
        ...prevState.CustomTable,
        [option]: {
          isActive: !prevState.CustomTable[option].isActive,
        },
      },
    }));
  };

  const [page_no, setPage_no] = useState(1);

  const SingleView = (id) => {
    if (sales_view) {
      const url = `${VIKNBOOKS_FRONT_URL}dashboard/sale-invoice-single?id=${id}&unq_id=${id}`;
      const newTab = window.open(url, "_blank");
      // navigate("/sales-invoice", {
      //   state: { page_no: page_no, uniq_id: id },
      // });
    }
  };

  const [IsConfirmBox, setConfirmBox] = useState(false);

  const noOfItems = appSetting.itemPerPage;

  
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("common");

  const fetchData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      search: searchText,
      page: page_no,
      noOfItems: noOfItems,
    };
    let responseData = await list_sales_api({ ...payload });
    
    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message:
            responseData?.message + " : " + responseData?.error ??
            "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const deleteData = async () => {
    if (sales_delete) {
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        id_list: state.selectedItems,
      };

      let responseData = await delete_sales_api({ ...payload });

      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Deleted Successfully",
            severity: "success",
          })
        );

        fetchData();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "You don't have permission for delete",
          severity: "warning",
        })
      );
    }
  };

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  useEffect(() => {
    fetchData();
  }, [searchText, page_no]);

  return (
    <>
        <Paper sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              px: "26px",
              py: "15px",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <FlexBox className="left">
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "500",
                  mr: 2,
                  color: "#001746",
                }}
                variant="h2"
              >
                {t("Sales List")}
              </Typography>
              <SearchTextField
                value={searchText}
                escClearState={setSearchText}
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  page_no !== 1 && setPage_no(1)
                  }}
          />
            </FlexBox>

            <FlexBox className="right">
              {!EnableEinvoiceKSA && (
                <DeleteButton onClick={confirmDelete} t={t} />
              )}

              <CreateNewButton
                onClick={() => {
                  if (sales_save) {
                    navigate("/create-sales");
                  } else {
                    dispatch(
                      openSnackbar({
                        open: true,
                        message: "You don't have permission for create",
                        severity: "warning",
                      })
                    );
                  }
                }}
                t={t}
              />

              <Pagenation
                totalItem={state.data_count}
                page_no={page_no}
                setPage_no={setPage_no}
              />
            </FlexBox>
          </Box>

          {/* ==============TABLE=============== */}

          <VBTableContainer>
            <VBTableHeader>
              <VBTableCellHeader
                sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
              >
                <Checkbox
                  checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
                  onChange={() => {
                    selectItems("all");
                  }}
                  sx={{
                    padding: "2px",
                    margin: 0,
                    color: blue[400],
                    "&.Mui-checked": {
                      color: blue[400],
                    },
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                />
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "left" }}>
                {t("Serial No")}
              </VBTableCellHeader>
              <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
              <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
              <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
              {filterOptions.CustomTable.User.isActive && <VBTableCellHeader>{t("User")}</VBTableCellHeader>}
              {filterOptions.CustomTable.Salesman.isActive && <VBTableCellHeader>{t("Salesman")}</VBTableCellHeader>}
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Gross Amount")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Total Tax")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader sx={{ textAlign: "right" }}>
                {t("Grand Total")}{" "}
              </VBTableCellHeader>
              <VBTableCellHeader>{t("Status")} </VBTableCellHeader>
              {/* {!EnableEinvoiceKSA && (
                <VBTableCellHeader
                  sx={{ textAlign: "right" }}
                > </VBTableCellHeader>
              )} */}

            <VBTableCellHeader sx={{textAlign:"right"}}>
              <BorderColorIcon
                sx={{ color: "#0A9EF3", cursor: "pointer", width: "20px",textAlign:"center" }}
                onClick={handleClick}
                />
            </VBTableCellHeader>
            </VBTableHeader>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{padding: '10px'}}
            >
              <ViknCheckboxGroup 
              radios={
                [{
                  label : t("User"),
                  checked: filterOptions.CustomTable.User.isActive,
                  onChange: () => handleChangeCheckbox('User'),
                },{
                  label : t("Salesman"),
                  checked: filterOptions.CustomTable.Salesman.isActive,
                  onChange: () => handleChangeCheckbox('Salesman'),
                }]
              }
              />

            </Popover>
            {loading ? (
              <VBSkeletonLoader />
            ) : (
              <TableBody>
                {state.dataList.map((data, i) => (
                  <TableRow sx={{height:EnableEinvoiceKSA && "48px"}}
                  // onClick={() =ableRow sx={{height:"48px"}}> navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
                  //   onClick={()=>{handleTableRowClick(data)}}
                  >
                    <VBTableCellBody
                      sx={{
                        // cursor: "pointer",
                        textAlign: "left",
                        padding: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      <Checkbox
                        checked={
                          state.selectedItems.includes(data.id) ? true : false
                        } // The checked prop determines whether this checkbox is checked.
                        onChange={() => {
                          selectItems("not", data.id);
                        }}
                        sx={{
                          padding: "2px",
                          margin: 0,
                          color: blue[400],
                          "&.Mui-checked": {
                            color: blue[400],
                          },
                          "& .MuiSvgIcon-root": { fontSize: "20px" },
                        }}
                      />
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "left", cursor: "pointer" }}
                    >
                      {(page_no - 1) * appSetting.itemPerPage + i + 1}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {data.VoucherNo}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      {data.Date}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      className="truncated-text "
                      sx={{ cursor: "pointer" }}
                    >
                      {data.LedgerName}
                    </VBTableCellBody>
                    {filterOptions.CustomTable.User.isActive && 
                      <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "left", cursor: "pointer" }}
                    >
                      {/* fetch data */}
                      {data.user} 
                    </VBTableCellBody>
                    }
                     {filterOptions.CustomTable.Salesman.isActive && 
                      <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "left", cursor: "pointer" }}
                    >
                      {/* fetch data  */}
                      {data.salesman}
                    </VBTableCellBody>
                    }
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "right", cursor: "pointer" }}
                    >
                      {formatNumber(data.TotalGrossAmt)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "right", cursor: "pointer" }}
                    >
                      {formatNumber(data.TotalTax)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      onClick={() => {
                        SingleView(data.id);
                      }}
                      sx={{ textAlign: "right", cursor: "pointer" }}
                    >
                      {formatNumber(data.GrandTotal)}
                    </VBTableCellBody>
                    <VBTableCellBody
                      sx={{
                        color:
                          data.billStatus === "paid"
                            ? "green"
                            : data.billStatus === "partially paid"
                            ? "blue"
                            : data.billStatus === "unpaid"
                            ? "red"
                            : null,
                      }}
                    >
                      {data.billStatus}
                    </VBTableCellBody>
                    {!EnableEinvoiceKSA && (
                      <VBTableCellBody
                        sx={{ cursor: "pointer", textAlign: "right" }}
                      >
                        <MenuOptions
                          OptionList={OptionList}
                          OptionOnClick={OptionOnClick}
                          uniq_id={data.id}
                          disable={
                            data.is_billwised || data.IsNewSale === false
                              ? true
                              : false
                          }
                        />
                      </VBTableCellBody>
                    )}
                  </TableRow>
                ))}
                <VBTableRowNull
                  length={
                    state.data_count ? state.data_count : state.dataList.length
                  }
                />
              </TableBody>
            )}
          </VBTableContainer>

          <ConfirmBox
            heading={"Confirm to delete?"}
            message={"Once you delete, you will not get it back!"}
            open={IsConfirmBox}
            setOpen={setConfirmBox}
            confirmFunction={deleteData}
          />
        </Paper>
      <SideMenuBottom data={MenuBottomData} />
    </>
  );
};

export default SalesInvoiceList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
