import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  // VBTableCellFooter,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../../../generalFunction";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { CallBankReconciliation } from "../../../../Api/Reports/AccountApi";
import { openSnackbar } from "../../../../features/SnackBarslice";
import {
  callBankReconsilationList,
  callCreateBankReconsil,
} from "../../../../Api/Reports/CommonReportApi";
import VBInputField from "../../../../Components/Utils/VBInputField";
import { blue } from "@mui/material/colors";

const BankReconcilation = () => {
  // Set State
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [reportData, setReportData] = useState({
    count: 0,
    data: [],
    filterReport: [],
    isSelectedAll: false,
    ReconsiledDateList:[
      // {
      //   id:'',
      //   Date : '',
      // },
    ],
    total: {
      Debit: 0,
      Credit: 0,
      LedgerID: "",
      LedgerName: t("Total"),
      Particulars: "",
      VoucherType: "",
      VoucherTypeName: "",
      VoucherNo: "",
      Date: "",
      Balance: 0,
      id: "",
      ReConsilDate: "",
      Reconsiled: 0
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const itemPerPage = 12
  const [filterOptions, setFilterOptions] = useState({
    bankValue: null,
    ToDate: moment(),
    FromDate: moment(),
    incRecon: true,
    allUnrec: false,
    ReconsiledOnly: false,
    VoucherType: null,
    closingbalance: ""
  });
  // const voucherTypes = ;
  

  const [filterData, setFilterData] = useState({
    bankList: [],
    voucherTypeList: [
      { key: 'JL', value: 'Journal' },
      { key: 'SI', value: 'Sales Invoice' },
      { key: 'PI', value: 'Purchase Invoice' },
      { key: 'SR', value: 'Sales Return' },
      { key: 'PR', value: 'Purchase Return' },
      { key: 'SO', value: 'Sales Order' },
      { key: 'PO', value: 'Purchase Order' },
      { key: 'CP', value: 'Cash Payment' },
      { key: 'BP', value: 'Bank Payment' },
      { key: 'PT', value: 'Payment' },
      { key: 'CR', value: 'Cash Receipt' },
      { key: 'RT', value: 'Receipt' },
      { key: 'BR', value: 'Bank Receipt' },
      { key: 'LOB', value: 'Ledger Opening Balance' },
      { key: 'WO', value: 'Work Order' },
      { key: 'ST', value: 'Stock Transfer' },
      { key: 'ES', value: 'Excess Stock' },
      { key: 'SS', value: 'Shortage Stock' },
      { key: 'DS', value: 'Damage Stock' },
      { key: 'US', value: 'Used Stock' },
      { key: 'EX', value: 'Expense' },
      { key: 'SE', value: 'Sales Estimate' },
      { key: 'OS', value: 'Opening Stock' }
    ]
  });

  //Handle functions

  const closeFilter = () => setOpenFilter(false);

  const onFilter = function () {
    fetchReport()
  };

  const handleDefaultFilter = async () => {
    setFilterOptions({
      ...filterOptions,
      bankValue: null,
      ToDate: moment(),
      FromDate: moment(),
      VoucherType: null,
      incRecon: true,
      allUnrec: false,
      closingbalance: ""
    });
  };

  const filterReport = function(option) {
    // let value = []

    // if(!option) {
    //   value = reportData.data.filter(e => e.ReConsilDate === null | "" | "No date")
    // }
    // if(option) {
    //   value = reportData.data
    // }
    
    // setReportData({
    //   ...reportData,
    //   filterReport: value
    // })
    // fetchReport()
  }

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading(true);
    const payload = {
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      LedgerID: filterOptions.bankValue?.LedgerID || 0,
      VoucherType: filterOptions.VoucherType ? filterOptions.VoucherType.key : null,
      items_per_page: itemPerPage,
      page_no: page_no,
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceDecimalPoint:PriceDecimalPoint,
      user_id:user_id,
      filterType:filterOptions.allUnrec ? 'uneconsiled' : filterOptions.ReconsiledOnly ? 'reconsiled' : 'all'
    };

    const data = await CallBankReconciliation(payload, dispatch);
    let value = data.data

    if(filterOptions.allUnrec) {
      value = data.data.filter(e => e.ReConsilDate === null | "" | t("No date"))
    }
    if (data?.StatusCode === 6000) {

      setReportData((prev) => ({
        ...prev,
        count: data.count,
        data: data.data,
        filterReport: value,
        total: data.total,
        isSelectedAll:false,
        ReconsiledDateList:[]
      }));
      // setPage()
    } else if (data?.StatusCode === 6001) {
      setReportData((prev) => ({
        ...prev,
        count: data.count,
        data: data.data,
        filterReport: value,
        total: data.total,
        isSelectedAll:false,
        ReconsiledDateList:[]
      }));
      dispatch(
        openSnackbar({
          open: true,
          message: data.message,
          severity: "warning",
        })
      );
    }
    setIsLoading(false);
  };

  const createBankReconsil = async function() {
    const payload = {
      ReconsilDate: reportData.ReconsiledDateList,
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceDecimalPoint:PriceDecimalPoint,
      user_id:user_id,
    }
    const res = await callCreateBankReconsil(payload)
    if (res?.StatusCode === 6000) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.message || "Created Successfully",
          severity: "success",
        })
      );
      setReportData((prev) => ({
        ...prev,
        ReconsiledDateList:[]
      }))
      fetchReport();
    }else if(res.StatusCode === 6001) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "warning",
        })
      );
    }
  }

  const filterApis = async function () {
    const LedgerResponse = await callBankReconsilationList(
      {
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:PriceDecimalPoint,
      CreatedUserID:user_id,
      }
    );
    if (LedgerResponse?.StatusCode === 6000) {
      setFilterData({
        ...filterData,
        bankList: LedgerResponse.data,
      });
      
    }
  };

  const selectItems = (type, id,date) => {
    let data = [...reportData.ReconsiledDateList];
    let isSelectedAll = reportData.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (reportData.isSelectedAll) {
        data = [];
      } else {
        reportData.data.map((i) => {
          data.push({
            id : i.Uid,
            Date : i.ReConsilDate !== null ? i.ReConsilDate : i.Date,
          });
          
        });
      }
    } else {
      let item = reportData.data.find((item) => item.id === id)
      const itemIndex = data.findIndex((i) => i.id === item.Uid);
      console.log(itemIndex,'datedatefilterrrrrrDFDDDDDDDDDDDD');
      
      if (itemIndex !== -1) {
        // Update existing item with the new date
        
        data[itemIndex] = {
          id: item.Uid,
          Date: date !== null ? date : item.Date,
        };
        data.splice(itemIndex, 1);
      } else {
        data.push({
          id: item.Uid,
          Date: date !== null ? date : item.Date,
        });
      }
      }

    setReportData({ ...reportData, ReconsiledDateList: data, isSelectedAll: isSelectedAll });
  };

  useEffect(() => {
    filterApis();
  }, []);

  useEffect(() => {
    fetchReport();
  }, [page_no]);

console.log(filterOptions,'datedatefilterrrrrr',reportData);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon sx={{ color: "#0A9EF3" }} />
            </IconButton>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Bank Reconcilation")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // width: "27%",
              justifyContent: "space-between",
            }}
          >
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            {/* <Box> */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                borderRadius: "6px",
                mx: 1,
                textTransform: "none",
                "&:hover": { backgroundColor: "#219121" },
              }}
              onClick={createBankReconsil}
            >
              Save
            </Button>
            <Pagenation
              totalItem={reportData.count}
              page_no={page_no}
              setPage_no={setPage_no}
              itemPerPage={itemPerPage}
            />
            {/* </Box> */}
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div  style={{fontSize:"16px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.FromDate.format("DD/MM/YYYY") 
              + " - " +
              filterOptions.ToDate.format("DD/MM/YYYY")
            }
          />
          {filterOptions.bankValue && (
            <FilterLable
              name={t("Bank Name")}
              value={filterOptions.bankValue.LedgerName}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  bankValue: null,
                })
              }
            />
          )}
          {filterOptions.VoucherType && (
            <FilterLable
              name={t("Voucher Type")}
              value={filterOptions.VoucherType.value}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  VoucherType: null,
                })
              }
            />
          )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
          <VBTableCellHeader>
          <Tooltip title="All items will be reconciled. If no date is provided, the voucher date will be used." arrow>
            <Checkbox
                checked={reportData.isSelectedAll} // The checked prop determines whether this checkbox is checked.
                onChange={() => {
                  selectItems("all"); // Ensure the function handles selection correctly
                }}
                sx={{
                  padding: "2px",
                  margin: 0,
                  color: blue[400],
                  "&.Mui-checked": {
                    color: blue[400],
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 255, 0.1)", // Adds a light blue background on hover
                    borderRadius: "4px", // Adjust the shape of the hover effect
                  },
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                }}
              />
            </Tooltip>
            </VBTableCellHeader>
            <VBTableCellHeader>{t("Serial No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Date")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Type")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Particular")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Transaction Type")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Reconsile Date")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Credit")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Debit")}</VBTableCellHeader>
          </VBTableHeader>
          {isLoading ? (
            <VBSkeletonLoader />
          ) : (
            [
              <TableBody>
                {reportData.filterReport.map((e, i) => ( 
                <TableRow sx={{ cursor: "pointer" }}>
                  <VBTableCellBody>
                  <Checkbox
                      checked={
                        reportData.ReconsiledDateList.some((item) => item.id === e.Uid)
                      } // The checked prop determines whether this checkbox is checked.
                      onChange={() => {
                        selectItems("not", e.id,e.ReConsilDate);
                      }}
                      sx={{
                        padding: "2px",
                        margin: 0,
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      }}
                    />
                  </VBTableCellBody>
                  <VBTableCellBody>  {(page_no - 1)* itemPerPage + i + 1} </VBTableCellBody>
                  <VBTableCellBody>{e.VoucherNo} </VBTableCellBody>
                  <VBTableCellBody>{e.Date} </VBTableCellBody>
                  <VBTableCellBody>{e.VoucherType} </VBTableCellBody>
                  <VBTableCellBody>{e.Particulars} </VBTableCellBody>
                  <VBTableCellBody>{e.LedgerName} </VBTableCellBody>
                  <VBTableCellBody>{e.VoucherTypeName} </VBTableCellBody>
                  <VBTableCellBody sx={{ width: "12%"}}> 
                    {(e.Date) === "No date" ? t("No Date") :
                    // <VBDatePiker
                    //   notLabel={true}
                    //   fromDate={moment(e.ReConsilDate)}
                    //   fromOnChange={(value) =>{
                    //     setFilterOptions({ ...filterOptions, date: value })
                    //     createBankReconsil(value, e.Uid)
                    //   }}
                    // /> 
                    <input 
                      type="date" 
                      value={e.ReConsilDate}
                      onChange={({ target: { value } }) => { 
                        const updatedReportData = reportData.filterReport.map((item) =>
                          item.Uid === e.Uid ? { ...item, ReConsilDate: value } : item
                        );

                        const updatedReconsiledDate = Array.isArray(reportData.ReconsiledDateList)
                          ? reportData.ReconsiledDateList.some((item) => item.id === e.Uid)
                            ? reportData.ReconsiledDateList.map((item) =>
                                item.id === e.Uid ? { ...item, Date: value } : item
                              )
                            : [...reportData.ReconsiledDateList, { id: e.Uid, Date: value }]
                          : [{ id: e.Uid, Date: value }];

                        setReportData((prev) => ({ 
                          ...prev, 
                          filterReport: updatedReportData,
                          ReconsiledDateList: updatedReconsiledDate
                        }));
            
                      }} 
                      // onChange={(value) => { 
                      //   setFilterOptions({ ...filterOptions, ReConsilDate: moment(value) }); 
                      //   createBankReconsil(value.target.value, e.Uid) 
                      // }} 
                    />
                    }
                  </VBTableCellBody>
                  <VBTableCellBody>{formatNumber(e.Credit)}</VBTableCellBody>
                  <VBTableCellBody>{formatNumber(e.Debit)}</VBTableCellBody>
                </TableRow>))}
                <TableRow></TableRow>
              </TableBody>,
              <VBTableFooter>
                <TableRow>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{" "}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{" "}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{" "}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{" "}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{" "}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{t("Closing Balance")}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", }}>{filterOptions.closingbalance}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", textAlign: "center", color: "black", fontSize: "14px", fontWeight: "400"}}  colspan="2">  {t("Balance as per company")}</VBTableCellFooter>
                  <VBTableCellFooter sx={{ borderTop: "1px solid #e6e6e6", borderBottom: "none", backgroundColor: "white", textAlign: "center", color: "black", fontSize: "14px", fontWeight: "400"}}  colspan="2">  {reportData.total?.Balance > 0 ? formatNumber(reportData.total?.Balance)+ "  "+t("Credit"): reportData.total?.Balance < 0 ? formatNumber(reportData.total?.Balance)+ "  "+t("Debit") : 0}</VBTableCellFooter>
                </TableRow>
                <TableRow>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}> </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}> </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}> </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}> </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}> </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}>{t('Difference')} </VBTableCellFooter>
                  <VBTableCellFooter sx={{backgroundColor: "white", borderTop: "none"}}>{filterOptions.closingbalance ? formatNumber(reportData.total.Balance - filterOptions.closingbalance): ""} </VBTableCellFooter>

                  <VBTableCellFooter
                    sx={{ borderBottom: "none", textAlign: "center", backgroundColor: "white", color: "black", fontSize: "14px", fontWeight: "400", borderTop: "none" }}
                    colspan="2"
                  >
                    {t("Not reflected in bank")}
                  </VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ borderBottom: "none", textAlign: "center", backgroundColor: "white", color: "black", fontSize: "14px", fontWeight: "400", borderTop: "none" }}
                    colspan="2"
                  >
                    {reportData.total.NotReflectedBnk > 0 ?formatNumber(reportData.total.NotReflectedBnk)+ "  " + t("Credit"):reportData.total.NotReflectedBnk < 0 ? formatNumber(reportData.total.NotReflectedBnk)+ "  " +t("Debit") : 0}
                  </VBTableCellFooter>
                </TableRow>
                <TableRow>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{ backgroundColor: "#3379A9", borderBottom: "none" }}
                  ></VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{
                      backgroundColor: "#3379A9",
                      color: "white",
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                    colspan="2"
                  >
                    {t("BALANCE AS PER BANK")}
                  </VBTableCellFooter>
                  <VBTableCellFooter
                    sx={{
                      backgroundColor: "#3379A9",
                      color: "white",
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                    colspan="2"
                  >
                    {/* {reportData.total.Reconsiled ? formatNumber(reportData.total.Reconsiled)+ "  " +"Credit": formatNumber(reportData.total.Reconsiled)+ "  " +"Debit"} */}
                    {reportData.total.Reconsiled}
                  </VBTableCellFooter>
                </TableRow>
              </VBTableFooter>,
            ]
          )}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={onFilter}
        setPage_no={setPage_no}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) => 
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>

            <Divider />

            {
              <ListItem>
                <ViknAutoComplete
                  label={t("Bank")}
                  placeholder={"Select Bank"}
                  value={filterOptions.bankValue}
                  options={filterData.bankList}
                  getOptionLabel={(option) => option.LedgerName}
                  onChange={(e, newValue) =>
                    setFilterOptions({ ...filterOptions, bankValue: newValue })
                  }
                />
              </ListItem>
            }
            <Divider />
            
            <ListItem>
                <ViknAutoComplete
                  label={t("Voucher Type")}
                  placeholder={"Select Voucher type"}
                  value={filterOptions.VoucherType}
                  options={filterData.voucherTypeList}
                  getOptionLabel={(option) => option.value}
                  onChange={(e, newValue) =>
                    setFilterOptions({ ...filterOptions, VoucherType: newValue })
                  }
                />
              </ListItem>

              <ListItem>
                <ViknCheckboxGroup 
                  radios={[
                    {
                      checked: filterOptions.incRecon,
                      label: t("Include Reconciled"),
                      onChange: () =>{ 
                        setFilterOptions({ ...filterOptions, incRecon: true, allUnrec: false,ReconsiledOnly: false });
                        filterReport(true)
                      }
                    },
                    {
                      checked: filterOptions.ReconsiledOnly,
                      label: t("All Reconciled"),
                      onChange: () =>{ 
                        setFilterOptions({ ...filterOptions, ReconsiledOnly: true,incRecon: false,allUnrec: false})
                        filterReport(true)
                      }
                    },
                    {
                      checked: filterOptions.allUnrec,
                      label: t("All unreconciled till Date"),
                      onChange: () =>{ 
                        setFilterOptions({ ...filterOptions, incRecon: false, allUnrec: true,ReconsiledOnly: false });
                        filterReport(false)
                      }
                    },
                  ]} 
                />

              </ListItem>

              <ListItem>
                <VBInputField 
                  placeholder={t("Closing Balance")}
                  value={filterOptions.closingbalance}
                  onChange={(e) => setFilterOptions({...filterOptions, closingbalance: e.target.value})}
                />
              </ListItem>
          </List>
        }
      />
    </>
  );
};

export default BankReconcilation;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "30%",
}));
