import "./Api/mainAxios";
import "@fontsource/poppins";
import { useState } from "react";
import {
  // Box,
  // Card,
  // CardContent,
  // CardHeader,
  // Container,
  // FormControlLabel,
  // FormGroup,
  // Switch,
  // Typography,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainPage from "./MainPage";
import { BrowserRouter } from "react-router-dom";
import { darkTheme, lightTheme } from "./theme";
import { Provider, useSelector } from "react-redux";
import { store } from "./app/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import TimeBlock from "./utils/common/TimeBlock";

const state = store.getState();
const language = state.appSettingsSclice.language;

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: language,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ar: {
      common: common_ar,
    },
  },
});

const App = () => {


  return (
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter>
          {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
          {/* <TimeBlock> */}
            <MainPage />
          {/* </TimeBlock> */}
          {/* </LocalizationProvider> */}
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
