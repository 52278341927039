import styled from "@emotion/styled";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function TableSection({
  selectedTransactionDetails = [], // Default to empty array if undefined
  comparingTransactionDetails = [], // Default to empty array if undefined
  highlightColor = "yellow", // Default color for highlighting
}) {
  // Function to compare values from selected and comparing transaction details
  const isDifferent = (key, index) => {
    // Check if comparingTransactionDetails exists and then compare values
    if (!comparingTransactionDetails || !comparingTransactionDetails[index]) {
      return false; // If no comparing data, don't highlight
    }

    const selectedValue = selectedTransactionDetails[index]?.[key];
    const comparingValue = comparingTransactionDetails[index]?.[key];

    return selectedValue !== comparingValue;
  };

  // Find the maximum length of both lists to ensure we can loop over both
  const maxLength = Math.max(
    selectedTransactionDetails.length,
    comparingTransactionDetails.length
  );

  return (
    <TableContainer
      component={Box}
      sx={{ marginTop: "5px", border: "1px solid black" }}
    >
      <Table sx={{ borderCollapse: "collapse" }}>
        {/* Table Header */}
        <TableHead sx={{ borderBottom: "0px solid red" }}>
          <TableRow
            sx={{
              borderBottom: "2px solid #002647",
            }}
          >
            <CustomTableHeadCell>SI</CustomTableHeadCell>
            <CustomTableHeadCell>P.Code</CustomTableHeadCell>
            <CustomTableHeadCell>Product</CustomTableHeadCell>
            <CustomTableHeadCell>Qty</CustomTableHeadCell>
            <CustomTableHeadCell>F.Qty</CustomTableHeadCell>
            <CustomTableHeadCell>Unit</CustomTableHeadCell>
            <CustomTableHeadCell>Rate</CustomTableHeadCell>
            <CustomTableHeadCell>G.Amt</CustomTableHeadCell>
            <CustomTableHeadCell>VAT</CustomTableHeadCell>
            <CustomTableHeadCell>Disc</CustomTableHeadCell>
            <CustomTableHeadCell>Total</CustomTableHeadCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {/* Loop over the maximum length of both lists */}
          {[...Array(maxLength)].map((_, index) => (
            <TableRow key={index}>
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("ProductCode", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.ProductCode
                  ? index + 1
                  : "-"}
              </CustomTableBodyCell>

              {/* Compare ProductCode */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("ProductCode", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.ProductCode || "-"}
              </CustomTableBodyCell>

              {/* Compare Product */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("Product", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.Product || "-"}
              </CustomTableBodyCell>

              {/* Compare Qty */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("Qty", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.Qty || "-"}
              </CustomTableBodyCell>

              {/* Compare FQty */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("FQty", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.FQty || "-"}
              </CustomTableBodyCell>

              {/* Compare Unit */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("Unit", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.Unit || "-"}
              </CustomTableBodyCell>

              {/* Compare Rate */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("Rate", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.Rate || "-"}
              </CustomTableBodyCell>

              {/* Compare GrossAmount */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("GrossAmount", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.GrossAmount || "-"}
              </CustomTableBodyCell>

              {/* Compare TaxAmount */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("TaxAmount", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.TaxAmount || "-"}
              </CustomTableBodyCell>

              {/* Compare DiscountAmount */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("DiscountAmount", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.DiscountAmount || "-"}
              </CustomTableBodyCell>

              {/* Compare NetAmount */}
              <CustomTableBodyCell
                sx={{
                  backgroundColor: isDifferent("NetAmount", index)
                    ? highlightColor
                    : "inherit",
                }}
              >
                {selectedTransactionDetails[index]?.NetAmount || "-"}
              </CustomTableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableSection;

const CustomTableHeadCell = styled(TableCell)(() => ({
  backgroundColor: "#002647",
  color: "white",
  fontFamily: "sans-serif",
  fontSize: "13px",
  padding: "5px",
  textAlign: "left",
}));

const CustomTableBodyCell = styled(TableCell)(() => ({
  fontFamily: "sans-serif",
  fontSize: "12px",
  padding: "5px",
  border: "1px solid black",
}));
