import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import CachedIcon from '@mui/icons-material/Cached';import {
  CallChartOfAccount,
  UpdateBalanceLedger,
} from "../../../../Api/Reports/CommonReportApi";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import the autoTable plugin

function ChartOfAccountReport() {
  // ==================== global states ====================
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const isAdmin = BranchID === 1 ? true : false;

  // ==================== variables ====================

  const [t] = useTranslation("common");

  // ==================== states ====================

  const [loading, setLoading] = useState(false);
  const [isReCalculate, setIsReCalculate] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [state, setState] = useState({
    pageNo: 1,
    anchorEl: null, // popover
    openFilter: false,
    clearFilter: false,
    customTable: {
      purchaseReturn: { isActive: false },
      salesReturn: { isActive: false },
    },
  });

  const [filterData, setFilterData] = useState({
    AccountGroupList: [],
  });
  // ==================== functions ====================
  const exportToPDF = () => {
    const doc = new jsPDF();
    
    // Title of the document
    doc.text("Chart of Accounts", 14, 10);
  
    // Define the table columns and headers
    const columns = [
      { header: "Account Group Name", dataKey: "AccountGroupName" },
      { header: "Code", dataKey: "AccountGroupCode" },
      { header: "Balance", dataKey: "Balance" },
      { header: "Created User", dataKey: "CreatedUser" },
      { header: "Created Date", dataKey: "CreatedDate" },
    ];
  
    // Prepare the rows data for autoTable
    const rows = [];
  
    // Function to recursively add group data and ledgers with proper indentation
    const addGroupData = (group, level = 0) => {
      // Add main group row with increased indentation for deeper levels
      rows.push({
        AccountGroupName: "  ".repeat(level) + group.AccountGroupName, // Increase indentation for nested levels
        AccountGroupCode: group.AccountGroupCode || '',
        Balance: group.Balance.toFixed(2),
        CreatedUser: group.CreatedUser || '',
        CreatedDate: group.CreatedDate || '',
      });
  
      // Add ledgers if present
      if (group.AccountLedgers && Array.isArray(group.AccountLedgers)) {
        group.AccountLedgers.forEach((ledger) => {
          rows.push({
            AccountGroupName: "    ".repeat(level) + ledger.LedgerName, // Add more indentation for ledgers
            AccountGroupCode: ledger.LedgerCode || '',
            Balance: ledger.Balance.toFixed(2),
            CreatedUser: ledger.CreatedUser || '',
            CreatedDate: ledger.CreatedDate || '',
          });
        });
      }
  
      // Handle nested groups recursively with increased indentation
      if (group.AccountGroup && Array.isArray(group.AccountGroup) && group.AccountGroup.length > 0) {
        group.AccountGroup.forEach((childGroup) => {
          addGroupData(childGroup, level + 1); // Recursive call with incremented level
        });
      }
    };
  
    // Loop through the top-level Account Groups and add their data
    filterData.AccountGroupList.forEach((group) => {
      addGroupData(group); // Start adding data from top-level groups
    });
  
    // Use the autoTable plugin to add the table to the PDF
    doc.autoTable({
      startY: 20, // Start the table below the title
      head: [columns.map(col => col.header)], // Table headers
      body: rows, // Table data rows
      columns: columns, // Column definitions
      margin: { left: 14, right: 14 }, // Margin settings
      theme: "striped", // Optional: to add a striped background to rows
      styles: {
        cellPadding: 3, // Padding inside cells
        fontSize: 9, // Font size for the table content
      },
      headStyles: {
        fillColor: [100, 100, 100], // Header row color
        textColor: [255, 255, 255], // Header text color
        fontSize: 10,
      },
      columnStyles: {
        0: { cellWidth: "auto" }, // Adjust the first column (Account Group Name) width automatically
        1: { cellWidth: 20 }, // Fix the width of the Code column
        2: { cellWidth: 22 }, // Fix the width of the Balance column
        3: { cellWidth: 30 }, // Fix the width of the Created User column
        4: { cellWidth: 22 }, // Fix the width of the Created Date column
      },
    });
  
    // Save the PDF document
    doc.save("ChartOfAccountsReport.pdf");
  };
console.log(filterData.AccountGroupList);
console.log(JSON.stringify(filterData.AccountGroupList, null, 2));

// const exportToExcel = () => {
//   const table = document.createElement("table");
//   const headerRow = table.insertRow();
//   headerRow.insertCell().textContent = "Account Group Name";
//   headerRow.insertCell().textContent = "Code";
//   headerRow.insertCell().textContent = "Balance";
//   headerRow.insertCell().textContent = "Created User";
//   headerRow.insertCell().textContent = "Created Date";

//   filterData.AccountGroupList.forEach((group) => {
//       const row = table.insertRow();
//       row.insertCell().textContent = group.AccountGroupName;
//       row.insertCell().textContent = group.AccountGroupCode || "";
//       row.insertCell().textContent = parseFloat(group.Balance).toFixed(2);
//       row.insertCell().textContent = group.CreatedUser;
//       row.insertCell().textContent = group.CreatedDate;
//   });

//   // Trigger Excel download
//   const html = table.outerHTML;
//   const blob = new Blob([html], { type: "application/vnd.ms-excel" });
//   const url = URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.href = url;
//   a.download = "ChartOfAccountsReport.xls";
//   a.click();
// };
  // ==================== API Fetching ====================


  // const filterApi = async function () {
  //   const payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     PriceRounding: Number(PriceDecimalPoint),
  //     CreatedUserID: user_id,
  //     show_all: true,
  //     items_per_page: 10,
  //     page_no: 1,
  //     search: "",
  //     PartyType: "1",
  //   };
  
  //   try {
  //     // Call the API
  //     const response = await CallChartOfAccount(payload);
  
  //     // Initialize the response object
  //     let AccountGroup = []
  
  //     // Handle the API response
  //     if (response.StatusCode === 6000) {
  //       const flatAccountGroup = response.AccountGroup || [];
  
  //       // Transform the data into a hierarchical structure
  //       const accountGroupMap = {};
  //       const topLevelGroups = [];
  
  //       // Create a map for easy lookup
  //       flatAccountGroup.forEach((group) => {
  //         accountGroupMap[group.AccountGroupID] = { ...group, AccountGroup: [] };
  //       });
  
  //       // Nest child groups under their parents
  //       flatAccountGroup.forEach((group) => {
  //         if (group.AccountGroupUnder && accountGroupMap[group.AccountGroupUnder]) {
  //           accountGroupMap[group.AccountGroupUnder].AccountGroup.push(
  //             accountGroupMap[group.AccountGroupID]
  //           );
  //         } else {
  //           // Add to top-level groups if no parent
  //           topLevelGroups.push(accountGroupMap[group.AccountGroupID]);
  //         }
  //       });
  
  //       // Update the state with the hierarchical structure
  //       setFilterData((prevState) => ({
  //         ...prevState,
  //         AccountGroupList: topLevelGroups,
  //       }));
  //     } else {
  //       console.log(`API returned StatusCode ${response.StatusCode}`);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while making the API call", error);
  //   }
  // };
  
  // ==================== useEffect ====================
  const filterApi = async function () {
    if(loading) return
    setLoading(true)
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    };
  
    try {
      // Call the API
      const response = await CallChartOfAccount(payload);
  
      // Initialize the response object
      let AccountGroup = [];
  
      // Handle the API response
      if (response.StatusCode === 6000) {
        const flatAccountGroup = response.AccountGroup || [];
  
        // Transform the data into a hierarchical structure
        const accountGroupMap = {};
        const topLevelGroups = [];
  
        // Create a map for easy lookup
        flatAccountGroup.forEach((group) => {
          accountGroupMap[group.AccountGroupID] = { ...group, AccountGroup: [] };
        });
  
        // Nest child groups under their parents
        flatAccountGroup.forEach((group) => {
          if (group.AccountGroupUnder && accountGroupMap[group.AccountGroupUnder]) {
            accountGroupMap[group.AccountGroupUnder].AccountGroup.push(
              accountGroupMap[group.AccountGroupID]
            );
          } else {
            // Add to top-level groups if no parent
            topLevelGroups.push(accountGroupMap[group.AccountGroupID]);
          }
        });
  
        // Function to calculate balances recursively
        const calculateBalances = (groups) => {
          return groups.map((group) => {
            let totalBalance = 0;
  
            // Sum up balances from AccountLedgers
            if (group.AccountLedgers) {
              totalBalance += group.AccountLedgers.reduce((sum, ledger) => sum + ledger.Balance, 0);
            }
  
            // Process nested AccountGroups
            if (group.AccountGroup && group.AccountGroup.length > 0) {
              const nestedBalances = calculateBalances(group.AccountGroup);
              totalBalance += nestedBalances.reduce((sum, balance) => sum + balance, 0);
            }
  
            // Add Balance property to the group
            group.Balance = totalBalance;
            return totalBalance;
          });
        };
  
        // Start the balance calculation for the top-level groups
        calculateBalances(topLevelGroups);
  
        // Update the state with the hierarchical structure and calculated balances
        setFilterData((prevState) => ({
          ...prevState,
          AccountGroupList: topLevelGroups,
        }));
      } else {
        console.log(`API returned StatusCode ${response.StatusCode}`);
      }
    } catch (error) {
      console.error("An error occurred while making the API call", error);
    }
    setLoading(false)

  };
  const reCalculateApi = async function () {
    setLoading(true)
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    };
  
    try {
      const response = await UpdateBalanceLedger(payload);
      // Handle the API response
      if (response.StatusCode === 6000) {
        setIsReCalculate(false)
        filterApi()
      } else {
        console.log(`API returned StatusCode ${response.StatusCode}`);
      }
    } catch (error) {
      console.error("An error occurred while making the API call", error);
    }
    setLoading(false)

  };
  
  useEffect(() => {
    filterApi();
  }, []);

  const AccountGroupRow = ({ group, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleGroup = () => {
      setIsOpen((prevState) => !prevState);
    };
  
    return (
      <>
        {/* Render the main Account Group row */}
        <TableRow>
          <VBTableCellBody sx={{ px: `${20 + level * 20}px` }}>
            <Box onClick={toggleGroup} sx={{ display: "flex", alignItems: "center" }}>
              {/* Toggle button with animation */}
              {(group.AccountLedgers?.length>0 ||group.AccountGroup?.length>0 )&&

                <Button
                size="small"
                sx={{ padding: 0, margin: 0, minWidth: 0 }}
                // onClick={toggleGroup}
                >
                <ExpandMoreIcon
                  sx={{
                    height: `${(30 - level)>25?30 - level:25}px`,
                    width: `${(30 - level)>25?30 - level:25}px`,
                    fontSize: `${(30 - level)>25?30 - level:25}px`,
                    minWidth: `${(30 - level)>25?30 - level:25}px`,
                    transition: "transform 0.3s ease",
                    transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                  />
              </Button>
                }
  
              {/* Group Name */}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: `${(18 - level)>13?18 - level:13}px`,
                  marginLeft: "8px",
                  cursor: "pointer"
                }}
                
              >
                {group.AccountGroupName}
                
              </Typography>
            </Box>
          </VBTableCellBody>
          <VBTableCellBody sx={{fontSize: `${(18 - level)>13?18 - level:13}px`, textAlign: "left" }}>{" "}</VBTableCellBody>
          <VBTableCellBody sx={{fontSize: `${(18 - level)>13?18 - level:13}px`,  textAlign: "right" }}>
            {parseFloat(group.Balance).toFixed(2)}
          </VBTableCellBody>
          <VBTableCellBody sx={{textAlign: "left" }}>{group.CreatedUser}</VBTableCellBody>
          <VBTableCellBody sx={{textAlign: "left" }}>{group.CreatedDate}</VBTableCellBody>
        </TableRow>
  
        {/* Child Ledgers */}
        {isOpen &&
          group.AccountLedgers?.map((ledger, ledgerIndex) => (
            <TableRow key={`ledger-${ledgerIndex}`}>
              <VBTableCellBody
                sx={{ px: `${40 + level * 25}px`, fontSize: "13px" }}
              >
                {ledger.LedgerName}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.LedgerCode}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "right", fontSize: "13px" }}>
                {ledger.Balance}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.CreatedUser}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.CreatedDate}
              </VBTableCellBody>
            </TableRow>
          ))}
  
        {/* Nested Account Groups */}
        {isOpen &&
          group.AccountGroup?.map((childGroup, childIndex) => (
            <React.Fragment key={`child-group-${childIndex}`}>
              <AccountGroupRow group={childGroup} level={level + 1} />
            </React.Fragment>
          ))}
        
      </>
    );
  };
  const AccountGroupTable = ({ accountGroups }) => {
    return (
      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader>{t("Account Group Name")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>{t("Code")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Balance")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Created User")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>{t("Created Date")}</VBTableCellHeader>
        </VBTableHeader>
        <TableBody>
          {/* Loop through top-level Account Groups */}
          {accountGroups.map((group, groupIndex) => (
            <React.Fragment key={`group-${groupIndex}`}>
              <AccountGroupRow group={group} />
            </React.Fragment>
          ))}
          <VBTableRowNull length = {accountGroups.data?.length}/>
        </TableBody>
      </VBTableContainer>
    );
  };
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {t("Chart of Accounts")}
      </Typography>
      </FlexBox>
      <FlexBox className="right">
      <Button onClick={exportToPDF}>Export to PDF</Button>
      {/* <Button onClick={exportToExcel}>Export to Excel</Button> */}

        {isReCalculate &&
      <Button onClick={()=>{reCalculateApi()}}>{t("Re-Calculate")}</Button>
      }
      <Button onClick={()=>{filterApi()}}><CachedIcon
        sx={{
          fontSize: "24px",
          animation: loading
            ? "rotate 0.5s linear infinite"
            : "none",
          "@keyframes rotate": {
            "0%": {
              transform: "rotate(360deg)",
            },
            "100%": {
              transform: "rotate(0deg)",
            },
          },
        }}
      /></Button>
      </FlexBox>
      </Box>
      <AccountGroupTable accountGroups={filterData.AccountGroupList} />
    </Paper>

    </>
  );
}

export default ChartOfAccountReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
}));
