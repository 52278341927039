const salesTemplateData = {
  masterDetailsOne: [
    { label: "Customer Name", key: "CustomerName" },
    { label: "Warehouse", key: "Warehouse" },
    { label: "VAT Treatment", key: "Treatment" },
    { label: "Place Of Supply", key: "PlaceOfSupply" },
    { label: "Sales Man", key: "Employee" },
    { label: "Price Category", key: "PriceCategory" },
  ],
  masterDetailsTwo: [
    { label: "Invoice No", key: "VoucherNo" },
    { label: "Invoice Date", key: "Date" },
    { label: "Delivery Date", key: "DeliveryDate" },
    { label: "Ref Bill No", key: "OrderNo" },
    { label: "Due Date", key: "DueDate" },
  ],
  totalDetailsOne: [
    { label: "Net Total", key: "NetTotal" },
    { label: "Grand Total", key: "GrandTotal" },
    { label: "Total Gross Amount", key: "TotalGrossAmt" },
    { label: "Total Discount", key: "TotalDiscount" },
    { label: "Excise Tax Amount", key: "ExciseTaxAmount" },
  ],
  totalDetailsTwo: [
    { label: "Total VAT", key: "VATAmount" },
    { label: "Total Tax", key: "TotalTax" },
    { label: "Shipping Charge", key: "ShippingCharge" },
    { label: "Shipping Tax", key: "shipping_tax_amount" },
    { label: "Round Off", key: "RoundOff" },
  ],
};

const purchaseTemplateData = {
  masterDetailsOne: [
    { label: "Supplier Name", key: "CustomerName" },
    { label: "Warehouse", key: "Warehouse" },
    { label: "VAT Treatment", key: "Treatment" },
    { label: "Place Of Supply", key: "PlaceOfSupply" },
  ],
  masterDetailsTwo: [
    { label: "Invoice No", key: "VoucherNo" },
    { label: "Invoice Date", key: "Date" },
    { label: "Vender Invoice Date", key: "VenderInvoiceDate" },
  ],
  totalDetailsOne: [
    { label: "Net Total", key: "NetTotal" },
    { label: "Grand Total", key: "GrandTotal" },
    { label: "Total Gross Amount", key: "TotalGrossAmt" },
    { label: "Total Discount", key: "TotalDiscount" },
    { label: "Excise Tax Amount", key: "ExciseTaxAmount" },
  ],
  totalDetailsTwo: [
    { label: "Total VAT", key: "VATAmount" },
    { label: "Total Tax", key: "TotalTax" },
    { label: "Shipping Charge", key: "ShippingCharge" },
    { label: "Shipping Tax", key: "shipping_tax_amount" },
    { label: "Round Off", key: "RoundOff" },
  ],
};

const salesOrderTemplateData = {
  masterDetailsOne: [
    { label: "Customer Name", key: "CustomerName" },
    { label: "VAT Treatment", key: "Treatment" },
    { label: "Place Of Supply", key: "PlaceOfSupply" },
    { label: "Sales Man", key: "Employee" },
    { label: "Price Category", key: "PriceCategory" },
  ],
  masterDetailsTwo: [
    { label: "Invoice No", key: "VoucherNo" },
    { label: "Invoice Date", key: "Date" },
    { label: "Delivery Date", key: "DeliveryDate" },
  ],
  totalDetailsOne: [
    { label: "Net Total", key: "NetTotal" },
    { label: "Grand Total", key: "GrandTotal" },
    { label: "Total Gross Amount", key: "TotalGrossAmt" },
    { label: "Total Discount", key: "TotalDiscount" },
  ],
  totalDetailsTwo: [
    { label: "Excise Tax Amount", key: "ExciseTaxAmount" },
    { label: "Total VAT", key: "VATAmount" },
    { label: "Total Tax", key: "TotalTax" },
    // { label: "Shipping Charge", key: "ShippingCharge" },
    // { label: "Shipping Tax", key: "shipping_tax_amount" },
    { label: "Round Off", key: "RoundOff" },
  ],
};

const PurchaseReturnTemplateData = {
  masterDetailsOne: [
    { label: "Supplier Name", key: "CustomerName" },
    { label: "Warehouse", key: "Warehouse" },
    { label: "VAT Treatment", key: "Treatment" },
    { label: "Place Of Supply", key: "PlaceOfSupply" },
  ],
  masterDetailsTwo: [
    { label: "Invoice No", key: "VoucherNo" },
    { label: "Invoice Date", key: "VoucherDate" },
    { label: "Vender Invoice Date", key: "VenderInvoiceDate" },
    // { label: "Price Category", key: "PriceCategory" },
    // { label: "Delivery Date", key: "DeliveryDate" },

    // { label: "Employee Name", key: "Employee" },
  ],
  totalDetailsOne: [
    { label: "Net Total", key: "NetTotal" },
    { label: "Grand Total", key: "GrandTotal" },
    { label: "Total Gross Amount", key: "TotalGrossAmt" },
    { label: "Total Discount", key: "TotalDiscount" },
  ],
  totalDetailsTwo: [
    { label: "Total VAT", key: "VATAmount" },
    { label: "Total Tax", key: "TotalTax" },
    { label: "Round Off", key: "RoundOff" },
    { label: "-", key: "" },
    // { label: "Shipping Charge", key: "ShippingCharge" },
    // { label: "Shipping Tax", key: "shipping_tax_amount" },
  ],
};

const SalesReturnTemplateData = {
  masterDetailsOne: [
    { label: "Customer Name", key: "CustomerName" },
    { label: "Warehouse", key: "Warehouse" },
    { label: "VAT Treatment", key: "Treatment" },
    { label: "Place Of Supply", key: "PlaceOfSupply" },
    { label: "Sales Man", key: "Employee" },
  ],
  masterDetailsTwo: [
    { label: "Invoice No", key: "VoucherNo" },
    { label: "Invoice Date", key: "VoucherDate" },
    { label: "Reference Bill No", key: "RefferenceBillNo" },
    { label: "Reference Bill Date", key: "RefferenceBillDate" },
  ],
  totalDetailsOne: [
    { label: "Net Total", key: "NetTotal" },
    { label: "Grand Total", key: "GrandTotal" },
    { label: "Total Gross Amount", key: "TotalGrossAmt" },
    { label: "Total Discount", key: "TotalDiscount" },
  ],
  totalDetailsTwo: [
    { label: "Excise Tax Amount", key: "ExciseTaxAmount" },
    { label: "Total VAT", key: "VATAmount" },
    { label: "Total Tax", key: "TotalTax" },
    // { label: "Shipping Charge", key: "ShippingCharge" },
    // { label: "Shipping Tax", key: "shipping_tax_amount" },
    { label: "Round Off", key: "RoundOff" },
  ],
};

export {
  salesTemplateData,
  purchaseTemplateData,
  salesOrderTemplateData,
  PurchaseReturnTemplateData,
  SalesReturnTemplateData,
};
