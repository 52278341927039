import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import moment from "moment";
import VBInputField from "../../../../Components/Utils/VBInputField";
import VBSelect from "../../../../Components/Utils/VBSelect";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ConsolidateTable from "./Components/ConsolidateTable";
import DebitorTable from "./Components/DebitorTable";
import { CallProfitAndLossAccount } from "../../../../Api/Reports/FinanceApi";
import FilterLable from "../../../../Components/Utils/FilterLable";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CallBranchsList } from "../../../../Api/Reports/CommonReportApi";
import { UserRolePermission } from "../../../../Function/Editions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const ProfitAndLossAccount = () => {
  let Export_view = UserRolePermission("Profit And Loss Account", "print_permission");
  // const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  // const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  // const { user_id, } = useSelector((state) => state.user);
  //Set Variables
  const {CompanyID,BranchID,BranchName} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [t] = useTranslation("common");
  const [openFilter, setOpenFilter] = useState(false);
  const [tabSwitch, setTabSwitch] = useState(1);
  const isAdmin = BranchID === 1 ? true : false;
  const tableRef = useRef(null);
  // const [page_no, setPage_no] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    select: "AVERAGE",
    closingStockValue: "",
    openingStockValue: "",
    
    BranchList:[],
    SelectedBranches:[],
    IsSelectedAllBranch:false,

    filterType: 'horizontal',
    Type: [
      { name: "horizontal", label: t("Horizontal") },
      { name: "Vertical", label: t("Vertical") },
    ],

    filterValue: 'All',
    filterTypes: [
      { name: "All", label: t("All") },
      { name: "TradingAccount", label: t("Trading Account") },
      { name: "ProfitAndLossAccount", label: t("Profit & Loss Account") },
    ],

    includeCostOfGoodsSold: false,
  });
  const [filterData, setFilterData] = useState({
    total_rows: 0,
    switchReport: true,
    accountList: [],
    finalDataList:[]

  });
  const [isLoading, setIsLoading] = useState(false)

  //Handle Functions
  const onFilter = function () {
    fetchReport();
  };

  const handleDefaultFilter = function() {
    
    let SelectedBranches = [];
    if (filterOptions.BranchList.length>0){ 
      let BranchIDList = filterOptions.BranchList.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });
  
      SelectedBranches.push(BranchIDList[0]);
    } 

    setFilterOptions({
      ...filterOptions,
      FromDate: moment(),
      ToDate: moment(),
      select: "AVERAGE",
      closingStockValue: "",
      openingStockValue: "",
      // BranchList:[],
      SelectedBranches,
      IsSelectedAllBranch:false
    })
  }
  
  
  const handleAutoComplete = function (e, value, name) {
    setFilterOptions({
      ...filterOptions,
      IsSelectedAllBranch: false,
      [name]: value,
    });
  };
  
  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!filterOptions[name] === true) {
      filterOptions.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });

      setFilterOptions({
        ...filterOptions,
        SelectedBranches,
        [name]: !filterOptions[name],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [name]: !filterOptions[name],
      });
    }
  };
  
  const handleChange = (name) => {
    if (name === 'includeCostOfGoodsSold') {
      setFilterOptions({
        ...filterOptions,
        [name] : !filterOptions[name],
      })
    }
  }
  

  // Call API
  const fetchReport = async function (array) {
    setIsLoading(true)
    
    
    let payload = {
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      ManualOpeningStock: filterOptions.openingStockValue,
      ManualClosingStock: filterOptions.closingStockValue,
      key: filterData.switchReport ? 1 : 2,
      filterMethod: filterOptions.select,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      TableType : filterOptions.filterType,
      
    }
    
        //if all branch selected
        if (isAdmin === true && filterOptions.IsSelectedAllBranch === true) {
          payload.IsAllBranch = true;
        }
    
        //if any branch selected
        let SelectedBranches = array ? array : filterOptions.SelectedBranches;
        if (SelectedBranches.length !== 0 && isAdmin) {
          let BranchList = [];
          console.log(SelectedBranches,"SelectedBranches*************");
          SelectedBranches.map((i) => {
            i.BranchID
              ? BranchList.push(i.BranchID)
              : console.log(`no branchid${i}`);
          });
    
          payload.BranchID = BranchList;
        }
    
    
    const res = await CallProfitAndLossAccount(payload);
    console.log(res);
    if (res || res?.StatusCode === "6000") {

      setFilterData({ ...filterData,finalDataList:res.profitAndLoss_data, accountList: res.data})
    }
    setIsLoading(false)
  };

  
  const get_initialData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    };
    const res = await CallBranchsList(payload);
    if (res.StatusCode === 6000) {
      let BranchIDList = res.data.filter((i) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });

      let SelectedBranches = [];
      SelectedBranches.push(BranchIDList[0]);
      setFilterOptions({
        ...filterOptions,
        BranchList: res.data,
        SelectedBranches,
      });
    } else if (res.StatusCode === 6001) {
      // dispatch(
      //   openSnackbar({
      //     open: true,
      //     message: res.message,
      //     severity: "warning",
      //   })
      // );
      console.log("ERROR - 6001")
    }
  };

  // const generatePDF = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();
  
  //   // Title of the PDF
  //   const title = "Profit and Loss Account";
  //   const margin = 10; // Top margin
  //   const startY = 15; // Starting position of the content
  
  //   // Add the title
  //   doc.setFontSize(14);
  //   // doc.text(title, doc.internal.pageSize.getWidth() / 2, margin, { align: "center" });
  //   doc.text("Chart of Accounts", 14, 10);
  
  //   // Column definitions for the table
  //   const columns = [
  //     { header: "PARTICULAR", dataKey: "particular" },
  //     { header: "", dataKey: "extrasub" },
  //     { header: "SUB TOTAL", dataKey: "subTotal" },
  //     { header: "TOTAL", dataKey: "total" },
  //   ];
  
  //   // Rows of data (you will dynamically generate this based on your actual table data)
  //   const rows = veritical_data?.map((row) => ({
  //     particular: row.particular,
  //     extrasub : row.extrasubTotal,
  //     subTotal: row.subTotal,
  //     total: row.total,
  //   })) || [];
  
  //   // Add the table to the PDF
  //   doc.autoTable({
  //     startY: startY + 10, // Start below the title
  //     head: [columns.map((col) => col.header)], // Table header
  //     body: rows, // Table data
  //     columns: columns, // Column definitions
  //     margin: { left: 14, right: 14 }, // Margin settings
  //     theme: "striped", // Optional: to add a striped background to rows
  //     styles: {
  //       cellPadding: 3, // Padding inside cells
  //       fontSize: 9, // Font size for the table content
  //     },
  //     headStyles: {
  //       fillColor: [100, 100, 100], // Header row color
  //       textColor: [255, 255, 255], // Header text color
  //       fontSize: 10,
  //     },
  //     columnStyles: {
  //       0: { cellWidth: "auto" }, // Adjust the first column (Account Group Name) width automatically
  //       1: { cellWidth: 20 }, // Fix the width of the Code column
  //       2: { cellWidth: 22 }, // Fix the width of the Balance column
  //     },
  //   });
  
  //   // Save the PDF
  //   doc.save("Profit_and_Loss_Account.pdf");
  // };

  

  // const generatePDF = (e) => {
  //   // Ensure the table reference exists
  //   if (tableRef.current) {
  //     e.preventDefault();
  //     html2canvas(tableRef.current, { scale: 1.5 }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/jpeg', 0.6); // Use JPEG format with reduced quality
  
  //       const pdf = new jsPDF('p', 'mm', 'a4');
        
  //       // Define the margins and dimensions for the table in the PDF
  //       const topMargin = 17; // Top margin in mm
  //       const leftMargin = 10; // Left margin in mm
  //       const rightMargin = 10; // Right margin in mm
  //       const pdfWidth = pdf.internal.pageSize.getWidth(); // PDF width
  //       const pdfHeight = pdf.internal.pageSize.getHeight(); // PDF height
  
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const imgWidth = pdfWidth - leftMargin - rightMargin; // Set image width to fit within the margins
  //       const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // Maintain aspect ratio
  
  //       const titleHeight = 20; // Height for the title
  //       const startY = topMargin + titleHeight; // Y-position for table (below title)
  
  //       // Add the title
  //       pdf.setFontSize(12);
  //       pdf.text(BranchName, pdfWidth / 2, topMargin , { align: 'center' });

  //       pdf.setFontSize(11);
  //       pdf.text("Profit and Loss Account", pdfWidth / 2, topMargin+6, { align: 'center' });

  //       pdf.setFontSize(9);
  //       // Format the date range
  //       const formattedFromDate = filterOptions.FromDate.format("MM/DD/YYYY");
  //       const formattedToDate = filterOptions.ToDate.format("MM/DD/YYYY");

  //       const dateRange = 'From : ' + formattedFromDate + ' - ' + 'To : ' + formattedToDate;
  //       pdf.text(dateRange, pdfWidth / 2, topMargin + 12, { align: 'center' });
  
  //       // Add the image of the table below the title, and ensure it fits within the page
  //       if (imgHeight < pdfHeight - startY) {
  //         pdf.addImage(imgData, 'JPEG', leftMargin, startY, imgWidth, imgHeight); // Add table image to the PDF
  
  //         // Draw a black border around the table content
  //         // pdf.setDrawColor(222, 224, 223, 0.1); // Set border color with the desired RGB and reduced opacity (Alpha = 0.1)
  //         // pdf.setLineWidth(0.01); // Set border thickness
  //         // pdf.rect(leftMargin, startY, imgWidth, imgHeight); // Draw border around the table
  //       } else {
  //         // Handle multi-page rendering if the table exceeds one page
  //         let position = startY;
  //         let heightLeft = imgHeight;
  
  //         while (heightLeft > 0) {
  //           pdf.addImage(imgData, 'JPEG', leftMargin, position, imgWidth, imgHeight); // Add table image to the current page
  
  //           // Draw the border around the table content on each page
  //           pdf.setDrawColor(0, 0, 0); // Set border color to black
  //           pdf.setLineWidth(0.5); // Set border thickness
  //           pdf.rect(leftMargin, position, imgWidth, imgHeight); // Draw the border
  
  //           heightLeft -= pdfHeight - topMargin - 20; // Reduce the height left to be printed
  //           position -= pdfHeight - topMargin - 20; // Adjust position for the next page
  
  //           if (heightLeft > 0) {
  //             pdf.addPage(); // Add a new page if the content doesn't fit
  //             position = topMargin; // Reset position to the top of the new page
  //           }
  //         }
  //       }
  
  //       // Save the PDF
  //       pdf.save('Profit_and_Loss_Account(detailed).pdf');
  //     });
  //   } else {
  //     console.error("Table ref is null");
  //   }
  // }
  
  useEffect(() => {
    fetchReport()
  }, [filterData.switchReport])
  
  
  
  useEffect(() => {
    console.log(EnableBranch, "EnableBranch", "BranchID", BranchID);
    if ((EnableBranch === true ||EnableBranch === "True") && BranchID === 1) {
      get_initialData();
    }
  }, []);
  
  
  
  
  console.log(filterData,"filterData");
  console.log(filterOptions,"~~~~~~~~~~~~~filterOptions");
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Profit And Loss Account")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {/* <ExportBtn /> */}
            {Export_view ? 
            <ExportToExcelButton
              ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
              FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
              // UserID={user_id}
              report_type={"profit_and_loss"}
              download_type={"PDF"}
              tab={filterData.switchReport ? 1 : 2}
              ManualOpeningStock={filterOptions.openingStockValue}
              ManualClosingStock={filterOptions.closingStockValue}
              VoucherType={filterOptions.select}
              BranchID = {`[${filterOptions.SelectedBranches.map((i) => i.BranchID)}]`}
              filtervalue = {filterOptions.filterValue}
              costofgoodssold = {filterOptions.includeCostOfGoodsSold}
              filtertype = {filterOptions.filterType}
              // generatePDF = {generatePDF}
            />
            : null}
            {/* <PrintBtn /> */}
            {/* <Pagenation
              totalItem={filterData.total_rows}
              page_no={page_no}
              setPage_no={setPage_no}
            /> */}
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
                  <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
          <FilterLable name={t("Date")} value={filterOptions.FromDate.format("DD/MM/YYYY") + " - " + filterOptions.ToDate.format("DD/MM/YYYY")} />
          <FilterLable name={t("Filter By")} value={filterOptions.select} />
          {filterOptions.closingStockValue && (
            <FilterLable
              name={t("Closing Stock Value")}
              value={filterOptions.closingStockValue}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  closingStockValue: "",
                })
              }
            />
          )}
          {filterOptions.openingStockValue && (
            <FilterLable
              name={t("Opening Stock Value")}
              value={filterOptions.openingStockValue}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  openingStockValue: "",
                })
              }
            />
          )}
          {filterOptions.Type && (
            <FilterLable
              name={t("Filter Option")}
              value={filterOptions.filterType}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  filterType: "horizontal",
                })
              }
            />
          )}

          {filterOptions.filterTypes && (
            <FilterLable
              name={t("Filter Type")}
              value={filterOptions.filterValue}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  filterValue: "all",
                })
              }
            />
          )}

          {(filterOptions.includeCostOfGoodsSold && filterOptions.filterType === "horizontal") && (
            <FilterLable
              name={t("Include Cost Of Goods Sold")}
              value={"True"}
              handleDelete={() =>
                setFilterOptions({
                  ...filterOptions,
                  includeCostOfGoodsSold:false,
                })
              }
            />
          )}
          
          {filterOptions.SelectedBranches.length !== 0 &&
            // filterOptions.IsSelectedAllBranch === false &&
            EnableBranch &&
            BranchID === 1 &&
            isAdmin === true && (
              <FilterLable
                name={t("Branch Name")}
                value={filterOptions.SelectedBranches.map((e, i) => (
                  <Chip
                    key={i}
                    label={e.NickName}
                    variant="outlined"
                    sx={{
                      p: "1px",
                      fontSize: "10px",
                      height: "22px",
                      mr: "4px",
                      ".MuiChip-label": {
                        px: "8px",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "15px",
                        color: "#ff00006b",
                      },
                    }}
                    onDelete={() => {
                      let SelectedBranches = filterOptions.SelectedBranches.filter(
                        (l) => l.BranchID !== e.BranchID
                      );
                      setFilterOptions({
                        ...filterOptions,
                        IsSelectedAllBranch: false,
                        SelectedBranches: SelectedBranches,
                      });

                      // fetchReport(SelectedBranches);
                    }}
                  />
                ))}
                handleDelete={() => {
                  let get_BranchID = filterOptions.BranchList.filter((i) => {
                    if (i.BranchID === BranchID) {
                      return i;
                    }
                  });

                  let SelectedBranches = [];
                  SelectedBranches.push(get_BranchID[0]);
                  setFilterOptions({
                    ...filterOptions,
                    SelectedBranches,
                    IsSelectedAllBranch: false,
                  });
                  fetchReport(SelectedBranches);
                }}
              />
            )}
          
          
          
        </Box>

        {/* Report Table */}
        {filterData.switchReport ? (
          <ConsolidateTable data={filterData.finalDataList} isLoading={isLoading} filterOptions ={filterOptions} />

        ) : (
          <DebitorTable data={filterData.finalDataList} isLoading={isLoading}  filterOptions ={filterOptions} ref={tableRef} />
        )}
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={onFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                  })
                }
              />
            </ListItem>
            <Divider />

            <ListItem>
              <VBInputField
                type="number"
                placeholder="Manual Opening Stock"
                value={filterOptions.openingStockValue}
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    openingStockValue: e.target.value,
                  })
                }
              />
            </ListItem>

            <ListItem>
              <VBInputField
                type="number"
                placeholder="Manual Closing Stock"
                value={filterOptions.closingStockValue}
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    closingStockValue: e.target.value,
                  })
                }
              />
            </ListItem>

            <ListItem>
              <VBSelect
                label={t("Filter")}
                value={filterOptions.select}
                options={["AVERAGE", "LIFO", "FIFO"]}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, select: e.target.value })
                }
              />
            </ListItem>
            <Divider />

            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknCheckboxGroup
                  radios={[
                    {
                      label: t("All Branches"),
                      checked: filterOptions.IsSelectedAllBranch,
                      onChange: () => branchCheckChange("IsSelectedAllBranch"),
                    },
                  ]}
                />
              </ListItem>
            )}


            {isAdmin === true && EnableBranch && BranchID === 1 && (
              <ListItem>
                <ViknAutoComplete
                  disabled={filterOptions.IsSelectedAllBranch}
                  multiple
                  value={filterOptions.SelectedBranches}
                  placeholder={"Select Branches"}
                  options={filterOptions.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) =>
                    handleAutoComplete(e, newValue, "SelectedBranches")
                  }
                />
              </ListItem>
            )}


            <ListItem>
              <ViknRadioGroup
                handleChange={(e) => handleAutoComplete(e,e.target.value,'filterValue')}
                radios={filterOptions.filterTypes?.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterValue}
                valueName={"filterValue"}
              />
            </ListItem>

            <ListItem>
              <ViknRadioGroup
                handleChange={(e) => handleAutoComplete(e,e.target.value,'filterType')}
                radios={filterOptions.Type?.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.filterType}
                valueName={"filterType"}
              />
            </ListItem>

            {filterOptions.filterType === "horizontal" &&
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Include Cost Of Goods Sold"),
                    checked: filterOptions.includeCostOfGoodsSold,
                    onChange: () => handleChange("includeCostOfGoodsSold"),
                  },
                ]}
              />
            </ListItem>
          }
            <ListItem>
              <ViknRadioGroup
                value={filterData.switchReport}
                radios={[
                  {
                    value: true,
                    label: t("Consolidate"),
                  },
                  {
                    value: false,
                    label: t("Detailed"),
                  },
                ]}
                handleChange={(e) =>
                  setFilterData((prev) => ({
                    ...prev,
                    switchReport: "true" === e.target.value,
                  }))
                }
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default ProfitAndLossAccount;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "40%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
