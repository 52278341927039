// import React, { lazy, Suspense } from "react";
import KSASettings from "../../Pages/Settings/Pages/KSASettings";
import TransactionNos from "../../Pages/Settings/Pages/TransactionNos";
import UserRoles from "../../Pages/Settings/Pages/UserRoles";
import Users from "../../Pages/Settings/Pages/Users";
import WhatsNew from "../../Pages/Settings/Pages/WhatsNew";
import Settings from "../../Pages/Settings/Settings";
import Setting from "../../Pages/Settings/Pages/Setting";
import ActivityLog from "../../Pages/ActivityLog/ActivityLog";
// import PageLoading from "../../Components/Loadings/PageLoading";
// import { Suspense } from "react";

// const Setting = lazy(() => import('../../Pages/Settings/Pages/Setting'));

// const LoadingComp = function ({ children }) {
//   return <Suspense fallback={<PageLoading />}>{children}</Suspense>;
// };

const settingsRout = [
  {
    path: "settings",
    element: <Settings />,
    children: [
      { path: "", element: <Setting />, index: true },
      { path: "transaction-nos", element: <TransactionNos /> },
      { path: "users", element: <Users /> },
      { path: "user-roles", element: <UserRoles /> },
      { path: "whats-new", element: <WhatsNew /> },
      { path: "e-invoice", element: <KSASettings /> },
    ],
  },
  {
    path: "activity-log",
    element: <ActivityLog />,
  },
];
export default settingsRout;
