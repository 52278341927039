import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { Box, colors, TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const DetailedTable =  React.forwardRef((props, ref) => {
  const [t, i18n] = useTranslation("common");
  let e = []
  const [length, setlength] = useState('')
  const [lengthDiff, setlengthDiff] = useState(0)
  const [detaildata, setdetaildata] = useState([])

  const DetailedRow = ({ horizontalFirstTable }) => (
    horizontalFirstTable.map((data, index) => {
  
      return (
        <React.Fragment key={index}>
          {(( data.is_gross === true  || (data.is_grossTotal === true && (detaildata?.GrossProfit === 0))) &&
            length === 'secondSectionTradingGreater') &&
            Array.from({ length: lengthDiff }).map((_, idx) => (
              <TableRow key={`empty-${idx}`}>
                <VBTableCellBody sx={{ color: 'transparent', fontWeight: 400 }}>{" ."}</VBTableCellBody>
                {props.filterOptions.includeCostOfGoodsSold &&
                <VBTableCellBody sx={{ textAlign: 'right' }}> </VBTableCellBody>
                }
                <VBTableCellBody sx={{ textAlign: 'right' }}> </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: 'right', color: 'normal', fontWeight: 400 }}>  </VBTableCellBody>
              </TableRow>
            ))
          }
  
          <TableRow>
            <VBTableCellBody sx={{ color: data.color ? data.color : 'normal', fontWeight: data.is_heading ? 600 : 400, backgroundColor: data?.backgroundColor }}>
            {data.is_sub ? '• ':null} {data.particular}
            </VBTableCellBody>
            {props.filterOptions.includeCostOfGoodsSold &&
            <VBTableCellBody sx={{ textAlign: 'right', backgroundColor: data?.backgroundColor }}>
              {data.ExtrasubTotal}
            </VBTableCellBody>
            }
            <VBTableCellBody sx={{ textAlign: 'right', backgroundColor: data?.backgroundColor }}>
              {data.subTotal}
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: 'right', borderRight: "1px solid #e6e6e6", color: data.color ? data.color : 'normal', fontWeight: data.is_heading ? 600 : 400, backgroundColor: data?.backgroundColor }}>
              {data.total}
            </VBTableCellBody>
          </TableRow>
        </React.Fragment>
      );
    })
  );
  
  

  const DetailedRowSec = ({ horizontalSecondTable }) => (
    horizontalSecondTable.map((data, index) => {      
      return (
        <React.Fragment key={index}>
          { ((data.is_gross === true ) && length === 'firstSectionTradingGreater') &&
            Array.from({ length: lengthDiff }).map((_, idx) => (
              <TableRow key={`empty-${index}-${idx}`}>
                <VBTableCellBody sx={{ color: 'transparent', fontWeight: 400 }}>{" ."}</VBTableCellBody>
                {props.filterOptions.includeCostOfGoodsSold &&
                <VBTableCellBody> {data.ExtrasubTotal} </VBTableCellBody>
                }
                <VBTableCellBody sx={{ textAlign: 'right' }}> </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: 'right', color: 'normal', fontWeight: 400 }}>  </VBTableCellBody>
              </TableRow>
            ))
          }
          <TableRow>
            <VBTableCellBody sx={{ color: data.color ? data.color : 'normal', fontWeight: data.is_heading ? 600 : 400, backgroundColor: data?.backgroundColor }}>
            {data.is_sub ? '• ':null}  {data.secondParticular}
            </VBTableCellBody>

            {props.filterOptions.includeCostOfGoodsSold &&
            <VBTableCellBody sx={{ textAlign: 'right', backgroundColor: data?.backgroundColor }}>
              {data.ExtrasubTotal}
            </VBTableCellBody>
            }
            <VBTableCellBody sx={{ textAlign: 'right', backgroundColor: data?.backgroundColor }}>
              {data.secondSubTotal}
            </VBTableCellBody>
            <VBTableCellBody sx={{ textAlign: 'right', color: data.color ? data.color : 'normal', fontWeight: data.is_heading ? 600 : 400, backgroundColor: data?.backgroundColor }}>
              {data.secondTotal}
            </VBTableCellBody>
          </TableRow>
        </React.Fragment>
      );
    })
  );
  


  const VeriticalRow = ({ data, isTotal }) => (
    <TableRow>
      <VBTableCellBody sx={{color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 600 : 400}}>{data.is_sub ? '• ':null}  {data.particular}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right' }}>{data.extrasubTotal ? data.extrasubTotal : ''}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right' }}>{data.subTotal}</VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: 'right',color : data.color ? data.color : 'normal',fontWeight :data.is_heading  ? 600 : 400 }}>{data.total}</VBTableCellBody>
      <VBTableCellBody > </VBTableCellBody>
      <VBTableCellBody> </VBTableCellBody>
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
      <VBTableCellBody>  </VBTableCellBody> 
    </TableRow>
  );
  
  const lengthDifference = () => {
    const firstSectionTradingLength = firstSectionTrading.length;
    const secondSectionTradingLength = secondSectionTrading.length;
  
    const lengthDiff = Math.abs(firstSectionTradingLength - secondSectionTradingLength);
    setlengthDiff(lengthDiff);    
    
    if (firstSectionTradingLength > secondSectionTradingLength) {
      setlength('firstSectionTradingGreater');
    } else if (secondSectionTradingLength > firstSectionTradingLength) {
      setlength('secondSectionTradingGreater');
    } else {
      setlength('equal');
    }
  
  };
  


  const firstSectionTrading = [
    ...(props.filterOptions.includeCostOfGoodsSold ? [
      {
        particular: 'Cost of goods sold',
        ExtrasubTotal : '',
        subTotal: '',
        total: detaildata?.CostOfGoodsSold,
        is_heading:true,
      },
      {
        particular: 'Opening Inventory',
        ExtrasubTotal : '',
        subTotal: detaildata?.TotalOpeningStockValue,
        total: '',
      },
      {
        particular: 'Purchase Accounts',
        ExtrasubTotal : '',
        subTotal: detaildata?.purchase,
        total: ''
      },
      {
        particular: 'Direct Expenses',
        ExtrasubTotal : '',
        subTotal: detaildata?.DirectExpence,
        total: '',
      },
      ...(Array.isArray(detaildata?.DirectExpenses) ? detaildata.DirectExpenses.map((i) => ({
        particular: i.LedgerName,
        ExtrasubTotal: `(${i.Balance})`,
        subTotal: '',
        total: '',
        is_heading: false,
        is_sub: true,
      })) : []),
      {
        particular: 'Less Closing Inventory',
        ExtrasubTotal : '',
        subTotal: `(${detaildata?.TotalClosingStockValue})`,
        total: '',
      },
      {
        particular: 'Less Purchase Return',
        ExtrasubTotal : '',
        subTotal: `(${detaildata?.PurchaseReturn})`,
        total: '',
      },
    ] : [

      {
        particular: 'Opening Stock',
        subTotal: '',
        total: detaildata?.TotalOpeningStockValue,
        is_heading:true,
      },
      {
        particular: 'Stock In hand',
        subTotal:detaildata?.TotalOpeningStockValue,
        total: '',
        is_heading:false,
      },
      {
        particular: 'Purchase Accounts',
        subTotal: '',
        total: detaildata?.Purchase,
        is_heading:true,
      },
      {
        particular: 'Purchase',
        subTotal: detaildata?.purchase,
        total: '',
        is_heading:false,
      },
      {
        particular: 'Less Purchase Return Outwards',
        subTotal: `(${detaildata?.PurchaseReturn})`,
        total: '',
        is_heading:false,
      },
      {
        particular: 'Direct Expenses',
        subTotal: '',
        total:detaildata?.DirectExpence,
        is_heading:true,
      },
      ...(Array.isArray(detaildata?.DirectExpenses) ? detaildata.DirectExpenses.map((i) => ({
        particular: i.LedgerName,
        subTotal: i.Balance,
        total: '',
        is_heading: false,
      })) : []),
    ]),
    ...(detaildata?.GrossProfit > 0 ? [{
      particular: 'Gross Profit c/o',
      ExtrasubTotal : '',
      subTotal: '',
      total: detaildata?.GrossProfit,
      color: 'green',
      backgroundColor: '#e1e1e1',
      is_heading: true,
      is_gross:true,
    }] : []),
    ...(props.filterOptions.includeCostOfGoodsSold ? [
      {
        particular: '',
        ExtrasubTotal : '',
        subTotal: '',
        total: detaildata?.GrossTotalInCostOfGoodsSold,
        is_heading:true,
        is_grossTotal : true,
      },
    ] : [
      {
        particular: '',
        subTotal: '',
        total: detaildata?.GrossTotal,
        is_heading:true,
        is_grossTotal : true,
      },
    ])
  ]

  const secondSectionTrading = [
    ...(props.filterOptions.includeCostOfGoodsSold ? [
      {
        secondParticular: 'Sales Accounts',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: detaildata?.Sales,
        is_heading:true,
      },
      {
        secondParticular: 'Sales',
        ExtrasubTotal : '',
        secondSubTotal: detaildata?.sales,
        secondTotal: '',
      },
      {
        secondParticular: 'Sales Return Inwards',
        ExtrasubTotal : '',
        secondSubTotal: `(${detaildata?.SalesReturn})`,
        secondTotal: '',
      },
      {
        secondParticular: 'Direct Incomes',
        ExtrasubTotal : '',
        secondSubTotal: detaildata?.DirectIncome,
        secondTotal: '',
      },
      ...(Array.isArray(detaildata?.DirectIncomes) ? detaildata.DirectIncomes.map((i) => ({
        secondParticular: i.LedgerName,
        ExtrasubTotal: `(${i.Balance})`,
        secondSubTotal: '',
        secondTotal: '',
        is_sub: true,
      })) : []),
    ] : [
      {
        secondParticular: 'Sales Account',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: detaildata?.Sales,
        is_heading:true,
      },
      {
        secondParticular: 'Sales',
        ExtrasubTotal : '',
        secondSubTotal: detaildata?.sales,
        secondTotal: '',
        is_heading:false,
      },
      {
        secondParticular: 'Less Sales Return Inwards',
        ExtrasubTotal : '',
        secondSubTotal: `(${detaildata?.SalesReturn})`,
        secondTotal: '',
        is_heading:false,
      },
      {
        secondParticular: 'Closing Stocks',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: detaildata?.TotalClosingStockValue,
        is_heading:true,
      },
      {
        secondParticular: 'Stock in Hands',
        ExtrasubTotal : '',
        secondSubTotal:detaildata?.TotalClosingStockValue,
        secondTotal: '',
        is_heading:false,
      },
      {
        secondParticular: 'Direct Income',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal:detaildata?.DirectIncome,
        is_heading:true,
      },
      ...(Array.isArray(detaildata?.DirectIncomes) ? detaildata.DirectIncomes.map((i) => ({
        ExtrasubTotal: '',
        secondParticular: i.LedgerName,
        secondSubTotal: i.Balance,
        secondTotal: '',
      })) : []),
    ]),
    ...(detaildata?.GrossLoss > 0 || (detaildata?.GrossLoss === 0 && detaildata?.GrossProfit === 0) ? [
      {
        secondParticular: 'Gross Loss c/o',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: detaildata?.GrossLoss,
        color : 'red',
        is_heading:true,
        is_gross:true,
        backgroundColor: '#e1e1e1',
      },
    ] : [{
      is_gross : true,
    }]),
    ...(props.filterOptions.includeCostOfGoodsSold ? [
    {
      secondParticular: '',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: detaildata?.GrossTotalInCostOfGoodsSold,
      is_heading:true,
      is_grossTotal : true,
    },
  ] : [
    {
      secondParticular: '',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: detaildata?.GrossTotal,
      is_heading:true,
      is_grossTotal : true,
    },
  ])
  ]
  
  const firstSectionProfitandLoss = [
    ...((detaildata?.GrossLoss > 0 || ( detaildata?.GrossLoss === 0 && detaildata?.GrossProfit === 0 )) ? [{
      particular: 'Gross Loss b/f',
      ExtrasubTotal : '',
      subTotal: '',
      total: detaildata?.GrossLoss,
      is_heading: true,
      backgroundColor: '#e1e1e1',
    }] : [
      {
        particular: ' .',
        ExtrasubTotal : '',
        subTotal: ' ',
        total: ' ',
        color: 'transparent',
        is_heading: true,    
      }
    ]),
    {
      particular: '.',
      ExtrasubTotal : '',
      subTotal: '',
      total: '',
      color:'transparent',
    },
    {
      particular: 'Indirect Expenses',
      ExtrasubTotal : '',
      subTotal: '',
      total: detaildata?.IndirectExpence,
      is_heading: true,
    },
    ...(Array.isArray(detaildata?.indirectExpenses) ? detaildata.indirectExpenses.map((i) => ({
      particular: i.LedgerName,
      ExtrasubTotal: '',
      subTotal: i.Balance === '-' ? '' : i.Balance,
      total: '',
      is_heading: false,
      color: i.LedgerName === '-' ? 'transparent' : 'normal'
    })) : []),
    {
      particular: '.',
      ExtrasubTotal : '',
      subTotal: '',
      total: '',
      color:'transparent',
    },
    ...(detaildata?.NetProfit > 0 ? [{
      particular: 'Net Profit',
      ExtrasubTotal : '',
      subTotal: '',
      total: detaildata?.NetProfit,
      is_heading: true,
      color : 'green',
    }] : [
      {
        particular: '.',
        ExtrasubTotal : '',
        subTotal: '',
        total: '',
        color:'transparent',
      },
    ]),
    {
      particular: 'Total',
      ExtrasubTotal : '',
      subTotal: '',
      total:detaildata?.NetTotal,
      is_heading: true,
      backgroundColor: '#e1e1e1',
      isNetTotal : true,
    },
  ]

  const secondSectionProfitandLoss = [
    ...(detaildata?.GrossProfit > 0 ? [{
      secondParticular: 'Gross Profit b/f',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: detaildata?.GrossProfit,
      is_heading: true,
      backgroundColor: '#e1e1e1',
    }] : [
      {
        secondParticular: '.',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: '',
        color:'transparent',
      },
    ]),
    {
      secondParticular: '.',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: '',
      color:'transparent',
    },
    {
      secondParticular: 'Indirect Incomes',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: detaildata?.IndirectIncome,
      is_heading: true,
    },
    ...(Array.isArray(detaildata?.indirectIncomes) ? detaildata.indirectIncomes.map((i) => ({
      secondParticular: i.LedgerName,
      ExtrasubTotal: '',
      secondSubTotal: i.Balance === '-' ? '' : i.Balance,
      secondTotal: '',
      is_heading: false,
      color: i.LedgerName === '-' ? 'transparent' : 'normal'
    })) : []),
    {
      secondParticular: '.',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: '',
      color:'transparent',
    },
    ...(detaildata?.NetLoss > 0 || (detaildata?.NetLoss === 0 && detaildata?.NetProfit === 0) ? [{
      secondParticular: 'Net Loss',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal: detaildata?.NetLoss,
      color:'red',
      is_heading: true,
    }] : [
      {
        secondParticular: '.',
        ExtrasubTotal : '',
        secondSubTotal: '',
        secondTotal: '',
        color:'transparent',
      },
    ]),
    {
      secondParticular: 'Total',
      ExtrasubTotal : '',
      secondSubTotal: '',
      secondTotal:detaildata?.NetTotal,
      is_heading: true,
      backgroundColor: '#e1e1e1',
      isNetTotal : true,
    },
  ]
 
  const veritical_trading_account = [
    {
      particular: 'Sales Account',
      subTotal: '',
      total : detaildata?.Sales ,
      is_heading: true,
    },
    {
      particular: 'Sales',
      subTotal: detaildata?.sales,
      total : '',
    },
    {
      particular: 'Less Return Inward',
      subTotal: detaildata?.SalesReturn,
      total : '',
    },
    {
      particular: 'Direct Income',
      subTotal: '',
      total : detaildata?.DirectIncome,
      is_heading: true,
    },
    ...(Array.isArray(detaildata?.DirectIncomes) ? detaildata?.DirectIncomes.map((i) => ({
      particular: i.LedgerName,
      subTotal: `${i.Balance}`,
      total: '',
    })) : []),
    {
      particular: 'Net Sales',
      subTotal: '',
      total :(parseFloat(detaildata?.Sales) + parseFloat(detaildata?.DirectIncome)).toFixed(2),
      is_heading: true,
    },
    {
      particular: 'Less Cost',
      subTotal: '',
      total :'',
      is_heading: true,
    },
    {
      particular: 'Opening Inventory',
      subTotal: detaildata?.TotalOpeningStockValue,
      total :'',
    },
    {
      particular: 'Add Purchase',
      subTotal: detaildata?.purchase,
      total :'',
    },
    {
      particular: 'Direct Expense',
      subTotal: detaildata?.DirectExpence,
      total :'',
    },
    ...(Array.isArray(detaildata?.DirectExpenses ) ? detaildata?.DirectExpenses.map((i) => ({
      particular: i.LedgerName,
      extrasubTotal: `${i.Balance}`,
      subTotal: '',
      total: '',
      is_sub : true,
    })) : []),
    {
      particular: 'Cost of goods available for sales',
      subTotal: detaildata?.Costofgoodsavailable,
      total :'',
    },
    {
      particular: 'Less Return Outward',
      subTotal: detaildata?.PurchaseReturn,
      total :'',
    },
    {
      particular: 'Less Closing Inventory',
      subTotal: detaildata?.TotalClosingStockValue,
      total :'',
    },
    {
      particular: 'Cost of goods sold',
      subTotal: '',
      total :detaildata?.CostOfGoodsSold,
      is_heading: true,
    },
    {
      particular: ' .',
      subTotal: '',
      total :'',
      color :'transparent',
    },
    ...(props.data?.GrossProfit > 0 ? [
    {
      particular: 'Gross Profit',
      subTotal: '',
      total :detaildata?.GrossProfit,
      is_heading: true,
      color : 'green',
    },
    ] : [
      {
        particular: 'Gross Loss',
        subTotal: '',
        total :detaildata?.GrossLoss,
        is_heading: true,
        color : 'red',
      }
    ]),
  ]

  const veritical_profit_and_Loss = [
    ...(props?.filterOptions?.filterValue === 'ProfitAndLossAccount' ? [
      ...(detaildata?.GrossProfit > 0 ? [
        {
          particular: 'Gross Profit',
          subTotal: '',
          total: detaildata?.GrossProfit,
          is_heading : true,
          color : 'green',
        },
        ] : [
          {
            particular: 'Gross Loss',
            subTotal: '',
            total: detaildata?.GrossLoss,
            is_heading : true,
            color : 'red',
          },
        ])
  
    ] : []),
    {
      particular: ' .',
      subTotal: '',
      total :'',
      color :'transparent',
    },
    {
      particular: 'Add Other Incomes',
      subTotal: '',
      total :detaildata?.IndirectIncome,
      is_heading: true,
    },
    ...(Array.isArray(detaildata?.indirectIncomes ) ? detaildata?.indirectIncomes.map((i) => ({
      particular: i.LedgerName,
      subTotal: `${i.Balance}`,
      total: '',
    })) : []),
    {
      particular: 'Less Operating Expenses',
      subTotal: '',
      total :detaildata?.IndirectExpence,
      is_heading: true,
    },
    ...(Array.isArray(detaildata?.indirectExpenses ) ? detaildata?.indirectExpenses.map((i) => ({
      particular: i.LedgerName,
      subTotal: `${i.Balance}`,
      total: '',
    })) : []),
    {
      particular: ' .',
      subTotal: '',
      total :'',
      color :'transparent',
    },
    ...(props.data?.GrossProfit > 0 ? [
    {
      particular: 'Net Profit',
      subTotal: '',
      total :detaildata?.NetProfit,
      is_heading: true,
      color :'green'
    },
    ] : [
      {
      particular: 'Net Loss',
      subTotal: '',
      total :detaildata?.NetLoss,
      is_heading: true,
      color :'red'
    }
    ])
  ]

  useEffect(() => {
    setdetaildata(props?.data)
  }, [props.data]);  

  useEffect(() => {
    lengthDifference()
  }, [detaildata]);
  


const horizontalFirstTable = props?.filterOptions?.filterValue === 'All' ? [...firstSectionTrading, ...firstSectionProfitandLoss] : props?.filterOptions?.filterValue === 'TradingAccount' ? firstSectionTrading : firstSectionProfitandLoss ;
const horizontalSecondTable = props?.filterOptions?.filterValue === 'All' ? [...secondSectionTrading, ...secondSectionProfitandLoss] : props?.filterOptions?.filterValue === 'TradingAccount' ? secondSectionTrading : secondSectionProfitandLoss ;

const veritical_data = props?.filterOptions?.filterValue === 'All' ? [...veritical_trading_account, ...veritical_profit_and_Loss] : props?.filterOptions?.filterValue === 'TradingAccount' ? veritical_trading_account : veritical_profit_and_Loss ;

  return (
    <div ref={ref}>
    <VBTableContainer >
    {props.isLoading ? 
    <VBSkeletonLoader /> 
    : props?.filterOptions?.filterType === 'horizontal' ? 

    <Box sx={{display:'flex', width:'100%'}} >
      <VBTableContainer sx={{width:'50%'}}>
        <VBTableHeader sx={{border:'2px solid red',backgroundColor:'#dfdfdf', width:'100%'}}>
          <VBTableCellHeader sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellHeader>
          {props.filterOptions.includeCostOfGoodsSold &&
          <VBTableCellHeader sx={{ fontWeight: "bold",textAlign: "right"}} > </VBTableCellHeader>
          }
          <VBTableCellHeader sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellHeader>
          <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> TOTAL</VBTableCellHeader>
        </VBTableHeader>
          
        <DetailedRow 
          horizontalFirstTable={horizontalFirstTable}
        />
      </VBTableContainer>

      <VBTableContainer sx={{width:'50%'}}>
        <VBTableHeader sx={{border:'2px solid red',backgroundColor:'#dfdfdf'}}>
          <VBTableCellHeader sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellHeader>
          {props.filterOptions.includeCostOfGoodsSold &&
          <VBTableCellHeader sx={{ fontWeight: "bold",textAlign: "right"}} > </VBTableCellHeader>
          }
          <VBTableCellHeader sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellHeader>
          <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> TOTAL</VBTableCellHeader>
        </VBTableHeader>
          
        <DetailedRowSec 
          horizontalSecondTable={horizontalSecondTable}
        />
      </VBTableContainer>
    </Box>


    : 
    <TableBody >
      <TableRow>
        <VBTableCellHeader sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellHeader>
        <VBTableCellHeader > </VBTableCellHeader>      
        <VBTableCellHeader sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellHeader>
        <VBTableCellHeader sx={{ fontWeight: "bold", textAlign: "right"}}> TOTAL</VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>      
        <VBTableCellHeader > </VBTableCellHeader>               
        <VBTableCellHeader > </VBTableCellHeader>       
        <VBTableCellHeader > </VBTableCellHeader>       
        <VBTableCellHeader > </VBTableCellHeader>       
        <VBTableCellHeader > </VBTableCellHeader>       
        <VBTableCellHeader > </VBTableCellHeader>       
      </TableRow>

      {veritical_data?.map((row,index) => (
        <VeriticalRow
          key={index}
          data={row}
          isTotal={row.particular === 'Total' || row.secondParticular === 'Total'}
        />
      ))}

      <TableRow></TableRow>
  </TableBody>
  }
  </VBTableContainer>
  </div>
  )
});

export default DetailedTable