import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled
} from "@mui/material";
import moment from "moment";

import React, { Suspense, useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TranslateIcon from "@mui/icons-material/Translate";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppsIcon from "@mui/icons-material/Apps";
import { Icons } from "../../Assets/AssetLog";
import { Link, useNavigate } from "react-router-dom";
// import styled from "@emotion/styled";
import { ACCOUNTS_API_BaseURL, ACCOUNTS_API_MEDIA_URL, ACCOUNTS_FRONT_URL, BASE_URL, BASE_V11_URL, VIKNBOOKS_FRONT_URL, domain,  } from "../../generalVeriable";
import { checkWordLength, getAvatar, getServiceUrl, get_unq_browserID, set_handleLogout } from "../../Function/comonFunction";
// import Modal from "@mui/material/Modal";
import { Modal } from "@mui/material";
import { Decrypt, getCookie } from "../../generalFunction";
import { get_UserRoleSettings } from "../../Api/generalSettings/generalSettings";
import { get_user_role_data } from "../Utils/UserRoleJsons";
import { useDispatch, useSelector } from "react-redux";
import { financialYearSuccess } from "../../features/financialYearSlice";
import { userTableSuccess } from "../../features/userTableSlice";
import { companyDetailsSuccess, selectCompanyID, selectCompanyName } from "../../features/companyDetailsSlice";
import Tooltip from "@mui/material/Tooltip";
import QuickMenu from "./Component/QuickMenu";
import { generalSettingsLogoutSuccess, generalSettingsSuccess } from "../../features/generalSettingsSclice";
import { userRoleSettingsLogoutSuccess, userRoleSettingsSuccess } from "../../features/userRoleSclice";
import { branchSettingsLogoutSuccess, branchSettingsSuccess } from "../../features/branchSettingsSlice";
import { accessToken, loginSuccess, logoutSuccess, selectAuthenticated, selectIsUpdate } from "../../features/userSlice";
import axios from "axios";
import { submitModeLogoutSuccess } from "../../features/submitModeSlice";
import { FilterLogoutSuccess } from "../../features/salesFilter";
import { store } from "../../app/store";
import { useTranslation } from "react-i18next";
import { languageSuccess } from "../../features/languageSlice";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { companyUrlSuccess } from "../../features/companyUrlSettingsSlice";
import { versionSliceSuccess } from "../../features/versionSlice";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { UserRolePermission } from "../../Function/Editions";
const getCurrentDate = () => {
  var date = new Date().getDate();
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();

  //Alert.alert(date + '-' + month + '-' + year);
  // You can turn it in to your desired format
  return date + "/" + month + "/" + year; //format: dd-mm-yyyy;
};

const Header = (props) => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  let showExpiredModal = false;
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let valueparams = params.data;
  let token = "";
  let service = "accounts";
  let is_admin = false;
  let remember_me = true;
  if (valueparams) {
    let data = Decrypt(valueparams);
    service = data.service;
    if (!service || service === "") {
      service = "accounts";
    }
    token = data.token;
    is_admin = params.is_admin;
    remember_me = params.remember_me;
    showExpiredModal = true;
  }
  const store_values = store.getState();
  const [changeMainVB ,setChangeMainVB] = useState(getCookie("is_change") ? getCookie("is_change") : false)
  const [access, setAccess] = useState(
    getCookie("VBID") ? getCookie("VBID") : token
  );
  const [openSwitchCompany, setOpenSwitchCompany] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [expanded, setExpanded] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState({
    event: "",
    id: ""
  });
  const [organizationAction, setOrganizationAction] = useState({
    id: "",
    delete: false,
    edit: false,
    leave: false,
  });
  const handleSwitchCompanyModalClose = () => {
    setOpenSwitchCompany(false);
  };
  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id);
  };
  const handleClickPoper = (event, id) => {
    setAnchorEl({
      event: event.currentTarget,
      id: id
    });
  };

  const handleClose = () => {
    setAnchorEl({
      event: "",
      id: ""
    });
  };
  let [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString("en-US")
  );
  const [showQuickMenu, setQuickMenu] = useState(false);
  const [showBranchSettings, setBranchSettings] = useState(false);
  const [showDropMenu, setDropMenu] = useState(false);
  const {
    CompanyLogo,Edition,BusinessTypeName,BranchName,
    BranchID
    ,IsBranch,IsTest
  } = useSelector((state) => state.companyDetails);
  const [state, setState] = useState({
    companies: [],
    branches: [
    ],
    expiry_days: "",
    is_test: IsTest,
    user_type: 1,
    UserTypeName: "Admin",
    user_details: [],
    company_details: [],
    loading: true,
    call_renew: false,
    updateMessage: { finished: true },
  });
  const [t, i18n] = useTranslation("common");
  const [language, setLanguage] = useState(store_values.language.language);
  let CompanyName = useSelector(selectCompanyName);
  let CompanyID = useSelector(selectCompanyID);
  const { user_id, email, username, photo } = useSelector((state) => state.user);
  const companyDetailsState = useSelector((state) => state.companyDetails);

  let { user_type, is_owner } = useSelector((state) => state.userTable);
  const { isAuth } = useSelector((state) => state.user);
  const {EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const companyDetailsRX = useSelector((state) => state.companyDetails);
  const generalSettingsRX = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const financialYearRX = useSelector((state) => state.financialYear);
  const userTableRX = useSelector((state) => state.userTable);
  const userRollSettingsRX = useSelector((state) => state.userRollSettings);
  const branchSettings = useSelector((state) => state.branchSettings);
  const branchSettingsState = useSelector((state) => state.branchSettings);
  const { IsUpdationWindowClosed } = useSelector((state) => state.versionDetails);
  const isAuthenticated = useSelector(selectAuthenticated);
  const access_token = useSelector(accessToken);
  const is_update = useSelector(selectIsUpdate);
  // handle function
  const gotoViknBooks = function() {
    window.location.href = `${VIKNBOOKS_FRONT_URL}dashboard/home`;
  }
  let role_data = get_user_role_data;
  const handleChangeLanguage = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    dispatch(languageSuccess({ language: e.target.value }));
    i18n.changeLanguage(e.target.value);
  };
  const switchCompany = async ({ id, CompanyName, branchId }) => {
    // const generalSettingsResponse = await fetch(generalSettingsListUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     CompanyID: id,
    //     CreatedUserID:UserDetails.user_id,
    //     BranchID: branchId,
    //   }),
    // }).then((response) => response.json());

    // const branchSettingsResponse = await fetch(branchSettingsListUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     CompanyID: id,
    //     CreatedUserID: UserDetails.user_id,
    //     BranchID: branchId,
    //   }),
    // }).then((response) => response.json());

    // const switchCompanyResponse = await fetch(switchCompanyUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     CompanyID: id,
    //     user_id: UserDetails.user_id,
    //     BranchID: branchId,
    //   }),
    // }).then((response) => response.json());
    // let BaseURL = switchCompanyResponse.BaseURL;
    // dispatch(companyUrlSuccess({companyUrl:BaseURL}));
    // const companyDetailsResponse = await fetch(companyDetailsUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     userId: UserDetails.user_id,
    //     BranchID: branchId,
    //   }),
    // }).then((response) => response.json());

    // const financialYearResponse = await fetch(getFinancialYearUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     CompanyID: id,
    //     BranchID: branchId,
    //   }),
    // }).then((response) => response.json());

    // const getUserTableResponse = await fetch(getUserTableUrl, {
    //   method: "POST",
    //   headers: {
    //     "content-type": "application/json",
    //     Authorization: `Bearer ${access}`,
    //     accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     CompanyID: id,
    //     BranchID: branchId,
    //     userId: UserDetails.user_id,
    //   }),
    // }).then((response) => response.json());
    // user_type = getUserTableResponse.data.user_type;
    // const response_datas = await get_UserRoleSettings(
    //   companyDetailsResponse.data.CompanyID,
    //   user_type,
    //   access,
    //   UserDetails.user_id,
    //   role_data,
    //   "List"
    // );
    // if (response_datas.length) {
    //   role_data = response_datas;
    // }
    // dispatch(
    //   financialYearSuccess({
    //     ...financialYearResponse.data,
    //   })
    // );
    // dispatch(
    //   userTableSuccess({
    //     ...getUserTableResponse.data,
    //   })
    // );

    // dispatch(
    //   companyDetailsSuccess({
    //     ...companyDetailsState,
    //     CompanyID: id,
    //     CompanyName: CompanyName,

    //     ...switchCompanyResponse.data,
    //   })
    // );
    // dispatch(
    //   generalSettingsSuccess({
    //     ...generalSettingsResponse.data,
    //   })
    // );
    // dispatch(
    //   branchSettingsSuccess({
    //     ...branchSettingsResponse.data,
    //   })
    // );
    // dispatch(userRoleSettingsSuccess(role_data));

    // dispatch(submitModeSuccess({}));
    // // history.push("/");
    // // const get_Company_db_data = await get_Company_db(
    // //   CompanyID,
    // //   user_id,
    // //   access
    // // );

    // // dispatch(companyUrlSuccess(get_Company_db_data));
    // history.push("/dashboard/home");
    // window.location.reload();
  };

  const companyList = async () => {
    try {
      let company_list_response = await fetch(`${BASE_V11_URL}users/companies/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`, // or `access` based on your setup
        },
        body: JSON.stringify({
          userId: user_id,
        }),
      });
  
      let response = await company_list_response.json();
  
      if (response.StatusCode === 6000) {
        setState((prevState) => ({
          ...prevState,
          companies: response.data,
        }));
      } else {
        console.log("Reload This Page");
      }
    } catch (error) {
      console.error("Error in companyList:", error);
    }
  };
  const [infiniteLoad ,setInfiniteLoad] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      //auto change in all-----------------------------------------------------------------------------------------------------------------
      let updatedCookie_is_change =  getCookie("is_change")
      if ((updatedCookie_is_change  === true || updatedCookie_is_change  === "true") && access && infiniteLoad === true ){
        setInfiniteLoad(false)
        cookieAuthentication(access,true)
      }
      // ----------------------------------------------------------------------------------------------------------------------------------
      // else 
      if(!getCookie("VBID")){
        handleLogout()
      }
      setCurrentTime(new Date().toLocaleTimeString("en-US"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
    // outside click
    function useOutsideAlerter(ref) {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setQuickMenu(false);
            setDropMenu(false);
            setBranchSettings(false);
            // alert("You clicked outside of me!");
          }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
  async function cookieAuthentication(access,is_allchange = false) {
    props.setLoading(true);
    let LastLoginBranchID =null
    const loginResponse = await fetch(`${ACCOUNTS_API_BaseURL}users/get-user-details/`, {
    // const loginResponse = await fetch(`${BASE_URL}users/get-user-details/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
        accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((response) => response.json());
    CompanyID = loginResponse.LastLoginID;
    LastLoginBranchID = loginResponse.LastLoginBranchID;
    // baseurl saving
    let BASE_URL = loginResponse.BaseURL;
    dispatch(companyUrlSuccess({companyUrl:BASE_URL}));
    if (!companyDetailsRX.CompanyID||changeMainVB === true ||changeMainVB === "true"||is_allchange === true) {
      const companyDetailsResponse = await fetch(`${BASE_URL}/api/v10/users/get-company-details-by-company/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
          accept: "application/json",
        },
        body: JSON.stringify({
          userId: loginResponse.data.user_id,
          CompanyID:CompanyID,
          BranchID: LastLoginBranchID,
        }),
      }).then((response) => response.json());
      if (companyDetailsResponse.StatusCode === 6000) {
        dispatch(
          companyDetailsSuccess({
            ...companyDetailsResponse.data,
          })
        );

        CompanyID = companyDetailsResponse.data.CompanyID;
        // const get_Company_db_data = await get_Company_db(
        //   CompanyID,
        //   companyDetailsResponse.data.CreatedUserID,
        //   access
        // );

        // dispatch(companyUrlSuccess(get_Company_db_data));

        const generalSettingsResponse = await fetch(`${BASE_URL}/api/v10/users/general-settings-list/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
            accept: "application/json",
          },
          body: JSON.stringify({
            CompanyID: companyDetailsResponse.data.CompanyID,
            CreatedUserID: companyDetailsResponse.data.CreatedUserID,
            // CreatedUserID: loginResponse.data.user_id,
            BranchID: 1,
          }),
        }).then((response) => response.json());
        dispatch(
          generalSettingsSuccess({
            ...generalSettingsResponse.data,
          })
        );
        const financialYearResponse = await fetch(`${BASE_URL}/api/v10/users/get-financial-year/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
            accept: "application/json",
          },
          body: JSON.stringify({
            CompanyID: companyDetailsResponse.data.CompanyID,
          }),
        }).then((response) => response.json());

        dispatch(
          financialYearSuccess({
            ...financialYearResponse.data,
          })
        );
        const getUserTableResponse = await fetch(`${BASE_URL}/api/v10/users/get-user-table/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
            accept: "application/json",
          },
          body: JSON.stringify({
            CompanyID: companyDetailsResponse.data.CompanyID,
            BranchID: LastLoginBranchID,
            userId: companyDetailsResponse.data.CreatedUserID,
          }),
        }).then((response) => response.json());

        dispatch(
          userTableSuccess({
            ...getUserTableResponse.data,
          })
        );

        let user_typeID = getUserTableResponse.data.user_type;
        const response_datas = await get_UserRoleSettings(
          companyDetailsResponse.data.CompanyID,
          LastLoginBranchID,
          user_typeID,
          access,
          companyDetailsResponse.data.CreatedUserID,
          role_data,
          "List",
          BASE_URL
        );
        if (response_datas.length) {
          role_data = response_datas;
          dispatch(userRoleSettingsSuccess(role_data));
        }

        // Take Branch Settings List
        const branchSettingsResponse = await fetch(`${BASE_URL}/api/v11/branches/branch-settings-list/`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
            accept: "application/json",
          },
          body: JSON.stringify({
            CompanyID: companyDetailsResponse.data.CompanyID,
            CreatedUserID: companyDetailsResponse.data.CreatedUserID,
            BranchID: companyDetailsResponse.data.BranchID,
          }),
        }).then((response) => response.json());
        dispatch(
          branchSettingsSuccess(
            branchSettingsResponse.data
          )
        );
        document.cookie = `is_change=false; path=/;domain=${domain}`;
        setChangeMainVB(false)

        // if (loginResponse.success === 6000) {
        // dispatch(
        //   loginSuccess({
        //     // ...loginResponse.data,
        //     access: access1,
        //     user_id: loginResponse.data.user_id,
        //     username: loginResponse.data.username,
        //     // photo: loginResponse.photo,
        //     isAuth: true,
        //     admin_login: false,
        //   })
        // );

        // history.push("/dashboard/home");

        // setState({ ...state, loading: false });
        // } else {
        //   window.alert("user not found");
        //   setState({ loading: false });
        // }
        window.location.reload()
      } else {
        console.log("COMPSNY DOSNOT EXISTS");
      }
    }
    
    // ==== Commented By Nashid maybe not neeed
    // if (is_update === false) {
    //   let update_token = await fetch(
    //     ACCOUNTS_API_BaseURL + `accounts/update-token`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "content-type": "application/json",
    //         // Authorization: `Bearer ${access_token}`,
    //         Authorization: `Bearer ${access}`,
    //         // "accept": "application/json"
    //       },
    //       body: JSON.stringify({
    //         UserID: user_id,
    //         service: "viknbooks",
    //         token: access,
    //       }),
    //     }
    //   ).then((response) => response.json());
    // }
    dispatch(
      loginSuccess({
        // ...loginResponse.data,
        access: access,
        user_id: loginResponse.data.user_id,
        username: loginResponse.data.username,
        photo: loginResponse.photo,
        isAuth: true,
        admin_login: false,
        is_update: true,
      })
    );
    return CompanyID;
  }//`${}`
  async function lastFetchData(CompanyID) {
    let company_list_response = await fetch(`${BASE_V11_URL}users/companies/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        // Authorization: `Bearer ${access_token}`,
        Authorization: `Bearer ${access}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        userId: user_id,
      }),
    }).then((response) => response.json());
    // .then(async (response) => {
    // setState({
    //   ...state,
    //   loading: false,
    //   companies: response.data,
    //   CompanyName: response.data.CompanyName,
    //   CompanyID: response.data.id,
    //   ExpiryDate: response.data.ExpiryDate,
    // });

    function getNextValidBranch(Branches) {
      let today = new Date();
      today.setHours(0, 0, 0, 0); // to ignore time and compare only dates
  
      for (let i = Branches.length - 1; i >= 0; i--) {
        let expiryDate = new Date(Branches[i].ExpiryDate);
        if (expiryDate > today) {
          return Branches[i];
        }
      }
      return false;
    }
    if (company_list_response.StatusCode === 6000) {
      setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          companies: company_list_response.data,
        };
      });
      let CompaniesList = company_list_response.data;
      let current_company = CompaniesList.filter((i) => i.id === CompanyID);

      var today = new Date();
      today.setDate(today.getDate());
      var DefaultDate = today.toISOString().substr(0, 10);
      if (current_company.length) {
        if (current_company[0].ExpiryDate < DefaultDate) {
          // swal({
          //   title: t("Expired  "),
          //   text:
          //     current_company[0].CompanyName +
          //     " " +
          //     t("Expired! Please Contact us") +
          //     "(+91 95775 00400 | +966 53 313 4959 | +971 52295 6284)" +
          //     t("to continue"),
          //   icon: "warning",
          //   button: true,
          // });
          let new_company = CompaniesList.filter((i) => i.ExpiryDate > DefaultDate);
            if (new_company.length > 0) {
              let newBranch = new_company[0].IsBranch && getNextValidBranch(new_company[0].Branches)
              await switchCompany({
                id: new_company[0].id,
                CompanyName: new_company[0].CompanyName,
                Permission: new_company[0].Permission,
                branchId: newBranch.BranchID || 1
              });
            } else {
              // history.push("/create-company");
              navigate("/create-company");
            }
        } else {
          // await switchCompany({
          //   id: current_company[0].id,
          //   CompanyName: current_company[0].CompanyName,
          //   Permission: current_company[0].Permission,
          // });
          let Branch_ID = BranchID
          if(!Branch_ID){
            Branch_ID = JSON.parse(localStorage.getItem('companyDetails')).BranchID
          }
          let branchFilter = current_company[0].IsBranch && current_company[0].Branches.filter(i => i.BranchID === Branch_ID)
          if(branchFilter[0] && branchFilter[0].ExpiryDate < DefaultDate){
            // swal({
            //   title: t("Expired"),
            //   text:
            //   current_company[0].CompanyName +
            //   " " +
            //   t("Expired! Please Contact us") +
            //   "(+91 95775 00400 | +966 53 313 4959 | +971 52295 6284)" +
            //   t("to continue"),
            //   icon: "warning",
            //   button: true,
            // });
            let new_branch = current_company[0].Branches.filter((i) => i.ExpiryDate > DefaultDate);
            if (new_branch.length > 0){
              await switchCompany({
                id: current_company[0].id,
                CompanyName: current_company[0].CompanyName,
                Permission: current_company[0].Permission,
                branchId: new_branch[0].BranchID
              });
            }
            else{
              let new_company = CompaniesList.filter((i) => i.ExpiryDate > DefaultDate);
              if (new_company.length > 0) {
                let newBranch = new_company[0].IsBranch && getNextValidBranch(new_company[0].Branches)
                  await switchCompany({
                    id: new_company[0].id,
                    CompanyName: new_company[0].CompanyName,
                    Permission: new_company[0].Permission,
                    branchId: newBranch.BranchID || 1
                  });
              } else {
              navigate("/create-company");
              // history.push("/create-company");
              }
            }

          }else{
            setState((prevState) => {
              return {
                ...prevState,
                loading: false,
                // companies: company_list_response.data,
                CompanyName: current_company[0].CompanyName,
                CompanyID: current_company[0].id,
                ExpiryDate: current_company[0].ExpiryDate,
                call_renew: true,
              };
            });
          }
        }
      } else {
        let new_company = CompaniesList.filter(
          (i) => i.ExpiryDate > DefaultDate
        );
        if (new_company.length > 0) {
          let newBranch = new_company[0].IsBranch && getNextValidBranch(new_company[0].Branches)
          await switchCompany({
            id: new_company[0].id,
            CompanyName: new_company[0].CompanyName,
            Permission: new_company[0].Permission,
            branchId: newBranch.BranchID || 1,
            call_renew: true,
          });
        } else {
          // history.push("/create-company");
          navigate("/create-company");
        }
      }
    } else {
      // ==============UVAIS
      // history.push("/create-company");
      navigate("/create-company");
    }
  }
  useEffect( () => {
    const loadData = async()=>{
    if ((access && token === "")||changeMainVB) {
      let CompanyID = await cookieAuthentication(access);
      // ==========================
      await lastFetchData(CompanyID);
      props.setLoading(false);
    } else if (valueparams) {
      let account_uqNo = params.uqNo;
      // let uqNo = getCookie("uqNo");
      let uqNo = "";
      if (account_uqNo !== "123456") {
        try {
          uqNo = await get_unq_browserID();
        } catch (err) {
          uqNo = 12345;
          // uqNo = getUnqNo();
        }
      } else {
        uqNo = "123456";
      }

      if (String(account_uqNo) === String(uqNo)) {
        if (service && token) {
          props.setLoading(true);
          axios
            .get(
              `${BASE_URL}users/accounts?service=${service}&token=${token}&is_admin=${is_admin}&remember_me=${remember_me}`,
              {
                withCredentials: true,
                service: service,
                token: token,
                is_admin: is_admin,
                remember_me: remember_me,
              }
            )
            .then(async () => {
              if (service === "viknbooks") {
                setAccess(token);
                let CompanyID = await cookieAuthentication(token);
                await lastFetchData(CompanyID);
                props.setLoading(false);
              } else {
                getServiceUrl(service);
                props.setLoading(false);
              }
            });
        }
      } else {
        handleLogout();
      }
    } else {
      handleLogout();
    } 
    setInfiniteLoad(true)//auto change in all
    }
    loadData()
  }, []);

  let updatedCookie_is_refresh =  getCookie("is_refresh")

  const ClearCache = async () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      await dispatch(
        versionSliceSuccess({
          ...props.state,
        })
      );
      // props.setState({
      //   ...props.state,
      //   is_updation: false,
      //   show_center_menu: false,
      // });
      const storedInfo = JSON.parse(localStorage.getItem("app_info"));
      // storedInfo.is_updation = false
      localStorage.setItem("app_info", JSON.stringify(storedInfo));
      window.location.reload(true);
    }
  };

  useEffect(() => {
    if (updatedCookie_is_refresh === true || updatedCookie_is_refresh === "true") {
      ClearCache();
      document.cookie = `is_refresh=false; path=/;domain=${domain}`;
    }
  }, [updatedCookie_is_refresh === true || updatedCookie_is_refresh === "true"]);

  // SSO END---------
  const handleLogout = async () => {
    let dic = {
      username: username,
      email: email,
      image_url: photo,
    };
    dispatch(logoutSuccess());
    dispatch(userRoleSettingsLogoutSuccess());
    dispatch(branchSettingsLogoutSuccess());
    dispatch(submitModeLogoutSuccess());
    dispatch(FilterLogoutSuccess());
    dispatch(generalSettingsLogoutSuccess());
    // =====================
    let return_value = await set_handleLogout(dic);
    // window.open(
    //   `${VIKNBOOKS_FRONT_URL}service?service=task_manager&type=logout`,
    //   "_self"
    // );
    // =======================
    // document.cookie = `VBID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;
    // history.push("/");
    // window.location.reload();
    window.open(`${ACCOUNTS_FRONT_URL}signin?service=viknbooks`, "_self");
    // ClearCache()
  };

  // let user_role_settings = useSelector((state) => state.userRollSettings);
  // let settings_permission = user_role_settings.filter(
  //   (i) => i.name === "ActivityLog"
  // );
  
  // let view_permission = settings_permission[0].view_permission

  let activity_log_view_permission = UserRolePermission("ActivityLog", "view_permission");

  return (
    <Box sx={{ display: "flex", width: "100%" }} ref={wrapperRef}>
      <LogoBox onClick={gotoViknBooks} variant="contained">
        <img
          src={Icons.VBLogo}
          alt="vbLogo"
          style={{ width: "100%", height: "100%",objectFit: "contain", }}
        />
      </LogoBox>

      <HeaderBox>
        <Leftpaper>
        <Tooltip
                  title={`You cannot view company details on this page.`}
                >
          <Box sx={{ display: "flex", width: "60%", cursor: "pointer" }} onClick={()=>{
            companyList();
            setOpenSwitchCompany(true);
            
          }}>
            {CompanyLogo?
            <Avatar sx={{ m:0.5, mr: 1,width:"32px",height:"32px",
            objectFit: "cover",border: "2px solid #1fa500",
          }} src={CompanyLogo}>C</Avatar>
            :<Avatar sx={{m:0.5, mr: 1,width:"32px",height:"32px",
            objectFit: "cover",border: "2px solid #1fa500",
          }} src={getAvatar(CompanyID)}>C</Avatar>
            }
            <LeftAliner>
              <div style={{
                width: "120px",
                overflow: "hidden",
              }}>
                <MovingCompanyTypography 
                  move={checkWordLength(CompanyName)}
                  >{CompanyName}</MovingCompanyTypography>
                  {EnableBranch ? (
                <MovingBranchTypography move={checkWordLength(branchSettings?branchSettings.NickName?branchSettings.NickName:BranchName:BranchName)}>
                  {branchSettings?branchSettings.NickName?branchSettings.NickName:BranchName:BranchName}
                </MovingBranchTypography>
                ):null} 
              </div>
              <div>
                <div style={{ height: "8px" }}></div>
                {/* <DroapDownBtn >
                  <KeyboardArrowDownIcon sx={{ fontSize: "18px" }} />
                </DroapDownBtn> */}
              </div>
            </LeftAliner>
          </Box>
          </Tooltip>
          {/* <QuickMenuBTN variant="outlined" startIcon={<AppsIcon />}>
            Quick Menu
          </QuickMenuBTN> */}
          <QuickMenu
                setQuickMenu={setQuickMenu}
                showQuickMenu={showQuickMenu}
              />
              <>
          {/* <Modal
                open={openSwitchCompany}
                onClose={handleSwitchCompanyModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 445,
                      // bgcolor: "#141414",
                      bgcolor: "white",
                      // border: '2px solid #000',
                      borderRadius: "8px",
                      boxShadow: 24,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: !showLoader ? "none" : "block",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                      }}
                    >
                      <CircularProgress sx={{ color: "black" }} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "black", fontSize: "16px" }}
                      >
                        Switch Company
                      </Typography>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            color: "#0b4772",
                            fontSize: "13px",
                            mr: 2,
                            cursor: "pointer",
                          }}
                          component={Link}
                          to={"/create-company"}
                        >
                          + Add New
                        </Typography>
                        <IconButton onClick={handleSwitchCompanyModalClose}>
                          <img
                            style={{ }}
                            src="../images/plus.svg"
                            alt=""
                          />
                        </IconButton>
                      </div>
                    </Box>

                    <Box
                      sx={{
                        height: "350px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: 3,
                        },
                        "&::-webkit-scrollbar-track": {
                          display: "none",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#FFFFFF",
                          borderRadius: 15,
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#555",
                        },
                      }}
                    >
                      {state.companies.map((company) => (
                        <Box>
                          <MUIButton
                            disabled={showLoader}
                            style={{
                              width: "100%",
                              padding: "6px",
                              display: "flex",
                              marginBottom: "6px",
                              justifyContent: "space-between",
                              textTransform: "none",
                              backgroundColor: "#F3F3F3",
                              border: "0.7px solid #E2E2E2"
                            }}
                            sx={{
                              "&:hover": {
                                bgcolor: "#F3F3F3",
                              },
                            }}
                          >
                            <Box
                              sx={{ display: "flex", alignItems: "center", width: '100%' }}
                              // onClick={() => {
                              //   switchCompany({ ...company });
                              //   setShowLoader(true);
                              // }}
                              onClick={() => {
                                if (company.Branches.length > 0) {
                                  handleExpandClick(company.id);
                                } else {
                                  switchCompany({
                                    id: company.id,
                                    CompanyName: company.CompanyName,
                                    branchId: 1,
                                  });
                                  setShowLoader(true);
                                }
                              }}
                            >
                              <MUIBadge
                                color={
                                  moment(company.ExpiryDate).isAfter(moment())
                                    ? "success"
                                    : "error"
                                }
                                overlap="circular"
                                badgeContent=" "
                                variant="dot" // if you comment this the dot will come big
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <CompanySelectImgContainer>
                                  {company.CompanyLogo ? (
                                    <CompanyImg
                                    component="img"
                                      selected={company.id === CompanyDetails.CompanyID}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = getAvatar(company.id);
                                      }}
                                      src={company.CompanyLogo}
                                    ></CompanyImg>
                                  ) : (
                                    <CompanyImg
                                    component="img"
                                      selected={company.id === CompanyDetails.CompanyID}
                                      src={getAvatar(company.id)}
                                    ></CompanyImg>
                                  )}
                                </CompanySelectImgContainer>
                              </MUIBadge>
                              <Box sx={{ overflow: "hidden", width: "80%" }}>
                                <Typography
                                  // sx={{ ml: 2, mr: 0.5, color: "#FFFFFF" }}
                                  sx={{
                                    ml: 2,
                                    mr: 0.5,
                                    color: "#000",
                                    textAlign: company.CompanyName.length > 23 ? "center" : "left",
                                    width:
                                      company.CompanyName.length > 23
                                        ? "130%"
                                        : "auto",
                                    transform:
                                      company.CompanyName.length > 23
                                        ? "translateX(50%)"
                                        : "none",
                                    animation:
                                      company.CompanyName.length > 23
                                        ? "bouncing-text 10s linear infinite alternate"
                                        : "none",
                                      "@keyframes bouncing-text": {
                                      "0%": {
                                        transform: "translateX(10%)",
                                      },
                                      "100%": {
                                        transform: "translateX(-30%)",
                                      },
                                    },
                                  }}
                                >
                                  {company.CompanyName}
                                </Typography>
                              </Box>
                              {company.id === CompanyDetails.CompanyID && (
                                <img src={Icons.GreenTick} />
                              )}
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <div>
                                <Typography
                                  style={{ fontSize: "10px", color: "#808080" }}
                                >
                                  Expires on
                                </Typography>
                                <Typography
                                  style={{ fontSize: "10px", color: "#000" }}
                                >
                                  {moment(company.ExpiryDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                              </div>
                              <IconButton
                                onClick={(e) => {
                                  // handleSwitchCompanyModalClose();
                                  // history.push(
                                  //   "updateCompanySettings?id=" + company.id
                                  // );
                                  // handleDotMenu(e)
                                  handleClickPoper(e, company.id);
                                }}
                                aria-describedby={company.id}
                                // data-dot-menu-id={company.id}
                              >
                                <img
                                  // onClick={(e) => handleDotMenu(e)}
                                  // data-dot-menu-id={company.id}
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    backgroundColor: "black",
                                    borderRadius: "30px",
                                    cursor: "pointer",
                                  }}
                                  src={Icons.settingsOption}
                                  alt="lnn"
                                />
                              </IconButton>
                            </Box>
                            <Popover
                              id={company.id}
                              open={anchorEl.id === company.id}
                              anchorEl={anchorEl.event}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              sx={{
                                "& .MuiPaper-root": {
                                  width: "80px",
                                  backgroundColor: "#18191C", // Change this to the desired background color
                                  borderRadius: "6px",
                                  boxShadow:
                                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px, rgba(255, 255, 255, 0.1) 0px 10px 15px -3px, rgba(255, 255, 255, 0.05) 0px 4px 6px -2px",
                                  // padding: theme.spacing(2),
                                },
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {company.company_type === "Personal" ? <>
                                <MUIButton
                                  onClick={() => {
                                    handleClose()
                                    handleSwitchCompanyModalClose()
                                    navigate(`/updateCompanySettings?id=${company.id}`)
                                  }}
                                  style={{
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    justifyContent: "flex-start",
                                    "&.MuiButton-root": {
                                      paddingBottom: "2.5px",
                                      paddingTop: "5px",
                                    },
                                  }}
                                >
                                  {t("Edit")}
                                </MUIButton>
                                <MUIButton
                                  onClick={() => {
                                    handleClose()
                                    handleSwitchCompanyModalClose()
                                    setOrganizationAction({
                                      ...organizationAction,
                                      id: company.id,
                                      delete: true,
                                    });
                                  }}
                                  style={{
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    justifyContent: "flex-start",
                                    "&.MuiButton-root": {
                                      paddingBottom: "5px",
                                      paddingTop: "2.5px",
                                    },
                                  }}
                                >
                                  {t("Delete")}
                                </MUIButton>
                                </>

                                : company.company_type === "Member" && 
                                <MUIButton
                                  onClick={() => {
                                    handleClose()
                                    handleSwitchCompanyModalClose()
                                    setOrganizationAction({
                                      ...organizationAction,
                                      id: company.id,
                                      leave: true,
                                    });
                                  }}
                                  style={{
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    justifyContent: "flex-start",
                                    "&.MuiButton-root": {
                                      paddingBottom: "5px",
                                      paddingTop: "2.5px",
                                    },
                                  }}
                                >
                                  {t("Leave")}
                                </MUIButton>}
                              </Box>
                            </Popover>
                          </MUIButton>

                          <Collapse
                            in={expanded === company.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ p: 1 }}>
                              {company.Branches.map((item, i) => (
                                <MUIButton
                                  onClick={() => {
                                    switchCompany({
                                      id: company.id,
                                      CompanyName: company.CompanyName,
                                      branchId: item.BranchID,
                                    });
                                    setShowLoader(true);
                                  }}
                                  key={item.id}
                                  style={{
                                    width: "100%",
                                    textTransform: "none",
                                    borderBottom: "1px solid #00000029",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    py: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: "12px", color: "black" }}
                                  >
                                    {item.NickName
                                      ? item.NickName
                                      : item.BranchName}
                                    {CompanyDetails.BranchID === item.BranchID ? (
                                      <Box
                                        component="img"
                                        src="../images/Group3270.svg"
                                        alt="kii"
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          marginLeft: "4px",
                                        }}
                                      />
                                    ) : null}
                                  </Typography>

                                  <Typography
                                    sx={{ fontSize: "9px", color: "0b4772" }}
                                  >
                                    {item.BranchID === 1
                                      ? "Head Office"
                                      : "Branch"}
                                  </Typography>
                                </MUIButton>
                              ))}
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <Typography
                        sx={{
                          color: "#F83D3D",
                          textAlign: "center",
                          fontSize: "12px",
                          width: "343px",
                        }}
                      >
                        Expired company and all its data will be automatically
                        Removed after{" "}
                        <span style={{ color: "black" }}>90 days.</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
          </Modal> */}
          </>
        </Leftpaper>

        <RightPaper>
          <TranceBTN
            onClick={(e)=>handleChangeLanguage(e)}
            variant="outlined"
            startIcon={
              <TranslateIcon
                sx={{ fontSize: "14px !important", color: "rgb(43, 88, 236)" }}
              />
            }
            value={language === "ar" ? "en" : "ar"}
          >
           {language === "ar" ? "en" : "ar"}
          </TranceBTN>
          <Tooltip title={`${t("Expires in")} ${state.expiry_days} ${t("Days")}`}>
          <PlanBTN style={{backgroundColor: state.is_test ? '#ffc02d' : null}} >
            {t(Edition)}
            </PlanBTN>
          </Tooltip>
          <RemainderCalender variant="outlined">
            <DateTimeText ><b>{currentTime}</b> {getCurrentDate()}</DateTimeText>
          </RemainderCalender>

          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Typography sx={{ mr: 1, fontSize: 12 }} >{username}</Typography>
            {/* {!true ?  */}
            <Suspense fallback={ 
            <Avatar onError={(e) => {
                e.target.onerror = null;
                e.target.src = getAvatar(String(user_id), "mp");
              }}src={getAvatar(String(user_id), "mp")}  onClick={() => {window.location.href = ACCOUNTS_FRONT_URL;}} sx={{width: "30px", height: "30px"}}
            /> }>
            <Avatar onError={(e) => {
                e.target.onerror = null;
                e.target.src = getAvatar(String(user_id), "mp");
              }}src={ACCOUNTS_API_MEDIA_URL+"/media/"+photo}  onClick={() => {window.location.href = ACCOUNTS_FRONT_URL;}} sx={{width: "30px", height: "30px"}}
            /> 
            </Suspense>
            {/* : <Avatar src={getAvatar(String(user_id), "mp")} ></Avatar>} */}
          </Box>
          <DropMenu onClick={() => setDropMenu(!showDropMenu)}>
                  <DropMenuImgContainer>
                  <MoreVertIcon sx={{objectFit:"cover",width:"100%",height:"100%",color:"#000"}}/>
                  </DropMenuImgContainer>
                  <DropMenuSelectContainer showDropMenu={showDropMenu}>
                    {user_type === 1 && IsBranch
                      ? [
                          <DropMenuSelectList
                            onClick={() => setBranchSettings(true)}
                          >
                            {t("Branch Settings")}
                          </DropMenuSelectList>,
                        ]
                      : null}
                    <DropMenuSelectList
                      onClick={() => window.open(`${VIKNBOOKS_FRONT_URL}dashboard/settings`, "_self")}
                    >
                      <SettingsIcon  fontSize="small" /> &nbsp;{t("Settings")}
                    </DropMenuSelectList>
                    { activity_log_view_permission &&
                      <DropMenuSelectList
                        onClick={() => navigate('activity-log')}
                      >
                        <ManageHistoryIcon  fontSize="small" /> &nbsp;{t("Activity Log")}
                      </DropMenuSelectList>
                    }
                    <DropMenuSelectList onClick={() => handleLogout()} style={{color:"red"}}>
                    <LogoutIcon  fontSize="small"  /> &nbsp;{t("Logout")}
                    </DropMenuSelectList>
                  </DropMenuSelectContainer>
                </DropMenu>
          {/* <IconButton>
            <MoreVertIcon />
          </IconButton> */}
        </RightPaper>
      </HeaderBox>
    </Box>
  );
};

export default Header;

const MovingBranchTypography = styled(Typography)(({ move }) => ({
  color: "#0a9ef3",
  whiteSpace: "nowrap",
  fontSize: "11px",
  fontWeight: "bold",
  ...(move && {
    width: "230%",
    MozTransform: "translateX(20%)",
    WebkitTransform: "translateX(20%)",
    transform: "translateX(20%)",
    MozAnimation: "bouncing-text 5s linear infinite alternate",
    WebkitAnimation: "bouncing-text 5s linear infinite alternate",
    animation: "bouncing-text 10s linear infinite alternate",
  }),
}));
const MovingCompanyTypography = styled(Typography)(({ move }) => ({
  whiteSpace: "nowrap",
  fontSize: "13px",
  fontWeight: "bold",
  ...(move && {
    width: "230%",
    MozTransform: "translateX(20%)",
    WebkitTransform: "translateX(20%)",
    transform: "translateX(20%)",
    MozAnimation: "bouncing-text 5s linear infinite alternate",
    WebkitAnimation: "bouncing-text 5s linear infinite alternate",
    animation: "bouncing-text 10s linear infinite alternate",
  }),
  "@-webkit-keyframes bouncing-text": {
    "0%": {
      WebkitTransform: "translateX(10%)",
    },
    "100%": {
      WebkitTransform: "translateX(-30%)",
    },
  },
  "@keyframes bouncing-text": {
    "0%": {
      MozTransform: "translateX(10%)",
      WebkitTransform: "translateX(10%)",
      transform: "translateX(10%)",
    },
    "100%": {
      MozTransform: "translateX(-30%)",
      WebkitTransform: "translateX(-30%)",
      transform: "translateX(-30%)",
    },
  },
}));

const LogoBox = styled(Button)(({ theme }) => ({
  boxShadow: "0px 0px 3px 1px #e7e7e7",
  width: "50px",
  height: "50px",
  minWidth: "50px",
  minHeight: "50px",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  borderRadius: "10px",
  padding: "8px",
  margin: "6px",
  ":hover": {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  }
}));

const HeaderBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

const Leftpaper = styled(Paper)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "0px 0px 25px 25px",
  padding: "5px",
  height: "50px",
  minWidth: "355px",
}));

const LeftAliner = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));
const CompanySelectImgContainer =  styled(Box)(() => ({
  width: "40px",
  height: "40px",
  padding: "4px",
}));

const RightPaper = styled(Paper)(() => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0px 0px 0px 25px",
  padding: "15px",
  paddingRight: "0px",
  height: "50px",
  minWidth: "400px",
}));

const QuickMenuBTN = styled(Button)(() => ({
  fontSize: "12px",
  padding: "4px",
  paddingLeft: "10px",
  paddingRight: "10px",
  textTransform: "none",
  borderRadius: "20px",
  border: "2px solid #e5e5e5",
}));

const DroapDownBtn = styled(IconButton)(() => ({
  border: "2px solid #e5e5e5",
  padding: "0px",
}));

const TranceBTN = styled(Button)(() => ({
  marginRight: "4px",
  fontSize: "13px",
  padding: "1px",
  height: "30px",
  paddingLeft: "15px",
  paddingRight: "15px",
  borderRadius: "5px",
  border: "1px solid #e5e5e5",
  '&:hover' :{
    border: "1px solid #e5e5e5",
  },
  textTransform: "uppercase",
}));

const PlanBTN = styled(Button)(() => ({
  marginRight: "4px",
  fontSize: "13px",
  padding: "4px 15px",
  paddingLeft: "10px",
  paddingRight: "10px",
  textTransform: "none",
  borderRadius: "20px",
  minWidth:"110px",
  border: "1px solid #e5e5e5",
  '&:hover' :{
    border: "1px solid #e9b601",
  },
}));

const RemainderCalender = styled(Paper)(() => ({
  // background: "#212121",
  color: "#fff",
  borderRadius: "20px",
  padding: "5px 25px",
  marginRight: "5px",
}));

const DateTimeText = styled(Typography)(() => ({
  // fontWeight: "bold",
  fontSize: "13px",
  minWidth: "130px",
  display: "inline-block",
}));
const DropMenu = styled(Box)`
  position: relative;
  margin-right:15px;
`;
const DropBranchMenu = styled(Box)`
  position: relative;
`;
const DropMenuImgContainer = styled(Box)`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  // padding: 5px 5px;
  display:grid;

  transition: background ease-in-out 0.3s;
  &:hover {
    background: #e5e5e5;
    transition: background ease-in-out 0.3s;
  }
`;
const DropBranchMenuSelectContainer = styled(Box)`
  position: absolute;
  top: 45px;
  right: 10px;
  bottom: 0;
  background: #fff;
  border-radius: 5px;
  width: max-content;
  height: max-content;
  box-shadow: 0px 0px 5px 0px #dcd9d9;
  z-index: 15;
  transform: ${({ showBranchSettings }) =>
    showBranchSettings ? "translateY(0)" : "translateY(-265%)"};
  opacity: ${({ showBranchSettings }) => (showBranchSettings ? "1" : "0.5")};
  transition: all 500ms cubic-bezier(0.25, 0.25, 0, 1.2);
`;
const DropBranchMenuSelectList = styled(Box)`
  margin: 5px;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #e5e5e5;
  }
`;
const DropMenuSelectContainer = styled(Box)`
  position: absolute;
  top: 45px;
  right: 10px;
  bottom: 0;
  background: #fff;
  border-radius: 5px;
  width: max-content;
  height: max-content;
  box-shadow: 0px 0px 5px 0px #dcd9d9;
  z-index: 115;
  transform: ${({ showDropMenu }) =>
    showDropMenu ? "translateY(0)" : "translateY(-365%)"};
  opacity: ${({ showDropMenu }) => (showDropMenu ? "1" : "0.5")};
  transition: all 500ms cubic-bezier(0.25, 0.25, 0, 1.2);
`;
const DropMenuSelectList = styled(Box)`
  margin: 5px;
  padding: 8px 35px 8px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size:13px;
  &:hover {
    cursor: pointer;
    background: #e5e5e5;
  }
`;
const DropMenuImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
