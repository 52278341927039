import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import TableSection from "./TableSection";
import styled from "@emotion/styled";
import {
  PurchaseReturnTemplateData,
  purchaseTemplateData,
  salesOrderTemplateData,
  SalesReturnTemplateData,
  salesTemplateData,
} from "../ActivityLogSupportFunction";

function DetailTemplate({
  selectedTransaction,
  comparingTransaction,
  color,
  backgroundColor,
  isTemplateOne,
  invoiceType,
}) {
  let masterDetailsOne = [];
  let masterDetailsTwo = [];
  let totalDetailsOne = [];
  let totalDetailsTwo = [];

  const templateMapping = {
    sales: salesTemplateData,
    purchase: purchaseTemplateData,
    sales_order: salesOrderTemplateData,
    purchase_return: PurchaseReturnTemplateData,
    sales_return: SalesReturnTemplateData,
  };

  if (templateMapping[invoiceType]) {
    const selectedTemplate = templateMapping[invoiceType];
    masterDetailsOne = selectedTemplate.masterDetailsOne;
    masterDetailsTwo = selectedTemplate.masterDetailsTwo;
    totalDetailsOne = selectedTemplate.totalDetailsOne;
    totalDetailsTwo = selectedTemplate.totalDetailsTwo;
  }

  const isDifferent = (key) =>
    comparingTransaction &&
    selectedTransaction?.[key] !== comparingTransaction?.[key];

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1.5,
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: isDifferent("CreatedUser")
              ? backgroundColor
              : "inherit",
          }}
        >
          <SubHeadingOne
            sx={{
              backgroundColor: isDifferent("CreatedUser") ? color : "inherit",
            }}
          >
            Modified By :
          </SubHeadingOne>
          <Typography
            sx={{ fontFamily: "sans-serif", fontSize: "14px", fontWeight: 600 }}
          >
            {selectedTransaction?.CreatedUser}
          </Typography>
        </Box>
      </Box>
      <Grid container sx={{ border: "1px solid black", mt: 1 }}>
        {/* Left Column */}
        <Grid item xs={6} sx={{ borderRight: "1px solid black", p: 1 }}>
          {masterDetailsOne.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                pb: "2px",
                backgroundColor: isDifferent(item.key)
                  ? backgroundColor
                  : "inherit",
              }}
            >
              <HeadingOne>{item.label} :</HeadingOne>
              <SubHeadingOne
                sx={{
                  backgroundColor: isDifferent(item.key) ? color : "inherit",
                }}
              >
                {selectedTransaction?.[item.key]}
              </SubHeadingOne>
            </Box>
          ))}
        </Grid>

        {/* Right Column */}
        <Grid item xs={6} sx={{ p: 1 }}>
          {masterDetailsTwo.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                pb: "2px",
                backgroundColor: isDifferent(item.key)
                  ? backgroundColor
                  : "inherit",
              }}
            >
              <HeadingOne>{item.label} :</HeadingOne>
              <SubHeadingOne
                sx={{
                  backgroundColor: isDifferent(item.key) ? color : "inherit",
                }}
              >
                {selectedTransaction?.[item.key]}
              </SubHeadingOne>
            </Box>
          ))}
        </Grid>
      </Grid>

      {/* Table Section */}
      <TableSection
        selectedTransactionDetails={selectedTransaction?.Details}
        comparingTransactionDetails={comparingTransaction?.Details}
        highlightColor={isTemplateOne ? "#FAA5A5" : "#89E3C5"}
      />

      {/* Total Section */}
      <Grid container sx={{ border: "1px solid black", mt: 1 }}>
        {/* Left Side Table */}
        <Grid item xs={6} sx={{ borderRight: "1px solid black" }}>
          <Table>
            <TableBody>
              {totalDetailsOne.map((item, index) => (
                <TableRow key={index}>
                  <CustomTableCell
                    sx={{
                      backgroundColor: isDifferent(item.key)
                        ? backgroundColor
                        : "inherit",
                    }}
                  >
                    {item.label}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{
                      backgroundColor: isDifferent(item.key)
                        ? color
                        : "inherit",
                    }}
                    price
                  >
                    {selectedTransaction?.[item.key]}
                  </CustomTableCell>
                </TableRow>
              ))}
              {invoiceType !== "sales_order" && (
                <>
                  <TableRow>
                    <CustomTableCell heading noBorder>
                      Cash :
                    </CustomTableCell>
                    <CustomTableCell noBorder></CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell
                      sx={{
                        backgroundColor: isDifferent("CashAccount")
                          ? color
                          : "inherit",
                      }}
                    >
                      {selectedTransaction?.CashAccount}
                    </CustomTableCell>
                    <CustomTableCell
                      price
                      sx={{
                        backgroundColor: isDifferent("CashAmount")
                          ? color
                          : "inherit",
                      }}
                    >
                      {selectedTransaction?.CashAmount}
                    </CustomTableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>

        {/* Right Side Table */}
        <Grid item xs={6}>
          <Table>
            <TableBody>
              {totalDetailsTwo.map((item, index) => (
                <TableRow key={index}>
                  <CustomTableCell
                    sx={{
                      backgroundColor: isDifferent(item.key)
                        ? backgroundColor
                        : "inherit",
                    }}
                  >
                    {item.label}
                  </CustomTableCell>
                  <CustomTableCell
                    sx={{
                      backgroundColor: isDifferent(item.key)
                        ? color
                        : "inherit",
                    }}
                    price
                  >
                    {selectedTransaction?.[item.key]}
                  </CustomTableCell>
                </TableRow>
              ))}
              {invoiceType !== "sales_order" && (
                <>
                  <TableRow>
                    <CustomTableCell heading noBorder>
                      Bank :
                    </CustomTableCell>
                    <CustomTableCell noBorder></CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell
                      sx={{
                        backgroundColor: isDifferent("BankAccount")
                          ? color
                          : "inherit",
                      }}
                    >
                      {selectedTransaction?.BankAccount}
                    </CustomTableCell>
                    <CustomTableCell
                      price
                      sx={{
                        backgroundColor: isDifferent("BankAmount")
                          ? color
                          : "inherit",
                      }}
                    >
                      {selectedTransaction?.BankAmount}
                    </CustomTableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Box
          sx={{
            display: "flex",
            p: "4px",
            backgroundColor: isDifferent("Notes") ? backgroundColor : "inherit",
            fontSize: "14px",
            width: "100%",
          }}
        >
          <Typography>Invoice Note :</Typography>
          <Typography
            sx={{
              backgroundColor: isDifferent("Notes") ? color : "inherit",
              fontSize: "14px",
            }}
          >
            {selectedTransaction?.Notes}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
}

export default DetailTemplate;

// Styled Components
const HeadingOne = styled(Typography)(() => ({
  fontFamily: "sans-serif",
  fontSize: "14px",
  fontWeight: 600,
  marginRight: "10px",
}));

const SubHeadingOne = styled(Typography)(() => ({
  fontFamily: "sans-serif",
  fontSize: "14px",
}));

const CustomTableCell = styled(TableCell)(({ noBorder, price, heading }) => ({
  padding: "2px 4px",
  borderBottom: noBorder ? "none" : "1px solid black",
  textAlign: price ? "right" : "left",
  fontWeight: heading ? 500 : 400,
}));
