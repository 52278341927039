import { Button, Paper } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { URL, VIKNBOOKS_FRONT_URL } from "../generalVeriable";
import EinvoiceTemplate from "./EinvoiceTemplate";
import { useReactToPrint } from "react-to-print";
import InvoicePDFLoading from "../Components/Loadings/InvoicePDFLoading";
import CustomeTemplate from "./CustomeTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styled from "@emotion/styled";
import { t } from "i18next";

const InvoiceComponent = forwardRef(
  (
    {
      invoice_id,
      printPage = 1,
      invoice_type = "sales_invoice",
      VoucherNo,
      setPrintLoading,
    },
    ref
  ) => {
    // Separate refs for different components
    const einvoiceTemplateRef = useRef(null);
    const iframeRef = useRef(null);
    const { CompanyID, BranchID, IsTrialVersion } = useSelector(
      (state) => state.companyDetails
    );
    const [invoiceType, setInvoiceType] = useState("ST");
    const [pdfLoading, setPdfLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState("");

    const [PrintInvoiceData, setPrintInvoiceData] = useState({});

    const printURL = `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=1&invoice_type=${invoice_type}&invoice_id=${invoice_id}&PriceRounding=3&BranchID=${BranchID}`;

    const handleAfterPrint = React.useCallback(() => {
    }, []);
    //
    const handleBeforePrint = React.useCallback(() => {
      return Promise.resolve();
    }, []);

    const printMultipleCopies = () => {
      const content = einvoiceTemplateRef.current.innerHTML;
      const newContent = new Array(printPage)
        .fill(content)
        .join("<div style='page-break-before:always'></div>");
      einvoiceTemplateRef.current.innerHTML = newContent;
      printFn();
      einvoiceTemplateRef.current.innerHTML = content;
    };

    const printFn = useReactToPrint({
      contentRef: einvoiceTemplateRef,
      documentTitle: "AwesomeFileName",
      onAfterPrint: handleAfterPrint,
      onBeforePrint: handleBeforePrint,
    });

    const shareExport = function () {
      const getHighestTop = (data) => {
        return data.reduce(
          (max, item) => (item.top > max ? item.top : max),
          data[0].top
        );
      };

      const handleGeneratePdf = async () => {
        const input = einvoiceTemplateRef.current;
        try {
          const highestTop = getHighestTop(PrintInvoiceData.FooterData);
          const canvasHeight = highestTop + 0 + 20;
          input.style.height = `${canvasHeight}px`;
          const canvas = await html2canvas(input);
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const marginTop = 0;
          const marginLeftRight = 0;
          const imgHeight =
            (canvasHeight * imgWidth - marginLeftRight) / canvas.width;
          let heightLeft = imgHeight + marginTop * 2;
          let position = marginTop;
          pdf.addImage(
            imgData,
            "PNG",
            marginLeftRight / 2,
            position,
            imgWidth - marginLeftRight,
            imgHeight
          );
          heightLeft -= pageHeight + marginTop;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(
              imgData,
              "PNG",
              marginLeftRight / 2,
              position,
              imgWidth - marginLeftRight,
              imgHeight
            );
            heightLeft -= pageHeight;
          }
          pdf.save(`SalesInvoice_${VoucherNo}.pdf`);
        } catch (error) {
          console.error("Error generating PDF:", error);
        }
      };

      const GeneratePDF = async () => {
        const input = einvoiceTemplateRef.current;
        // Convert the DOM element to canvas
        const canvas = await html2canvas(input, { scale: 2, useCORS: true });
        // Convert canvas to Base64 data URL
        const imgData = canvas.toDataURL("image/png");
        // Create a new jsPDF instance
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        // Add more pages if the content is longer than one page
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(`SalesInvoice_${VoucherNo}.pdf`);
      };

      if (invoiceType === "CT") {
        handleGeneratePdf();
      } else if (invoiceType === "ET") {
        GeneratePDF();
      } else {
        // downloadPDF();
      }
    };

    // Existing fetchTemplate function remains the same as in your previous code...
    const fetchTemplate = async function () {
      try {
        setPdfLoading(true);
        const response = await fetch(printURL + `&print_template="True"`);
        if (!response.ok) {
          throw new Error("Failed to fetch print content");
        }

        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          let json = await response.json();

          if (json?.IsCustom) {
            setInvoiceType("CT");
            const TextData = json?.master || [];
            const TableHead = json.table_head;
            const TableData = json.details;
            const FooterData = json.footer;

            const textFieldData = TextData.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              IsImage: detail.IsImage,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
            }));
            const TableHeadData = TableHead.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
              border_Top: detail.BorderTop,
              border_Bottom: detail.BorderBottom,
              border_Left: detail.BorderLeft,
              border_Right: detail.BorderRight,
              paddingTop: detail.PaddingTop,
              paddingBottom: detail.PaddingBottom,
              paddingLeft: detail.PaddingLeft,
              paddingRight: detail.PaddingRight,
            }));
            const FooterFieldData = FooterData.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              IsImage: detail.IsImage,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
              border_Top: detail.BorderTop,
              border_Bottom: detail.BorderBottom,
              border_Left: detail.BorderLeft,
              border_Right: detail.BorderRight,
              paddingTop: detail.PaddingTop,
              paddingBottom: detail.PaddingBottom,
              paddingLeft: detail.PaddingLeft,
              paddingRight: detail.PaddingRight,
            }));
            setPrintInvoiceData({
              TextField: textFieldData,
              // ImageField : textFieldData,
              TableHead: TableHeadData,
              TableData: TableData,
              FooterData: FooterFieldData,
              Table: {
                TableWidth: json.Table.TableWidth,
                TableTop: json.Table.TableTop,
                TableLeft: json.Table.TableLeft,
              },
            });
          } else if (json?.data) {
            setInvoiceType("ET");
            setPrintInvoiceData(json);
          }
        } else {
          setInvoiceType("ST");
          const content = await response.text();
          setHtmlContent(content);
        }

        setPdfLoading(false);
      } catch (error) {
        console.error("Error loading print content:", error);
        setPdfLoading(false);
      }
    };

    const handleCustomizeButtonNavigation = () => {
      const url = `${VIKNBOOKS_FRONT_URL}dashboard/print-settings?invoice_type=${invoice_type}`;
      window.location.href = url;
    };

    // const printStandardTemplate = async function () {
    //   try {
    //     setPrintLoading(true);
    //     const response = await fetch(
    //       `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${printPage}&invoice_type=${invoice_type}&invoice_id=${invoice_id}&PriceRounding=3&BranchID=${BranchID}&print_template="True"`
    //     );

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch print content");
    //     }

    //     const content = await response.text();
    //     if (content) {
    //       // Create an invisible iframe
    //       const iframe = document.createElement("iframe");
    //       document.body.appendChild(iframe);

    //       // Write the fetched content into the iframe's document
    //       const iframeDocument =
    //         iframe.contentWindow.document || iframe.contentDocument;

    //       iframeDocument.open();
    //       iframeDocument.write(content);
    //       iframeDocument.close();

    //       // Check if the QR code image has loaded
    //       const qrImage = iframeDocument.querySelector(".qr-code");

    //       if (qrImage) {

    //         qrImage.onload = () => {
    //           iframe.contentWindow.focus();
    //           iframe.contentWindow.print();
    //           setPrintLoading(false);
    //         };

    //         qrImage.onerror = () => {
    //           console.error("Error loading QR code image");
    //           setPrintLoading(false);
    //         };
    //       } else {
    //         iframe.contentWindow.focus();
    //         iframe.contentWindow.print();
    //         setPrintLoading(false);
    //       }
    //       // Optional cleanup after print
    //       iframe.onload = function () {
    //         document.body.removeChild(iframe);
    //       };
    //     }
    //   } catch (error) {
    //     console.error("Error loading print content:", error);
    //     setPrintLoading(false);
    //   }
    // };



    const printStandardTemplate = async function () {
      try {
        setPrintLoading(true);
        const response = await fetch(
          `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${printPage}&invoice_type=${invoice_type}&invoice_id=${invoice_id}&PriceRounding=3&BranchID=${BranchID}&print_template="True"`
        );
    
        if (!response.ok) {
          throw new Error("Failed to fetch print content");
        }
    
        const content = await response.text();
        if (content) {
          const iframe = document.createElement("iframe");
          document.body.appendChild(iframe);
    
          const iframeDocument =
            iframe.contentWindow.document || iframe.contentDocument;
    
          iframeDocument.open();
          iframeDocument.write(content);
          iframeDocument.close();
    
          iframe.onload = () => {
            const qrImage = iframeDocument.querySelector(".qr-code");
    
            if (qrImage) {
              qrImage.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                setPrintLoading(false);
              };
    
              qrImage.onerror = () => {
                console.error("Error loading QR code image");
                setPrintLoading(false);
              };
            } else {
              iframe.contentWindow.focus();
              iframe.contentWindow.print();
              setPrintLoading(false);
            }
          };
    
          iframe.onload = () => {
            setTimeout(() => {
              iframe.contentWindow.focus();
              iframe.contentWindow.print();
              document.body.removeChild(iframe);
              setPrintLoading(false);
            }, 500); // Short delay for rendering
          };
        }
      } catch (error) {
        console.error("Error loading print content:", error);
        setPrintLoading(false);
      }
    };
    

    // Expose print method to parent component
    useImperativeHandle(ref, () => ({
      printTemplate: () => {
        switch (invoiceType) {
          case "ST":
            printStandardTemplate();
            break;
          case "ET":
          case "CT":
            // For E-Invoice and Custom Templates
            printMultipleCopies();
            // printFn();
            break;
          default:
            console.warn("Unknown invoice type for printing");
        }
      },
      pdfExport: () => shareExport(),
    }));

    useEffect(() => {
      fetchTemplate();
    }, [invoice_id, CompanyID, BranchID]);

    useEffect(() => {
      if (htmlContent && iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;

        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();

        setPdfLoading(false);
      }
    }, [htmlContent]);

    return (
      <LoadingContainer isLoading={pdfLoading}>
        <Paper
          sx={{
            position: "relative", // Make Paper the reference for absolute positioning
            "&:hover": {
              // Change the background color and font color of the button on hover of Paper
              ".customize-button": {
                display: "flex",
                backgroundColor: "#ccc",
                color: "black", // Set font color to black on hover
                fontSize: "12px",
              },
            },
          }}
        >
          {invoiceType === "ST" && (
            <Paper
              elevation={10}
              sx={{
                width: "800px",
                height: "1123px",
                minHeight: "1123px",
                borderRadius: "15px",
                filter: IsTrialVersion ? "blur(3px)" : "none",
                position: "relative", // Make Paper the reference for absolute positioning
                "&:hover": {
                  // Change the background color and font color of the button on hover of Paper
                  ".customize-button": {
                    display: "flex",
                    backgroundColor: "#ccc",
                    color: "black", // Set font color to black on hover
                    fontSize: "12px",
                  },
                },
              }}
            >
              <iframe
                ref={iframeRef}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  borderRadius: "15px",
                }}
                title="Invoice-Preview"
              />
            </Paper>
          )}

          {invoiceType === "ET" && (
            <Paper sx={{ filter: IsTrialVersion ? "blur(3px)" : "none" }}>
              <EinvoiceTemplate
                ref={einvoiceTemplateRef}
                data={PrintInvoiceData}
              />
            </Paper>
          )}

          {invoiceType === "CT" && (
            <Paper sx={{ filter: IsTrialVersion ? "blur(3px)" : "none" }}>
              <CustomeTemplate
                ref={einvoiceTemplateRef}
                data={PrintInvoiceData}
              />
            </Paper>
          )}
          <Button
            className="customize-button" // Add a class for targeting the button
            sx={{
              display: "none",
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "transparent", // Default background color
              borderBottomLeftRadius: "16px",
              color: "initial", // Set default font color (can be adjusted as needed)
              transition: "background-color 0.3s, color 0.3s", // Smooth transition
            }}
            onClick={handleCustomizeButtonNavigation}
          >
            Customize
          </Button>
          {IsTrialVersion && (
            <UpgradeButton elevation={10}>
              <img
                src="../images/icons/crown.svg"
                alt=""
                style={{
                  height: "30px",
                  padding: "8px 10px 1px 10px",
                  filter: "brightness(6.5)",
                }}
              />
              <Background>{t("Upgrade your Subscription")}</Background>
            </UpgradeButton>
          )}
        </Paper>
      </LoadingContainer>
    );
  }
);

const LoadingContainer = function ({ children, isLoading }) {
  return <>{isLoading ? <InvoicePDFLoading /> : children}</>;
};

export default InvoiceComponent;

const UpgradeButton = styled(Paper)`
  display: flex;
  position: absolute;
  top: 78px;
  right: -72px;
  padding: 0px 50px;
  border: 0.5px solid #ded8b9; /* Light gold border */
  background: #1f1f1f; /* Dark background for the button */
  border-radius: 5px;
  cursor: pointer;
  transform: rotate(45deg);
  z-index: 2; /* Bring UpgradeButton in front */
  clip-path: polygon(11% 0, 89% 0, 102% 122%, -2% 122%);
  background: linear-gradient(
    45deg,
    #000000,
    #ad9515,
    #1f1f1f
  ); /* Gold gradient */
  background-size: 300% 300%;
  animation: runningLight 10s linear infinite; /* Running light effect */
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;

  /* Adjusting pseudo-elements to appear behind UpgradeButton */
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 17px; /* Creates a right triangle */
    border-color: transparent #1f1f1f transparent transparent;
    z-index: 1; /* Place it behind UpgradeButton */
    transform: rotate(45deg);
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 0; /* Creates a right triangle */
    border-color: transparent #1f1f1f transparent transparent;
    z-index: 1; /* Place it behind UpgradeButton */
    transform: rotate(45deg);
  }

  /* Animation for the running light effect */
  @keyframes runningLight {
    0% {
      background-position: 300% 0;
    }
    50% {
      background-position: -300% 0;
    }
    100% {
      background-position: 300% 0;
    }
  }
`;

const Background = styled.div`
  /* Gradient text using background-clip */
  background: linear-gradient(90deg, #f2f2f2 4.29%, #f2f2f2 97.5%);
  color: transparent; /* Make the text transparent so the gradient shows */
  background-clip: text; /* Apply the gradient to the text only */
  -webkit-background-clip: text; /* For Safari and Chrome */
  font-size: 15px;
  padding: 10px 0px;
`;
