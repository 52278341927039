import "./sales_style.css";

import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import {
  ClearButton,
  ListButton,
  SaveButton,
  LoadingButton,
} from "../../Components/Utils/StyledButtons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BasicDatePicker,
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
  SelectShippingAddress,
} from "../../Components/CommonComponents";
import MyGrid from "./Details";
import moment from "moment";
import NotesTextArea from "../../Components/Utils/TextArea";
import { sales_details_api, create_sales_api,view_sales_api,get_sales_details_api, edit_sales_api, create_party, sales_order_for_sales_invoice_api, import_sales_order_details_api, sales_order_filter_api, sales_estimate_for_sales_invoice_api, import_sales_estimate_details_api, sales_estimate_filter_api } from "../../Api/Sales/SalesApis";
import { openSnackbar } from "../../features/SnackBarslice";
import { CustomiseTableApi, get_formset_values_api, get_pricelist_list_new_api, get_product_by_barcode_api, get_product_history_api, get_single_pricelist_new_api, get_stock_of_products_api, get_VoucherNo, ledger_details_api, ledger_list_api, place_of_supply_api, product_search_new_api } from "../../Api/CommonApi/CommonApis";
import { SalesInvoiceCalc, SalesLineCalc,Sales_Validation } from "./functions";
import SummaryDetails from "./SummaryDetails";
import { AddressModal, HistoryModal } from "../../Components/CommonForms";
import { create_user_address, list_user_address } from "../../Api/User/User";
import LoaderLite from "../../Components/Utils/LoaderLite";
import { CallEmployees, CallLedgerListById } from "../../Api/Reports/CommonReportApi";
import {debounce} from "../../Function/comonFunction" 
// import { get_e_invoice_submit_api } from "../../Api/EInvoice/EInvoiceApis";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";
import VBInputField from "../../Components/Utils/VBInputField";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ViknAutoComplete from "../../Components/Utils/ViknAutoComplete";
import Serialnumbercomponent from "./SerialNumberComponent";
import AddIcon from '@mui/icons-material/Add';
import { func } from "prop-types";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImportAndExport from "../../Components/ImportAndExportModal/ImportAndExport";

const SalesInvoice = () => {
  const [t] = useTranslation("common");
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  // Determine is_edit and uniq_id from URL params or location state
  const is_edit = location.state?.is_edit ?? params.get('is_edit');
  const is_clone = location.state?.is_clone ?? params.get('is_clone');
  const uniq_id = location.state?.uniq_id ?? params.get('unq_id');
  const order_no = location.state?.orderNo
  
  // const [apiResult,setApiResult] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [IsButtonClick, setIsButtonClick] = useState(false);
  
  const [IsShipping, setIsShipping] = useState(false);
  const [IsBilling, setIsBilling] = useState(false);
  
  const [IsSalesHistory, setIsSalesHistory] = useState(false);
  const [IsPurchaseHistory, setIsPurchaseHistory] = useState(false);
  const [IsConfirmBox, setConfirmBox] = useState(false);
  
  const [is_manual_roundoff,setIs_manual_roundoff] = useState(false)

  const [progress, setProgress] = useState(0);

  const [IsWarningBox, setIsWarningBox] = useState(false);
  const [warningMessage, setWarningMessage] = useState('something went wrong')
  
  const [IsErrorBox, setIsErrorBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState('something went wrong')

  const [isBackendValidation, setIsBackendValidation] = useState(false)

  const [isForceSave, setIsForceSave] = useState(false)

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { 
    PriceDecimalPoint,
     EnableBranch,
     EnableProductBatchWise,
     GST,
     VAT,
     CurrencySymbol,
     EnableBillwise,
     EnableWarehouse,
     EnableSalesManInSales,
     PriceCategory,
     EnableDeliveryDateInSales,
     EnableShippingCharge,
     RoundOffSales,
     PurchasePriceInSales,
     show_brand_InsearchSale,
     show_stock_InsearchSale,
     show_purchasePrice_InsearchSale,
     show_productcode_InsearchSale,
     EnableEinvoiceKSA,
     AvoidLedgerwiseFilterInSalesHistory,
     SalesPriceLessThanMinimumSalesPrice,
     ShowCostWithExpenseAsPurchasePrice,
     CurrencyFormat,
     validationSerialNumber,
     EstimateConvertion
     } = useSelector((state) => state.generalSettingsSclice.generalSettings);
     
     //USER Role
     let user_role_settings = useSelector((state) => state.userRollSettings);
     let sales_permission = user_role_settings.filter((i) => i.name === "Sales Invoice");
     let sales_discount_perm = sales_permission[0].discount;
     let sales_discount_limit = sales_permission[0].discount_limit? sales_permission[0].discount_limit: 100;
     let sales_save = sales_permission[0].save_permission;
     let sales_purchase_price_perm = sales_permission[0].purchase_price;
     
     let sales_history_permission = user_role_settings.filter((i) => i.name === "Sales History");
     let purchase_history_permission = user_role_settings.filter((i) => i.name === "Purchase History");

     let sales_history_view_permission = sales_history_permission[0].view_permission
     let purchase_history_view_permission = purchase_history_permission[0].view_permission
     
     
  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain:"books"
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];

     
     let GeneralSettingsData = {
       PriceDecimalPoint,
       EnableBranch,
       EnableProductBatchWise,
       GST,
       VAT,
       CurrencySymbol,
       EnableBillwise,
       EnableWarehouse,
       EnableSalesManInSales,
       PriceCategory,
       EnableDeliveryDateInSales,
       EnableShippingCharge,
       
       PurchasePriceInSales,
       show_brand_InsearchSale,
       show_stock_InsearchSale,
       show_purchasePrice_InsearchSale,
       show_productcode_InsearchSale,
       //userorrll
       sales_discount_limit,
       sales_discount_perm,
       sales_save,
       sales_purchase_price_perm,
       EnableEinvoiceKSA,
       SalesPriceLessThanMinimumSalesPrice,
       ShowCostWithExpenseAsPurchasePrice,
       EstimateConvertion,
       CurrencyFormat,
     }

  const { user_id } = useSelector((state) => state.user);
  const { CountryName} = useSelector((state) => state.companyDetails)
  const {Country,State} = useSelector((state) => state.branchSettings)
  
  const CompanyState = State
  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular",is_gst:true },
    { value: "1", name: "Registered Business - Composition",is_gst:true },
    { value: "2", name: "Consumer",is_gst:true,default:true },
    { value: "4", name: "Overseas",is_gst:true },
    { value: "5", name: "Special Economic Zone",is_gst:true },
    { value: "6", name: "Deemed Export",is_gst:true },
    { value: "0", name: "Business to Business(B2B)",is_vat:true },
    { value: "1", name: "Business to Customer(B2C)",is_vat:true,default:true},
    ]
    
    const GST_TreatmentData = TreatmentData.filter((i)=> i.is_gst === true)
    const VAT_TreatmentData = TreatmentData.filter((i)=> i.is_vat === true)

  const [dataState,setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData : [],
    CashList : [],
    BankList : [],
    CountryData : [],
    EmployeeData : [],
    PriceCategoryData : [],
    TaxCategoryData : [],
    UserTableData : [],
    WarehouseData : [],
    PlaceOfSupplyData:[],
    ProductList: [],
    ProductCodeList: [],
    SalesHistory:[],
    PurchaseHistory:[]
  })
  
  const [defaultValue,setDefaultValue] = useState({
      AccountLedger:null,
      CashAccount:null,
      BankAccount:null,
      PriceCategory:null,
      Warehouse:null,
      Treatment:null,
      PlaceOfSupply:null,
      ShippingTax:null
  })

  
  //when you adding any field here add in clearData function tooo if needed
  const [state, setState] = useState({
    AccountLedger:null,
    CashAccount:null,
    BankAccount:null,
    Employee :null,
    PriceCategory:null,
    Warehouse:null,
    Treatment:null,
    PlaceOfSupply:null,
    Date:DefaultDate,
    RefNo:"",    
    ShippingCharge:0,
    ShippingTax:null,
    shipping_tax_amount:0,
    BillDiscPercent:0,
    BillDiscAmt:0,
    CashAmount:0,
    BankAmount:0,
    Notes:"",
    ShippingAddress:null,
    BillingAddress:null,
    RoundOff:"0",
    DueDate:DefaultDate,
    DeliveryDate:DefaultDate,
    //Total
    TotalQty:0,
    TotalGrossAmt:0,
    TotalDiscount:0,
    TotalTax:0,
    NetTotal:0,
    TotalTaxableAmount:0,
    TaxTaxableAmount:0,
    NonTaxTaxableAmount:0,
    GrandTotal:0,
    TotalSGST:0,
    TotalCGST:0,
    TotalIGST:0,
    TaxType:VAT ? "VAT" : "GST Intra-state B2C",
    TaxID:VAT ? 32 : 22,
    BillDiscTaxAmt : 0,
    BillDiscTaxPerc : 0,
    BillDiscTaxID : 0,
    Status:'N'
  });
  
  const[ShippingAddress,setShippingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    ShippingAddressList:[],
  }) 
  
  
  const[BillingAddress,setBillingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    BillingAddressList:[],
  }) 
  
  
  const [Errors,setErrors] = useState( {
    field: null,
    message: null,
    error: false,
    indexList: [],
  })
  
  
  //Declare Fields those want to call the calculation (Total amount calculations) 
  const MasterCalcFields = ["BillDiscPercent","BillDiscAmt","CashAmount","BankAmount","ShippingTax","shipping_tax_amount","ShippingCharge","RoundOff"]
  
  //Mandatory Data for details change
  const MandatoryMasterFields = ["AccountLedger","PriceCategory","Warehouse","Treatment"]
  const missingMasterMandatory = MandatoryMasterFields.find((field) => !state[field]);
  
  
  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax:null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP:"",
    PurchasePrice:"",
    MinimumSalesPrice:"",
    AverageCost:"",
    TaxableAmount:0,
    NonTaxableAmount:0,
    
    is_inclusive:false,
    SalesPrice:0,
    SalesPrice1:0,
    SalesPrice2:0,
    SalesPrice3:0,
    
    BatchCode:null,
    IGSTAmount : 0,
    IGSTPerc : 0,
    CGSTAmount : 0,
    CGSTPerc : 0,
    SGSTAmount : 0,
    SGSTPerc : 0,
    CostWithExpense:0,
    SerialNo:"",
    ItemCode:"",
    To:"",
    SerialNos:[],
    IsSerialNo:true
  }
  const [DataList, setDataLists] = useState([DataListItem]);
//  const [DataList, setDataLists] = useState([
//     {
//       Amount: 138,
//       AverageCost: "",
//       AvgCost: 4.34782608,
//       BarCode: "0",
//       BatchCode: null,
//       CGSTAmount: 0,
//       CGSTPerc: 0,
//       CostWithExpense: 100,
//       Description: "تفاح",
//       DiscountAmount: 0,
//       DiscountPerc: 0,
//       FQty: 0,
//       GrossAmount: 120,
//       IGSTAmount: 0,
//       IGSTPerc: 0,
//       InclusivePrice: 138,
//       MRP: 120,
//       MinimumSalesPrice: 0,
//       NonTaxableAmount: 0,
//       Product: {
//         Barcode: "0",
//         BatchCode: "",
//         BrandName: "Grape",
//         Description: "تفاح",
//         HSNCode: null,
//         ProductCode: "PC1004",
//         ProductID: 4,
//         ProductName: "Grape",
//         ProductTaxID: 3,
//         PurchasePrice: 86.95652173913044,
//         SalesPrice: 120,
//         Stock: -4,
//         StockMaximum: 0,
//         StockMinimum: 0,
//         id: "66d2d887-1670-46a2-91bf-d2ebdd896aa6",
//       },
//       PurchasePrice: 100,
//       Qty: 1,
//       Rate: 120,
//       SGSTAmount: 0,
//       SGSTPerc: 0,
//       SalesPrice: 120,
//       SalesPrice1: 120,
//       SalesPrice2: 120,
//       SalesPrice3: 120,
//       Stock: -4,
//       Tax: {
//         Inclusive: false,
//         IsDefault: true,
//         PurchaseTax: "15.00000000",
//         SalesTax: "15.00000000",
//         TaxID: 3,
//         TaxName: "vat 15",
//         TaxType: "1",
//         id: "d13824f1-4340-4ad3-9b68-df6fcd3106ad",
//       },
//       TaxAmount: 18,
//       TaxableAmount: 120,
//       Unit: {
//         BatchList: [],
//         DefaultUnit: true,
//         MultiFactor: 1,
//         PriceListID: 4,
//         UnitInSales: true,
//         UnitName: "KG",
//         id: "85f0dfce-f486-409a-aacb-e97543608cd9",
//       },
//       UnitList: [
//         {
//           id: "85f0dfce-f486-409a-aacb-e97543608cd9",
//           UnitName: "KG",
//           PriceListID: 4,
//           DefaultUnit: true,
//           MultiFactor: 1,
//           // Other unit-specific properties...
//         },
//       ],
//       is_inclusive: true,
//       SerialNo:"",
//       ItemCode:"",
//       To:"",
//       SerialNos:[]
//     },
//     {
//       Amount: 138,
//       AverageCost: "",
//       AvgCost: 4.34782608,
//       BarCode: "0",
//       BatchCode: null,
//       CGSTAmount: 0,
//       CGSTPerc: 0,
//       CostWithExpense: 100,
//       Description: "تفاح",
//       DiscountAmount: 0,
//       DiscountPerc: 0,
//       FQty: 0,
//       GrossAmount: 120,
//       IGSTAmount: 0,
//       IGSTPerc: 0,
//       InclusivePrice: 138,
//       MRP: 120,
//       MinimumSalesPrice: 0,
//       NonTaxableAmount: 0,
//       Product: {
//         Barcode: "0",
//         BatchCode: "",
//         BrandName: "APPLE",
//         Description: "تفاح",
//         HSNCode: null,
//         ProductCode: "PC1004",
//         ProductID: 4,
//         ProductName: "APPLE",
//         ProductTaxID: 3,
//         PurchasePrice: 86.95652173913044,
//         SalesPrice: 120,
//         Stock: -4,
//         StockMaximum: 0,
//         StockMinimum: 0,
//         id: "66d2d887-1670-46a2-91bf-d2ebdd896aa6",
//       },
//       PurchasePrice: 100,
//       Qty: 1,
//       Rate: 120,
//       SGSTAmount: 0,
//       SGSTPerc: 0,
//       SalesPrice: 120,
//       SalesPrice1: 120,
//       SalesPrice2: 120,
//       SalesPrice3: 120,
//       Stock: -4,
//       Tax: {
//         Inclusive: false,
//         IsDefault: true,
//         PurchaseTax: "15.00000000",
//         SalesTax: "15.00000000",
//         TaxID: 3,
//         TaxName: "vat 15",
//         TaxType: "1",
//         id: "d13824f1-4340-4ad3-9b68-df6fcd3106ad",
//       },
//       TaxAmount: 18,
//       TaxableAmount: 120,
//       Unit: {
//         BatchList: [],
//         DefaultUnit: true,
//         MultiFactor: 1,
//         PriceListID: 4,
//         UnitInSales: true,
//         UnitName: "KG",
//         id: "85f0dfce-f486-409a-aacb-e97543608cd9",
//       },
//       UnitList: [
//         {
//           id: "85f0dfce-f486-409a-aacb-e97543608cd9",
//           UnitName: "KG",
//           PriceListID: 4,
//           DefaultUnit: true,
//           MultiFactor: 1,
//           // Other unit-specific properties...
//         },
//       ],
//       is_inclusive: true,
//       SerialNo:"",
//       ItemCode:"",
//       To:"",
//       SerialNos:[]
//         },
//         // You can add other entries in the array here as needed.
//       ]);
      
  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Description: false,
    Unit: true,
    Stock: false,
    Qty: true,
    FQty: false,
    Rate: true,
    AvgCost: false,
    Tax: true,
    InclusivePrice: false,
    GrossAmount: true,
    DiscountPerc: false,
    DiscountAmount: true,
    // ExciseTax: true,
    TaxAmount:true,
    Amount: true,
    Activity: true,
    
    BatchCode:true
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Description: true,
    Unit: true,
    Stock: false,
    Qty: true,
    FQty: true,
    Rate: true,
    AvgCost: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    // ExciseTax: true,
    TaxAmount:true,
    Amount: true,
    Activity: true,
    
    BatchCode:true
  });
  
  const [customerModal, setCustomerModal] = useState(false)
  
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    displayName: "",
    phone: "",
    email: ""
  })

  const [loadAccountLedger, setLoadAccountLedger] = useState({
    pageNo:1,
    search:''
  })

  const [warningBox, setWarningBox] = useState(false)

  function customRound(x) {
    
    if (EnableEinvoiceKSA){    
      const rounded = Math.round(x * 100) / 100;

      if (rounded - x === 0.5) {
        return rounded;
      }

      return rounded;
    }
    else{
      return Number(x)
    }
  }

  
  const [openCustomVoucher, setopenCustomVoucher] = useState(false)
  
  const is_customer = state.AccountLedger?.AccountGroupUnder !== 9 && state.AccountLedger?.AccountGroupUnder !== 8;
  
  //=============== INITIAL APIS AND DATA =======================
  
  const fetchData = async () => {
    setProgress(100);
    let ResponseView = null
    
    let AccountLedgerData = []
    let CashList = []
    let BankList = []
    let CountryData = []
    let EmployeeData = []
    let PriceCategoryData = []
    let TaxCategoryData = []
    let UserTableData = []
    let WarehouseData = []
    let Seperator = ""
    let Suffix = ""
    let SuffixSeparator = ""
    let PreFix = ""
    let InvoiceNo = ""
    let VoucherNo =  ""
    let PlaceOfSupplyData = []
    let Date = DefaultDate
    let DeliveryDate = DefaultDate
    let BankAmount = 0
    let CashAmount = 0
    
    let formSetData = await Call_formset_api({
        CompanyID:CompanyID,
        BranchID: BranchID,
        UserID: user_id,
        VoucherType: "SI",
        SettingsName: "",
        SettingsValue: "",
        Type: "List"
    
    })

    
    let is_name = formSetData?.find((field) => field.SettingsName === "is_name")?.SettingsValue ?? true;
    let is_amount = formSetData?.find((field) => field.SettingsName === "is_amount")?.SettingsValue ?? true;
    let is_gross_amount = formSetData?.find((field) => field.SettingsName === "is_gross_amount")?.SettingsValue ?? true;
    let is_rate = formSetData?.find((field) => field.SettingsName === "is_rate")?.SettingsValue ?? true;
    // let is_delete = formSetData?.find((field) => field.SettingsName === "is_delete")?.SettingsValue ?? true;
    let is_unit = formSetData?.find((field) => field.SettingsName === "is_unit")?.SettingsValue ?? true;
    let is_average_cost = formSetData?.find((field) => field.SettingsName === "is_average_cost")?.SettingsValue ?? false;
    let is_discount_amount = formSetData?.find((field) => field.SettingsName === "is_discount_amount")?.SettingsValue ?? true;
    let is_tax = formSetData?.find((field) => field.SettingsName === "is_tax")?.SettingsValue ?? true;
    let is_discount_percentage = formSetData?.find((field) => field.SettingsName === "is_discount_percentage")?.SettingsValue ?? false;
    let is_stock = formSetData?.find((field) => field.SettingsName === "is_stock")?.SettingsValue ?? false;
    let is_purchase_price = formSetData?.find((field) => field.SettingsName === "PurchasePrice")?.SettingsValue ?? false;
    let is_qty = formSetData?.find((field) => field.SettingsName === "is_qty")?.SettingsValue ?? true;
    let is_product_code = formSetData?.find((field) => field.SettingsName === "is_product_code")?.SettingsValue ?? true;
    let is_free_qty = EnableEinvoiceKSA?false:formSetData?.find((field) => field.SettingsName === "is_free_qty")?.SettingsValue ?? false;
    let is_inclusive_tax = formSetData?.find((field) => field.SettingsName === "is_inclusive_tax")?.SettingsValue ?? false;
    let is_description = formSetData?.find((field) => field.SettingsName === "is_description")?.SettingsValue ?? false;
    let is_barcode = formSetData?.find((field) => field.SettingsName === "is_barcode")?.SettingsValue ?? false;
    let is_cost_with_expense = formSetData?.find((field) => field.SettingsName === "CostWithExpense")?.SettingsValue ?? false;
    
    
    setTableSettings({
      ...tableSettings,
      index: true,
      ProductCode: is_product_code,
      BarCode: is_barcode,
      Product: is_name,
      Description: is_description,
      Unit: is_unit,
      Stock: is_stock,
      Qty: is_qty,
      FQty: is_free_qty,
      Rate: is_rate,
      AvgCost: is_average_cost,
      Tax: is_tax,
      InclusivePrice: is_inclusive_tax,
      GrossAmount: is_gross_amount,
      DiscountPerc: is_discount_percentage,
      DiscountAmount: is_discount_amount,
      TaxAmount: is_tax,
      Amount: is_amount,
      Activity: true,
    });
    
    if (PurchasePriceInSales && ShowCostWithExpenseAsPurchasePrice){
      setTableSettings((prevState)=>({
        ...prevState,
        CostWithExpense:is_cost_with_expense
      }))
      setFocusSettings((prevState)=>({
        ...prevState,
        CostWithExpense:true
      }))
    }
    else if(PurchasePriceInSales){
      setTableSettings((prevState)=>({
        ...prevState,
        PurchasePrice:is_purchase_price
      }))
      setFocusSettings((prevState)=>({
        ...prevState,
        PurchasePrice:true
      }))
    }

    const Data = await sales_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    })
    
    if (Data.StatusCode === 6000){
      
      const PlaceOfSupplyResponse = await place_of_supply_api(Country)
      if (PlaceOfSupplyResponse.StatusCode === 6000){
        PlaceOfSupplyData = PlaceOfSupplyResponse.data
      }
      
      
      AccountLedgerData = Data.AccountLedgerData
      let CashAndBankData = Data.CashAndBankData
      if (CashAndBankData){
        CashList = CashAndBankData.filter((i)=> i.AccountGroupUnder === 9 )
        BankList = CashAndBankData.filter((i)=> i.AccountGroupUnder === 8 )
      }
      
      CountryData = Data.CountryData
      EmployeeData = Data.EmployeeData
      PriceCategoryData = Data.PriceCategoryData 
      TaxCategoryData = Data.TaxCategoryData
      UserTableData = Data.UserTableData
      WarehouseData = Data.WarehouseData
      
      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData
      Seperator = VoucherNoGenerateTableData.Seperater
      Suffix = VoucherNoGenerateTableData.Suffix
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator
      PreFix = VoucherNoGenerateTableData.PreFix
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo
      VoucherNo =  VoucherNoGenerateTableData.VoucherNo
      
      //Default Values
      let AccountLedgerDefault = AccountLedgerData.find((i)=> i.is_default === true)
      let Warehouse = WarehouseData.find((i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      let UserPriceCategory  = UserTableData.PriceCategoryID
      let Treatment = Data.VAT ? VAT_TreatmentData.find((i) => i.default === true) : Data.GST ? GST_TreatmentData.find((i) => i.default === true) :null
      let PlaceOfSupply = PlaceOfSupplyData.find((i)=> i.id === State)   
      let PriceCategory = PriceCategoryData.find((i)=> i.PriceCategoryID === UserPriceCategory)
      let CashAccount = CashList[0]
      let BankAccount = BankList[0]
      let ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")
      
      // AccountLedger = AccountLedgerData.find((i)=> i.is_default === true)
      // Warehouse = WarehouseData.find((i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      // Treatment = VAT ? VAT_TreatmentData.find((i) => i.default === true) : GST ? GST_TreatmentData.find((i) => i.default === true) :null
      // PlaceOfSupply = PlaceOfSupplyData.find((i)=> i.id === State)   
      // PriceCategory = PriceCategoryData.find((i)=> i.PriceCategoryID = 1)
      // CashAccount = CashList[0]
      // BankAccount = BankList[0]
      // ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")

      let customise_tale = await CustomiseTableApi({
        CompanyID : CompanyID,
        BranchID : BranchID,
        UserID : user_id,
        VoucherType : "SI",
        SettingsName : '',
        SettingsValue : '',
        Type : 'List'
      })
      const res = customise_tale.datas.reduce((acc, curr) => {
        acc[curr.SettingsName] = curr.SettingsValue;
        return acc;
      }, {});
      
      setTableSettings((prev) => ({
        ...prev,
        
        Description : res.Description,
        BarCode : res.is_barcode
      }))

      setDefaultValue((prev)=>{
        return({...prev,
          AccountLedger:AccountLedgerDefault,
          CashAccount:CashAccount,
          BankAccount:BankAccount,
          PriceCategory:PriceCategory,
          Warehouse:Warehouse,
          Treatment:Treatment,
          PlaceOfSupply:PlaceOfSupply,
          ShippingTax:ShippingTax,
        })
      })
      

      //calling single view api
      if (is_edit && uniq_id || is_clone && uniq_id){
        ResponseView = await Call_Sales_Master_View({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id})
        if (ResponseView){
          let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(ResponseView.AccountLedger)
          AccountLedgerDefault=AccountLedger          
          Warehouse = ResponseView.Warehouse
          Treatment = ResponseView.Treatment
          PlaceOfSupply = ResponseView.PlaceOfSupply   
          PriceCategory = ResponseView.PriceCategory
          CashAccount = ResponseView.CashAccount
          BankAccount = ResponseView.BankAccount
          ShippingTax = ResponseView.ShippingTax
          if(is_edit && uniq_id){
            VoucherNo = ResponseView.VoucherNo
            DeliveryDate = ResponseView.DeliveryDate
            Date = ResponseView.Date
            CashAmount = ResponseView.CashAmount
            BankAmount = ResponseView.BankAmount
          } 
          
          
          setIs_manual_roundoff(ResponseView.is_manual_roundoff)
          
          // Calling details in mutiple for reduce 
          let TotalQty = Number(ResponseView.TotalQty)
          // setProgress(TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);

          let data = []
          for (let i=1;i<=noOfPages;i++){
            let DetailsReponse = await Call_sales_details({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id,page:i})
            if (DetailsReponse){
              data = [...data,...DetailsReponse]
            }
          }
          setProgress(0);
          setDataLists([...data])
          
        }
      }
      
      if(order_no){
        let payload = {
          CompanyID:CompanyID,
          OrderNo:order_no,
          BranchID:BranchID,
          CreatedUserID:user_id,
          PriceRounding:Number(PriceDecimalPoint || 2),
        }
    
        const SalesOrderFetchAPiResponse = await sales_order_for_sales_invoice_api(payload)
        
        if (SalesOrderFetchAPiResponse.StatusCode === 6000){
          let UserTableData = []
          let WarehouseData = []

          ResponseView = SalesOrderFetchAPiResponse.data
          
          let {AccountLedger} = await Call_LedgerDetials(ResponseView.AccountLedger)
          AccountLedgerDefault = AccountLedger   
          Treatment = ResponseView.Treatment
          PlaceOfSupply = ResponseView.PlaceOfSupply   
          PriceCategory = ResponseView.PriceCategory
          let TotalQty = Number(ResponseView.TotalQty)
          let noOfPages = Math.ceil(TotalQty / 100);
          let Warehouse = null
          let uniq_id = ResponseView.id
    
          const SalesDetailsAPiResponse = await sales_details_api({
            CompanyID: CompanyID,
            BranchID: BranchID,
            CreatedUserID: user_id,
          })
    
          if (SalesDetailsAPiResponse.StatusCode === 6000){
            UserTableData = SalesDetailsAPiResponse.UserTableData
            WarehouseData = SalesDetailsAPiResponse.WarehouseData
            
            Warehouse = WarehouseData.find(
              (i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
          }
          
          let data = []
          for (let i=1; i<=noOfPages; i++){
            let importSalesOrderDetailsAPiResponse = await import_sales_order_details_api({
              CompanyID: CompanyID,
              BranchID: BranchID,
              "uniq_id":uniq_id,
              page:i,
              WarehouseID:Warehouse.WarehouseID
            }
          )
            if (importSalesOrderDetailsAPiResponse){
              data = [...data, ...importSalesOrderDetailsAPiResponse.data]
            }
          }
            
            setDataLists([...data])
        }
      }

      setState((prev)=>{
        return({...prev,
          ...ResponseView,
          AccountLedger:AccountLedgerDefault,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          PriceCategory,
          CashAccount,
          BankAccount,
          ShippingTax,
          Date,
          DeliveryDate,
          CashAmount,
          BankAmount
        })      
      })
      
      setDataState((prev)=>{
        return({...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
        })
      })
      
      
      setShippingAddress({...ShippingAddress,CountryData,StateData:PlaceOfSupplyData})
      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})
      setProgress(0);
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  }
  // ==========================Import form serial number=========================
  const [openSerial, setOpenSerial] = React.useState(false);
  const handleOpenSerial = () => setOpenSerial(true);
  const handleCloseSerial = () => setOpenSerial(false);
  // ==========================Import form sales order=========================
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [exportModalOpen, setExportModalOpen] = React.useState(false);
  const [importHeading, setimportHeading] = useState('')

  const handleExportModal = (state,type) => {
    setExportModalOpen(state)
    setAnchorEl(null)

    if (state === false){
      handleModalDataClear()
    }
    if (type === 'import_estimate'){
      setimportHeading("Import From salesEstimate")
      setImportStates({
        invoiceType: 'salesEstimate',
      });
      
    } else {
      setimportHeading("Import From sales Order")
      setImportStates({
        invoiceType: 'salesOrder',
      });
      
    }
  }

  const handleModalDataClear = () =>{
    setImportStates((prevState)=>({
      ...prevState,
      orderNo: '',
      customer: null,
      fromDate: null,
      toDate: null,
      isSelectedAll: false
    }))

    setImportListStates((prevState)=>({
      ...prevState,
      customerList:[],
      ImportDataList:[],
      selectedItems:[]
    }))

    setTabState('voucherNo')
  }

  const [importStates, setImportStates] = useState({
    voucherNo: '',
    customer: null,
    fromDate: null,
    toDate: null,
    isSelectedAll:false,
    invoiceType: null,
  })
  
  const [importListStates, setImportListStates] = useState({
    customerList:[],
    ImportDataList:[],
    selectedItems:[]
  })
  
  
  const [tabState, setTabState] = useState('voucherNo');

  const handleTabChange = async(event, newValue) => {
    console.log(newValue,'KKKKKKKKKKKKKKKKKKKKKKKKKK');
    
    handleModalDataClear()
    setTabState(newValue);

    if (newValue === 'customer'){
      let LedgerAPiResponse = await CallLedgerListById({
        CompanyID: CompanyID,
        CreatedUserID: user_id,
        BranchID: BranchID,
        type_invoice: "SalesInvoice",
        PriceRounding: 1,
        load_data: false,
        ledger_name: "",
        length: 1,
      });
      
      if (LedgerAPiResponse.StatusCode === 6000){
        setImportListStates((prevState)=> ({
          ...prevState,
          customerList:LedgerAPiResponse.data
        }))
      }
    }
  };


  const handleImportTextChange = (e) => {
    let {name, value} = e.target
    
    setImportStates((prevState)=>({
      ...prevState,
      [name]: value
    }))
  }

  const handleImportFromVoucherNo = async (e) => {
    e.preventDefault()

    const payload = (type) => ({
      CompanyID,
      BranchID,
      CreatedUserID: user_id,
      PriceRounding: Number(PriceDecimalPoint || 2),
      ...(type === 'salesOrder' ? { OrderNo: importStates.voucherNo } : { EstimateNo: importStates.voucherNo }),
    });
    let invoiceFetchAPiResponse ;
    if (importStates.invoiceType === 'salesOrder') {
      invoiceFetchAPiResponse = await sales_order_for_sales_invoice_api(payload('salesOrder'))
    } else {
      invoiceFetchAPiResponse = await sales_estimate_for_sales_invoice_api(payload('salesEstimate'))
    }  
      if (invoiceFetchAPiResponse?.StatusCode === 6000){
        let UserTableData = []
        let WarehouseData = []
  
        let {AccountLedger} = await Call_LedgerDetials(invoiceFetchAPiResponse.data.AccountLedger)      
        let Treatment = invoiceFetchAPiResponse.data.Treatment
        let PlaceOfSupply = invoiceFetchAPiResponse.data.PlaceOfSupply   
        let PriceCategory = invoiceFetchAPiResponse.data.PriceCategory
        let TotalQty = Number(invoiceFetchAPiResponse.data.TotalQty)
        let noOfPages = Math.ceil(TotalQty / 100);
        let RefNo = invoiceFetchAPiResponse.data.RefNo
        let Employee = invoiceFetchAPiResponse.data.Employee
        let Warehouse = null
        let uniq_id = invoiceFetchAPiResponse.data.id
  
        const SalesDetailsAPiResponse = await sales_details_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
        })
  
        if (SalesDetailsAPiResponse.StatusCode === 6000){
          UserTableData = SalesDetailsAPiResponse.UserTableData
          WarehouseData = SalesDetailsAPiResponse.WarehouseData
          
          Warehouse = WarehouseData.find(
            (i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
        }
        
        let data = []
        for (let i=1; i<=noOfPages; i++){
          const detailPayload = {
            CompanyID: CompanyID,
            BranchID: BranchID,
            "uniq_id":uniq_id,
            page:i,
            WarehouseID:Warehouse.WarehouseID
          }
          let importDetailsAPiResponse;
          if (importStates.invoiceType === 'salesOrder') {
            importDetailsAPiResponse = await import_sales_order_details_api(detailPayload)
          } else {
            importDetailsAPiResponse = await import_sales_estimate_details_api(detailPayload)
          }
            if (importDetailsAPiResponse){
              data = [...data, ...importDetailsAPiResponse.data]
            }
          }
          
          setDataLists([...data])
        
        setState((prevState)=>
          ({...prevState,
            ...invoiceFetchAPiResponse.data,
            AccountLedger:AccountLedger,
            Warehouse,
            Treatment,
            PlaceOfSupply,
            PriceCategory,
            RefNo,
            Employee,
          })
        )
        
        handleExportModal(false)
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: invoiceFetchAPiResponse?.message ?? "Something went wrong",
            severity: "warning",
          })
        );
      }
    // } 
  
      // const SalesEstimateFetchAPiResponse = await sales_estimate_for_sales_invoice_api(payload('salesEstimate'))
      // if (SalesEstimateFetchAPiResponse.StatusCode === 6000){
      //   let UserTableData = []
      //   let WarehouseData = []
  
      //   let {AccountLedger} = await Call_LedgerDetials(SalesEstimateFetchAPiResponse.data.AccountLedger)      
      //   let Treatment = SalesEstimateFetchAPiResponse.data.Treatment
      //   let PlaceOfSupply = SalesEstimateFetchAPiResponse.data.PlaceOfSupply   
      //   let PriceCategory = SalesEstimateFetchAPiResponse.data.PriceCategory
      //   let TotalQty = Number(SalesEstimateFetchAPiResponse.data.TotalQty)
      //   let noOfPages = Math.ceil(TotalQty / 100);
      //   let RefNo = SalesEstimateFetchAPiResponse.data.RefNo
      //   let Employee = SalesEstimateFetchAPiResponse.data.Employee
      //   let Warehouse = null
      //   let uniq_id = SalesEstimateFetchAPiResponse.data.id


      //   const SalesDetailsAPiResponse = await sales_details_api({
      //     CompanyID: CompanyID,
      //     BranchID: BranchID,
      //     CreatedUserID: user_id,
      //   })
  
      //   if (SalesDetailsAPiResponse.StatusCode === 6000){
      //     UserTableData = SalesDetailsAPiResponse.UserTableData
      //     WarehouseData = SalesDetailsAPiResponse.WarehouseData
          
      //     Warehouse = WarehouseData.find(
      //       (i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      //   }

      //   let data = []
      //   for (let i=1; i<=noOfPages; i++){
      //     let importSalesEstimateDetailsAPiResponse = await import_sales_estimate_details_api({
      //       CompanyID: CompanyID,
      //       BranchID: BranchID,
      //       uniq_id:uniq_id,
      //       page:i,
      //       WarehouseID:Warehouse.WarehouseID
      //     }
      //   )
      //       if (importSalesEstimateDetailsAPiResponse){
      //         data = [...data, ...importSalesEstimateDetailsAPiResponse.data]
      //       }
      //       console.log(importSalesEstimateDetailsAPiResponse,'importSalesEstimateDetailsAPiResponse');
      //     }
          
      //     setDataLists([...data])
        
      //   setState((prevState)=>
      //     ({...prevState,
      //       ...SalesEstimateFetchAPiResponse.data,
      //       AccountLedger:AccountLedger,
      //       Warehouse,
      //       Treatment,
      //       PlaceOfSupply,
      //       PriceCategory,
      //       RefNo,
      //       Employee,
      //     })
      //   )
        
      //   handleExportModal(false)
        
      //  else {
      //   dispatch(
      //     openSnackbar({
      //       open: true,
      //       message: SalesEstimateFetchAPiResponse?.message ?? "Something went wrong",
      //       severity: "warning",
      //     })
      //   );
      // }
    // }
  }


  // ----------------- import from customer -----------------------

  const handleCustomerSelect = (e, newValue) => {
    setImportStates((prevState) => ({
      ...prevState,
      customer:newValue
    }))
  }

  const handleCustomerFilter = async (e) => {
    e.preventDefault()

    const apiParams = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_invoice: "SalesInvoice",
      OrderCustomerID: importStates?.customer?.LedgerID,
      OrderFromDate: importStates?.fromDate?.format('YYYY-MM-DD'),
      OrderToDate: importStates?.toDate?.format('YYYY-MM-DD'),
    };

    let responseData;
    if (importStates?.invoiceType === 'salesOrder'){
      responseData = await sales_order_filter_api(apiParams);
    } else {
      responseData = await sales_estimate_filter_api(apiParams);
    }

    if (responseData?.StatusCode === 6000){
      
      setImportListStates((prevState)=>({
        ...prevState,
        ImportDataList:responseData?.data
      }))
      
    }else{
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong",
          severity: "warning",
        })
      );
    }

  }
    const selectItems = (type, VoucherNo) => {
    let data = [...importListStates.selectedItems];

    let isSelectedAll = importStates.isSelectedAll;
    
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (importStates.isSelectedAll) {
        data = [];
      } else {
        importListStates.ImportDataList.forEach((item) => {
          data.push(item);
        });
      }
    } else {
      let index = data.findIndex((item) => item.VoucherNo === VoucherNo);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        let item =importListStates.ImportDataList.find(item=>item.VoucherNo === VoucherNo)
        data.push(item);
      }
    }
    
    setImportListStates((prevState)=>({
      ...prevState,
      selectedItems:data
    }))

    setImportStates((prevState)=>({
      ...prevState,
      isSelectedAll:isSelectedAll
    }))
    
  };

  const handleCustomerFilterInputChange = async(newValue) => {
    
    let LedgerAPiResponse = await CallLedgerListById({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      type_invoice: "SalesInvoice",
      PriceRounding: 1,
      load_data: false,
      ledger_name: newValue,
      length: 1,
    });
    
    if (LedgerAPiResponse.StatusCode === 6000){
      setImportListStates((prevState)=> ({
        ...prevState,
        customerList:LedgerAPiResponse.data
      }))
    }
  }

  const handleImportFromCustomer = async (e) => {
    e.preventDefault()

    let refBillNo = importListStates.selectedItems.map(item => item.VoucherNo);
    let uniq_id_list = importListStates.selectedItems.map(item => item.id)
    
    // fetch latest voucher no based on date
    const latestVoucherNo = importListStates.selectedItems.reduce((latest, current) => 
      new Date(current.Date) > new Date(latest.Date) ? current : latest
    );
    
    let payload = {
      CompanyID:CompanyID,
      [importStates?.invoiceType === 'salesOrder' ? 'OrderNo' : 'EstimateNo']: latestVoucherNo.VoucherNo,
      BranchID:BranchID,
      CreatedUserID:user_id,
      PriceRounding:Number(PriceDecimalPoint || 2),
    }
    let responseData;
    if (importStates?.invoiceType === 'salesOrder'){
      responseData = await sales_order_for_sales_invoice_api(payload)
    } else {
      responseData = await sales_estimate_for_sales_invoice_api(payload)
    }
    
    if (responseData.StatusCode === 6000){
      let UserTableData = []
      let WarehouseData = []

      let {AccountLedger} = await Call_LedgerDetials(responseData.data.AccountLedger)      
      let Treatment = responseData.data.Treatment
      let PlaceOfSupply = responseData.data.PlaceOfSupply   
      let PriceCategory = responseData.data.PriceCategory
      let TotalQty = Number(responseData.data.TotalQty)
      let noOfPages = Math.ceil(TotalQty / 100);
      let RefNo = refBillNo
      let Employee = responseData.data.Employee
      let Warehouse = null

      const SalesDetailsresponseData = await sales_details_api({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
      })

      if (SalesDetailsresponseData.StatusCode === 6000){
        UserTableData = SalesDetailsresponseData.UserTableData
        WarehouseData = SalesDetailsresponseData.WarehouseData
        
        Warehouse = WarehouseData.find(
          (i)=> i.WarehouseID === UserTableData.DefaultWarehouse)
      }
      
      let data = []
      for (let i=1; i<=noOfPages; i++){
        let importSalesDetailsresponseData ;
        let detailPayload = {
          CompanyID: CompanyID,
          BranchID: BranchID,
          uniq_id: uniq_id_list,
          page: i,
          WarehouseID: Warehouse.WarehouseID
        }
        if (importStates?.invoiceType === 'salesOrder'){
          importSalesDetailsresponseData = await import_sales_order_details_api(detailPayload)
        } else {
          importSalesDetailsresponseData = await import_sales_estimate_details_api(detailPayload)
        }
          if (importSalesDetailsresponseData){
            data = [...data, ...importSalesDetailsresponseData.data]
          }
        }
        
        setDataLists([...data])
      
      setState((prevState)=>
        ({...prevState,
          ...responseData.data,
          AccountLedger:AccountLedger,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          PriceCategory,
          RefNo,
          Employee,
        })
      )
      handleExportModal(false)
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong",
          severity: "warning",
        })
      );
    }

  }


  // ========================================================================

  //======================CALLING APIS==========================
  
  //Ledger Searching api 
  const Call_LedgerDetials = async (data) =>{
    
    
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id:data.id
    })
    
    if (LedgerDetails.StatusCode === 6000){
      
      let AccountLedger = data//state.AccountLedger      
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName
      AccountLedger["Balance"] = LedgerDetails.data.Balance
      AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID
      // AccountLedger["BillingAddress_object"]:LedgerDetails
      
      let BillingAddress_object = LedgerDetails.data.BillingAddress_object
      // setState({...state,AccountLedger:AccountLedger})

      
      return {AccountLedger,BillingAddress_object}
      
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    
  }
  
  // Product Searching api
  const Call_ProductSearchFunction = async (payload) =>{
    let data = []
    const Response = await product_search_new_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  // Product UnitList(PriceList) list api
  const Call_PriceList_list_Function = async (payload) =>{
    let data = []
    const Response = await get_pricelist_list_new_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) =>{
    let data = null
    const Response = await get_single_pricelist_new_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  // Sales details view
  const Call_sales_details = async(payload) => {
    let data = null
    const Response = await get_sales_details_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  
  //Single View api
  const Call_Sales_Master_View = async (payload) =>{
    let data = []
    const Response = await view_sales_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = []
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }
  
  //BarCode Api calling
  const Call_Barcode_api = async(payload) =>{
    let data = null
    const Response = await get_product_by_barcode_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  
  
  //get states of a give country api  
  const Call_States_of_Country_api = async(payload) =>{
    let data = null
    const Response = await place_of_supply_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  
  

    const Call_Employees_api = async(payload) =>{
      let data = []
      const Response = await CallEmployees(payload)
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = []
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
      
    //get formset values (customize table)
    const Call_formset_api = async(payload) =>{
      let data = null
      const Response = await get_formset_values_api(payload)
      if (Response.StatusCode === 6000){
        data = Response.datas
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
    const Call_Stock_api = async(payload) =>{
      let data = null
      const Response = await get_stock_of_products_api(payload)
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
        
    const Call_Product_History_api = async(payload) =>{
      let data = []
      let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
      if (ProductIDs.length > 0){          
        let LastProduct =  ProductIDs[ProductIDs.length - 1].Product?.ProductID
        payload.CompanyID = CompanyID
        payload.BranchID = BranchID
        payload.CreatedUserID = user_id
        payload.LedgerID = state.AccountLedger.LedgerID
        payload.PriceRounding = 2
        payload.ProductIDs = [LastProduct]
        payload.CompanyID = CompanyID
        payload.AvoidLedgerwise = AvoidLedgerwiseFilterInSalesHistory
      }
      
      const Response = await get_product_history_api(payload)
      if (Response.StatusCode === 6000){
        data = Response.history_datas
      }
      else if (Response.StatusCode === 6001){
        data = []
        dispatch(
          openSnackbar({
            open: true,
            message: "No More Data!",
            severity: "warning",
          })
        );
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
    
    
    
  //==============CREATE/UPDATE SHIPPING ADDRESS=================
  const Create_ShippingAddress = async(payload,method) =>{
    
    
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "ShippingAddress"	
      const list_resposne = await list_user_address({
        CompanyID : CompanyID,
        BranchID:BranchID,
        LedgerID:state.AccountLedger.LedgerID,
        Type : "ShippingAddress"	
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setShippingAddress({...ShippingAddress,ShippingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    } 
  }
  
  //==============CREATE/UPDATE BILLING ADDRESS==================
  const Create_BillingAddress = async(payload,method) =>{
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "BillingAddress"	
      const list_resposne = await list_user_address({
      CompanyID : CompanyID,
      BranchID:BranchID,
      LedgerID:state.AccountLedger.LedgerID,
      Type : "BillingAddress"	,
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      payload.AddressType = "BillingAddress"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    }
    
  }
  
  
  //================E-invoice phase2===========================
  
  
    
  // const submitInvoice = async(data,type_of_egs) => {  
  //     let payload = {
  //       CompanyID: CompanyID,
  //       BranchID: BranchID,
  //       invoice_id:data.master_id,
  //       InvoiceName:data.InvoiceName,
  //       VoucherNumber:data.VoucherNumber,
  //       type_of_egs:type_of_egs,
  //       submit_type:"submit"
  //     }
      
  //     let res = await get_e_invoice_submit_api({...payload});
  //       let status_code = res.status_code
      
  //       if (res.StatusCode === 6000){
  //         setApiResult(res.data)
  //         return status_code
  //       }
  //       else{
  //         return status_code
  //       }
    
  // }
  
  
  
  
  
  
  //==============CREATE INVOICE API=============================
  
  const handleSubmit = async() =>{
    
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    })

    let { error, message, field, indexList, severity } = await Sales_Validation(dataState,state,DataList,GeneralSettingsData);
    if (error){
      let errors = {
        error,
        message,
        field,
        indexList,
      };      
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: severity,
        })
      );
      setErrors({ ...Errors, ...errors });
    } else if (EstimateConvertion === 'Warning' && state.Status === 'I'){  
      setConfirmBox(true);      
    }
    else if (SalesPriceLessThanMinimumSalesPrice === "Warning"){
      let data = DataList.filter(item=> item.MinimumSalesPrice > item.Rate)
      if (data.length>0){
        setWarningBox(true)
          dispatch(
            openSnackbar({
              open: true,
              message:`Rate of ${data[0].Product.ProductName} less then min sales price` ,
              severity: "warning",
            })
          );
      }
      else{
        setIsButtonClick(true)
        handleSaveAPISubmit()
      }
    }
    else if (SalesPriceLessThanMinimumSalesPrice === "Block"){      
      let data = DataList.filter(item=> item.MinimumSalesPrice > item.Rate)
      if (data.length>0){
          dispatch(
            openSnackbar({
              open: true,
              message:`Rate of ${data[0].Product.ProductName} less then min sales price` ,
              severity: "error",
            })
          );
      }else{
        setIsButtonClick(true)
        handleSaveAPISubmit()
      }     
    }
    else{
      setIsButtonClick(true)
      handleSaveAPISubmit()
    }
  }  
  const handleSaveAPISubmit = async () =>{
    let Details = []
      DataList.map((i)=>{
        if (i.Product){          
          let item = {
            id:i.id,
            ProductID : i.Product.ProductID,
            Qty : i.Qty,
            UnitPrice : i.Rate,
            InclusivePrice : i.InclusivePrice,
            // RateWithTax : i.Rate,
            DiscountAmount : i.DiscountAmount,
            GrossAmount : i.GrossAmount,
            TaxableAmount : i.TaxableAmount,
            TaxAmount : i.TaxAmount,
            NetAmount : i.Amount,
            FreeQty : i.FQty,
            // CostPerPrice : i.Rate,
            PriceListID : i.Unit.PriceListID,
            DiscountPerc : i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode : i?.BatchCode?.BatchCode,
            Description : i.Description,
            ProductTaxID : i.Tax.TaxID,
            NonTaxableAmount:i.NonTaxableAmount,
            PurchasePrice:i.PurchasePrice,
            SGSTAmount:i.SGSTAmount,
            CGSTAmount:i.CGSTAmount,
            IGSTAmount:i.IGSTAmount,
            IGSTPerc:i.IGSTPerc,
            CGSTPerc : i.CGSTPerc,
            SGSTPerc:i.SGSTPerc,
            SerialNos:i.SerialNos,
            IsSerialNo: i?.SerialNos?.length > 0 || validationSerialNumber === 'Block'? true: false
          }
          Details.push(item)
        }
      })
      
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        Details:Details,
        is_manual_roundoff:is_manual_roundoff,
        uniq_id:uniq_id,//uniq_id
        //=============
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        //===============
        LedgerID:state.AccountLedger.LedgerID,
        AccountLedgerBalance : state.AccountLedger?.Balance??0,
        CustomerName:state.AccountLedger.LedgerName,
        CashID:state.CashAccount.LedgerID,
        BankID:state.BankAccount.LedgerID,
        EmployeeID :state.Employee?.EmployeeID,
        PriceCategoryID:state.PriceCategory.PriceCategoryID,
        WarehouseID:state.Warehouse.WarehouseID,
        Treatment:state.Treatment.value,
        Country_of_Supply:state.PlaceOfSupply.Country,
        State_of_Supply:state.PlaceOfSupply.id,
        Date:state.Date,
        RefNo:state.RefNo,    
        ShippingCharge:state.ShippingCharge,
        ShippingTax:state.ShippingTax?.TaxID,
        shipping_tax_amount:state.shipping_tax_amount,
        BillDiscPercent:state.BillDiscPercent,
        BillDiscAmt:state.BillDiscAmt,
        CashAmount:state.CashAmount,
        BankAmount:state.BankAmount,
        Notes:state.Notes,
        
        BillingAddress:state.BillingAddress?.id,
        ShippingAddress:state.ShippingAddress?.id,
        
        RoundOff:state.RoundOff,
        DueDate:state?.DueDate,
        DeliveryDate:state.DeliveryDate,
        //Total
        TotalQty:state.TotalQty,
        TotalGrossAmt:state.TotalGrossAmt,
        TotalDiscount:state.TotalDiscount,
        TotalTax:state.TotalTax,
        NetTotal:state.NetTotal,
        TotalTaxableAmount:state.TotalTaxableAmount,
        TaxTaxableAmount:state.TaxTaxableAmount,
        NonTaxTaxableAmount:state.NonTaxTaxableAmount,
        GrandTotal:state.GrandTotal,
        TotalCGST:state.TotalCGST,
        TotalSGST:state.TotalSGST,
        TotalIGST:state.TotalIGST,
        TaxType : state.TaxType,
        TaxID : state.TaxID,
        BillDiscTaxAmt : state.BillDiscTaxAmt,
        BillDiscTaxPerc : state.BillDiscTaxPerc,
        BillDiscTaxID : state.BillDiscTaxID,
        IsForceSave: isForceSave
      }
      let responseData = null
      if (is_edit && uniq_id){
        responseData = await edit_sales_api(payload)
      }
      else{
        responseData = await create_sales_api(payload)
      }
      
      if (responseData.StatusCode === 6000) {        
        if (EnableEinvoiceKSA && !is_edit){
          
          let EInvoiceData = responseData.EInvoiceData
          // let master_id = EInvoiceData.master_id
          // let VoucherNumber = responseData.VoucherNumber
          // let InvoiceName = responseData.InvoiceName
          // let type_of_egs = responseData.type_of_egs 
          let EinvoiceStatus = responseData.EinvoiceStatus 
          
          if (EInvoiceData){
            //b2b
            if (state.Treatment.value === "0"){
              
              if (EinvoiceStatus===1 || EinvoiceStatus==='1'){
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully  And Einvoice Submitted Successfully",
                    severity: "success",
                  })
                );
              }
              else if (EinvoiceStatus===3 || EinvoiceStatus==='3'){
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully  And Einvoice Submitted With Warnings Successfully",
                    severity: "success",
                  })
                );
              }
              else {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Created Successfully  And Einvoice Submitted Is Failed!",
                    severity: "warning",
                  })
                );
              }
              
              
            }
            //b2c
            else{
              dispatch(
                openSnackbar({
                  open: true,
                  message: "Created Successfully  And Einvoice Submitted Successfully",
                  severity: "success",
                })
              );
            }
          }
          else{
            dispatch(
              openSnackbar({
                open: true,
                message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
                severity: "error",
              })
            );
          }

        }
        else{
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          setIsForceSave(false)
          setIsBackendValidation(false)
        }
        clearData();
      } else if (responseData.StatusCode === 6001) {
        setIsButtonClick(false)
        if (responseData?.isSerialNoError && responseData?.isWarning){
          setIsWarningBox(true)
          setWarningMessage(responseData?.message + " do you wanna force save?")
          setIsBackendValidation(true)
        }
        else if(responseData?.isSerialNoError && !responseData?.isWarning){
          setIsErrorBox(true)
          setErrorMessage(responseData?.message)
        }
        else{
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        }
      } 
      else if (responseData.StatusCode === 6002) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
      } 
      else {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " + responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
  }
  
  // const calculateData = (dataState, state, DataList, customRound, GST, CompanyState, setDataLists) => {
  //   const updatedData = DataList.map((lineItem) => {
  //     const updatedLineItem = SalesLineCalc(dataState, state, lineItem, customRound, GST, CompanyState);
  //     return updatedLineItem;
  //   });
    
  //   setDataLists([...updatedData]);
  // };

  const calculateData = (dataState, state, DataList, customRound, GST, CompanyState, setDataLists) => {
    const taxTreatment = [
      "Special Economic Zone",
      "Deemed Export",
      "Overseas"
    ];
  
    const isSpecialTreatment = taxTreatment.includes(state?.Treatment?.name);
    
    const noneTax = isSpecialTreatment
      ? dataState.TaxCategoryData.find((i) => i.TaxName === "None")
      : null;
  
    const taxUpdatedData = DataList.map((lineItem) => {
      if (isSpecialTreatment) {
        return { 
          ...lineItem,
          Tax: noneTax 
        }
      } 
      else {
        const applicableTax = dataState.TaxCategoryData.find(
          (i) => i.TaxID === lineItem?.Product?.ProductTaxID
        );
        return { ...lineItem, Tax: applicableTax };
      }
    });
  
    const updatedData = taxUpdatedData.map((lineItem) => {
      return SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound,
        GST,
        CompanyState
      );
    });
    setDataLists(updatedData);
  };
  
  
  
  //===============HANDLE CHANGE FUNCTIONS=======================
  
  
  
  const onMasterChange = (e) => {
    if(e){
      const { name, value } = e.target;
      
      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);
      
      if (isCalcField){
        state[name] = value
        
        
        //handling bill disc 
        if (name === "BillDiscAmt"){
          state.BillDiscPercent = 100 * (Number(value)/Number(state.TotalGrossAmt))
        }
        else if (name === "BillDiscPercent"){
          state.BillDiscAmt = (Number(value)/100) * Number(state.TotalGrossAmt)
        }
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound, GST, CompanyState,RoundOffSales,Country);
        setState({ ...newState });
      }
      else{
        setState((prevState) => ({ ...prevState, [name]: value }));
      } 
    }
    
  }
  
  const onMasterInputChange = async (search,name) => {
    if(search && name){
      if (name === "AccountLedger") {
    const LedgerList = await ledger_list_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      PriceRounding:Number(PriceDecimalPoint || 2),
      voucher_type:"SI",
      search:search,
      items_per_page: 5 * loadAccountLedger.pageNo ,
      page_no:1
    })
    if (LedgerList.StatusCode === 6000){
      let AccountLedgerData = [...LedgerList.data]
      setDataState({...dataState,AccountLedgerData:AccountLedgerData})
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerList?.message ?? "Api Error",
          severity: "error",
        })
      );
    } 
  }
      else if (name === "Employee"){
        const EmployeeList = await Call_Employees_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding:Number(PriceDecimalPoint || 2),
          search:search,
          list_type:"sales",

        })
        
        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some((employee) => employee.id === salesman.id);
        });
          let EmployeeData = [...dataState.EmployeeData,...uniqueSalesmansData]
          setDataState({...dataState,EmployeeData:EmployeeData})
        
      }
    }
  }
  
  const onMasterAutoChange = async(e, v, name) => {
    
    if(v && name){
      const isCalcField = MasterCalcFields.includes(name);
      let value = v
      
      if (name === "AccountLedger"){
        let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(value)
        let data =AccountLedger
        let PlaceOfSupplyData;
        let CountryID = Country

        if (data.CountryID){
          CountryID = data.CountryID
        }

        const PlaceOfSupplyResponse = await place_of_supply_api(CountryID);
        if (PlaceOfSupplyResponse.StatusCode === 6000) {
          PlaceOfSupplyData = PlaceOfSupplyResponse.data;
        }

        let Treatment = null;
        if(VAT) {
          Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        } else {
          Treatment = GST_TreatmentData.find((i)=> i.value === data.Treatment)
        }

        let stateID = CompanyState
        if(data.State_id){
          stateID = data.State_id
        }
        let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === stateID)

        // let Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        
        let PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === 1)
        if (data.PriceCategoryID){
          PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === data.PriceCategoryID)
        }
         
        if (VAT&&(Treatment === null || Treatment === undefined )) {
          Treatment = {value:"1",name:'Business To Customer(B2C)'}
        } 
        else if ((GST && (Treatment === null || Treatment === undefined))) {
          Treatment = {value: "2", name: 'Consumer'};
      }

      let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply.id)
        // if (value.Treatment === null){

        //   Treatment = VAT
        //   ? VAT_TreatmentData.find((i) => i.default === true)
        //   : GST
        //   ? GST_TreatmentData.find((i) => i.default === true)
        //   : null;
            
        //   } 

        if (BillingAddress_object){
          PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === BillingAddress_object?.state)
        }

        setDataState({
          ...dataState,
          PlaceOfSupplyData : PlaceOfSupplyData
        })

        setState({
          ...state,[name]:data,
          Treatment,
          PlaceOfSupply, 
          ShippingAddress:null,
          BillingAddress:BillingAddress_object,
          PriceCategory,
          TaxType:NewTaxType,
          TaxID:NewTaxID
        })
      }
      
      else if (name === "PriceCategory"){
        let Data = DataList
        Data.map((lineItem,index)=>{
          if (value?.PriceCategoryID === 1){
            lineItem.Rate = lineItem.SalesPrice
          }
          else if (value?.PriceCategoryID === 2){
            lineItem.Rate = lineItem.SalesPrice1
          }
          else if (value?.PriceCategoryID === 3){
            lineItem.Rate = lineItem.SalesPrice2
          }
          else if (value?.PriceCategoryID === 4){
            lineItem.Rate = lineItem.SalesPrice3
          }
          
          let calculated_lineItem =  SalesLineCalc(dataState,state,lineItem,customRound)
          Data[index] = calculated_lineItem
        }) 
        
        setDataLists([...Data]);
        setState({...state,[name]:value})
      }
      
      
      else if (name === "Warehouse"){
        let Data = DataList
        let payload = DataList.map(object => object?.Unit?.PriceListID);
        let StockData = await Call_Stock_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          PriceListIDList : payload ,
          WarehouseID:value.WarehouseID
        })
        
        // Data.map((lineItem,index) => {
        //   if (lineItem.Product){            
        //     lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
        //     Data[index] = lineItem
        //   }
        // }) 
        Data.forEach((lineItem) => {
          if (lineItem.Product) {            
            lineItem.Stock = StockData.find((i) => i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0;
          }
        });
        setDataLists([...Data]);
        setState({...state,[name]:value})
        
        
      }
      
      else if (name === "Treatment") {
        let Treatment = value;
        // const {TaxType , TaxID} = state
        let PlaceOfSupply = state.PlaceOfSupply?.id;
        let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply)        
        
        setState({
          ...state,
          TaxType:NewTaxType,
          TaxID:NewTaxID,
          Treatment:Treatment,
        })
      }
      
      else if (name === 'PlaceOfSupply') {

        if (value){
          let PlaceOfSupply = value;
          let TaxType = state.TaxType;
          let TaxID = state.TaxID;

          if(CompanyState === PlaceOfSupply?.id && state.Treatment?.value != "5" ){
            if(TaxID === 23){
              TaxType = "GST Intra-state B2B";
              TaxID = 21;
            } else if(TaxID === 24) {
              TaxType = "GST Intra-state B2C";
              TaxID = 22;
            }
          } else {
            if (TaxID === 21) {
              TaxType = "GST Inter-state B2B";
              TaxID = 23;
            } else if (TaxID === 22) {
              TaxType = "GST Inter-state B2C";
              TaxID = 24;
            }
          }
          
          setState({
            ...state,
            PlaceOfSupply: PlaceOfSupply,
            TaxType : TaxType,
            TaxID : TaxID,
          })
        } else {
          setState({...state,[name]:value})
        }
      }

      else if(isCalcField){
        state[name] = value
        
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound, GST,CompanyState,RoundOffSales ,Country );
        setState({ ...newState });
      }
      else{
        setState({...state,[name]:value})
      }
    }  
  }
  
  const onDetailsInputChange = async (e, name) => {
    
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse?.WarehouseID,
          is_product_image: false,
          length: 10,
          product_name: value,
          type: "Sales"
        });        
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };
  
  const onDetailsAutoChange = async(v, index, name) => {
    if(v && name && v?.id){
      
      let Data = DataList;
      let lineItem = Data[index] 
      
      if (name === "Product" || name === "ProductCode"){
        
        let Product = v
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id : Product.id,
          type_data : "SI"
        })
        
        let Unit = UnitList.find((i) => i.UnitInSales === true)
        let PriceListID = Unit.PriceListID
        
        
        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID:PriceListID,
          PriceRounding:Number(PriceDecimalPoint || 2),
          WarehouseID:state?.Warehouse?.WarehouseID
        })
        
        let Rate = 0 
        let Qty = 1
        let FQty = 0
        let DiscountPerc = 0
        let DiscountAmount = 0
        

        
        if (UnitDetails){
          Rate = Number(UnitDetails.Rate)
          
          lineItem.MRP = UnitDetails.MRP
          lineItem.Rate = Rate
          lineItem.PurchasePrice = UnitDetails.PurchasePrice
          lineItem.BarCode = UnitDetails.BarCode
          lineItem.BatchCode = UnitDetails.BatchCode
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
          lineItem.AvgCost = UnitDetails.AverageCost
          lineItem.is_inclusive = UnitDetails.is_inclusive
          lineItem.Stock = UnitDetails.Stock
          lineItem.CostWithExpense = UnitDetails.CostWithExpense

          lineItem.SalesPrice = UnitDetails.SalesPrice
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3

          if (state?.PriceCategory?.PriceCategoryID === 1){
            lineItem.Rate = UnitDetails.SalesPrice
          }
          else if (state?.PriceCategory?.PriceCategoryID === 2){
            lineItem.Rate = UnitDetails.SalesPrice1
          }
          else if (state?.PriceCategory?.PriceCategoryID === 3){
            lineItem.Rate = UnitDetails.SalesPrice2
          }
          else if (state?.PriceCategory?.PriceCategoryID === 4){
            lineItem.Rate = UnitDetails.SalesPrice3
          }
        }
        
        
        if (EnableProductBatchWise === true){
          if (UnitDetails.BatchCode){
            lineItem.Rate = UnitDetails.BatchCode.SalesPrice
            lineItem.PurchasePrice = UnitDetails.BatchCode.PurchasePrice
            lineItem.Stock = lineItem.BatchCode.Stock
          }
        }
        
        let taxTreatment = [
          "Special Economic Zone",
          "Deemed Export",
          "Overseas"
        ]

        let Tax = null

        if (taxTreatment.includes(state.Treatment.name)){
          Tax =  dataState.TaxCategoryData.find((i) => i.TaxName === "None")
        }
        else{
          Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
        }
        // if (state.Treatment.value === "1"){
        //   Tax =  dataState.TaxCategoryData.find((i) => i.TaxName === "None")
        // }
        

        lineItem.Product = Product
        lineItem.ProductCode = Product
        lineItem.Description = Product.Description
        // lineItem.Stock = Product.Stock
        lineItem.Qty = Qty
        lineItem.FQty = FQty
        lineItem.Tax = Tax
        lineItem.DiscountPerc = DiscountPerc
        lineItem.DiscountAmount = DiscountAmount

        lineItem.Unit = Unit
        lineItem.UnitList = UnitList
        
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound, GST, CompanyState)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
 
      }
      else if (name === "Unit"){
        let Unit = v
        
        let PriceListID = Unit.PriceListID
        
        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID:PriceListID,
          PriceRounding:Number(PriceDecimalPoint || 2),
          WarehouseID:state?.Warehouse?.WarehouseID
        })
        let Rate = 0 

        if (UnitDetails){
          Rate = Number(UnitDetails.Rate)
  
          lineItem.MRP = UnitDetails.MRP
          lineItem.Rate = Rate
          lineItem.PurchasePrice = UnitDetails.PurchasePrice
          lineItem.BarCode = UnitDetails.BarCode
          lineItem.BatchCode = UnitDetails.BatchCode
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
          lineItem.AverageCost = UnitDetails.AverageCost
          lineItem.Stock = UnitDetails.Stock
          
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3
          
          if (state?.PriceCategory?.PriceCategoryID === 1){
            lineItem.Rate = UnitDetails.SalesPrice
          }
          else if (state?.PriceCategory?.PriceCategoryID === 2){
            lineItem.Rate = UnitDetails.SalesPrice1
          }
          else if (state?.PriceCategory?.PriceCategoryID === 3){
            lineItem.Rate = UnitDetails.SalesPrice2
          }
          else if (state?.PriceCategory?.PriceCategoryID === 4){
            lineItem.Rate = UnitDetails.SalesPrice3
          }
          
          
          if (EnableProductBatchWise === true){
            if (lineItem.BatchCode){
              lineItem.Rate = lineItem.BatchCode.SalesPrice
              lineItem.PurchasePrice = lineItem.BatchCode.PurchasePrice
              lineItem.Stock = lineItem.BatchCode.Stock
            }
          }

        }
        lineItem.Unit = Unit
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound,customRound, GST, CompanyState)
        
        Data[index] = calculated_lineItem
        setDataLists([...Data]);
        
      }
      else if (name === "Tax"){
        let Tax = v
        lineItem.Tax = Tax
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound, GST, CompanyState)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
      
      else if (name === "BatchCode"){
        let BatchCode = v
        lineItem.BatchCode = BatchCode
        lineItem.Rate = BatchCode.SalesPrice
        lineItem.PurchasePrice = BatchCode.PurchasePrice
        lineItem.Stock = BatchCode.Stock
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound, GST, CompanyState)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
 
    }  
  }
  
  const onDetailsChange = async(e,index) =>{    
    if (e){
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      let lineItem = Data[index]
      lineItem[name] = value
      
      //handling disc 
      let Amount = lineItem.GrossAmount //+ lineItem.TaxAmount
      if (name === "DiscountAmount"){
        lineItem.DiscountPerc = 100 * (Number(value)/Number(Amount))
      }
      else if (name === "DiscountPerc"){
        lineItem.DiscountAmount = (Number(value)/100) * Number(Amount)
      }
      else if (name === "InclusivePrice"){
        lineItem.Rate = Number(value) / (1 + (lineItem.Tax?.SalesTax || 0) / 100);
      }
      
      
      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem,customRound, GST, CompanyState)
      
      Data[index] = calculated_lineItem
      setDataLists([...DataList])
    }
  }
  
  const addByBarCode = async(text) =>{
    
    let Data = DataList;
    // let index = Data.length
    let lineItem =  DataListItem
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    })
    
    if (Product){
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id : Product.id,
        type_data : "SI"
      })
      
      let Unit = UnitList.find((i) => i.UnitInSales === true)
      let PriceListID = Unit.PriceListID
      
      
      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID:PriceListID,
        PriceRounding:Number(PriceDecimalPoint || 2),
        WarehouseID:state?.Warehouse?.WarehouseID
      })
      
      let Rate = 0 
      let Qty = 1
      let FQty = 0
      let DiscountPerc = 0
      let DiscountAmount = 0
      
  
      
      if (UnitDetails){
        Rate = Number(UnitDetails.Rate)
        
        lineItem.MRP = UnitDetails.MRP
        lineItem.Rate = Rate
        lineItem.PurchasePrice = UnitDetails.PurchasePrice
        lineItem.BarCode = UnitDetails.BarCode
        lineItem.BatchCode = UnitDetails.BatchCode
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
        lineItem.AverageCost = UnitDetails.AverageCost
        
        
        
        lineItem.SalesPrice = UnitDetails.SalesPrice
        lineItem.SalesPrice1 = UnitDetails.SalesPrice1
        lineItem.SalesPrice2 = UnitDetails.SalesPrice2
        lineItem.SalesPrice3 = UnitDetails.SalesPrice3

        if (state?.PriceCategory?.PriceCategoryID === 1){
          lineItem.Rate = UnitDetails.SalesPrice
        }
        else if (state?.PriceCategory?.PriceCategoryID === 2){
          lineItem.Rate = UnitDetails.SalesPrice1
        }
        else if (state?.PriceCategory?.PriceCategoryID === 3){
          lineItem.Rate = UnitDetails.SalesPrice2
        }
        else if (state?.PriceCategory?.PriceCategoryID === 4){
          lineItem.Rate = UnitDetails.SalesPrice3
        }
  
      }
      
      let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
  
      lineItem.Product = Product
      lineItem.ProductCode = Product
      lineItem.Description = Product.Description
      lineItem.Stock = Product.Stock
      lineItem.Qty = Qty
      lineItem.FQty = FQty
      lineItem.Tax = Tax
      lineItem.DiscountPerc = DiscountPerc
      lineItem.DiscountAmount = DiscountAmount
  
      lineItem.Unit = Unit
      lineItem.UnitList = UnitList
      
      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound,
        GST,
        CompanyState
      )
      
      Data.push(calculated_lineItem)
      
      setDataLists([...Data]);
      
    }
    
  }
  
  
  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };
  
  
  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }
        Data.splice(index, 1);
      }
    }
    
    let newState = SalesInvoiceCalc(dataState,state, Data,is_manual_roundoff,customRound, GST, CompanyState,RoundOffSales,Country);
    setState({ ...newState,deletedData:deletedData });  
    setDataLists([...Data]);
  };
  
  const RemoveSerialNo = (dataIndex, serialNoToRemove) => {  
    setDataLists((prevState) => {
      const newState = [...prevState];
  
      newState[dataIndex] = {
        ...newState[dataIndex],
        SerialNos: newState[dataIndex].SerialNos.filter(
          (data) => data.SerialNo !== serialNoToRemove
        ),
      };
  
      return newState;
    });
  };
  
  
  const serialNumberContainNumber = (serialNo) => {
    if (serialNo){
      const match = serialNo.match(/\d+$/);
      if (match) {
        const number = parseInt(match[0]);
        const stringBeforeNumber = serialNo.replace(number.toString(), '');
        return {
          endsWithNumber: true,
          number: number,
          stringBeforeNumber: stringBeforeNumber || null,  // Return null if no string part is found
        };
      }
      return {
        endsWithNumber: false,
        number: null,
        stringBeforeNumber: null,  // Explicitly return null
      };

    }
  };

const handleSerialNoAdd = (index) => {
  let serialNo = DataList[index]?.SerialNo
  let itemCode = DataList[index]?.ItemCode || "";
  let to = DataList[index]?.To || 0;
  const serialNosCount = DataList[index].SerialNos.length
  const currentQty = DataList[index].Qty

  const isSerialNoContainNumber = serialNumberContainNumber(serialNo)
  const isToSerialNoContainNumber = serialNumberContainNumber(to)
  if (currentQty < serialNosCount){
    dispatch(
      openSnackbar({
        open: true,
        message: `Qty and serial no count missmatch add ${serialNosCount - currentQty} more qty`,
        severity: "warning",
      })
    );
    return
  }
  if (serialNo && !to && DataList[index]?.SerialNos.length >= DataList[index]?.Qty){
    dispatch(
      openSnackbar({
        open: true,
        message: "Please add one more qty",
        severity: "warning",
      })
    );
    return
  }
  if (serialNo && to && !isSerialNoContainNumber.endsWithNumber)
    {dispatch(
      openSnackbar({
        open: true,
        message: "cant generate multiple serial no please give valid serial no",
        severity: "warning",
      })
    );
    return;
  }
  if (serialNo && !to && DataList[index]?.SerialNos.some((item) => item.SerialNo === serialNo)) 
    {dispatch(
      openSnackbar({
        open: true,
        message: "Serial no already exists. Please provide another serial no.",
        severity: "error",
      })
    );
    return;
  }

  if (serialNo && to && isToSerialNoContainNumber.endsWithNumber && isToSerialNoContainNumber.number < isSerialNoContainNumber.number) 
    {dispatch(
      openSnackbar({
        open: true,
        message: "'To' should be greater than serial no.",
        severity: "error",
      })
    );
    return;
  }
  if (serialNo && to && isToSerialNoContainNumber.endsWithNumber) {
    const serialNoCount = DataList[index]?.SerialNos.length
    const currentQty = DataList[index]?.Qty
    const vacantQty = currentQty - serialNoCount

    const differenceInQty = (isToSerialNoContainNumber.number + 1)  - isSerialNoContainNumber.number
    if (vacantQty < differenceInQty){
      dispatch(
        openSnackbar({
          open: true,
          message: `Add ${differenceInQty - vacantQty} more qty`,
          severity: "error",
        })
      );
      return
    }
  }

  // Update the Serial Nos based on the provided range
  setDataLists((prevState) => {
    const updatedList = [...prevState];
    const selectedItem = { ...updatedList[index] };

    if (to && serialNo) {
      // Create a range of Serial Nos
      const rangeSerialNos = [];
      for (let i = 0; i <= isToSerialNoContainNumber.number - isSerialNoContainNumber.number; i++) {
        let newSerialNo
        if (isSerialNoContainNumber.stringBeforeNumber){
          newSerialNo = `${isSerialNoContainNumber.stringBeforeNumber}${isSerialNoContainNumber.number + i}`
        }else{
          newSerialNo = parseInt(serialNo) + i;
        }
        if (
          !selectedItem.SerialNos.some(
            (item) => item.SerialNo === newSerialNo
          )
        ) {
          rangeSerialNos.push({ SerialNo: newSerialNo, ItemCode: itemCode });
        }
      }
      selectedItem.SerialNos = [...selectedItem.SerialNos, ...rangeSerialNos];
    } else if (serialNo || itemCode) {
      // Add single Serial No
      selectedItem.SerialNos = [
        ...selectedItem.SerialNos,
        { SerialNo: String(serialNo), ItemCode: itemCode },
      ];
    }

    // Reset inputs for the current index
    selectedItem.SerialNo = "";
    selectedItem.ItemCode = "";
    selectedItem.To = "";

    updatedList[index] = selectedItem;
    return updatedList;
  });
};

  const Get_TaxType_TaxID = async (GST_Treatment, PlaceOfSupply) => {
    let { TaxType, TaxID, Customer_state_Code: CustomerStateCode } = state;
    let company_state = CompanyState;
  
    PlaceOfSupply = PlaceOfSupply || company_state;
  
    if (GST) {
      switch (GST_Treatment) {
        case "0":
        case "1":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2B";
            TaxID = 21;
          } else {
            TaxType = "GST Inter-state B2B";
            TaxID = 23;
          }
          break;
  
        case "5":
          TaxType = "GST Inter-state B2B";
          TaxID = 23;
          break;
  
        case "3":
        case "2":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
          break;
  
        case "4":
        case "6":
          TaxType = "Export";
          TaxID = 25;
          break;
  
        default:
          GST_Treatment = "2";
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
      }
    } else if (VAT) {
      TaxType = "VAT";
      TaxID = 32;
    }
    
    
    return [TaxType, TaxID, GST_Treatment];
  };
  
  const clearData = async () => {
    
    if (is_edit){
      navigate("/create-sales")
    }
    setIsButtonClick(false)
    
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SI",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas
    })
  
    setState(({
      ...state,
      AccountLedger:defaultValue.AccountLedger,
      CashAccount:defaultValue.CashAccount,
      BankAccount:defaultValue.BankAccount,
      // Employee :defaultValue.Employee,
      PriceCategory:defaultValue.PriceCategory,
      Warehouse:defaultValue.Warehouse,
      Treatment:defaultValue.Treatment,
      PlaceOfSupply:defaultValue.PlaceOfSupply,
      Date:DefaultDate,
      RefNo:"",    
      ShippingCharge:0,
      ShippingTax:defaultValue.ShippingTax,
      shipping_tax_amount:0,
      BillDiscPercent:0,
      BillDiscAmt:0,
      CashAmount:0,
      BankAmount:0,
      Notes:"",
      DueDate:DefaultDate,
      DeliveryDate:DefaultDate,
      //Total
      TotalQty:0,
      TotalGrossAmt:0,
      TotalDiscount:0,
      TotalTax:0,
      NetTotal:0,
      TotalTaxableAmount:0,
      TaxTaxableAmount:0,
      NonTaxTaxableAmount:0,
      GrandTotal:0,
      RoundOff:0,
      BillingAddress:null
    }))
    setIs_manual_roundoff(false)
    setDataLists([DataListItem,]);
    
  };
  
  function isSerialNoAndQtyDifferent() {
    let result = false;
  
    DataList.forEach(item => {
      const { Qty, SerialNos } = item;

      if (Qty !== "" && Qty !== SerialNos.length ) {
        result = true;
      }
    });
  
    return result;
  }

  function handleSerialNumberSave(){
   if (validationSerialNumber === 'Warning'){
    let isWarning = isSerialNoAndQtyDifferent()
      if (isWarning){
        setWarningMessage('You could not gave serial number more than qunatity')
        setIsWarningBox(true)
        return
      }
   }
   handleCloseSerial()
   return
  }


  function handleSerialNumberWarning(){
    handleCloseSerial()
    if(isBackendValidation){
      setIsForceSave(true)
    }
    setIsWarningBox(false)
  }

  function handleWarnigBoxClose(){
    setIsWarningBox(false)
    setIsForceSave(false)
    setIsBackendValidation(false)
  }


  function handleOpenSerialNumberModal(){
    setOpenSerial(true)
  }
  
  function handleErrorBoxClose(){
    setIsErrorBox(false)
  }
  
  const ChangeTableCustomization = async (type, name) => {
    let value = null;
    let new_name ;
    if (type === "tableSettings") {
      new_name = (name === "BarCode") ? "is_barcode" : name;
      value = !tableSettings[name];
      setTableSettings({ 
        ...tableSettings, 
        [name]: value 
      });
      await CustomiseTableApi({
        CompanyID : CompanyID,
        BranchID : BranchID,
        UserID : user_id,
        VoucherType : "SI",
        SettingsName : new_name,
        SettingsValue : value,
        Type : 'changes'
      })
    } else {
      value = !focusSettings[name];
      setFocusSettings({ 
        ...focusSettings, 
        [name]: value 
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  
  
  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }
  
  
  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator?dataState.SuffixSeparator:"";
    let Suffix = dataState.Suffix?dataState.Suffix:"";
    let VoucherNo = String(PreFix) + String(Seperator) + String(InvoiceNo) +String(SuffixSeparator) +String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  }
  
  const handleCustomerModalOpenClose = (state) => {
    if (state === false){
      clearCustomerDetails()
    }
    setCustomerModal(state)
  }

  const handleCustomerChange = (e) =>{
    let {name, value} = e.target

    if (name === 'customerName'){
      setCustomerDetails((prevState)=>({
        ...prevState,
        customerName:value,
        displayName:value
      }))
    }
    setCustomerDetails((prevState)=>({
      ...prevState,
      [name]:value
    }))
  }

  const clearCustomerDetails = () =>{
    setCustomerDetails((prevState)=>({
      ...prevState,
      customerName:"",
      displayName:"",
      phone:"",
      email:""
    }))
  }

  const isEmptyString = (input) => {
    return typeof input === 'string' && input.trim() === '';
}


  const handleCustomerCreate = async (e) => {
    e.preventDefault();

    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      PartyType: 1, //customer
      PartyImage:"",
      VAT_Treatment: 1,
      Country: Country,
      CountryName: CountryName,
      State: State,
      PartyName: customerDetails.customerName,
      DisplayName: customerDetails.displayName,
      Email: customerDetails.email,
      WorkPhone: customerDetails.phone,
      Mobile: customerDetails.phone,
      as_on_date: moment().format('YYYY-MM-DD'),
      isQuickCustomer: true,
      PriceCategoryID: 1,
      RouteID: 1,
      UpdationDelay: 0,
      OpeningBalance: 0,
      CrOrDr: 0,
      CreditLimit: 0,
      CreditPeriod: 0,
      CurrencyID: 0,
      IsVbAssist: false,
      IsActive: true,
      IsBillwiseApplicable: false,
      InterestOrNot: true,
      
      Address1:'',
      Address2:'',
      City:'',
      FirstName:'',
      LastName:'',
      Attention:'',
      PostalCode:'',
      OfficePhone:'',
      WebURL:''
    }

    let isError = false
    let message = ''
    
    if (isEmptyString(payload.PartyName)){
      isError = true
      message = 'customer name is required'
    }

    if (!isError){
      const createPartyResponse = await create_party(payload)
      if (createPartyResponse.StatusCode === 6000){
        dispatch(
          openSnackbar({
            open: true,
            message: "Customer created successfully",
            severity: "success",
          })
        );
        handleCustomerModalOpenClose(false)
        clearCustomerDetails()
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message || createPartyResponse.message || "Api Error",
            severity: "error",
          })
        );
      }}
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
    }
    
  }

  const handleWarningConfirmation = () =>{
    handleSaveAPISubmit()
  }

  //============useEffects============
  
  useEffect  (()=>{
    fetchData()
  },[])
  
  useEffect(()=>{
    if(isForceSave){
      handleSubmit()
    }
  },[isForceSave])

  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound, GST, CompanyState,RoundOffSales,Country);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);
  
  useEffect(() => {
    if(GST) {
      calculateData(dataState, state, DataList, customRound, GST, CompanyState, setDataLists);
    }
  }, [state.PlaceOfSupply,state.Treatment]);


  //giving a little time waiting for onDetailsInputChange function
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);
  
  
  
  //=============KEYBOARD SHORTCUTS===================== 
  const shortCutKeyPress = useCallback (async(event) => {
    //sales history
    if (event.altKey && (event.key === 'q' || event.keyCode === 81) && sales_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let SalesHistoryResponse = await Call_Product_History_api({
            Type:"Sales",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,SalesHistory:SalesHistoryResponse})
        }
      }
      setIsSalesHistory(true)
      setIsPurchaseHistory(false)
    }
    //purchase history
    else if (event.altKey && (event.key === 'w' || event.keyCode === 87 || event.keyCode === 119) && purchase_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let PurchaseHistoryResponse = await Call_Product_History_api({
            Type:"Purchase",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,PurchaseHistory:PurchaseHistoryResponse})
        }
      }
      setIsPurchaseHistory(true)
      setIsSalesHistory(false)
    }
    //closing modal
    else{
      setIsSalesHistory(false)
      setIsPurchaseHistory(false)
    }
    
  },[state.AccountLedger,DataList])
  
  useEffect(() => {
    window.addEventListener('keydown', shortCutKeyPress);
    return () => {
      window.removeEventListener('keydown', shortCutKeyPress);
    };
  }, [shortCutKeyPress ]);


  useEffect(()=>{
    onMasterInputChange(loadAccountLedger.search,'AccountLedger')
  },[loadAccountLedger.pageNo])


  console.log(state,'tabStatetabStatetabState',DataList);


  //======================================================

  // ==================== Currency Formatter============= //
  const Currency_Format = (num) => {
    if (num === null || num === undefined) return ""; 
    
    num = Number(num); 
    if (isNaN(num)) return "";
    
    const numStr = num.toString();
    
    let [integer, fraction] = numStr.split(".");
  
    if (fraction === undefined) {
      fraction = "00";
    } else {
      fraction = (fraction + "00").substring(0, 2); 
    }
    if (GeneralSettingsData.CurrencyFormat === "INR") {
     
      let lastThree = integer.substring(integer.length - 3);
      let otherNumbers = integer.substring(0, integer.length - 3);
    
      if (otherNumbers !== "") {
        lastThree = "," + lastThree;
      }
      otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      integer = otherNumbers + lastThree;
      return `${integer}.${fraction}`;
    
    } else if (GeneralSettingsData.CurrencyFormat === "SAR") {
      console.log("Formatting for SAR:", numStr);
      integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${integer}.${fraction}`;
    
    } else {
      // Default: no formatting
      return `${integer}.${fraction}`;
    }
  };
    // ==================== Currency Formatter============= //

  return (
    <>
      <Paper sx={{ height: "800px", width: "100%", overflow: "scroll" }}>
        {/*=================================== PART ONE==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Sales Invoice") : t("Create Sales Invoice")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ fontSize: 12, color: "text.primary" }}
              startIcon={<ImportExportIcon />}
            >
              Import
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{paddingTop:'4px', paddingBottom:'4px'}}
            >
              <MenuItem onClick={()=>handleExportModal(true,'import_order')} sx={{fontSize: '12px !important' }}><InsertDriveFileIcon sx={{marginRight:'4px',fontSize:'20px'}}/> Import from sales order</MenuItem>
              <MenuItem onClick={()=>handleExportModal(true,'import_estimate')} sx={{fontSize: '12px !important' }}><InsertDriveFileIcon sx={{marginRight:'4px',fontSize:'20px'}}/>Import from sales estimate</MenuItem>
              {/* <MenuItem onClick={()=>handleExportModal(true)}>Import from excel</MenuItem> */}
            </Menu>
            <ClearButton onClick={() => { clearData()}} t={t} />

            <ListButton
              onClick={() => {
                navigate("/list-sales");
              }}
              t={t}
            />

            {IsButtonClick ? <LoadingButton t={t} /> : <SaveButton onClick={() => handleSubmit()} t={t} />}
          </FlexBox>
        </Box>

        {/*=================================== PART TWO==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          {/* SEC - 1 */}
          <div className="sales-grid-item  give-10px-border-radious ">
            {/* customer search */}
            <InvoiceLedgerSearch 
              name={"AccountLedger"} 
              label={"Select Customer"} 
              optionLabel={"LedgerName"} 
              optionLabel2={"LedgerCode"} 
              OptionList={dataState.AccountLedgerData} 
              Value={state.AccountLedger}  
              OnChange={onMasterAutoChange} 
              OnInputChange={onMasterInputChange} 
              openBillingAddressModal={openBillingAddressModal} 
              BillingAddress={state.BillingAddress} 
              is_customer={is_customer} 
              GST={GST}
              setCustomerModal={setCustomerModal}  
              setLoadAccountLedger={setLoadAccountLedger}
              isSales={true} // load more and create customer now enabled only for sale if it add to all component remove this -- Anirudh
            />
          </div>

          {/* SEC - 2 */}

          <div className="sales-grid-item no-border-and-shadow">
            {/* shipping Address */}
            {is_customer && EnableShippingCharge === true?(
              <>
            <SelectShippingAddress state={state} setState={setState} setShippingAddress={setShippingAddress} ShippingAddress={ShippingAddress} Create_ShippingAddress={Create_ShippingAddress}  Value={state.ShippingAddress} open={IsShipping} setOpen={setIsShipping} />
             
             <div
              style={{
                display: EnableBillwise?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "150px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                DueDate:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DueDate"} label = {"Select a Date"} Value={state.DueDate} OnChange={onMasterChange} width={155} />
              </Typography>
            </div>
              </>
            ):null}
            
            
            
          </div>

          {/* SEC - 3 */}
          <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: EnableWarehouse?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Warehouse"} label = {"Select a Warehouse"} optionLabel = {"WarehouseName"} List = {dataState.WarehouseData} Value = {state.Warehouse} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
            <div
              style={{
                display: GeneralSettingsData.EnableEinvoiceKSA ? "none" : "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
             
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                {VAT ? "VAT Treatment:" : "GST Treatment:"}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete 
                  name={"Treatment"} 
                  label={"Select a Treatment"} 
                  optionLabel={"name"} 
                  List={VAT?VAT_TreatmentData: GST?GST_TreatmentData:[]} 
                  Value={state.Treatment} 
                  OnChange={onMasterAutoChange} 
                  OnInputChange={onMasterInputChange} 
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PlaceOfSupply"} label = {"Select a Place Of Supply"} optionLabel = {"Name"} List = {dataState.PlaceOfSupplyData} Value = {state.PlaceOfSupply} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange}  />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Employee"} label = {"Select a Employee"} optionLabel = {"FirstName"} List = {dataState.EmployeeData} Value = {state.Employee} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>

            {/* <button onClick={()=>{
              let data = DataList[0]
              let List = []
              for (let i=0;i<=300;i++){
                List.push(data)
              }
              setDataLists(List)
            }}>Click</button> */}

            <div
              style={{
                display: PriceCategory?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PriceCategory"} label = {"Select a Price Category"} optionLabel = {"PriceCategoryName"} List = {dataState.PriceCategoryData} Value = {state.PriceCategory} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
          </div>

          {/* SEC - 4 */}
          <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox name = {"VoucherNo"} label = {"Type a VoucherNo"} Value={dataState.VoucherNo} openCustomVoucher={openCustomVoucher} setCustomVoucher = {setopenCustomVoucher}  />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <CustomVoucher 
              open = {openCustomVoucher}
              setCustomVoucher = {setopenCustomVoucher}
              state={dataState}
              handleChange={handleChange}
              CustomVoucherSet = {CustomVoucherSet}
            />
              
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox disabled={EnableEinvoiceKSA} name = {"Date"} label = {"Select a Date"} Value={state.Date} OnChange={onMasterChange} />
              </Typography>
            </div>
            
            <div
              style={{
                display:  GeneralSettingsData.EnableEinvoiceKSA ?"none":"flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DeliveryDate"} label = {"Select a DeliveryDate"} Value={state.DeliveryDate} OnChange={onMasterChange} />
              </Typography>
            </div>
   
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox name = {"RefNo"} label = {"Type a RefNo"} Value={state.RefNo} OnChange={onMasterChange} placeholder={" Ref Bill No"} />
              </Typography>
            </div>
            <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', py:2}}>
              <Button startIcon={<AddIcon/>} onClick={handleOpenSerialNumberModal}> Add Serial No</Button>
            </Box>
          </div>
        </Box>
        {/*=================================== PART THREE==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ==========TABLE========== */}
          <MyGrid
            dataState = {dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges = {onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine = {AddLine}
            RemoveLine = {RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            missingMasterMandatory={missingMasterMandatory}
            openSnackbar={openSnackbar}
            ChangeTableCustomization={ChangeTableCustomization}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>
        
         {/*=================================== PART FOUR==================================== */}
        
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span style={{ whiteSpace: "nowrap", color: "#0A9EF3", margin: 0,fontSize:"13px" }}>
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap",fontSize:"13px" }}>Add By Barcode : </span>
            <InvoiceBarCodeTextBox onEnter ={addByBarCode} placeholder={"Add By Barcode"}/>
          </div>
        </Box>


        {/*=================================== PART Five==================================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>

            {/* -----------CASH PAYMENT---------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Cash Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"CashAccount"} label = {"Select a CashAccount"} optionLabel = {"LedgerName"} List = {dataState.CashList} Value = {state.CashAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"CashAmount"} label = {"Type a CashAmount"} Value={state.CashAmount} OnChange={onMasterChange} />
              </Typography>
            </div>

            {/* ---------BANK PAYMENT-------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Bank Account:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"BankAccount"} label = {"Select a BankAccount"} optionLabel = {"LedgerName"} List = {dataState.BankList} Value = {state.BankAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox  type={"number"} name = {"BankAmount"} label = {"Type a BankAmount"} Value={state.BankAmount} OnChange={onMasterChange} />
              </Typography>
            </div>

            {/* -------------------NOTES-------------*/}
            <br></br>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          {/* SHIPPING AND DISCOUNT */}
          <div>
            {/* Shipping */}

            <div
              style={{
                // display: EnableShippingCharge?"flex":"none",
                display:"none",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Shipping Charge:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Shipping Tax:
              </Typography>
            </div>

            <div
              style={{
                // display: EnableShippingCharge?"flex":"none",
                display:"none",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"ShippingCharge"} label = {"Type a ShippingCharge"} Value={state.ShippingCharge} OnChange={onMasterChange} />
              
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                  <InvoiceAutoComplete name = {"ShippingTax"} label = {"Select a ShippingTax"} optionLabel = {"TaxName"} List = {dataState.TaxCategoryData} Value = {state.ShippingTax} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
            {/* BILL DISCOUNT */}

            <br></br>

            <div
              style={{
                // display: GeneralSettingsData.EnableEinvoiceKSA ? "none" : "flex",
                display:"flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                // display: GeneralSettingsData.EnableEinvoiceKSA ? "none" : "flex",
                display:"flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"BillDiscPercent"} label = {"Type a BillDiscPercent"} Value={state.BillDiscPercent} OnChange={onMasterChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
              <InvoiceTextBox type={"number"} name = {"BillDiscAmt"} label = {"Type a BillDiscAmt"} Value={state.BillDiscAmt} OnChange={onMasterChange} />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

           <SummaryDetails onChange = {onMasterChange}  state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} EnableShippingCharge={EnableShippingCharge} Vat={VAT} Gst={GST} Currency_Format = {Currency_Format}/>
        </Box>
        
        {/* =======SHIPPING ADDRESS MODAL======== */}
        {EnableShippingCharge === true?(
        <AddressModal type={"Shipping"}  state={state} setState={setState} open={IsShipping} setOpen={setIsShipping} List = {ShippingAddress?.ShippingAddressList} name={"Shipping Address"} Address={ShippingAddress} setAddress={setShippingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_ShippingAddress} />
        ):null}
        {/* =======BILLING ADDRESS MODAL========= */}
        <AddressModal type={"Billing"}  state={state} setState={setState} open={IsBilling} setOpen={setIsBilling} List = {BillingAddress?.BillingAddressList} name={"Billing Address"} Address={BillingAddress} setAddress={setBillingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_BillingAddress} />
        {/* ============HISTORY MODALS============== */}
        <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
      
      </Paper>
      
      {progress > 0 ? (
        <LoaderLite message={"Please wait......." } fullscreen={true} />
      ) : null}
      <BottomNavigationMenu data={MenuBottomData}/>

      <Modal
        open={customerModal}
        onClose={()=>handleCustomerModalOpenClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <form onSubmit={handleCustomerCreate}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderBottom: "2px solid #F5F5F5",
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
              Add a Customer
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={()=>handleCustomerModalOpenClose(false)} />
            </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <VBInputField
                label="Customer Name"
                required
                name="customerName"
                value={customerDetails.customerName}
                onChange={(e) => {
                  handleCustomerChange(e)
                }
              }
              />
            </Grid> 
            <Grid item xs={6}>
            <VBInputField
                label="Display Name"
                required
                name="displayName"
                value={customerDetails.displayName}
                onChange={(e) => {
                  handleCustomerChange(e)
                }
              }
              />
            </Grid>
            <Grid item xs={6}>
              <VBInputField
                label="Phone No"
                name="phone"
                type="tel"
                value={customerDetails.phone}
                onChange={(e) => {
                  handleCustomerChange(e)
                }
              }
              />
            </Grid> 
            <Grid item xs={6}>
            <VBInputField
                label="Email"
                type="email"
                name="email"
                value={customerDetails.email}
                onChange={(e) => {
                  handleCustomerChange(e)
                }
              }
              />
            </Grid>
            <Grid item xs={6}>
              <Button onClick={()=>navigate('/create-customer')}>Add More Details</Button>
            </Grid>
            <Grid item xs={6} sx={{display:'flex', justifyContent:'end'}}>
              <Button variant="contained" type="submit">Save</Button>
            </Grid>
          </Grid>
          </form>
        </Box>
      </Modal>
      <ConfirmBox
        heading={"Warnig"}
        message={"Rate of product less then the Min sales price."}
        open={warningBox}
        setOpen={setWarningBox}
        confirmFunction={handleWarningConfirmation}
      />
      <ImportAndExport 
        open={exportModalOpen}
        handleExportModal={handleExportModal}
        tabState={tabState}
        handleTabChange={handleTabChange}
        handleImportFromVoucherNo={handleImportFromVoucherNo}
        heading={importHeading}
        importStates={importStates}
        handleImportTextChange={handleImportTextChange}
        importListStates={importListStates}
        handleCustomerSelect={handleCustomerSelect}
        handleCustomerFilterInputChange={handleCustomerFilterInputChange}
        setImportStates={setImportStates}
        handleCustomerFilter={handleCustomerFilter}
        selectItems={selectItems}
        handleImportFromCustomer={handleImportFromCustomer}
      />

{/*       
      <Modal
        open={exportModalOpen}
        onClose={()=>handleExportModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={exportModalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderBottom: "2px solid #F5F5F5",
              p:2
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: "600", pb:'12px'}}>
              Import from sales order
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={()=>handleExportModal(false)} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={tabState}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab value="orderNo" label="orderNo"/>
              <Tab value="customer" label="Customer" />
            </Tabs>
            <Divider/>
          </Box>
            {tabState === 'orderNo' ? 
              <Box sx={{p:'20px'}}>
                <form onSubmit={handleImportFromVoucherNo}>
                  <Typography sx={{pb:'12px'}}>Order No</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <VBInputField
                        name="orderNo"
                        value={importStates.orderNo}
                        onChange={(e) => {
                          handleImportTextChange(e)
                          }
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant="contained" type="submit" sx={{width:'100%'}}>Add</Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
              :
              <Box>
                <Box sx={{p:'20px'}}>
                  <form onSubmit={handleCustomerFilter}>
                    <Typography sx={{pb:'12px'}}>Customer</Typography>
                    <Grid container spacing={2} >
                      <Grid item xs={3}>
                        <ViknAutoComplete
                          placeholder={"Customer"}
                          value={importStates.customer}
                          options={importListStates.customerList}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newValue) => handleCustomerSelect(e, newValue)}
                          onInputChange={(e, newValue, type) => {
                            if (type === "input") {
                                handleCustomerFilterInputChange(newValue);
                                // setSearch(newInputValue)
                              }
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <BasicDatePicker 
                          value={importStates.fromDate}
                          onChange={(newValue) => 
                            setImportStates((prevState) => ({
                              ...prevState,
                              fromDate:newValue
                            }))
                        }/>
                      </Grid>
                      <Grid item xs={3}>
                        <BasicDatePicker 
                          value={importStates.toDate}
                          onChange={(newValue) => 
                            setImportStates((prevState) => ({
                              ...prevState,
                              toDate:newValue
                            }))
                        }/>
                      </Grid>
                      <Grid item xs={3}>
                        <Button variant="contained" type="submit" sx={{width:'100%'}} >Search</Button>
                      </Grid>    
                    </Grid>
                  </form>
                </Box>
                {importListStates.ImportDataList.length > 0 &&
                  <>
                    <Divider sx={{pt:'16px'}}/>
                    <TableContainer sx={{ maxHeight: 350 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <CustomTableCell>
                              <Checkbox 
                                size="small"
                                checked={state.isSelectedAll} 
                                onChange={() => {
                                  selectItems("all");
                                }}
                              />
                            </CustomTableCell>
                            <CustomTableCell>Voucher No</CustomTableCell>
                            <CustomTableCell>Date</CustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {importListStates.ImportDataList.map((item, index) => 
                          <>
                            <TableRow>
                              <CustomTableCell>
                                <Checkbox 
                                  size="small"
                                  checked={
                                    importListStates.selectedItems.find(data => data.VoucherNo === item.VoucherNo) ? true : false
                                  }
                                  onChange={() => {
                                    selectItems("not", item.VoucherNo);
                                  }}
                                /></CustomTableCell>
                              <CustomTableCell>{item.VoucherNo}</CustomTableCell>
                              <CustomTableCell>{item.Date}</CustomTableCell>
                            </TableRow>
                          </>
                          )
                        }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box sx={{display:'flex', justifyContent:'end', p:'16px'}}>
                      <Button variant="contained" type="submit" sx={{ width:'100px', mt:'16px'}} onClick={handleImportFromCustomer}>Add</Button>
                    </Box>
                  </>
                }
              </Box>
            }
          </Box>
      </Modal> */}

      <ConfirmBox
        heading={""}
        message={"This estimate has already been invoiced. Would you like to continue.!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={handleSaveAPISubmit}
      />

      <Serialnumbercomponent 
        openSerial={openSerial}
        handleOpenSerial={handleOpenSerial}
        handleCloseSerial={handleCloseSerial}
        dataState = {dataState}
        state={state}
        DataList={DataList}
        setDataLists={setDataLists}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        focusSettings={focusSettings}
        setFocusSettings={setFocusSettings}
        handleInputChange={onDetailsInputChange}
        handleCompleteChanges = {onDetailsAutoChange}
        handleChanges={onDetailsChange}
        AddLine = {AddLine}
        RemoveLine = {RemoveLine}
        PriceDecimalPoint={PriceDecimalPoint}
        Errors={Errors}
        missingMasterMandatory={missingMasterMandatory}
        openSnackbar={openSnackbar}
        ChangeTableCustomization={ChangeTableCustomization}
        debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
        GeneralSettingsData={GeneralSettingsData}
        RemoveSerialNo={RemoveSerialNo}
        handleSerialNoAdd={handleSerialNoAdd}
        handleSerialNumberSave={handleSerialNumberSave}
      />
      <Dialog
        open={IsWarningBox}
        onClose={handleWarnigBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Warning
        </DialogTitle>
        <DialogContent sx={{maxWidth:'450px', overflow:'hidden'}}>
          <DialogContentText id="alert-dialog-description">
            {warningMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarnigBoxClose}>Cancel</Button>
          <Button onClick={handleSerialNumberWarning} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* error box */}
      <Dialog
        open={IsErrorBox}
        onClose={handleErrorBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorBoxClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SalesInvoice;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:2
};

const exportModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:2,
  width:'600px'
};

const CustomTableCell = styled(TableCell)(() => ({
  padding: '8px',
}));