import axios from "axios";
import { download_excel } from "../Components/ReportTable/ReportTable";
import { BASE_URL } from "../generalVeriable";
import { objectToQueryString } from "../generalFunction";

const downloadReport = function (
  params = {
    columns: "",
    ReffNo: "",
    CompanyID: "",
    FromDate: "",
    ToDate: "",
    BranchID: "",
    PriceListID: "",
    UserID: "",
    LedgerID: "",
    key: "",
    download_type: "",
    ManualOpeningStock: "",
    ManualClosingStock: "",
    EmployeeID: "",
    invoice_type: "",
    PriceRounding: "",
    ManualOpeningBalance: "",
    value: "",
    VoucherType: "",
    WarehouseID: "",
    WareHouseFrom: "",
    WareHouseTo: "",
    RouteID: "",
    ProductFilter: "",
    StockFilter: "",
    ProductID: "",
    Barcode: "",
    CategoryID: "",
    BrandID: "",
    GroupID: "",
    lang: "",
    CashLedgers: "",
    CustomerID: "",
    PartyID: "",
    AsOfDate: "",
    BatchCode: "",
    SerialNo: "",
    ItemCode: "",
    SupplierID: "",
    filterValue: "",
    templateType: "",
    LedgerList: "",
    RouteLedgers: "",
    is_manualOpening: "",
    is_send_mail: "",
    PartyType: "",
    is_all_branch: "",
  }
) {
  axios
    .get("api/v11/reports/download-report/", {
      params: params,
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.pdf'; // Set the file name for download
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error(error);
    });
};

const exportTaxSummaryReportExcel = ({ FromDate, ToDate, Type,CompanyID,BranchID,PriceRounding,lang }) => {
  const params = {
    CompanyID: CompanyID,
    BranchID: BranchID,
    PriceRounding:PriceRounding,
    FromDate: FromDate,
    ToDate: ToDate,
    Type: "GST",
    lang:lang
  };

  if (Type === "GST") {
    params.Type = "GST";
  } else if (Type === "VAT") {
    params.Type = "VAT";
  }
  // let link = "https://www.api.viknbooks.com/api/v10/sales/taxSummary-report-excel/?CompanyID=199909ce-3f3c-435d-a4a6-d1bc1ea95a4e&BranchID=1&PriceRounding=2&FromDate=2024-04-04&ToDate=2024-04-04&Type=VAT"
  const queryString = objectToQueryString(params);
  const url = `${BASE_URL + "sales/taxSummary-report-excel/?"}${queryString}`;
  download_excel(url);
  // axios
  // .get("api/v10/sales/taxSummary-report-excel/", {
  //   params: params,
  // })
  // .then((response) => {
  //   if (response.status === 200) {
  //     console.log(response);
  //     download_excel(response.request.responseURL);
  //   } else {
  //     console.log("ERROR");
  //   }
  // })
  // .catch((error) => {
  //   console.error(error);
  // });
};

export { downloadReport, exportTaxSummaryReportExcel };

// downloadReport({
//     download_type: "PDF",
//     invoice_type: Company_Type === "GST" ? "tax_summary_gst" : "tax_summary_vat",
//     FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
//     ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
//   })

// excelFun={() => exportFun("excel")}
// pdfFun={() => exportFun("pdf")}
