import { Box, Button, Checkbox, Divider, Grid, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import React from "react";
import ViknAutoComplete from "../Utils/ViknAutoComplete";
import { BasicDatePicker } from "../CommonComponents";
import CloseIcon from "@mui/icons-material/Close";
import VBInputField from "../Utils/VBInputField";
import styled from "@emotion/styled";


const ImportAndExport = ({
    open,
    handleExportModal,
    tabState,
    handleTabChange,
    handleImportFromVoucherNo,
    importStates,
    handleImportTextChange,
    handleCustomerFilter,
    importListStates,
    handleCustomerSelect,
    handleCustomerFilterInputChange,
    setImportStates,
    state,
    selectItems,
    handleImportFromCustomer,
    heading,

}) => {
  return (
    <Modal
      open={open}
      onClose={() => handleExportModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={exportModalStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            borderBottom: "2px solid #F5F5F5",
            p: 2,
          }}
        >
          <Typography sx={{ fontSize: "17px", fontWeight: "500", pb: "12px" }}>
            {heading}
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => handleExportModal(false)}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabState}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab value="voucherNo" label="Voucher No" />
            <Tab value="customer" label="Customer" />
          </Tabs>
          <Divider />
        </Box>
        {tabState === 'voucherNo'  ? (
          <Box sx={{ p: "20px" }}>
            <form onSubmit={handleImportFromVoucherNo}>
              <Typography sx={{ pb: "12px" }}>{importStates?.invoiceType === 'salesOrder' ? 'Order No' : 'Estimate No'}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <VBInputField
                    name="voucherNo"
                    value={importStates?.voucherNo}
                    onChange={(e) => {
                      handleImportTextChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "100%" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        ) : (
          <Box>
            <Box sx={{ p: "20px" }}>
              <form onSubmit={handleCustomerFilter}>
                <Typography sx={{ pb: "12px" }}>Customer</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <ViknAutoComplete
                      placeholder={"Customer"}
                      value={importStates?.customer}
                      options={importListStates?.customerList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, newValue) =>
                        handleCustomerSelect(e, newValue)
                      }
                      onInputChange={(e, newValue, type) => {
                        if (type === "input") {
                          handleCustomerFilterInputChange(newValue);
                          // setSearch(newInputValue)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BasicDatePicker
                      value={importStates?.fromDate}
                      onChange={(newValue) =>
                        setImportStates((prevState) => ({
                          ...prevState,
                          fromDate: newValue,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BasicDatePicker
                      value={importStates?.toDate}
                      onChange={(newValue) =>
                        setImportStates((prevState) => ({
                          ...prevState,
                          toDate: newValue,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ width: "100%" }}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
            {importListStates?.ImportDataList.length > 0 && (
              <>
                <Divider sx={{ pt: "16px" }} />
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>
                          <Checkbox
                            size="small"
                            checked={state?.isSelectedAll}
                            onChange={() => {
                              selectItems("all");
                            }}
                          />
                        </CustomTableCell>
                        <CustomTableCell>Voucher No</CustomTableCell>
                        <CustomTableCell>Date</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {importListStates?.ImportDataList.map(
                        (item, index) => (
                          <>
                            <TableRow>
                              <CustomTableCell>
                                <Checkbox
                                  size="small"
                                  checked={
                                    importListStates?.selectedItems.find(
                                      (data) =>
                                        data.VoucherNo === item.VoucherNo
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    selectItems("not", item.VoucherNo);
                                  }}
                                />
                              </CustomTableCell>
                              <CustomTableCell>
                                {item.VoucherNo}
                              </CustomTableCell>
                              <CustomTableCell>{item.Date}</CustomTableCell>
                            </TableRow>
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ display: "flex", justifyContent: "end", p: "16px" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px", mt: "16px" }}
                    onClick={handleImportFromCustomer}
                  >
                    Add
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const exportModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:2,
    width:'600px'
  };

const CustomTableCell = styled(TableCell)(() => ({
padding: '8px',
}));

export default ImportAndExport;
