import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../../Components/ReportTable/ReportTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatNumber } from "../../../../../generalFunction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Collapse icon from Material-UI
import { BorderRight } from "@mui/icons-material";

// const ExpandTable = function (props) {
//   // const [expanded, setExpanded] = useState("panel" + props.index +1)
//   return (
//     <VBTableCellBody colspan="5" sx={{ padding: 0, paddingBottom: "10px" }}>
//       <Accordion
//         // expanded={props.expanded === `panel${props.index+1}`}
//         expanded={props.expanded.includes(`panel${props.index + 1}`)}
//         onChange={props.handleChange(`panel${props.index + 1}`)}
//       >
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel1-content"
//           IconButtonProps={{ edge: 'start' }}
//           id="panel1-header"
//           disableSpacing={false}
//           sx={{
//             pl: 0,
//             minHeight: "auto",
//             backgroundColor: "#cac6c6",
//             "&.MuiAccordionSummary-root": {
//               minHeight: "10px", // Adjust the minHeight as needed
//             },
//             ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
//               my: "0px",
//             },
//             ".MuiAccordionSummary-content": {
//               my: 0,
//             },
//             // '.Mui-expanded .MuiAccordionSummary-content': {
//             //   // Customize the margin only when expanded
//             //   my: 0,
//             // },
//           }}
//         >
//           {/* {props.name} */}
//           <Table sx={{ width: "100%" }}>
//             <TableBody>
//               <TableRow>
//                 <VBTableCellBody sx={{ width: "10.42%" }}>
//                   {" "}
//                   {props.index}
//                 </VBTableCellBody>
//                 <VBTableCellBody sx={{ width: "13.76%" }}>
//                   {props.name}
//                 </VBTableCellBody>
//                 <VBTableCellBody sx={{ width: "23.99%" }}>
//                   <table style={{ width: "100%" }}>
//                     <tr>
//                       <td>{formatNumber(props.OpeningDebit)}</td>
//                       <td style={{ textAlign: "right" }}>
//                         {formatNumber(props.OpeningCredit)}
//                       </td>
//                     </tr>
//                   </table>
//                 </VBTableCellBody>
//                 <VBTableCellBody sx={{ width: "19.75%" }}>
//                   <table style={{ width: "100%" }}>
//                     <tr>
//                       <td>{formatNumber(props.TotalDebit)}</td>
//                       <td style={{ textAlign: "right" }}>
//                         {formatNumber(props.TotalCredit)}
//                       </td>
//                     </tr>
//                   </table>
//                 </VBTableCellBody>
//                 <VBTableCellBody sx={{ width: "18.2%" }}>
//                   <table style={{ width: "100%" }}>
//                     <tr>
//                       <td>{formatNumber(props.ClosingDebit)}</td>
//                       <td style={{ textAlign: "right" }}>
//                         {formatNumber(props.ClosingCredit)}
//                       </td>
//                     </tr>
//                   </table>
//                 </VBTableCellBody>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </AccordionSummary>
//         <AccordionDetails sx={{ p: 0 }}>
//           <VBTableContainer sx={{ width: "100%" }}>
//             <TableBody>
//               {props.data.map((e, i) => (
//                 <TableRow>
//                   <VBTableCellBody sx={{ width: "10.42%" }}>
//                     <span> {i + 1} </span>
//                   </VBTableCellBody>
//                   <VBTableCellBody sx={{ width: "13.76%" }}>
//                     {e.LedgerName}
//                   </VBTableCellBody>
//                   <VBTableCellBody sx={{ width: "23.99%" }}>
//                     <table style={{ width: "100%" }}>
//                       <tr>
//                         <td>{formatNumber(e.OpeningDebit)}</td>
//                         <td style={{ textAlign: "right" }}>
//                           {formatNumber(e.OpeningCredit)}
//                         </td>
//                       </tr>
//                     </table>
//                   </VBTableCellBody>
//                   <VBTableCellBody sx={{ width: "19.75%" }}>
//                     <table style={{ width: "100%" }}>
//                       <tr>
//                         <td>{formatNumber(e.TotalDebit)}</td>
//                         <td style={{ textAlign: "right" }}>{formatNumber(e.TotalCredit)}</td>
//                       </tr>
//                     </table>
//                   </VBTableCellBody>
//                   <VBTableCellBody sx={{ width: "21.18%" }}>
//                     <table style={{ width: "100%" }}>
//                       <tr>
//                         <td>{formatNumber(e.ClosingDebit)}</td>
//                         <td style={{ textAlign: "right" }}>
//                           {formatNumber(e.ClosingCredit)}
//                         </td>
//                       </tr>
//                     </table>
//                   </VBTableCellBody>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </VBTableContainer>
//         </AccordionDetails>
//       </Accordion>
//     </VBTableCellBody>
//   );
// };

// export const ClosingTrialBalanceTable = ({
//   filterData,
//   expanded,
//   handleChangeExpand,
//   isLoadiing
// }) => {
//   const [grandTotal, setGrandTotal] = useState({
//     totalCredit: "",
//     totalDebit: "",
//     openingDebit: "",
//     openingCredit: "",
//     closingDebit: "",
//     closingCredit: "",
//   });
//   const [t, i18n] = useTranslation("common");
//   const calculateTotals = () => {

//     // Calculate OpeningDebitTotal and ClosingDebitTotal
//     filterData.trileBalanceList.forEach(item => {
//       let OpeningDebitTotal, OpeningCreditTotal, ClosingCreditTotal, ClosingDebitTotal
//       OpeningDebitTotal = item.data.reduce((total, current) => total + current.OpeningDebit, 0);
//       OpeningCreditTotal = item.data.reduce((total, current) => total + current.OpeningCredit, 0);
//       ClosingCreditTotal = item.data.reduce((total, current) => total + current.ClosingCredit, 0);
//       ClosingDebitTotal = item.data.reduce((total, current) => total + current.ClosingDebit, 0);
//         item.OpeningCredit = OpeningCreditTotal 
//         item.OpeningDebit = OpeningDebitTotal
//         item.ClosingCredit = ClosingCreditTotal 
//         item.ClosingDebit = ClosingDebitTotal 
//       // if (OpeningCreditTotal > OpeningDebitTotal) {
//       //   item.OpeningDebit = 0
//       //   item.OpeningCredit = OpeningCreditTotal - OpeningDebitTotal
//       // } else {
//       //   item.OpeningDebit = OpeningDebitTotal - OpeningCreditTotal
//       //   item.OpeningCredit = 0
//       // }

//       // if (ClosingCreditTotal > ClosingDebitTotal) {
//       //   item.ClosingDebit = 0
//       //   item.ClosingCredit = ClosingCreditTotal - ClosingDebitTotal
//       // } else {
//       //   item.ClosingDebit = ClosingDebitTotal - ClosingCreditTotal
//       //   item.ClosingCredit = 0
//       // }
    
//     });

//     const { totalDebit,
//       totalCredit,
//       openingDebit,
//       openingCredit,
//       closingDebit,
//       closingCredit } = filterData.trileBalanceList.reduce(
//       (accumulator, currentItem) => {

//         return {
//           openingDebit: accumulator.openingDebit + currentItem.OpeningDebit,
//           openingCredit: accumulator.openingCredit + currentItem.OpeningCredit,
//           closingDebit: accumulator.closingDebit + currentItem.ClosingDebit,
//           closingCredit: accumulator.closingCredit + currentItem.ClosingCredit,
//           totalDebit: accumulator.totalDebit + currentItem.TotalDebit,
//           totalCredit: accumulator.totalCredit + currentItem.TotalCredit,
//         };
//       },
//       { 
//         totalDebit: 0, 
//         totalCredit: 0, 
//         openingDebit: 0,
//         openingCredit: 0,
//         closingDebit: 0,
//         closingCredit: 0, 
//       }
//     );
//     setGrandTotal({ 
//       totalDebit,
//       totalCredit,
//       openingDebit,
//       openingCredit,
//       closingDebit,
//       closingCredit,
//     });
//       console.log(totalDebit,
//     totalCredit,
//     openingDebit,
//     openingCredit,
//     closingDebit,
//     closingCredit);
//   };

// console.log(filterData.trileBalanceList);

//   useEffect(() => {
//     calculateTotals();
//   }, [filterData.trileBalanceList]);
//   return (
//     <VBTableContainer>
//       <VBTableHeader>
//         <VBTableCellHeader sx={{ fontWeight: "bold", width: "10.62%" }}>{t("Serial No")} </VBTableCellHeader>
//         <VBTableCellHeader sx={{ fontWeight: "bold", width: "13.76%" }}>{t("Ledger Name")} </VBTableCellHeader>
//         <VBTableCellHeader sx={{ width: "23.99%" }}>
//           <table style={{ width: "100%" }}>
//             <tr>
//               <th colSpan={2} style={{ textAlign: "center" }}>
//                 {t("Opening Balance")}{" "}
//               </th>
//             </tr>
//             <tr>
//               <td>{t("Debit")}</td>
//               <td style={{ textAlign: "right" }}>{t("Credit")}</td>
//             </tr>
//           </table>
//         </VBTableCellHeader>
//         <VBTableCellHeader sx={{ width: "19.75%" }}>
//           <table style={{ width: "100%" }}>
//             <tr>
//               <th colSpan={2} style={{ textAlign: "center" }}>
//                 {t("Transactions")}{" "}
//               </th>
//             </tr>
//             <tr>
//               <td>{t("Debit")}</td>
//               <td style={{ textAlign: "right" }}>{t("Credit")}</td>
//             </tr>
//           </table>
//         </VBTableCellHeader>
//         <VBTableCellHeader sx={{ width: "21.2%" }}>
//           <table style={{ width: "100%" }}>
//             <tr>
//               <th colSpan={2} style={{ textAlign: "center" }}>
//                 {t("Closing Balance")}{" "}
//               </th>
//             </tr>
//             <tr>
//               <td>{t("Debit")}</td>
//               <td style={{ textAlign: "right" }}>{t("Credit")}</td>
//             </tr>
//           </table>
//         </VBTableCellHeader>
//       </VBTableHeader>
//       {isLoadiing ? <VBSkeletonLoader /> : 
//           <TableBody>
//             {filterData.trileBalanceList.map((e, i) => (
//               <TableRow>
//                 <ExpandTable
//                   expanded={expanded}
//                   handleChange={handleChangeExpand}
//                   index={i + 1}
//                   name={e.AccountGroupName}
//                   data={e.data}
//                   TotalCredit={e.TotalCredit}
//                   TotalDebit={e.TotalDebit}
//                   OpeningCredit={e.OpeningCredit}
//                   OpeningDebit={e.OpeningDebit}
//                   ClosingCredit={e.ClosingCredit}
//                   ClosingDebit={e.ClosingDebit}
//                 />
//           </TableRow>
//         ))}
//         <TableRow></TableRow>
//       </TableBody>}
//       <VBTableFooter>
//         <TableRow>
//           <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
//           <VBTableCellFooter></VBTableCellFooter>
//           <VBTableCellFooter>
//             <table style={{ width: "100%" }}>
//               <tr>
//                 <td>{formatNumber(grandTotal.openingDebit)}</td>
//                 <td style={{ textAlign: "right" }}>{formatNumber(grandTotal.openingCredit)}</td>
//               </tr>
//             </table>
//           </VBTableCellFooter>
//           <VBTableCellFooter>
//             <table style={{ width: "100%" }}>
//               <tr>
//                 <td>{formatNumber(grandTotal.totalDebit)}</td>
//                 <td style={{ textAlign: "right" }}>{formatNumber(grandTotal.totalCredit)}</td>
//               </tr>
//             </table>
//           </VBTableCellFooter>
//           <VBTableCellFooter>
//             <table style={{ width: "100%" }}>
//               <tr>
//                 <td>{formatNumber(grandTotal.closingDebit)}</td>
//                 <td style={{ textAlign: "right" }}>{formatNumber(grandTotal.closingCredit)}</td>
//               </tr>
//             </table>
//           </VBTableCellFooter>
//         </TableRow>
//       </VBTableFooter>
//     </VBTableContainer>
//   );
// };


export const ClosingTrialBalanceTable = ({
  filterData,
  isLoading
}) => {
  const [expanded, setExpanded] = useState(null);  // To manage open/close state
  const [grandTotal, setGrandTotal] = useState({
    totalCredit: 0,
    totalDebit: 0,
    openingDebit: 0,
    openingCredit: 0,
    closingDebit: 0,
    closingCredit: 0,
  });

  const [t] = useTranslation("common");

  const calculateTotals = () => {
    filterData.trileBalanceList.forEach(item => {
      item.OpeningDebit = item.data.reduce((total, current) => total + current.OpeningDebit, 0);
      item.OpeningCredit = item.data.reduce((total, current) => total + current.OpeningCredit, 0);
      item.ClosingDebit = item.data.reduce((total, current) => total + current.ClosingDebit, 0);
      item.ClosingCredit = item.data.reduce((total, current) => total + current.ClosingCredit, 0);
    });
console.log(grandTotal.closingCredit,"grandTotal.closingCredit------------------------");

    const totals = filterData.trileBalanceList.reduce((acc, item) => {
      return {
        openingDebit: acc.openingDebit + item.OpeningDebit,
        openingCredit: acc.openingCredit + item.OpeningCredit,
        closingDebit: acc.closingDebit + item.ClosingDebit,
        closingCredit: acc.closingCredit + item.ClosingCredit,
        totalDebit: acc.totalDebit + item.TotalDebit,
        totalCredit: acc.totalCredit + item.TotalCredit,
      };
    }, {
      totalDebit: 0, 
      totalCredit: 0, 
      openingDebit: 0,
      openingCredit: 0,
      closingDebit: 0,
      closingCredit: 0, 
    });

    setGrandTotal(totals);
  };

  useEffect(() => {
    calculateTotals();
  }, [filterData.trileBalanceList]);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle open/close
  };
console.log(isLoading,"isLoading151dv-----------------");

  return (
    <VBTableContainer>
      <VBTableHeader>
        <VBTableCellHeader sx={{ borderRight:"1px solid #cac6c6",width:"92px"}} >{t("Serial No")}</VBTableCellHeader>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",}}>{t("Ledger Name")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "center",borderRight:"1px solid #cac6c6",borderBottom:"1px solid #cac6c6" }} colSpan={2}>{t("Opening Balance")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "center",borderRight:"1px solid #cac6c6",borderBottom:"1px solid #cac6c6" }} colSpan={2}>{t("Transactions")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "center",borderBottom:"1px solid #cac6c6" }} colSpan={2}>{t("Closing Balance")}</VBTableCellHeader>
        <VBTableCellHeader > {"  "} </VBTableCellHeader>
      </VBTableHeader>
      <TableRow>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",}}>{"  "}</VBTableCellHeader>
        <VBTableCellHeader sx={{borderRight:"1px solid #cac6c6",}}>{"  "}</VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Debit")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ borderRight:"1px solid #cac6c6", textAlign: "right" }}>{t("Credit")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ borderRight:"1px solid #cac6c6", textAlign: "right" }}>{t("Debit")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ borderRight:"1px solid #cac6c6", textAlign: "right" }}>{t("Credit")}</VBTableCellHeader>
        <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Debit")}</VBTableCellHeader>
        <VBTableCellHeader sx={{  textAlign: "right" }}>{t("Credit")}</VBTableCellHeader>
        <VBTableCellHeader > {" "} </VBTableCellHeader>
      </TableRow>

      {isLoading ? <VBSkeletonLoader /> : 
        <TableBody>
          {filterData.trileBalanceList.map((e, i) => (
            <React.Fragment key={i}>
              {/* Main Account Row */}
              <TableRow onClick={() => handleToggle(i)} >
                <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#b9e2fe":"",fontSize: "15px",paddingLeft: "20px", cursor: 'pointer' }}>{i + 1}</VBTableCellBody>
                <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#b9e2fe":"",fontSize: "15px" }}>{e.AccountGroupName}</VBTableCellBody> {/* Reduced font size */}
                <VBTableCellBody sx={{ backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.OpeningDebit)}</VBTableCellBody>
                <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.OpeningCredit)}</VBTableCellBody>
                <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.TotalDebit)}</VBTableCellBody>
                <VBTableCellBody sx={{ borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.TotalCredit)}</VBTableCellBody>
                <VBTableCellBody sx={{ backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.ClosingDebit)}</VBTableCellBody>
                <VBTableCellBody sx={{ backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" }}>{formatNumber(e.ClosingCredit)}</VBTableCellBody>
                <VBTableCellBody sx={{ backgroundColor:expanded === i?"#b9e2fe":"",textAlign: "right" ,transition: 'transform 0.3s ease', width:"15px",}}>
                    <ExpandLessIcon sx={{ color: '#0a9ef3' ,marginLeft: 'auto', display: 'inline-block',transition: 'transform 0.3s ease', transform:expanded === i ? 'rotate(0deg)' : 'rotate(180deg)', }} /> 
                </VBTableCellBody>
              </TableRow>

              {/* Data Rows under this account with toggle (open/close) */}
              {expanded === i && e.data.map((item, idx) => (
                <TableRow key={`data-${idx}`}>
                  <VBTableCellBody sx={{borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", paddingRight: "40px" ,textAlign: "right",}}>{idx + 1}</VBTableCellBody> {/* Indentation */}
                  <VBTableCellBody sx={{borderRight:"1px solid #cac6c6",backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px",}}>{item.LedgerName}</VBTableCellBody>
                  <VBTableCellBody sx={{backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.OpeningDebit)}</VBTableCellBody>
                  <VBTableCellBody sx={{borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.OpeningCredit)}</VBTableCellBody>
                  <VBTableCellBody sx={{borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.TotalDebit)}</VBTableCellBody>
                  <VBTableCellBody sx={{borderRight:"1px solid #cac6c6", backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.TotalCredit)}</VBTableCellBody>
                  <VBTableCellBody sx={{backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.ClosingDebit)}</VBTableCellBody>
                  <VBTableCellBody sx={{ backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{formatNumber(item.ClosingCredit)}</VBTableCellBody>
                  <VBTableCellBody sx={{ backgroundColor:expanded === i?"#dff0ff":"",fontSize: "13px", textAlign: "right" }}>{" "}</VBTableCellBody>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      }

      <VBTableFooter>
        <TableRow>
          <VBTableCellFooter sx={{borderRight:"1px solid #cac6c6",}}>{t("Total")}</VBTableCellFooter>
          <VBTableCellFooter sx={{borderRight:"1px solid #cac6c6",}}></VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }}>{grandTotal?.openingDebit?formatNumber(grandTotal.openingDebit):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{ borderRight:"1px solid #cac6c6",textAlign: "right" }}>{grandTotal?.openingCredit?formatNumber(grandTotal.openingCredit):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{ borderRight:"1px solid #cac6c6",textAlign: "right" }}>{grandTotal?.totalDebit?formatNumber(grandTotal.totalDebit0):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{borderRight:"1px solid #cac6c6", textAlign: "right" }}>{grandTotal?.totalCredit?formatNumber(grandTotal.totalCredit0):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }}>{grandTotal?.closingDebit > 0 ?formatNumber(grandTotal.closingDebit):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }}>{grandTotal?.closingCredit > 0 ?formatNumber(grandTotal.closingCredit):"-"}</VBTableCellFooter>
          <VBTableCellFooter sx={{ textAlign: "right" }}></VBTableCellFooter>
        </TableRow>
      </VBTableFooter>
    </VBTableContainer>
  );
};
