import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";

const baseurl = "api/v11/activity-log/";

export const activityLogHistoryAPi = async function (body, dispatch) {
  try {
    const { data } = await axios.post(baseurl + "get-history/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("Error in activity log api", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

export const activityLogDetailedHistoryAPi = async function (body, dispatch) {
  try {
    const { data } = await axios.post(baseurl + "get-detailed-history/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("Error in activity log detailed api", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

export const getInvoiceHistoryAPi = async function (body, dispatch) {
  try {
    const { data } = await axios.post(baseurl + "get-invoice-history/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("Error in invoice History Api", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};
