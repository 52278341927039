import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import Pagenation from "../../Components/Utils/Pagenation";

import {
  activityLogDetailedHistoryAPi,
  activityLogHistoryAPi,
  getInvoiceHistoryAPi,
} from "../../Api/ActivityLog/ActivityLogAPis";

import { useDispatch, useSelector } from "react-redux";
import ViknAutoComplete from "../../Components/Utils/ViknAutoComplete";
import DetailTemplate from "./component/DetailTemplate";
import { openSnackbar } from "../../features/SnackBarslice";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import moment from "moment";

function ActivityLog() {
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const dispatch = useDispatch();

  const invoiceType = [
    { name: "Sales", value: "sales" },
    { name: "Purchase", value: "purchase" },
    { name: "Sales Order", value: "sales_order" },
    { name: "Purchase Return", value: "purchase_return" },
    { name: "Sales Return", value: "sales_return" },
  ];

  const [state, setState] = useState({
    invoiceType: { name: "Sales", value: "sales" },
    currentTab: "all",
  });

  const [dataList, setDataList] = useState({
    transactionList: [],
    previousTransactionList: [],
    selectedTransactionIDList: [],
  });

  const [page, setPage] = useState(1);

  const [data, setData] = useState({
    type: "all",
    search: "",
    totalItems: 0,
    selectedTransaction: null,
    selectedTransactionOneDetails: null,
    selectedTransactionTwoDetails: null,
    isPageOneVacant: true,
    isPageTwoVacant: true,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [loadingState, setLoadingState] = useState({
    isListLoading: false,
    isInvoicePrintLoading: false,
  });

  const handleDrawerOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClearState = () => {
    setDataList((prevState) => ({
      ...prevState,
      transactionList: [],
      previousTransactionList: [],
      selectedTransactionIDList: [],
    }));
    setPage(1);
    setData((prevState) => ({
      ...prevState,
      type: "all",
      search: "",
      totalItems: 0,
      selectedTransaction: null,
      selectedTransactionOneDetails: null,
      selectedTransactionTwoDetails: null,
      isPageOneVacant: true,
      isPageTwoVacant: true,
    }));
  };

  const handleInvoiceChange = (e, newValue) => {
    setState((prevState) => ({
      ...prevState,
      invoiceType: newValue,
    }));
    handleClearState();
  };

  const handleTabChange = (event, newValue) => {
    setData((prevState) => ({
      ...prevState,
      type: newValue,
    }));

    setState((prevState) => ({
      ...prevState,
      currentTab: newValue,
    }));
  };

  const handleTransactionSelection = (selectedItem, isMainTransaction) => {
    setData((prevState) => ({
      ...prevState,
      selectedTransaction: selectedItem,
    }));

    fetchPreviousTransactions(
      selectedItem.VoucherNo,
      state?.invoiceType?.value
    );

    if (isMainTransaction) {
      fetchTransactionDetails(selectedItem.ID);
    } else {
      fetchListTransactionDetails(selectedItem.ID);
    }
  };

  const handleTemplateClick = (template, id) => {
    setDataList((prevState) => ({
      ...prevState,
      selectedTransactionIDList: [id],
    }));
    if (template === "template_one") {
      setData((prevState) => ({
        ...prevState,
        isPageOneVacant: !prevState.isPageOneVacant,
        // selectedTransactionOneDetails: null,
      }));
    } else if (template === "template_two") {
      setData((prevState) => ({
        ...prevState,
        isPageTwoVacant: !prevState.isPageTwoVacant,
        // selectedTransactionTwoDetails: null,
      }));
    }
  };
  // ========================= api ===============================

  const fetchTransaction = async () => {
    const response = await activityLogHistoryAPi({
      company_id: CompanyID,
      branch_id: BranchID,
      invoice_type: state?.invoiceType?.value,
      type: data.type,
      current_page: page,
      items_per_page: 12,
    });

    if (response.StatusCode === 6000) {
      setDataList((prevState) => ({
        ...prevState,
        transactionList: response.data,
      }));

      setData((prevState) => ({
        ...prevState,
        totalItems: response.count,
      }));
    }
  };

  const fetchPreviousTransactions = async (InvoiceNo, InvoiceType) => {
    const response = await getInvoiceHistoryAPi({
      CompanyID: CompanyID,
      BranchID: BranchID,
      InvoiceNo: InvoiceNo,
      InvoiceType: InvoiceType,
    });
    if (response.StatusCode === 6000) {
      setDataList((prevState) => ({
        ...prevState,
        previousTransactionList: response.data,
      }));
    }
  };

  const fetchListTransactionDetails = async (salesMasterLogId) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      master_log_id: salesMasterLogId,
      invoice_type: state?.invoiceType?.value,
    };
    const response = await activityLogDetailedHistoryAPi(payload);

    if (response.StatusCode === 6000) {
      if (data.isPageOneVacant) {
        setData((prevState) => ({
          ...prevState,
          selectedTransactionOneDetails: response.data,
          isPageOneVacant: false,
        }));
      } else if (data.isPageTwoVacant) {
        setData((prevState) => ({
          ...prevState,
          selectedTransactionTwoDetails: response.data,
          isPageTwoVacant: false,
        }));
      }
      if (data.isPageOneVacant || data.isPageTwoVacant) {
        setDataList((prevState) => ({
          ...prevState,
          selectedTransactionIDList: [
            ...prevState.selectedTransactionIDList,
            response.data.ID,
          ],
        }));
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };

  const fetchTransactionDetails = async (salesMasterLogId) => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      master_log_id: salesMasterLogId,
      invoice_type: state?.invoiceType?.value,
    };
    const response = await activityLogDetailedHistoryAPi(payload);

    if (response.StatusCode === 6000) {
      setData((prevState) => ({
        ...prevState,
        selectedTransactionOneDetails: response.data,
        isPageOneVacant: false,
        selectedTransactionTwoDetails: null,
        isPageTwoVacant: true,
      }));
      setDataList((prevState) => ({
        ...prevState,
        selectedTransactionIDList: [response.data.ID],
      }));
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };

  // ===================== fetch data =========================

  const fetchData = () => {
    fetchTransaction();
  };

  // ===================== use effect =========================

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [state.currentTab, page, state.invoiceType]);

  return (
    <Paper
      elevation={0}
      sx={{ height: "100%", display: "flex", position: "relative" }}
    >
      <Stack
        sx={{
          minWidth: isSidebarOpen ? "300px" : "100px",
          height: "100%",
          borderRight: "2px solid #F5F5F5",
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s ease-in-out, opacity 0.3s ease",
          overflow: "hidden",
          opacity: isSidebarOpen ? 1 : 0.8,
        }}
      >
        <Box sx={{ width: "100%", p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isSidebarOpen && (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#001746",
                  width: "100%",
                }}
              >
                Activity Log
              </Typography>
            )}
            <IconButton
              onClick={handleDrawerOpen}
              sx={{
                transition: "transform 0.3s ease-in-out",
                transform: `rotate(${isSidebarOpen ? 0 : 180}deg)`,
              }}
            >
              <MenuOpenIcon />
            </IconButton>
          </Box>
          {/* {isSidebarOpen && ( */}
          <Box sx={{ pt: 2 }}>
            <ViknAutoComplete
              value={state.invoiceType}
              options={invoiceType}
              getOptionLabel={(option) => option.name}
              onChange={handleInvoiceChange}
            />
          </Box>
          {/* )} */}
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {isSidebarOpen ? (
            <Tabs
              value={state.currentTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab sx={{ textTransform: "none" }} label="All" value="all" />
              <Tab
                sx={{ textTransform: "none" }}
                label="Created"
                value="create"
              />
              <Tab
                sx={{ textTransform: "none" }}
                label="Deleted"
                value="delete"
              />
            </Tabs>
          ) : (
            <Tabs
              value={state.currentTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab sx={{ textTransform: "none" }} label="All" value="all" />{" "}
            </Tabs>
          )}
        </Box>

        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          <TableContainer
            component={Box}
            sx={{
              // maxHeight:'480px',
              height: "100%",
              overflow: "scroll",
              p: 0,
            }}
          >
            <Table aria-label="simple table">
              <TableBody>
                <LoadingList isLoading={loadingState.isListLoading}>
                  {dataList.transactionList.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          width: "10%",
                          height: "100%",
                        }}
                        onClick={() => handleTransactionSelection(item, true)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: isSidebarOpen
                              ? "space-between"
                              : "flex-end",
                            width: "100%",
                            textAlign: "left",
                            p: 1.5,
                          }}
                        >
                          {isSidebarOpen && (
                            <Box>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: 500 }}
                              >
                                {item.CustomerName}
                              </Typography>
                              <Typography
                                sx={{ fontSize: "12px", color: "#4B4B4B" }}
                              >
                                {moment(
                                  item.CreatedDate,
                                  "YYYY-MM-DDTHH:mm:ss.SSSSSS"
                                ).format("MMMM Do YYYY, h:mm:ss A")}
                              </Typography>
                            </Box>
                          )}

                          <Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "right",
                                fontWeight: "500",
                                color: "#0A9EF3",
                              }}
                            >
                              {item.VoucherNo}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "right",
                              }}
                            >
                              {item.CreatedUser}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </LoadingList>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {isSidebarOpen && (
          <Box
            sx={{
              px: 4,
              py: 1,
              borderTop: "2px solid #F5F5F5",
              borderRight: "2px solid #F5F5F5",
            }}
          >
            <Pagenation
              totalItem={data.totalItems}
              page_no={page}
              setPage_no={setPage}
            />
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
            py: 2,
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "inline-block",
                color: "#4B4B4B",
                fontSize: "15px",
                mr: 1,
              }}
            >
              INV -NO:
            </Typography>
            <Typography
              sx={{
                display: "inline-block",
                color: "#2F2F2F",
                fontSize: "15px",
                fontWeight: "bold",
                mr: 2,
              }}
            >
              {data.selectedTransaction?.VoucherNo}
            </Typography>
          </Box>
          <Typography
            sx={{
              border: 1,
              fontSize: "12px",
              borderRadius: "6px",
              py: "4px",
              px: 2,
            }}
          >
            Last Modified User: {data.selectedTransaction?.LastModifiedUser}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            gap: 2,
            py: 1,
            pl: 2,
            height: "82px",
          }}
        >
          {dataList.previousTransactionList.map((item, index) => (
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                },
              }}
              badgeContent={
                item.ID === data?.selectedTransactionOneDetails?.ID
                  ? 1
                  : item.ID === data?.selectedTransactionTwoDetails?.ID
                  ? 2
                  : null
              }
              color="primary"
            >
              <Button
                key={index}
                sx={{
                  p: 0,
                  color:
                    item.ActionCode === "C"
                      ? "#28A745"
                      : item.ActionCode === "E"
                      ? "#007BFF"
                      : "#DC3545",
                  bgcolor:
                    item.ActionCode === "C"
                      ? "rgba(0, 170, 52, 0.07)"
                      : item.ActionCode === "E"
                      ? "rgb(237,246,255)"
                      : "rgb(252,241,242)",
                  width: "50px",
                  height: "64px",
                }}
                disabled={dataList.selectedTransactionIDList.find(
                  (id) => id === item.ID
                )}
                onClick={() => handleTransactionSelection(item, false)}
              >
                {item.ActionCode}
              </Button>
            </Badge>
          ))}
        </Box>
        <Box sx={{ p: 2, height: "100%", display: "flex" }}>
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid item xs={6}>
              <Paper
                elevation={4}
                sx={{
                  height: "calc(100vh - 200px - 32px - 16px)",
                  overflowY: "scroll",
                  // borderRadius: 2,
                  border: data.isPageOneVacant ? "1px solid #0A9EF3" : "none",
                }}
                onClick={() =>
                  handleTemplateClick(
                    "template_one",
                    data?.selectedTransactionTwoDetails?.ID
                  )
                }
              >
                {data?.selectedTransactionOneDetails && (
                  <DetailTemplate
                    selectedTransaction={data.selectedTransactionOneDetails}
                    comparingTransaction={data.selectedTransactionTwoDetails}
                    color="#FAA5A5"
                    backgroundColor="#FEEBEB"
                    isTemplateOne={true}
                    invoiceType={state.invoiceType.value}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={6} sx={{ height: "100%" }}>
              <Paper
                elevation={4}
                sx={{
                  height: "calc(100vh - 200px - 32px - 16px)",
                  overflowY: "scroll",
                  // borderRadius: 2,
                  border: data.isPageTwoVacant ? "1px solid #0A9EF3" : "none",
                }}
                onClick={() =>
                  handleTemplateClick(
                    "template_two",
                    data?.selectedTransactionOneDetails?.ID
                  )
                }
              >
                {data?.selectedTransactionTwoDetails && (
                  <DetailTemplate
                    selectedTransaction={data.selectedTransactionTwoDetails}
                    comparingTransaction={data.selectedTransactionOneDetails}
                    color="#89E3C5"
                    backgroundColor="#E5F9F2"
                    isTemplateOne={false}
                    invoiceType={state.invoiceType.value}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}

export default ActivityLog;

const LoadingList = function ({ isLoading, children }) {
  return isLoading ? (
    <Stack sx={{ pl: "4px", gap: 1 }}>
      {Array.from({ length: 7 }, (_) => (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={60}
          sx={{
            width: Math.floor(Math.random() * 100 + 1) + "%",
            backgroundColor: "#DFF0FF",
          }}
        />
      ))}
    </Stack>
  ) : (
    children
  );
};
