import styled from '@emotion/styled';
import { Box, IconButton, Modal, Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import CreateExpense from './CreateExpense';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteExpenseApi } from '../../Api/Expense/ExpenceApis';

const VBExpense = ({
  data,
  setExpense,
  open,
  handleExpenseOpen,
  handleClose,
  isCreateExpense,
  handleExpenseClose,
  is_edit,
  Expense,
  is_purchaseExpense,
  CompanyID,
  BranchID,
  user_id,
  fetchData,
  setState,
  ReffBillNo,
  ForeignCurrency
  
}) => {
  const [value, setValue] = useState(0);
  const [expenseData, setExpenseData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [unqID, setUniqID] = useState(null);
  const [currentInvoiceNo, setCurrentInvoiceNo] = useState(); 
  const [currentVoucherNo, setCurrentVoucherNo] = useState(); 

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleEdit = (itemIndex) => {
    const updatedItem = Expense[itemIndex];
    if (updatedItem) {
      console.log(updatedItem, ">>log");
      setUniqID(updatedItem.id);
      setUpdateData(updatedItem);
      handleExpenseOpen(updatedItem.id, true);    
    }
  };





  const handleDelete = async (id, index) => {
    console.log(Expense, "indexindex");
    let newExe = Expense.map((e, i) => {
      if (i > index) {
        let newValue = Number(e.VoucherNo.replace("EX", ""))
        // console.log(--newValue);
        return {...e, VoucherNo: "EX"+(--newValue)}
      } else{ 
        return e
      }
    })
    console.log(newExe);
    
    try {
      if (is_edit) {
        const payload = {
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          IdList: [id],
        };
  
        const responseData = await DeleteExpenseApi(payload);
  
        if (responseData?.StatusCode === 6000) {
          console.log("Deleted item with id:", id);
          setExpense((prev) =>
            id
              ? prev.filter((item) => item.id !== id) 
              : prev.filter((_, i) => i !== index) 
          );
        } else {
          console.error(
            "Failed to delete item:",
            responseData?.message || "Unknown error"
          );
          setExpense(() =>
            id
              ? newExe.filter((item) => item.id !== id) 
              : newExe.filter((_, i) => i !== index) 
          );
        }
      } else {
        setExpense(() => newExe.filter((_, i) => i !== index)); 
        setCurrentVoucherNo(Expense[Expense.length-1].VoucherNo)
        setCurrentInvoiceNo(Expense[Expense.length-1].InvoiceNo)
        console.log(newExe,"VoucherNohh")
      
      }
    } catch (error) {
      console.error("Error deleting item:", error.message || error);
    }
  };
  
  
  useEffect(() => {
    if (is_edit && Expense.length > 0) {
      setExpense([...Expense]); 
    }
    if (Expense.length > 0) {
      setState((prev) => ({
        ...prev,
        InDomesticCurrency: Expense?.reduce((sum, item) => sum + parseFloat(item.GrandTotal), 0),
        InForeignCurrency : Expense?.reduce((sum, item) => sum + parseFloat(item.GrandTotal), 0) / ForeignCurrency
      }))
    }
  }, [is_purchaseExpense]);


  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {is_edit  ? (
        <Paper
          sx={{
            width: "calc(100% - 200px)",
            borderRadius: "15px",
            height: "calc(100% - 150px)",
            position: "relative",
            overflow:'scroll',
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {/* Render the CreateExpense component or other UI based on the state */}
          <div style={{ display: isCreateExpense ? "block" : "none" }}>
            <CreateExpense
              isCreateExpense={isCreateExpense}
              handleExpenseClose={handleExpenseClose}
              expenseData={expenseData}
              setExpenseData={setExpenseData}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setExpense={setExpense}
              updateData={updateData}
              is_purchaseExpense = {is_purchaseExpense}
              unqID={unqID}
              fetchDataPurchase={fetchData}
              ReffBillNo = {ReffBillNo}
              currentInvoiceNo={currentInvoiceNo}
              setCurrentInvoiceNo = {setCurrentInvoiceNo}
              currentVoucherNo ={currentVoucherNo}
              setCurrentVoucherNo = {setCurrentVoucherNo}
            />
          </div>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              overflow:"scroll",
             
              display: isCreateExpense ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Expenses" sx={{ width: "288px", textTransform: "none" }} {...a11yProps(0)} />
            </Tabs>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
              <div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    color: "#0A9EF3",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpenseOpen}
                >
                  + Add New
                </span>
              </div>
              <div>
                <IconButton sx={{ color: "#0A9EF3" }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </Box>

          <Box sx={{  display: isCreateExpense ? "none" : "flex",justifyContent:"start",height:"700px",minHeight:"400px" ,overflow:"scroll" , scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" },}}>
            <CustomTabPanel value={value} index={0}>
              <MainDiv>
                {Expense?.length ? (
                  Expense.map((item, index) => (
                    <StyledDiv key={index}>
                      {/* First section: Voucher Information */}
                      <StyledSubDiv>
                        <VoucherText>#Voucher No: <StyleSpan>{item.VoucherNo}</StyleSpan></VoucherText>
                        <SupplierText> <StyleSpan>{item.AccountLedger?.LedgerName || item?.CustomerName}</StyleSpan></SupplierText>
                      </StyledSubDiv>

                      {/* Second section: Amount Information */}
                      <StyledSubDiv>
                        <StyledText>Total</StyledText>
                        {/* <StyledText>SAR <StyleSpan>{item.Total || '0.00'}</StyleSpan></StyledText> */}
                        <StyledText>Amount <StyleSpan>{item.GrandTotal || '0.00'}</StyleSpan></StyledText>
                      </StyledSubDiv>

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <IconButton onClick={() => handleEdit(index)}>
                          <EditIcon style={{ color: "#0A9EF3" }} />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(item.id,index)}>
                          <DeleteIcon style={{ color: "#FF0000" }} />
                        </IconButton>
                      </div>
                    </StyledDiv>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </MainDiv>
            </CustomTabPanel>
          </Box>

          <Box sx={{ display: isCreateExpense ? "none" : "flex" }}>
            <FooterData>
              <FooterSub>
                <StyledText>Total</StyledText>
                <StyledText><StyleSpan>{Expense?.reduce((sum, item) => sum + (parseFloat(item.GrandTotal) || 0), 0).toFixed(2)}</StyleSpan></StyledText>
              </FooterSub>
            </FooterData>
          </Box>
        </Paper>
      ) : (
        <Paper
            sx={{
              width: "calc(100% - 200px)",
              minHeight: "30rem",
              borderRadius: "15px",
              height: "calc(100% - 200px)",
              overflow: "scroll",
              position: "fixed",
              scrollbarWidth: "none", // For Firefox
              "&::-webkit-scrollbar": {
                display: "none", // For Chrome, Safari, Edge
              },
            }}
          >
          <div style={{ display: isCreateExpense ? "block" : "none" }}>
            <CreateExpense
              isCreateExpense={isCreateExpense}
              handleExpenseClose={handleExpenseClose}
              expenseData={expenseData}
              setExpenseData={setExpenseData}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setExpense={setExpense}
              is_purchaseExpense = {is_purchaseExpense}
              ReffBillNo = {ReffBillNo}
              fetchDataPurchase= {fetchData}
              currentInvoiceNo={currentInvoiceNo}
              setCurrentInvoiceNo = {setCurrentInvoiceNo}
              currentVoucherNo ={currentVoucherNo}
              setCurrentVoucherNo = {setCurrentVoucherNo}
            />
          </div>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 4,
              display: isCreateExpense ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Expenses" sx={{ width: "288px", textTransform: "none" }} {...a11yProps(0)} />
            </Tabs>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
              <div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    color: "#0A9EF3",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpenseOpen}
                >
                  + Add New
                </span>
              </div>
              <div>
                <IconButton sx={{ color: "#0A9EF3" }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </Box>

          <Box sx={{ display: isCreateExpense ? "none" : "flex" }}>
            <CustomTabPanel value={value} index={0}>
              <MainDiv>
                {Expense?.length ? (
                  Expense.map((item, index) => (
                    <StyledDiv key={index}>
                       <StyledSubDiv>
                        <VoucherText>#Voucher No: <StyleSpan>{item.VoucherNo}</StyleSpan></VoucherText>
                        <SupplierText><StyleSpan>{item.CustomerName || 'N/A'}</StyleSpan></SupplierText>
                      </StyledSubDiv>

                      <StyledSubDiv>
                        <StyledText>Total</StyledText>
                        {/* <StyledText>SAR <StyleSpan>{item.Total || '0.00'}</StyleSpan></StyledText> */}
                        <StyledText>Amount <StyleSpan>{item.GrandTotal || '0.00'}</StyleSpan></StyledText>
                      </StyledSubDiv>

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        {/* <IconButton onClick={() => handleEdit(index)}>
                          <EditIcon style={{ color: "#0A9EF3" }} />
                        </IconButton> */}
                        <IconButton onClick={() => handleDelete(item.id,index)}>
                          <DeleteIcon style={{ color: "#FF0000" }} />
                        </IconButton>
                      </div>
                    </StyledDiv>
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </MainDiv>
            </CustomTabPanel>
          </Box>

          <Box sx={{  display: isCreateExpense ? "none" : "flex",position:"re"}}>
            <FooterData>
              <FooterSub>
                <StyledText>Total</StyledText>
                <StyledText><StyleSpan>{Expense?.reduce((sum, item) => sum + (parseFloat(item.GrandTotal) || 0), 0).toFixed(2)}</StyleSpan></StyledText>
              </FooterSub>
            </FooterData>
          </Box>
        </Paper>
      )}
    </Modal>
  );
};

export default VBExpense;



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Styled components (you can customize these based on your design)

const MainDiv = styled.div`
  display: flex;
  gap:20px;
  flex-wrap:wrap;

`;
const StyledDiv = styled.div`
  height: 15rem;
  width: 25rem;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  border-radious:10px;

`;

const StyledSubDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
`;

const VoucherText = styled.text`
  margin-left: 20px;
`;

const SupplierText = styled.text`
  margin-left: 20px;
  color: #0a9ef3;
`;

const StyledText = styled.text`
  margin-left: 20px;
`;

const StyleSpan = styled.text`
font-weight:600;
`;


const FooterData =  styled.div`
height:100px;
position:absolute;
width:100%;
bottom:0;
border-top: solid 1px  #bcbbbb;

`

const FooterSub =  styled.div`

width:calc(100% - 80px);
display:flex;
justify-content:end;
align-items:center;
height:100%;

`