import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./style.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  List,
  ListItem,
  Modal,
  Paper,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import zIndex from "@mui/material/styles/zIndex";
import { Height } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../ReportTable/ReportTable";
import { ProductPaper, ProductRenderComponent } from "../CommonComponents";

const handleFocus = (e) => {
  e?.target?.select();
};

const ModalStyle = {
  position: "absolute",
  top: "55%",
  left: "calc(100% - 200px)",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  zIndex: 1000,
  height: "450px",
  borderRadius:"5px"
};

export function StyledTextField(props) {
  let {
    handleChanges,
    name,
    value,
    index,
    textFieldRef,
    autoFocus,
    numeric,
    PriceDecimalPoint,
  } = props;
  return (
    <TextField
      type={numeric ? "number" : "text"}
      className="StyledTextField "
      fullWidth
      label=""
      // id="fullWidth"
      name={name}
      value={
        numeric
          ? Number(Number(value || 0).toFixed(PriceDecimalPoint)) // Ensures precision and defaults to 0 if value is falsy
          : value
      }
      onChange={(e) => {
        handleChanges(e, index);
      }}
      onFocus={handleFocus}
      style={{ borderRadius: "0" }}
      sx={{ fontSize: "13px" }}
      focused
      autoFocus={autoFocus}
      ref={textFieldRef}
      InputProps={{
        inputProps: {
          style: { padding: 0, fontSize: "13px", paddingLeft: "10px" },
        },
      }}
    />
  );
}

export function StyledAutoComplete(props) {
  let {
    List,
    handleCompleteChanges,
    OptionName,
    textFieldRef,
    autoFocus,
    index,
    name,
    value,
    handleInputChange,
    disabled
  } = props;
  return (
    <Autocomplete
      freeSolo
      autoHighlight
      // id="free-solo-2-demo"
      disableClearable
      disabled={disabled}
      options={List}
      getOptionLabel={(option) => option[OptionName] || ""}
      onChange={(e, v) => {
        handleCompleteChanges(v, index, name);
      }}
      onInputChange={(e) => {
        handleInputChange(e, name,index);
      }}
      value={value ? value : null}
      name={name}
      renderInput={(params) => (
        <TextField
          // onFocus ={handleFocus}
          ref={textFieldRef}
          autoFocus={autoFocus}
          className="StyledTextField"
          {...params}
          label=""
          InputProps={{
            ...params.InputProps,
            type: "search",
            style: { padding: 0, fontSize: "13px" },
          }}
        />
      )}
    />
  );
}

export function ManualAutoComplete({ options, onSelect, placeholder }) {
  const [inputValue, setInputValue] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const listRef = React.useRef(null); // useRef hook for list visibility

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleListClick = (event) => {
    if (!listRef.current.contains(event.target)) {
      setIsOpen(false); // Close list on click outside
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setIsOpen(false);
    onSelect(option); // Pass selected option to parent component
  };

  React.useEffect(() => {
    const filterOptions = () => {
      if (inputValue) {
        const newFilteredOptions = options.filter((option) =>
          option.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        setFilteredOptions(newFilteredOptions);
      } else {
        setFilteredOptions([]);
      }
    };

    filterOptions();

    document.addEventListener("click", handleListClick); // Handle clicks outside

    return () => {
      document.removeEventListener("click", handleListClick);
    };
  }, [inputValue, options]); // Re-run useEffect on input change or options change

  return (
    <div className="autocomplete">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
      {isOpen && filteredOptions.length > 0 && (
        <ul ref={listRef} className="suggestions">
          {filteredOptions.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function ValueBox({
  index,
  column,
  value,
  ClickColumn,
  disabled,
  numeric,
  PriceDecimalPoint,
  openSnackbar,
  missingMasterMandatory,
}) {
  const dispatch = useDispatch();
  return (
    <div
      className="myclass"
      onClick={() => {
        if (disabled !== true) {
          ClickColumn(index, column.id);
        } else {
          if (missingMasterMandatory) {
            dispatch(
              openSnackbar({
                open: true,
                message: missingMasterMandatory + " " + "not selected",
                severity: "error",
              })
            );
          }
        }
      }}
      style={{ cursor: disabled ? "" : "pointer" }}
    >
      <div className="myclass2">
        {/* <p className="myclass3 textClass"> */}
        <p
          className={
            column.dataKey === "index"
              ? "indexClass myclass3 textClass"
              : " myclass3 textClass"
          }
          style={{
            // whiteSpace: "nowrap",
            overFlow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "290px", // Team Lead
            height: "17 px",
            lineHeight: "22px",
          }}
        >
          {/* index = {index},column = {column.id}{" "} */}
          {numeric
            ? Number(value ? value : "").toFixed(PriceDecimalPoint)
            : value
            ? value
            : ""}
        </p>
      </div>
    </div>
  );
}

export function DeleteButton({ index, RemoveLine }) {
  return (
    <div style={{ textAlign: "center" }} onClick={() => RemoveLine(index)}>
      <DeleteIcon sx={{ cursor: "pointer", width: "20px" }} />
    </div>
  );
}

export function CustomizeTableModal({
  open,
  setOpen,
  columns,
  ChangeTableCustomization,
  tableSettingsList,
  focusSettingsList,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let divRef = null;

  const handleClickOutside = (event) => {
    if (divRef && !divRef.contains(event.target)) {
      // console.log("Clicked outside of div");
      // Handle the click outside the div here
      handleClose();
    }
  };

  const setRef = (node) => {
    if (node) {
      divRef = node;
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  return (
    <div style={{ display: open ? null : "none" }} id="mymodal" ref={setRef}>
      <Box sx={ModalStyle}>
        {/* <Typography
          id="modal-modal-title"
          align="center"
          sx={{ fontSize: "15px", margin: 0 }}
        >
          Customize Table
        </Typography> */}
        <Typography variant="overline"  gutterBottom>
          <div >
            <span class="text" style={{ fontSize: "16px",
            fontWeight: "500", }}>
              Table Settings
            </span>
          </div>
        </Typography>
        <div
          style={{
            overflow: "scroll",
            scrollBehavior: "smooth",
            height: "160px",
            display:"grid",
            gridTemplateColumns: "auto auto",gap:5

          }}
        >
          {tableSettingsList.map((i) => (
            <div style={{ display: "flex"}}>
              
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <Checkbox
                  checked={i[i.dataKey]} // The checked prop determines whether this checkbox is checked.
                  onChange={() =>
                    ChangeTableCustomization("tableSettings", i.dataKey)
                  } // The onChange prop is a function that will be called when the checkbox's state changes.
                  // size="small" // The size prop determines the size of the checkbox. In this case, it is small.
                  disabled={i.dataKey === "Activity" || i.dataKey === "index"}
                  sx={{
                    padding: "2px",
                    margin: 0,
                    color: blue[400],
                    "&.Mui-checked": {
                      color: blue[400],
                    },
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                {i.dataKey}
              </Typography>
            </div>
          ))}
        </div>
        <Typography variant="overline"  >
          <div >
            {/* <span class="line"></span> */}
            <span class="text" style={{ fontSize: "16px",
            fontWeight: "500",}}>
              Focus Settings
            </span>
            {/* <span class="line"></span> */}
          </div>
        </Typography>
        <div
          style={{
            overflow: "scroll",
            scrollBehavior: "smooth",
            height: "160px",
            display:"grid",
            gridTemplateColumns: "auto auto",gap:5
          }}
        >
          {focusSettingsList.map((i) => (
            <div style={{ display: "flex" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <Checkbox
                  checked={i[i.dataKey]} // The checked prop determines whether this checkbox is checked.
                  onChange={() =>
                    ChangeTableCustomization("focusSettings", i.dataKey)
                  } // The onChange prop is a function that will be called when the checkbox's state changes.
                  // size="small" // The size prop determines the size of the checkbox. In this case, it is small.
                  disabled={i.dataKey === "Activity" || i.dataKey === "index"}
                  sx={{
                    padding: "2px",
                    margin: 0,
                    color: blue[400],
                    "&.Mui-checked": {
                      color: blue[400],
                    },
                    "& .MuiSvgIcon-root": { fontSize: "20px" },
                  }}
                />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                {i.dataKey}
              </Typography>
              
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
}

export function StyledProductAutoComplete(props) {
  let {
    handleCompleteChanges,
    OptionName,
    textFieldRef,
    autoFocus,
    index,
    name,
    value,
    handleInputChange,
    ProductColumns
  } = props;
  return (
    <Autocomplete
      freeSolo
      autoHighlight
      // id="free-solo-2-demo"
      disableClearable
      options={props.List}
      getOptionLabel={(option) => option[OptionName] || ""}
      onChange={(e, v) => {
        handleCompleteChanges(v, index, name);
      }}
      onInputChange={(e) => {
        handleInputChange(e, name);
      }}
      value={value ? value : null}
      name={name}
      PaperComponent={(props) => (
        <Paper
          {...props}
          style={{ height: "100%", overflow: "hidden", width: "1000px" }}
        >
          <ProductPaper data = {ProductColumns} />
          <List>{props.children}</List>
        </Paper>
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ProductRenderComponent data = {ProductColumns} option={option} />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          // onFocus ={handleFocus}
          ref={textFieldRef}
          autoFocus={autoFocus}
          className="StyledTextField"
          {...params}
          label=""
          InputProps={{
            ...params.InputProps,
            type: "search",
            style: { padding: 0, fontSize: "13px" },
          }}
        />
      )}
    />
  );
}
