import React, { Fragment, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Modal,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Card,
  Divider,
} from "@mui/material";
import { Icons } from "../../Assets/AssetLog";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import {
  StyledAutoComplete,
  StyledProductAutoComplete,
  StyledTextField,
  ValueBox,
} from "../../Components/TableComponents/TableComponents";
import { TableVirtuoso } from "react-virtuoso";
import AddIcon from "@mui/icons-material/Add";
import { SaveButton } from "../../Components/Utils/StyledButtons";
import ConfirmBox from "../../Components/Utils/ConfirmBox";

const columns = [
  {
    id: 1,
    width: 40,
    label: "SlNo",
    dataKey: "index",
    is_textBox: true,
    disabled: true,
    textAlign: "center",
    paddingLeft: "0px",
  },
  {
    id: 2,
    width: 120,
    label: "ProductCode",
    dataKey: "ProductCode",
    numeric: false,
    is_autoComplete: true,
    OptionName: "ProductCode",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce: true,
  },
  {
    id: 3,
    width: 80,
    label: "BarCode",
    dataKey: "BarCode",
    numeric: false,
    is_textBox: true,
    disabled: true,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 4,
    width: 400,
    label: "Product",
    dataKey: "Product",
    numeric: false,
    is_autoComplete: true,
    OptionName: "ProductName",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce: true,
  },
  {
    id: 5,
    width: 80,
    label: "Unit",
    dataKey: "Unit",
    numeric: false,
    is_autoComplete: true,
    OptionName: "UnitName",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce: false,
  },
  {
    id: 6,
    width: 80,
    label: "Qty",
    dataKey: "Qty",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 7,
    width: 120,
    label: "SerialNo",
    dataKey: "SerialNo",
    numeric: false,
    is_textBox: true,
    OptionName: "SerialNo",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce: false,
  },
  {
    id: 8,
    width: 120,
    label: "ItemCode",
    dataKey: "ItemCode",
    numeric: false,
    is_textBox: true,
    OptionName: "ItemCode",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce: false,
  },
  {
    id: 9,
    width: 120,
    label: "To",
    dataKey: "To",
    numeric: false,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 10,
    width: 40,
    label: "",
    dataKey: "Activity",
    numeric: true,
    is_delete_button: true,
    disabled: true,
    textAlign: "center",
    paddingLeft: "0px",
  },
];

const ShowColumn = (column, state, tableSettings, GeneralSettingsData) => {
  let data = true;
  data = tableSettings[column.dataKey];
  if (
    column.dataKey === "BatchCode" &&
    GeneralSettingsData.EnableProductBatchWise === false
  ) {
    data = false;
  }
  return data;
};

function fixedHeaderContent(
  index,
  column,
  state,
  setIsCustomize,
  tableSettings,
  GeneralSettingsData
) {
  return (
    <>
      {columns.map(
        (column) =>
          ShowColumn(column, state, tableSettings, GeneralSettingsData) && (
            <TableCell
              key={column.dataKey}
              variant="head"
              align={column.textAlign}
              style={{
                width: column.width,
                padding: "2px",
                paddingLeft: `${column.paddingLeft}`,
                height: "45px",
              }}
              sx={{
                backgroundColor: "#fff",
                fontSize: "13px",
                textTransform: "capitalize",
              }}
            >
              {column.label}
            </TableCell>
          )
      )}
    </>
  );
}

function rowContent(
  index,
  row,
  columns,
  state,
  handleChanges,
  handleCompleteChanges,
  CellValue,
  setCellValue,
  ClickColumn,
  textFieldRef,
  handleKeyDown,
  autoFocus,
  handleInputChange,
  tableSettings,
  RemoveLine,
  PriceDecimalPoint,
  dataState,
  Errors,
  missingMasterMandatory,
  openSnackbar,
  debouncedOnDetailsInputChange,
  GeneralSettingsData,
  handleSerialNoAdd
) {
  const ShowCell = (index, columnId) => {
    // return true
    if (index === CellValue.RowIndex && columnId === CellValue.ColumnId) {
      return true;
    } else {
      return false;
    }
  };

  const getValue = (index, row, column) => {
    let Value = null;
    if (column.dataKey === "index") {
      Value = index + 1;
    } else if (column.is_autoComplete === true) {
      let OptionName = column.OptionName;
      if (row[column.dataKey]) {
        Value = row[column.dataKey][OptionName];
      } else {
        Value = null;
      }
    } else {
      Value = row[column.dataKey];
    }
    return Value;
  };

  const get_AutoComplete_List = (index, row, column) => {
    let Data = [];
    if (column.dataKey === "Product") {
      Data = dataState["ProductList"];
    } else if (column.dataKey === "ProductCode") {
      Data = dataState["ProductList"];
    } else if (column.dataKey === "Unit") {
      Data = row["UnitList"];
    } else if (column.dataKey === "Tax") {
      Data = dataState.TaxCategoryData;
    } else if (column.dataKey === "BatchCode") {
      Data = row["Unit"]?.BatchList || [];
    }

    return Data;
  };

  return (
    <TableRow>
      {columns.map(
        (column, i) =>
          ShowColumn(column, state, tableSettings, GeneralSettingsData) && (
            <TableCell
              key={column.dataKey}
              sx={{
                align: "left",
                height: "17px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "0px",
                width: column.width || "auto",
                borderBottom:
                  Errors.error && Errors.indexList.includes(index)
                    ? "1px solid rgba(224, 224, 224, 1)"
                    : "none",
                borderColor:
                  Errors.error && Errors.indexList.includes(index)
                    ? "red"
                    : null,
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, index, column.id);
              }}
            >
              {column.is_textBox && ShowCell(index, column.id) ? (
                <StyledTextField
                  handleChanges={handleChanges}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                  index={index}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  numeric={column.numeric}
                  PriceDecimalPoint={PriceDecimalPoint}
                />
              ) : column.is_autoComplete &&
                ShowCell(index, column.id) &&
                (column.dataKey === "Product" ||
                  column.dataKey === "ProductCode") ? (
                <StyledProductAutoComplete
                  List={get_AutoComplete_List(index, row, column)}
                  handleCompleteChanges={handleCompleteChanges}
                  handleInputChange={
                    column.is_debounce
                      ? debouncedOnDetailsInputChange
                      : handleInputChange
                  }
                  OptionName={column.OptionName}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  index={index}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                  ProductColumns={[
                    {
                      name: "ProductCode",
                      value: "ProductCode",
                      width: "20%",
                      numeric: false,
                      permission:
                        GeneralSettingsData?.show_productcode_InsearchSale,
                    },
                    {
                      name: "ProductName",
                      value: "ProductName",
                      width: "30%",
                      numeric: false,
                      permission: true,
                    },
                    {
                      name: "BrandName",
                      value: "BrandName",
                      width: "20%",
                      numeric: false,
                      permission: GeneralSettingsData?.show_brand_InsearchSale,
                    },
                    {
                      name: "Stock",
                      value: "Stock",
                      width: "10%",
                      numeric: true,
                      permission: GeneralSettingsData?.show_stock_InsearchSale,
                    },
                    {
                      name: "PurchasePrice",
                      value: "PurchasePrice",
                      width: "10%",
                      numeric: true,
                      permission:
                        GeneralSettingsData?.show_purchasePrice_InsearchSale &&
                        GeneralSettingsData?.PurchasePriceInSales &&
                        GeneralSettingsData?.sales_purchase_price_perm,
                    },
                    {
                      name: "SalesPrice",
                      value: "SalesPrice",
                      width: "10%",
                      numeric: true,
                      permission: true,
                    },
                  ]}
                />
              ) : column.is_autoComplete && ShowCell(index, column.id) ? (
                <StyledAutoComplete
                  List={get_AutoComplete_List(index, row, column)}
                  handleCompleteChanges={handleCompleteChanges}
                  handleInputChange={
                    column.is_debounce
                      ? debouncedOnDetailsInputChange
                      : handleInputChange
                  }
                  OptionName={column.OptionName}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  index={index}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                />
              ) : column.is_delete_button ? (
                <IconButton onClick={() => handleSerialNoAdd(index)}>
                  <AddIcon index={index} />
                </IconButton>
              ) : (
                <ValueBox
                  index={index}
                  column={column}
                  value={getValue(index, row, column)}
                  ClickColumn={ClickColumn}
                  disabled={missingMasterMandatory ? true : column.disabled}
                  numeric={column.numeric}
                  PriceDecimalPoint={PriceDecimalPoint}
                  openSnackbar={openSnackbar}
                  missingMasterMandatory={missingMasterMandatory}
                />
              )}
            </TableCell>
          )
      )}
    </TableRow>
  );
}

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: "separate" }} />,
  TableHead: (props) => <TableHead {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
  TableRow: ({ item: _item, ...props }) => {
    return <Fragment {...props} />;
  },
};

const SerialNumberComponent = ({
  openSerial,
  handleOpenSerial,
  handleCloseSerial,
  DataList = [],
  setDataLists,
  handleChanges,
  handleCompleteChanges,
  handleInputChange,
  ChangeTableCustomization,
  RemoveLine,
  AddLine,
  PriceDecimalPoint,
  Errors,
  missingMasterMandatory,
  openSnackbar,
  debouncedOnDetailsInputChange,
  GeneralSettingsData,
  state,
  dataState,
  RemoveSerialNo,
  handleSerialNoAdd,
  handleSerialNumberSave,
}) => {
  const [t] = useTranslation("common");
  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Activity: true,
    SerialNo: true,
    ItemCode: true,
    To: true,
  });

  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: false,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Activity: true,
  });

  const [IsCustomize, setIsCustomize] = useState(false);
  const [CellValue, setCellValue] = useState({
    RowIndex: null,
    ColumnId: null,
  });
  const [autoFocus, setAutoFocus] = useState(false);
  const textFieldRef = useRef(null);
  const tableRef = useRef(null);
  const rows = DataList;
  const NowActiveColumns = columns.filter((col) => col.disabled === false); // Filter out disabled columns
  const columnsLength = NowActiveColumns.length + 2;

  const handleKeyDown = (event, index, columnId) => {
    let ColumnNewID = columnId;
    let indexNew = index;
    let columnIndex = columns.findIndex((col) => col.id === columnId);

    if (event.key === "Tab" || event.key === "Enter") {
      if (event.key === "Enter" && textFieldRef.current) {
        textFieldRef.current.focus();
      }

      if (event.key === "Enter") setAutoFocus(true);
      else setAutoFocus(false);

      let IsNextStep = false;

      while (!IsNextStep) {
        if (ColumnNewID === columnsLength) {
          ColumnNewID = 1;
          indexNew += 1;
          columnIndex = 0;
        } else {
          ColumnNewID += 1;
          columnIndex += 1;
        }

        IsNextStep =
          columns[columnIndex].disabled === false &&
          ShowColumn(
            columns[columnIndex],
            state,
            tableSettings,
            GeneralSettingsData
          );
      }

      setCellValue({ RowIndex: indexNew, ColumnId: ColumnNewID });

      // Auto scroll
      if (isMultipleOf(indexNew) && ColumnNewID === 2)
        tableRef.current.scrollToIndex(indexNew);

      // Add new line if last row
      if (indexNew + 1 === rows.length) {
        AddLine();
        tableRef.current.scrollToIndex(indexNew);
      }

      if (textFieldRef.current) textFieldRef.current.focus();
    }
  };

  const isMultipleOf = (n) => n % 9 === 0;

  const ShowColumn = (column, state, tableSettings, GeneralSettingsData) => {
    let data = true;
    data = tableSettings[column.dataKey];
    if (
      column.dataKey === "BatchCode" &&
      GeneralSettingsData.EnableProductBatchWise === false
    ) {
      data = false;
    }
    return data;
  };

  const ClickColumn = (index, columnId) => {
    setCellValue({ RowIndex: index, ColumnId: columnId });
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleScrollComplete = () => {};

  return (
    <>
      <Modal
        open={openSerial}
        onClose={handleCloseSerial}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "80vw",
            maxHeight: "70vh",
            margin: "auto",
            marginTop: 20,
            borderRadius: 2,
          }}
        >
          <Grid
            container
            sx={{
              p: 2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: "Poppins", fontWeight: 500, color: "#001746" }}
            >
              {t("Add Serial No & Item Code")}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="text"
                startIcon={<img src={Icons.Export} alt="export" />}
                sx={{ textTransform: "none", fontWeight: "400" }}
              >
                {t("Export")}
              </Button>
              <Button
                variant="text"
                startIcon={
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={Icons.Export}
                    alt="export"
                  />
                }
                sx={{ textTransform: "none", fontWeight: "400" }}
              >
                {t("Import")}
              </Button>
              <SaveButton t={t} onClick={handleSerialNumberSave} />
            </Box>
          </Grid>
          <Divider />
          <Paper
            style={{
              height: 400,
              width: "100%",
              padding: 5,
              boxShadow: "none",
            }}
          >
            <TableContainer
              component={Paper}
              style={{
                maxHeight: "400px", // Adjust the height as needed
                overflowY: "auto", // Enable vertical scrolling
                boxShadow: "none", // Preserve original styling
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {/* Table Head */}
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0, // Keeps the header fixed at the top
                    backgroundColor: "white", // Prevents header from blending with content
                    zIndex: 1, // Ensures the header stays above the body
                  }}
                >
                  <TableRow>
                    {columns.map((column, index) =>
                      ShowColumn(
                        column,
                        state,
                        tableSettings,
                        GeneralSettingsData
                      ) ? (
                        <TableCell
                          key={column.dataKey}
                          variant="head"
                          align={column.textAlign}
                          style={{
                            width: column.width,
                            padding: "2px",
                            paddingLeft: `${column.paddingLeft}`,
                            height: "45px",
                          }}
                          sx={{
                            backgroundColor: "#fff",
                            fontSize: "13px",
                            textTransform: "capitalize",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ) : null
                    )}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {rows.map((row, index) => {
                    // Generate main body content
                    const bodyContent = rowContent(
                      index,
                      row,
                      columns,
                      state,
                      handleChanges,
                      handleCompleteChanges,
                      CellValue,
                      setCellValue,
                      ClickColumn,
                      textFieldRef,
                      handleKeyDown,
                      autoFocus,
                      handleInputChange,
                      tableSettings,
                      RemoveLine,
                      PriceDecimalPoint,
                      dataState,
                      Errors,
                      missingMasterMandatory,
                      openSnackbar,
                      debouncedOnDetailsInputChange,
                      GeneralSettingsData,
                      handleSerialNoAdd
                    );

                    // Generate child content
                    const bodyChildContent =
                      row?.SerialNos?.length > 0 ? (
                        <TableRow>
                          <TableCell colSpan={columns.length}>
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(auto-fit, minmax(370px, 1fr))",
                                gap: 2,
                              }}
                            >
                              {row.SerialNos.map((item, idx) => (
                                <SerialNumberCard
                                  key={idx}
                                  data={item}
                                  index={index}
                                  RemoveSerialNo={RemoveSerialNo}
                                />
                              ))}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null;

                    // Return the main content with optional child content
                    return (
                      <React.Fragment key={index}>
                        {bodyContent}
                        {bodyChildContent}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            {/* <TableVirtuoso
              ref={tableRef}
              data={rows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={(index, column) => {
                const headContent = fixedHeaderContent(
                  index,
                  column,
                  state,
                  setIsCustomize,
                  tableSettings,
                  GeneralSettingsData
                );
                return <>{headContent}</>;
              }}
              onScrollComplete={handleScrollComplete}
              itemContent={(index, row) => {
                const bodyContent = rowContent(
                  index,
                  row,
                  columns,
                  state,
                  handleChanges,
                  handleCompleteChanges,
                  CellValue,
                  setCellValue,
                  ClickColumn,
                  textFieldRef,
                  handleKeyDown,
                  autoFocus,
                  handleInputChange,
                  tableSettings,
                  RemoveLine,
                  PriceDecimalPoint,
                  dataState,
                  Errors,
                  missingMasterMandatory,
                  openSnackbar,
                  debouncedOnDetailsInputChange,
                  GeneralSettingsData,
                  handleSerialNoAdd
                );
                const bodyChildContent = (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fit,minmax(370px, 1fr))",
                          gap: 2,
                        }}
                      >
                        {row?.SerialNos?.map((item, idx) => (
                          <SerialNumberCard
                            key={idx}
                            data={item}
                            index={index}
                            RemoveSerialNo={RemoveSerialNo}
                          />
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
                return (
                  <>
                    {bodyContent}
                    {row?.SerialNos?.length > 0 && row?.SerialNos && (
                      <>{bodyChildContent}</>
                    )}
                  </>
                );
              }}
            /> */}
          </Paper>
          {/* Add New Line */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 2,
            }}
          >
            <Button variant="text" onClick={AddLine} startIcon={<AddIcon />}>
              {t("Add Line")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SerialNumberComponent;

const SerialNumberCard = ({ data, index, RemoveSerialNo }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        gap: 1,
        px: 1,
        py: "2px",
        alignItems: "center",
        backgroundColor: "#F9F9F9",
        borderRadius: 3,
        width: "fit-content",
      }}
    >
      <Typography
        color="primary"
        sx={{
          fontSize: "14px",
          width: "150px",
          textAlign: "left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Serial No: <span style={{ color: "black" }}>{data.SerialNo}</span>
      </Typography>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography
        color="primary"
        sx={{
          fontSize: "14px",
          width: "150px",
          textAlign: "left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Item Code: <span style={{ color: "black" }}>{data.ItemCode}</span>
      </Typography>
      <IconButton
        color="primary"
        size="small"
        onClick={() => RemoveSerialNo(index, data.SerialNo)}
      >
        <ClearIcon />
      </IconButton>
    </Card>
  );
};
