import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, CircularProgress } from "@mui/material";
import { ImportExport as ImportExportIcon, Autorenew as AutorenewIcon, Api  } from "@mui/icons-material";
import { store } from "../../app/store";
import { BASE_URL } from "../../generalVeriable";
import { download_excel } from "../ReportTable/ReportTable";
import ExportMenu from "./ExportMenu";
import { handleCashLedgers } from "../../Function/comonFunction";
import { useSelector } from "react-redux";

const ExportToExcelButton = (props) => {
  const [t] = useTranslation("common");
  const [showLoader, setLoader] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id,access } = useSelector((state) => state.user);
  const PriceRounding = Number(PriceDecimalPoint);
  const state = store.getState();
  // const { lang } = state.appSettingsSclice;
  const { language} = useSelector((state) => state.language);
  const lang  = language

  const ExportToExcel = async (download_type,nul,is_celery,e) => {
    setLoader(true);
    let ProductGroupID = props.ProductGroupID;
    console.log(ProductGroupID);

    let BatchCode = props.BatchCode;
    let FromDate = props.FromDate;
    let ToDate = props.ToDate;
    let UserID = props.UserID;
    let WareHouseID = props.WareHouseID;
    let RouteID = props.RouteID;
    let CustomerID = props.CustomerID;
    let report_type = props.report_type;
    let ProductFilter = props.ProductFilter;
    let StockFilter = props.StockFilter;
    let ProductID = props.ProductID;
    let Barcode = props.Barcode;
    let CategoryID = props.CategoryID;
    let GroupID = props.GroupID;
    let BrandID = props.BrandID;
    let ManualOpeningBalance = props.ManualOpeningBalance;
    let is_manualOpening = props.is_manualOpening;
    let ManualOpeningStock = props.ManualOpeningStock;
    let ManualClosingStock = props.ManualClosingStock;
    // let download_type = props.download_type;
    let ReffNo = props.ReffNo;
    let key = props.tab;
    let VoucherType = props.VoucherType;
    let LedgerID = props.LedgerID;
    let CashLedgers = "";
    let DeviceId = props.DeviceId;
    let filtervalue = props.filtervalue;
    let costofgoodssold = props.costofgoodssold;
    let filterType = props.filtertype;
    if (props.CashLedgers) {
      CashLedgers = props.CashLedgers;
    }
    let columns = "";
    if (props.columns) {
      columns = JSON.stringify(props.columns);
    }

    let new_data = "";
    if (props.new_data) {
      new_data = JSON.stringify(props.new_data);
    }
    if(props.handleCashLedgers){
      CashLedgers = await handleCashLedgers(props.filterOptions,true)
    }
    console.log(download_type,'download_typedownload_type',report_type,key);
    
    let Api_Url = ''

    if (report_type === "faera_report" &&(download_type === "EXCEL" || StockFilter === "2")){
      Api_Url = `${ BASE_URL+"sales/faeraSummary-report-excel/"}?CompanyID=${CompanyID}&BranchID=${props.BranchID? props.BranchID:BranchID}&device_id=${DeviceId}&FromDate=${FromDate}&ToDate=${ToDate}&PriceRounding=${PriceRounding}&VoucherType=${VoucherType}&tabSwitch=${StockFilter}&UserID=${UserID}`;
    } else {
      Api_Url = `${BASE_URL+"settings/report-export-to-excel" }?PageType=${props.PageType}&VoucherType=${VoucherType}&LedgerID=${LedgerID}&ProductGroupID=${ProductGroupID}&BatchCode=${BatchCode}&new_data=${new_data}&download_type=${download_type}&columns=${columns}&lang=${lang}&CompanyID=${CompanyID}&BranchID=${props.BranchID?props.BranchID:BranchID}&FromDate=${FromDate}&ToDate=${ToDate}&PriceRounding=${PriceRounding}&UserID=${UserID}&ReffNo=${ReffNo}&WareHouseID=${WareHouseID}&ManualOpeningStock=${ManualOpeningStock}&ManualClosingStock=${ManualClosingStock}&key=${key}&RouteID=${RouteID}&CustomerID=${CustomerID}&report_type=${report_type}&ProductFilter=${ProductFilter}&StockFilter=${StockFilter}&ProductID=${ProductID}&Barcode=${Barcode}&CategoryID=${CategoryID}&GroupID=${GroupID}&BrandID=${BrandID}&ManualOpeningBalance=${ManualOpeningBalance}&is_manualOpening=${is_manualOpening}&CashLedgers=${CashLedgers}&filtervalue=${filtervalue}&costofgoodssold=${costofgoodssold}&filterType=${filterType}`;
    }
    if (Api_Url != ''){

    // }else{
      fetch(
        Api_Url,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      )
        .then(async (response) => {
          if (response.status === 200) {
            await download_excel(response.url);
            await setLoader(false);
          } else {
            console.log("ERROR");
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log("err");
          setLoader(false);
        });
      }
  };

  return (
  <ExportMenu
      // icon={<StyledImportExportIcon />}
      // label={t("Export")}
      // color="#000"
      // background="transparent"
      // onClick={() => ExportToExcel()}

      icon={showLoader ? <AutorenewIcon /> : <ImportExportIcon sx={{color:"black"}}/>}
      label={props.download_type === "PDF" ? t("PDF") : t("Excel")}
      color="#000"
      background="transparent"
      showLoader={showLoader}
      ExportTo={ExportToExcel}
      ExportToXLS={props.ExportToXLS}

      // onClick={() => {
      //   showLoader ? console.log("DESABLES") : ExportToExcel();
      // }}
    />
  );
};

export default ExportToExcelButton;

// const SIconButton = styled(IconButton)`
//   && {
//     color: #000;
//   }
// `;

// const StyledImportExportIcon = styled(ImportExportIcon)`
//   color: #000;
// `;

