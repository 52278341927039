import Routes from "../../Pages/Route/Route";

const RouteRoutes = [
  {
    path: "routes",
    element: <Routes/>,
    isAuth: true,
  },
];

export default RouteRoutes;